import { ProgramRouteTypeEnum } from '../../routing/routes';

import { useFetchProgramOrInstance } from './useFetchProgram';

/**
 * get a program depending
 * @param {string} programInstanceId to be fetched
 * @returns isLoading, isError, error, program, refetch
 */
export const useFetchProgramInstance = (programInstanceId: string = '') => {
  return useFetchProgramOrInstance(
    programInstanceId,
    ProgramRouteTypeEnum.INSTANCE
  );
};
