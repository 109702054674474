import { Box, Tab, Tabs, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  Link,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { AccountContext } from '../account';
import ErrorBoundary from '../components/ErrorBoundary';
import { AppHeaderContext } from '../layout/AppHeaderContext';
import { ProductTourManager } from '../producttour/ProductTourManager';
import { AppRoutes, ReceptionRoutesArray } from '../routing/routes';
import { NotOptimizedForMobile } from '../components/reception/NotOptimizedForMobile';

import { useReceptionTourPage } from './ReceptionPageTour/ReceptionPage.tour';

const receptionPageMessages = defineMessages({
  moduletab: {
    id: 'player.pages.reception.moduletab.header',
    defaultMessage: 'Chatbot Themen',
  },
  sessiontab: {
    id: 'player.pages.reception.sessiontab.header',
    defaultMessage: 'Chatbot Protokolle',
  },
  programtab: {
    id: 'player.pages.reception.programtab.header',
    defaultMessage: 'Coaching Programme',
  },
  loadingaccount: {
    id: 'player.pages.reception.loadaccount.hint',
    defaultMessage: 'Anmeldung läuft',
  },
  loadingaccount1: {
    id: 'player.pages.reception.loadaccount1.hint',
    defaultMessage:
      'Danke, dass du evoach benutzt. Anwendung wird gestartet ...',
  },
  loadingaccount2: {
    id: 'player.pages.reception.loadaccount2.hint',
    defaultMessage:
      'Heute scheint es etwas länger zu dauern. Vielen Dank für deine Geduld ...',
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component="span" sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const getTabProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const ReceptionPage: React.FC = () => {
  const intl = useIntl();

  const { tourid, toursteps } = useReceptionTourPage();

  const { isLoading, failureCount, account } = useContext(AccountContext);
  const { setAppHeaderTitle, setAppHeaderHomeroute } =
    useContext(AppHeaderContext);
  useEffect(() => {
    setAppHeaderHomeroute('/reception');
  });

  const history = useLocation();
  const navigate = useNavigate();

  let tabvalue = history.pathname ?? '/reception' + ReceptionRoutesArray[0];

  useEffect(() => {
    if (!account) return;

    if (tabvalue === '/reception' || tabvalue === '/login') {
      // PROD-1784
      if (
        (account &&
          (account.statistics?.numberOfProgramInstancePermissions ?? 0) ===
            0) ||
        account?.statistics?.numberOfProgramInstancePermissions === undefined
      ) {
        navigate('/reception' + ReceptionRoutesArray[1]);
      } else {
        navigate('/reception' + ReceptionRoutesArray[0]);
      }
    }
  }, [account, navigate, tabvalue]);

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    navigate(newValue);
  };

  useEffect(() => {
    setAppHeaderTitle(
      intl.formatMessage({
        id: 'player.pages.reception.headertitle',
        defaultMessage: 'Chatbot Player',
      })
    );
  });

  if (isLoading) {
    if (failureCount > 0 && failureCount <= 4)
      return (
        <Typography>
          {intl.formatMessage(receptionPageMessages.loadingaccount1)}
        </Typography>
      );

    if (failureCount > 4 && failureCount <= 6)
      return (
        <Typography>
          {intl.formatMessage(receptionPageMessages.loadingaccount2)}
        </Typography>
      );

    return (
      <Typography>
        {intl.formatMessage(receptionPageMessages.loadingaccount)}
      </Typography>
    );
  }

  const pendingInvitation = localStorage.getItem('evoachModuleInvitation');
  if (pendingInvitation && pendingInvitation !== '') {
    return <Navigate to={AppRoutes.INVITATION + '/pending'} />;
  }

  const pendingProgramInvitation = localStorage.getItem(
    'evoachProgrammInvitation'
  );
  if (pendingProgramInvitation && pendingProgramInvitation !== '') {
    return <Navigate to={AppRoutes.PROGRAMINVITATION + '/pending'} />;
  }

  return (
    <Box component="span" sx={{ width: '100%' }}>
      <ProductTourManager tourid={tourid} toursteps={toursteps} />
      <NotOptimizedForMobile />

      <Box component="span" sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={
            tabvalue === '/reception'
              ? '/reception' + ReceptionRoutesArray[0]
              : tabvalue
          }
          onChange={handleChange}
          aria-label="Reception"
        >
          <Tab
            value={'/reception' + ReceptionRoutesArray[0]}
            to={'/reception' + ReceptionRoutesArray[0]}
            component={Link}
            label={intl.formatMessage(receptionPageMessages.programtab)}
            {...getTabProps(0)}
            data-tut="reactour__programtab"
          />
          <Tab
            value={'/reception' + ReceptionRoutesArray[1]}
            to={'/reception' + ReceptionRoutesArray[1]}
            component={Link}
            label={intl.formatMessage(receptionPageMessages.moduletab)}
            {...getTabProps(1)}
            data-tut="reactour__moduletab"
          />
          <Tab
            value={'/reception' + ReceptionRoutesArray[2]}
            to={'/reception' + ReceptionRoutesArray[2]}
            component={Link}
            label={intl.formatMessage(receptionPageMessages.sessiontab)}
            {...getTabProps(2)}
            data-tut="reactour__sessiontab"
          />
        </Tabs>
      </Box>
      <ErrorBoundary>
        <TabPanel
          value={tabvalue}
          index={'/reception' + ReceptionRoutesArray[0]}
        />
        <TabPanel
          value={tabvalue}
          index={'/reception' + ReceptionRoutesArray[1]}
        />
        <TabPanel
          value={tabvalue}
          index={'/reception' + ReceptionRoutesArray[2]}
        />
      </ErrorBoundary>
      <Outlet />
    </Box>
  );
};
