import { MachineContext } from '@evoach/ui-components';

/**
 * @returns true
 */
export const sentimentGuard = (
  context: MachineContext,
  _event: any,
  testSentiment: string
): boolean => {
  const sentiment = context.userData['evoach.externalCall.sentimentValue'];
  // if there is no proper sentiment result, take NEUTRAL
  //console.log('sentiment: ', sentiment, testSentiment);
  if (!['POSITIVE', 'NEUTRAL', 'NEGATIVE'].includes(sentiment)) {
    return testSentiment === 'NEUTRAL';
  }
  // return true, if sentiment matches result, false otherwise
  return testSentiment === sentiment;
};

/**
 * @returns true if sentiment matches.
 * NEUTRAL is true if result does not match anything!
 */
export const sentimentPositiveGuard = (
  context: MachineContext,
  _event: any
): boolean => {
  return sentimentGuard(context, _event, 'POSITIVE');
};
export const sentimentNeutralGuard = (
  context: MachineContext,
  _event: any
): boolean => {
  return sentimentGuard(context, _event, 'NEUTRAL');
};
export const sentimentNegativeGuard = (
  context: MachineContext,
  _event: any
): boolean => {
  return sentimentGuard(context, _event, 'NEGATIVE');
};

/** classification guards */
export const sentimentGuards = {
  sentimentPositiveGuard,
  sentimentNeutralGuard,
  sentimentNegativeGuard,
};
