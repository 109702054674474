import { CardImageEnum } from '@evoach/ui-components';

import { Asset } from '../../entities/Asset';

import { CardImageList } from './CardImageList';

export enum AudioFileExtensions {
  wav = 'wav',
  wma = 'wma',
  mp3 = 'mp3',
  mp4 = 'mp4',
  m4a = 'm4a',
  aac = 'aac',
  ogg = 'ogg',
  webm = 'webm',
  flac = 'flac',
}

export enum VideoFileExtensions {
  webm = 'webm',
  mp4 = 'mp4',
  m4p = 'm4p',
  m4v = 'm4v',
  mpg = 'mpg',
  mpeg = 'mpeg',
  mpv = 'mpv',
  mp2 = 'mp2',
  avi = 'avi',
  mov = 'mov',
  qt = 'qt',
  wmv = 'wmv',
  mkv = 'mkv',
  aac = 'aac',
}

export enum ImageFileExtensions {
  jpg = 'jpg',
  jpeg = 'jpeg',
  gif = 'gif',
  png = 'png',
  bmp = 'bmp',
}
export enum AssetType {
  IMAGE = 'image',
  AUDIO = 'audio',
  VIDEO = 'video',
}

/**
 * return the file extension of the key in lower case
 * @param {string} key of the asset
 * @returns {string} file extension like 'jpg'
 */
export const getFileExtension = (fileName: string) => {
  if (fileName.split('.').length > 0) {
    return fileName.split('.').pop()!.toLowerCase();
  } else {
    return '';
  }
};

export const isAudioExtension = (extension: string): boolean => {
  return Object.values<string>(AudioFileExtensions).includes(extension);
};

export const isVideoExtension = (extension: string): boolean => {
  return Object.values<string>(VideoFileExtensions).includes(extension);
};

export const isImageExtension = (extension: string): boolean => {
  return Object.values<string>(ImageFileExtensions).includes(extension);
};

/**
 * add all files that are available in CardImageList which
 * is generated in Player by the script in generate.mjs
 *
 * @param {string} playerBasePath - get player base path from useEnvironment
 * @returns {Asset[]} additional assets
 */
export const getPublicAssets = (playerBasePath: string) => {
  const assets: Asset[] = [];

  CardImageList.forEach((filename: string) => {
    assets.push({
      asid: 0,
      assetId: '',
      isDefaultAsset: true,
      isPresent: true,
      key: filename, // filename!
      thumbnailUrl: `${playerBasePath}/assets/cardimages2/${filename}`,
      tscreated: Date(),
      tsupdated: Date(),
      account: undefined,
      metadata: {
        externalUrl: `${playerBasePath}/assets/cardimages2/${filename}`,
      },
    });
  });

  return assets;
};

/**
 * CardImageListParsed is a mapped helper list to map CardImageEnum to filenames
 */
const CardImageListParsed = CardImageList.map((filename: string) => {
  return filename
    .replace(' ', '_')
    .replace(' ', '_')
    .replace(' ', '_')
    .replace('.png', '')
    .replace('.jpg', '');
});

/**
 * map a CardImageEnum to a filename of a public asset
 *
 * @param {CardImageEnum} image to be mapped to a filename
 * @param {string} playerBasePath as defined in useEnvironment()
 * @returns
 */
export const mapImageToPublicAsset = (
  image: CardImageEnum,
  playerBasePath: string
) => {
  const imgAsString = image as string;
  const fileIndex = CardImageListParsed.findIndex((val) => val === imgAsString);
  return fileIndex > -1 && CardImageList[fileIndex]
    ? `${playerBasePath}/assets/cardimages2/${CardImageList[fileIndex]}`
    : '';
};
