import { MachineContext, translateWithFallback } from '@evoach/ui-components';
import { cloneDeep } from 'lodash';
import { IntlShape } from 'react-intl';
import { assign } from 'xstate';

import {
  authorizedGet,
  authorizedPost,
  unauthorizedGet,
  unauthorizedPost,
} from '../../api';
import {
  FINISH_MARKER,
  PromptData,
  PromptDataMicrochat,
  PromptEnum,
  PromptVariables,
} from '../../entities/ExternalServicesTypes';

import { getJsonFromAiResponse } from './actionsHelper';

//
//! ###################################################################
//
// initial render action to set up variables for external / async calls
//

/**
 * renderExternalWait is triggered by the state to retrieve an external URL
 * and wait for the result. This state immediatly redirects to the next one
 * but executes this render session first.
 * It initializes the values for the subsequent helper states that were
 * generated in moduleUtils in Creator
 *
 * Triggered as sendExternal in entry of state in SessionPlayer.tsx
 * See callInitialExternalUrl for variable handling
 *
 * @param {boolean} isPublicRoute - this has to be injected as it is only known in Player
 * @returns
 */
export const renderExternalWait = (intl: IntlShape) => {
  return assign((context: MachineContext, _event: any, actionMetadata) => {
    //console.log('===== renderExternalWait');
    const newContext = cloneDeep(context);
    newContext.widgetData = newContext.widgetData.filter(
      (element) => !element.temporary
    );

    // PROD-1966: free text prompt
    const payload = actionMetadata.action.payload;
    const overwritePrompt =
      payload.message && payload.message !== ''
        ? translateWithFallback(intl, payload.message, undefined, false)
        : undefined;

    let promptVariables: PromptVariables = payload.getStringValues
      ?.map((textVar: string) => {
        return { [textVar]: context.userData[textVar] };
      })
      .reduce((prev: any, curr: any) => ({ ...prev, ...curr }), {});

    // for AI Summary, we have additional params
    if (payload.getValuesFrom && payload.getValuesFrom.length > 0) {
      let textsToBeSummarized = '';
      let getValuesFrom: Array<string | Record<string, any>> = [];
      // get all texts from the variables and concatenate
      payload.getValuesFrom.forEach((textVar: string) => {
        textsToBeSummarized += context.userData[textVar] + '\n\n';
        getValuesFrom.push(context.userData[textVar]);
      });
      // add also maxSentences
      promptVariables = {
        ...promptVariables,
        maxSentences: payload.maxSentences,
        textsToBeSummarized: textsToBeSummarized,
        getValuesFrom: getValuesFrom, // add semantically independent version of var contents
        getValueFrom: payload?.getValueFrom, // add semantically independent version of var content
      };
      // variables are processed in openai.chatprompts.ts (summarize)
    }
    // save variables for external call handling and reset old values if
    // existing. Translate overwrite prompt if existing

    newContext.userData['evoach.externalCall.payload'] = {
      ...actionMetadata.action.payload,
      prompt:
        actionMetadata.action.payload.prompt &&
        actionMetadata.action.payload.prompt.trim() !== ''
          ? translateWithFallback(intl, actionMetadata.action.payload.prompt)
          : '',
      overwritePrompt: overwritePrompt,
      promptVariables: promptVariables,
      directedAgentMode: context.sessionData
        ? !!context.sessionData['evoach.directedAgentMode']
        : false,
      isPreview: context.sessionData
        ? !!context.sessionData['evoach.isPreview']
        : false,
    } as PromptData;

    // show wait indictaor as temporary element while waiting for the answer
    newContext.widgetData.push({
      type: 'threeDots',
      temporary: true,
      props: {},
    });
    // reset polling counter
    newContext.userData['evoach.externalCall.pollingCounter'] = 0;
    // reset task ID - very important => without that call, it would be
    // re-used and the memory functionality in backend would be used
    newContext.userData['evoach.externalCall.taskid'] = '';
    // reset classification
    newContext.userData[
      'evoach.externalCall.classificationKeyWithHighestValue'
    ] = undefined;
    newContext.userData['evoach.externalCall.classificationIndex'] = undefined;
    return newContext;
  });
};

//
//! ###################################################################
//
// New services for the initial call and the polling call.
//
// initially, the callInitialExternalUrl service is triggered and comes back with
// a taskid. After a wait state, the callPollExternalUrl service is called
// and uses the task id to poll whether there is already a result in backend.
//

/**
 * called as "src" in invoke state with suffix "_initialExternalCall"
 *
 * @param isPublicRoute
 * @param sessionId
 * @returns
 */

export const callInitialExternalUrl = async (context: MachineContext) => {
  if (!context.sessionData) return;
  const payload = context.userData['evoach.externalCall.payload'];
  //console.log('===== callInitialExternalUrl');
  //console.log(payload);
  const url = `/external/${payload.externalService}/prompt/${payload.promptType}?sessionid=${context.sessionData['evoach.sessionId']}`;

  //
  // build user input based on variables
  //
  const input =
    payload.getValueFrom !== undefined
      ? context.userData[payload.getValueFrom]
      : payload.getValuesFrom !== undefined
      ? payload.getValuesFrom
          .map((textVar: string) => context.userData[textVar])
          .join('\n')
      : undefined;
  //
  // Notes on variable handling. As the functionality evolved over time, variables
  // from the state machine are used in different ways. This is how they are passed_
  //
  // input: contaings the getValueFrom (single variable) => direyctly set from payload
  // promptVariables: contains the getValuesFrom (array of variables) => set in renderExternalWait (aka sendExternal)
  //
  const postPayload: PromptDataMicrochat = {
    ...payload,
    input: input,
  };

  const newTaskId = context.sessionData['evoach.isPublicModule']
    ? unauthorizedPost(
        url + `?sessionid=${context.sessionData['evoach.sessionId']}`,
        postPayload
      )
    : authorizedPost(url, postPayload);
  const response = await newTaskId();
  return await response.json();
};

/**
 * called as "src" in invoke state with suffix "_initialExternalCall"
 *
 * @param isPublicRoute
 * @param sessionId
 * @returns
 */

export const callPollExternalUrl = async (context: MachineContext) => {
  if (!context.sessionData) return;
  //console.log('===== callPollExternalUrl');
  //
  const taskid = context.userData['evoach.externalCall.taskid'];
  const url = `/external/${taskid}`;
  const newPolling = context.sessionData['evoach.isPublicModule']
    ? unauthorizedGet(url + `/${context.sessionData['evoach.sessionId']}`)
    : authorizedGet(url);
  const response = await newPolling();
  return await response.json();
};

/**
 * export services here for better readability of state machine init in SessionPlayer
 */
export const newExternalCallServices = {
  callPollExternalUrl: (context: MachineContext) =>
    callPollExternalUrl(context),
  callInitialExternalUrl: (context: MachineContext) =>
    callInitialExternalUrl(context),
};

//
//! ###################################################################
//
// Different actions that are hard coded in moduleUtils helper states
//

/**
 * savePollingResult is triggered in onDone of callPollExternalUrl service.
 * It gets the result in _event.data and stores it in the provided variablename.
 *
 * If we are coping with classificationKeys, the result is stored as JSON.
 * Furthermore, the key of the class with the highest value is stored.
 */
export const savePollingResult = assign(
  (context: MachineContext, _event: any) => {
    const payload = context.userData['evoach.externalCall.payload'];
    const newContext = cloneDeep(context);

    // classificationKeys contains the names of the keys in the JSON result of OpenAI
    const classificationKeys: string[] = payload.classificationKeys ?? [];

    // save result as string
    const resKey = payload.saveResultTo ?? 'evoach.externalCall.result';
    newContext.userData[resKey] = (_event.data.output as string).replaceAll(
      FINISH_MARKER,
      ''
    );
    // PROD-1993 - auto-set ai prop
    newContext.userData[resKey + '.isAi'] = true;

    // reset classification info as it is not needed here
    newContext.userData[
      'evoach.externalCall.classificationKeyWithHighestValue'
    ] = '';
    // reset sentiment value
    newContext.userData['evoach.externalCall.sentimentValue'] = '';

    //
    // first draft of classification, not provided as user component element any more
    // saved here to be backward compatible in case it is used in one of the old
    // experimental chatbots. May be deleted in future ( Mae, 04.08.2023 )
    //
    if (
      payload.promptType === PromptEnum.TOPICCLASSIFICATION &&
      classificationKeys.length > 0
    ) {
      // there is classiifcaiton Info ==> try to parse result
      // and find highest value
      const classificationResultAsString = _event.data.output;

      //
      // a classificationResult looks like this:
      /*
      {
        "Work/life balance": 0.9,
        "Career growth":  0.2,
        "Setting boundaries": 0.7,
        "Communication skills": 0.4,
        "Conflict": 0.3
        }
      */

      // try to parse result
      let resJson: Record<string, number> = {};
      try {
        resJson = JSON.parse(classificationResultAsString);
      } catch (_e: unknown) {
        // if parsing fails, return true if index = 0
        console.error(_e);
      }

      let keyWithHighestValue = '';
      let highestValue = 0;
      if (Object.keys(resJson).length > 0) {
        // find the result class with the highest value
        Object.keys(resJson).forEach((key: string) => {
          if ((resJson[key] as number) > highestValue) {
            highestValue = resJson[key];
            keyWithHighestValue = key;
          }
        });
      }

      // save result as JSON
      newContext.userData[
        payload.saveResultTo ?? 'evoach.externalCall.result'
      ] = resJson;
      // save name of key with highest value
      newContext.userData[
        'evoach.externalCall.classificationKeyWithHighestValue'
      ] = keyWithHighestValue;
    }

    //
    // New Generic Classification component
    //
    if (
      payload.promptType === PromptEnum.GENERIC_CLASSIFICATION &&
      classificationKeys.length > 0
    ) {
      let classificationIndex = 0;
      try {
        // TODO parse JSON from result in case there is sourrounding text
        // s. TypeChat
        const json = JSON.parse(getJsonFromAiResponse(_event.data.output));
        classificationIndex = json.index as number;
      } catch (reason: unknown) {
        console.error(reason);
      }

      newContext.userData['evoach.externalCall.classificationIndex'] =
        classificationIndex;
    }

    if (payload.promptType === PromptEnum.CLASSIFY_SENTIMENT) {
      // ! nodeType: nodeType: 'aiSentimentNodeStateEntry',promptType: PromptEnum.CLASSIFY_SENTIMENT,
      // save sentiment result
      const resString = (_event.data.output + '')
        .toUpperCase()
        .replaceAll('.', '')
        .replaceAll('"', '');

      newContext.userData['evoach.externalCall.sentimentValue'] = resString;
    }

    if (payload.promptType === PromptEnum.LISTPROMPT_SUBGOAL_SUGGESTIONS) {
      let resJson: Record<string, any> = {};
      try {
        const s = _event.data.output;
        const i = s.indexOf('[');

        resJson = JSON.parse(s.substring(i, s.length));
      } catch (_e: unknown) {
        // if parsing fails, return true if index = 0
        console.error(_e);
      }
      // save result as JSON
      newContext.userData[
        payload.saveResultTo ?? 'evoach.externalCall.result'
      ] = resJson;
    }

    return newContext;
  }
);

/**
 * increasePollingCounter is triggered in onError of callPollExternalUrl service.
 * It increases the polling counter by 1
 */
export const increasePollingCounter = assign(
  (context: MachineContext, _event: any) => {
    const newContext = cloneDeep(context);
    newContext.userData['evoach.externalCall.pollingCounter'] =
      (newContext.userData['evoach.externalCall.pollingCounter'] ?? 0) + 1;
    return newContext;
  }
);

/**
 * saveTaskId is triggered in onDone of callInitialExternalUrl service.
 * It gets the result in _event.data and stores the received taskid for future
 * calls of callPollExternalUrl service.
 */
export const saveTaskId = assign((context: MachineContext, _event: any) => {
  const newContext = cloneDeep(context);
  newContext.userData['evoach.externalCall.taskid'] = _event.data.taskid;
  return newContext;
});

/**
 * ! ###################################################################
 *
 * SampleStatesForExternalCalls
 *
 * The follwing JSON is not used for any purpose but serves as an example
 * to document the state, service and action sequence in a state sequence
 * from a real state machine
 *
 */
export const SampleStatesForExternalCalls = {
  s4veqckr: {
    stateKey: 's4veqckr',
    entry: [
      {
        version: '1',
        // initial render action that sets result for renderExternalWait (s. the file)
        type: 'sendExternal',
        temporary: false,
        payload: {
          // where comes the content for the paraphrasing from
          getValueFrom: 'concern',
          // where is the paraphrasing result written to
          saveResultTo: 'paraphrase',
        },
        getValueType: 0,
        saveResultType: 0,
        displayName: 'AI Paraphrasing',
        nodeType: 'paraphraseNodeStateEntry',
        nodeMiniMapColor: 'rgb(175, 132, 0)',
        handleOutCount: 1,
      },
      {
        type: 'setProgressPercent',
        payload: {
          progressPercent: 0,
        },
        displayName: 'Percentage',
        nodeType: 'setProgressPercentEntry',
      },
    ],
    on: {
      next: '30bfb54u',
    },
    after: [
      {
        delay: 0,
        // after the render action in entry above, immediatly proceed
        // to the next state that was automatically created
        target: 's4veqckr_initialExternalCall',
      },
    ],
    exit: [
      {
        type: 'renderExternalWait',
        payload: {
          saveResultTo: 'paraphrase',
          getValueFrom: 'concern',
        },
      },
    ],
  },
  s4veqckr_initialExternalCall: {
    // invoke call to handle external async calls for state transitions
    // here : create a task for an async call and get a task id
    invoke: {
      id: 's4veqckr_initialExternalCall',
      // initial service call callInitialExternalUrl (s. this file)
      src: 'callInitialExternalUrl',
      // if succesfill go to automatically created wait state
      // and save the result of callInitialExternalUrl service
      // with the action saveTaskId (s. this file)
      onDone: {
        target: 's4veqckr_waitForPolling',
        actions: 'saveTaskId',
      },
      onError: {
        target: '30bfb54u',
      },
    },
    stateKey: 's4veqckr_initialExternalCall',
  },
  s4veqckr_waitForPolling: {
    entry: [],
    after: [
      {
        // wait for 5 seconds and then automatically proceed to the next
        // automatically generated state
        delay: 5000,
        target: 's4veqckr_pollExternalCall',
      },
    ],
    stateKey: 's4veqckr_waitForPolling',
  },
  s4veqckr_pollExternalCall: {
    // invoke call to handle external async calls for state transitions
    // here : poll for result via task id
    invoke: {
      id: 's4veqckr_pollExternalCall',
      // initial service call callPollExternalUrl (s. this file)
      src: 'callPollExternalUrl',
      // if succesfill go next state in state machine that was
      // created by the user in Creator editor.
      // Save the result of callPollExternalUrl service
      // with the action savePollingResult (s. this file)
      // (e.g. paraphrasing output)
      onDone: {
        target: '30bfb54u',
        actions: 'savePollingResult',
      },
      // if polling fails, ....
      onError: {
        // ... jump back to wait state ...
        target: 's4veqckr_waitForPolling',
        // ... and increase polling counter with action
        // increasePollingCounter as defined in this file.
        actions: 'increasePollingCounter',
      },
    },
    stateKey: 's4veqckr_pollExternalCall',
  },
};
