import { Phase, translateWithFallback } from '@evoach/ui-components';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

interface ModuleMetadataContextProps {
  metadata: Record<string, any> | null;
  setMetadata: (metadata: any) => void;

  moduleTitle: string | null;
  moduleDuration: string | null;
  modulePhases: Phase[] | null;
}

export const ModuleMetadataContext =
  React.createContext<ModuleMetadataContextProps>({
    metadata: null,
    setMetadata: () => {},

    moduleTitle: null,
    moduleDuration: null,
    modulePhases: null,
  });

export type ModuleMetadataContextProviderProps = {
  children?: React.ReactNode;
};

export const ModuleMetadataContextProvider: React.FC<
  ModuleMetadataContextProviderProps
> = ({ children }) => {
  const intl = useIntl();

  const [metadata, setMetadata] =
    useState<ModuleMetadataContextProps['metadata']>(null);

  const moduleTitle = useMemo(() => {
    const title = metadata?.title
      ? translateWithFallback(intl, metadata?.title) // intl.formatMessage({ id: metadata?.title })
      : null;
    return title;
  }, [intl, metadata?.title]);

  const moduleDuration = useMemo(() => {
    return metadata?.duration
      ? translateWithFallback(intl, metadata?.duration) //intl.formatMessage({ id: metadata?.duration })
      : null;
  }, [intl, metadata?.duration]);

  const modulePhases = useMemo(() => {
    if (!metadata?.phases) {
      return null;
    }

    return metadata.phases.map(({ reference, text }: Phase) => ({
      reference: reference,
      text: translateWithFallback(intl, text), //intl.formatMessage({ id: text }),
    }));
  }, [intl, metadata?.phases]);

  return (
    <ModuleMetadataContext.Provider
      value={{
        metadata,
        setMetadata,

        moduleTitle,
        moduleDuration,
        modulePhases,
      }}
    >
      {children}
    </ModuleMetadataContext.Provider>
  );
};
