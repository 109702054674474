import {
  CardSize,
  CoachCard,
  EvoachButton,
  EvoachLinkify,
  formatDate,
  ShareSocials,
  URLIconEnum,
  VerticalTimeline,
  VerticalTimelineItem,
} from '@evoach/ui-components';
import { ArrowBack } from '@mui/icons-material';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Container,
  Fab,
  Grid,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useFetchAsset } from '../api';
import { getCoachData, useFetchProgram } from '../api/program/useFetchProgram';
import { ErrorAccordion } from '../components/ErrorAccordion';
import ErrorBoundary from '../components/ErrorBoundary';
import { mapImageToPublicAsset } from '../components/PropertiesAssets';
import { ProgramKeyVisual } from '../components/PublicPrograms/ProgramKeyVisual';
import ProgramKeyVisualModal from '../components/PublicPrograms/ProgramKeyVisualModal';
import { PublicProgramList } from '../components/PublicPrograms/PublicProgramList';
import { ProgramInstance } from '../entities';
import {
  KeyVisualPositionEnum,
  Program,
  ProgramTypeEnum,
} from '../entities/Program';
import { useEnvironment } from '../environment/useEnvironment';
import { AppHeaderContext } from '../layout/AppHeaderContext';
import { ProductTourManager } from '../producttour/ProductTourManager';
import { AppRoutes, PublicProgramRouteParams } from '../routing/routes';

import { useDisplayPublicProgramTour } from './DisplayPublicProgram.tour';

export const useKeyVisualUrl = (program: Program | ProgramInstance) => {
  const { asset: keyVisualAsset } = useFetchAsset(
    program?.program?.keyvisualassetid !== ''
      ? program?.program?.keyvisualassetid
      : ''
  );
  const resolvedAssetUrl = keyVisualAsset?.url ?? undefined;
  const externalUrl = program?.program?.keyvisualsrc ?? undefined;
  const url = resolvedAssetUrl ?? externalUrl;
  return url;
};

export const DisplayPublicProgram: React.FC = () => {
  const intl = useIntl();

  const navigate = useNavigate();
  const { playerBasePath } = useEnvironment();
  const { search } = useLocation();
  const { setAppHeaderTitle } = useContext(AppHeaderContext);
  const { programId, subprogramId } = useParams<PublicProgramRouteParams>();
  const {
    programinstance: program,
    isLoading,
    isError,
    error,
  } = useFetchProgram(programId);
  const { programinstance: subprogram, isLoading: subIsLoading } =
    useFetchProgram(subprogramId);
  const { givenname, familyname, email, profile } = getCoachData(
    program,
    isLoading
  );

  const keyVisualResolvedSrc = useKeyVisualUrl(program as Program);
  const [isKeyVisualLinkModalOpen, setIsKeyVisualLinkModalOpen] =
    useState(false);
  const isKeyVisualOnTop =
    program?.program?.keyvisualposition === undefined ||
    program?.program?.keyvisualposition === KeyVisualPositionEnum.TOP;

  const handleKeyVisualClick = () => {
    if (
      program.program?.keyvisuallinktext !== undefined &&
      program.program?.keyvisuallinktext !== ''
    ) {
      setIsKeyVisualLinkModalOpen(true);
    }
  };

  const { tourid, toursteps } = useDisplayPublicProgramTour();

  // if we run into a subprogram route, generate a back button
  const backlink = useMemo(() => {
    if (subprogramId) {
      return `${AppRoutes.PUBLICPROGRAM}/${programId}`;
    } else {
      return '';
    }
  }, [programId, subprogramId]);

  //
  // if query parameter producttour=1, enable producttour for coaches
  // who are interested in building their own product tour and need an
  // explanation, what a producttour is
  //
  // NOT YET IMMPLEMENTED, idea: producttour = 2 might be a different
  // product tour that targets customers of a coach that are interested who to
  // read the page
  //
  const enableProducttour = useMemo(() => {
    if (!search) return false;

    const query = new URLSearchParams(search);
    let retVal = false;
    query.forEach((val: string, key: string) => {
      if (key === 'producttour' && val === '1') {
        retVal = true;
      }
    });
    return retVal;
  }, [search]);

  // set header title to program title
  useEffect(() => {
    if (program && !isLoading && !isError) {
      setAppHeaderTitle(program.program.title);
      document.title = program.program.title;
    }
  }, [isError, isLoading, program, setAppHeaderTitle]);

  /**
   * timelineMapper prepares rendering of a timeline
   * used for programs and potential subprograms
   */
  const timelineMapper = useCallback(
    (program: Program) => {
      return program?.program.modules
        ? program?.program.modules.map((module) => {
            const timelineItem = {} as VerticalTimelineItem;
            timelineItem.title = module?.title;
            timelineItem.description = module?.description;
            timelineItem.duration = module?.duration;
            timelineItem.url = module?.actions[0].link;
            timelineItem.group = module?.group;
            timelineItem.cardSize = CardSize.SMALL;
            timelineItem.image = module?.image;
            timelineItem.resolvedSrc = module?.resolvedsrc
              ? module.resolvedsrc
              : module?.src
              ? module.src
              : mapImageToPublicAsset(module?.image, playerBasePath);
            timelineItem.urlIcon =
              URLIconEnum[
                module?.actions[0].linkType as keyof typeof URLIconEnum
              ];
            timelineItem.iconDescription = module?.actions[0].description;
            timelineItem.timelineText = module?.startDate
              ? formatDate(module?.startDate?.toString(), intl.locale)
              : '';
            timelineItem.id = module?.id;
            return timelineItem;
          })
        : [];
    },
    [intl.locale, playerBasePath]
  );

  // map ProgramModules to VerticalTimelineItem
  const timelineItems = useMemo(() => {
    return timelineMapper(program as Program);
  }, [timelineMapper, program]);

  // map SubProgramModules to VerticalTimelineItem
  const subProgramtimelineItems = useMemo(() => {
    return timelineMapper(subprogram as Program);
  }, [timelineMapper, subprogram]);

  if (isLoading) {
    return (
      <Typography>
        {intl.formatMessage({
          id: 'player.programs.public.isloading',
          defaultMessage: 'Programm wird geladen ...',
        })}
      </Typography>
    );
  }

  if (isError) {
    return (
      <ErrorAccordion
        readableErrorMessage="Error"
        error={error}
        additionalInfo={`useFetchProgram in DisplayPublicProgram with programId ${programId}`}
      />
    );
  }

  if (!isError && !isLoading) {
    //if (account) account.metainfos.profile = undefined;
    return (
      <ErrorBoundary>
        {enableProducttour && (
          <ProductTourManager tourid={tourid} toursteps={toursteps} />
        )}
        <Container>
          <Grid container spacing={2}>
            <Grid
              item
              xs={2}
              sx={{
                paddingBottom: '20px',
              }}
            ></Grid>
            <Grid
              item
              xs={10}
              sx={{
                paddingBottom: '20px',
              }}
              data-tut="reactour__programtitle"
            >
              <Box width="88%">
                {keyVisualResolvedSrc && isKeyVisualOnTop && (
                  <ProgramKeyVisual
                    src={keyVisualResolvedSrc}
                    handleClick={handleKeyVisualClick}
                  />
                )}
                <Typography
                  sx={{ marginBottom: '20px' }}
                  variant="h5"
                  fontWeight={400}
                  whiteSpace="pre-wrap"
                >
                  {program.program.title}
                </Typography>
                <Typography
                  variant="body1"
                  whiteSpace="pre-wrap"
                  textAlign="justify"
                >
                  <EvoachLinkify>{program.program.description}</EvoachLinkify>
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                paddingBottom: '20px',
              }}
            ></Grid>
            <Grid
              item
              xs={10}
              sx={{
                paddingBottom: '20px',
              }}
            >
              <Box width="88%">
                {profile ? (
                  <CoachCard
                    coachName={givenname + ' ' + familyname}
                    coachDescription={profile.description}
                    coachAvatar={profile.profilePictureResolvedUrl ?? ''}
                    coachLogo={profile.logoPictureResolvedUrl}
                    linkedInLink={profile.linkedinProfileUrl}
                    coachMail={
                      profile.coachingMail && profile.coachingMail !== ''
                        ? profile.coachingMail
                        : email
                    }
                    coachWebsite={profile.websiteUrl}
                    instagramLink={profile.instagramProfileUrl}
                    youtubeLink={profile.youtubeProfileUrl}
                    twitterLink={profile.twitterProfileUrl}
                  />
                ) : (
                  <Typography variant="body2">
                    {intl.formatMessage({
                      id: 'player.programs.public.coach',
                      defaultMessage: 'Dein Coach:',
                    })}{' '}
                    <Link
                      color="secondary"
                      sx={{ cursor: 'pointer' }}
                      href={`mailto:${email}`}
                    >
                      {givenname + ' ' + familyname}
                    </Link>
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                paddingBottom: '20px',
              }}
            ></Grid>
            <Grid
              item
              xs={10}
              sx={{
                paddingBottom: '20px',
              }}
            >
              <Box
                display="flex"
                flexDirection="row"
                data-tut="reacttour__languages"
              >
                <Box>
                  <Typography variant="body2" fontWeight={500}>
                    {intl.formatMessage({
                      id: 'player.programs.public.duration',
                      defaultMessage: 'Dauer:',
                    })}{' '}
                  </Typography>
                  <Typography variant="body2" fontWeight={500}>
                    {intl.formatMessage({
                      id: 'player.programs.public.languages',
                      defaultMessage: 'Sprache:',
                    })}{' '}
                  </Typography>
                </Box>
                <Box paddingLeft="2em">
                  <Typography variant="body2">
                    {program.program.duration}
                    <br />
                  </Typography>
                  <Typography variant="body2">
                    {program.program.language}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            {program.programtype ===
              ProgramTypeEnum.PROGRAM_WITHOUT_SUBMODULES && (
              <Grid
                item
                xs={12}
                sx={{
                  paddingBottom: '20px',
                }}
              >
                <ErrorBoundary>
                  <VerticalTimeline items={timelineItems} />
                  <Fab
                    sx={{ position: 'fixed', bottom: '2em', right: '2em' }}
                    color="secondary"
                    onClick={() =>
                      window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth',
                      })
                    }
                  >
                    <UpIcon />
                  </Fab>{' '}
                </ErrorBoundary>
              </Grid>
            )}
            {
              // Show list of subprograms
            }
            {program.programtype === ProgramTypeEnum.PROGRAM_WITH_SUBMODULES &&
              backlink === '' && (
                <>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      paddingBottom: '20px',
                    }}
                  ></Grid>
                  <Grid
                    item
                    xs={10}
                    sx={{
                      paddingBottom: '20px',
                    }}
                  >
                    <ErrorBoundary>
                      <Typography>
                        {intl.formatMessage({
                          id: 'player.programs.public.subprogram.listinfo',
                          defaultMessage:
                            'Dieses Program besteht aus einzelnen Programmmodulen. Du kannst die Details der Programmmodule einsehen, indem du auf "Öffnen" klickst.',
                        })}
                      </Typography>
                      <PublicProgramList
                        parentprogramid={(program as Program).programid}
                        programidlist={program.programmodules}
                      />
                    </ErrorBoundary>
                  </Grid>
                </>
              )}
            {
              // Show details of a single subprogram and a
              // back button to the list of subprograms
            }
            {program.programtype === ProgramTypeEnum.PROGRAM_WITH_SUBMODULES &&
              backlink !== '' &&
              !subIsLoading && (
                <>
                  <ErrorBoundary>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10}>
                      <Box width="88%">
                        <EvoachButton onClick={() => navigate(backlink)}>
                          <ArrowBack sx={{ marginRight: '0.5em' }} />
                          {intl.formatMessage({
                            id: 'player.programs.public.subprogram.backbutton',
                            defaultMessage: 'Zurück zur Programmübersicht',
                          })}
                        </EvoachButton>
                        <Typography
                          sx={{ marginBottom: '20px', marginTop: '20px' }}
                          variant="h5"
                          fontWeight={400}
                          whiteSpace="pre-wrap"
                        >
                          {subprogram.program.title}
                        </Typography>
                        <Typography
                          variant="body1"
                          whiteSpace="pre-wrap"
                          textAlign="justify"
                        >
                          {subprogram.program.description}
                        </Typography>

                        <Fab
                          sx={{
                            position: 'fixed',
                            bottom: '2em',
                            right: '2em',
                          }}
                          color="secondary"
                          onClick={() =>
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: 'smooth',
                            })
                          }
                        >
                          <UpIcon />
                        </Fab>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        paddingBottom: '20px',
                      }}
                    >
                      <VerticalTimeline items={subProgramtimelineItems} />
                    </Grid>
                  </ErrorBoundary>
                </>
              )}

            <Grid
              item
              xs={2}
              sx={{
                paddingBottom: '20px',
              }}
            ></Grid>
            <Grid
              item
              xs={10}
              sx={{
                paddingLeft: '0px !important',
                paddingBottom: '20px',
              }}
            >
              <Box marginTop="20px">
                {keyVisualResolvedSrc && !isKeyVisualOnTop && (
                  <ProgramKeyVisual
                    src={keyVisualResolvedSrc}
                    handleClick={handleKeyVisualClick}
                  />
                )}
              </Box>
              <Tooltip
                title={intl.formatMessage({
                  id: 'player.programs.public.socials.tooltip',
                  defaultMessage: 'Teile den Link auf diese Seite via:',
                })}
                arrow
                placement="top"
              >
                <Box>
                  <ShareSocials
                    shareTitle={intl.formatMessage({
                      id: 'player.programs.public.socials.tooltip',
                      defaultMessage: 'Teile den Link auf diese Seite via:',
                    })}
                    copyLinkText={intl.formatMessage({
                      id: 'player.programs.public.socials.copylink',
                      defaultMessage: 'Link',
                    })}
                    title={intl.formatMessage(
                      {
                        id: 'player.programs.public.socials',
                        defaultMessage:
                          'Schaue dir das Programm {programtitle} von {firstname} {lastname} an:',
                      },
                      {
                        programtitle: program.program.title,
                        firstname: givenname,
                        lastname: familyname,
                      }
                    )}
                    url={window.location.href}
                  />
                </Box>
              </Tooltip>
            </Grid>
          </Grid>
        </Container>
        <ProgramKeyVisualModal
          isOpen={isKeyVisualLinkModalOpen}
          text={program.program?.keyvisuallinktext}
          handleClose={() => setIsKeyVisualLinkModalOpen(false)}
        />
      </ErrorBoundary>
    );
  }

  return <></>;
};
