import {
  AdditionalResource,
  CardSize,
  CoachCard,
  EvoachButton,
  EvoachLinkify,
  forceDownload,
  formatDate,
  TimelineIconEnum,
  URLIconEnum,
  VerticalTimeline,
  VerticalTimelineItem,
} from '@evoach/ui-components';
import { ArrowBack } from '@mui/icons-material';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Container, Fab, Grid, Link, Typography } from '@mui/material';
import { tail } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { AccountContext } from '../account';
import { authorizedPost, resolveS3ApiCall } from '../api';
import { getCoachData } from '../api/program/useFetchProgram';
import { useFetchProgramInstance } from '../api/program/useFetchProgramInstance';
import { useFetchProgramInstanceSession } from '../api/program/useFetchProgramInstanceSession';
import { useUpdateProgramInstanceSession } from '../api/program/useUpdateProgramInstanceSession';
import { createNewSession } from '../components/actions/startNewSessionActions';
import { ErrorAccordion } from '../components/ErrorAccordion';
import ErrorBoundary from '../components/ErrorBoundary';
import { mapImageToPublicAsset } from '../components/PropertiesAssets';
import { ProgramKeyVisual } from '../components/PublicPrograms/ProgramKeyVisual';
import ProgramKeyVisualModal from '../components/PublicPrograms/ProgramKeyVisualModal';
import { ProgramList } from '../components/reception/ProgramList';
import { ProgramInstance, ProgramInstanceSession } from '../entities';
import {
  KeyVisualPositionEnum,
  ProgramModuleAction,
  ProgramModuleActionLinkTypeEnum,
  ProgramTypeEnum,
} from '../entities/Program';
import { useEnvironment } from '../environment/useEnvironment';
import { AppHeaderContext } from '../layout/AppHeaderContext';
import { AppRoutes, ProgramInstanceRouteParams } from '../routing/routes';

import { useKeyVisualUrl } from './DisplayPublicProgram';

export const ProgramInstancePage: React.FC = () => {
  const intl = useIntl();
  const { setAppHeaderTitle, setAppHeaderHomeroute } =
    useContext(AppHeaderContext);
  useEffect(() => {
    setAppHeaderHomeroute('/reception/programs');
  });
  const { account } = useContext(AccountContext);

  const { playerBasePath } = useEnvironment();

  let navigate = useNavigate();

  const { programInstanceId, subprogramInstanceId } =
    useParams<ProgramInstanceRouteParams>();
  // get information about the program
  const { programinstance, isLoading, isError, error } =
    useFetchProgramInstance(programInstanceId);
  // ... and its session ...
  const { sessionData } = useFetchProgramInstanceSession(programInstanceId);
  // ... allow updating the session ...
  const { mutate } = useUpdateProgramInstanceSession(programInstanceId);
  // ... and load subprogram infos if suitable
  const { programinstance: subprograminstance, isLoading: subIsLoading } =
    useFetchProgramInstance(subprogramInstanceId);
  const { sessionData: subSessionData } =
    useFetchProgramInstanceSession(subprogramInstanceId);
  const { mutate: subMutate } =
    useUpdateProgramInstanceSession(subprogramInstanceId);

  const [session, setSession] = useState<ProgramInstanceSession>();
  const [subSession, setSubSession] = useState<ProgramInstanceSession>();
  const [isKeyVisualLinkModalOpen, setIsKeyVisualLinkModalOpen] =
    useState(false);

  const { givenname, familyname, email, profile } = getCoachData(
    programinstance,
    isLoading,
    account
  );

  const isProgramKeyVisualOnTop =
    programinstance?.program?.keyvisualposition === undefined ||
    programinstance?.program?.keyvisualposition === KeyVisualPositionEnum.TOP;

  // const isSubProgramKeyVisualOnTop =
  //   subprograminstance?.program?.keyvisualposition === undefined ||
  //   subprograminstance?.program?.keyvisualposition ===
  //     KeyVisualPositionEnum.TOP;

  const keyVisualResolvedSrc = useKeyVisualUrl(
    programinstance as ProgramInstance
  );

  // if we run into a subprogram route, generate a back button
  const backlink = useMemo(() => {
    if (subprogramInstanceId) {
      return `${AppRoutes.PROGRAMINSTANCE}/${programInstanceId}`;
    } else {
      return '';
    }
  }, [programInstanceId, subprogramInstanceId]);

  useEffect(() => {
    setSession(sessionData);
  }, [sessionData]);

  useEffect(() => {
    setSubSession(subSessionData);
  }, [subSessionData]);

  // set header title to program title
  useEffect(() => {
    if (programinstance && !isLoading && !isError) {
      setAppHeaderTitle(programinstance.program.title);
      document.title = programinstance.program.title;
    }
  }, [isError, isLoading, programinstance, setAppHeaderTitle]);

  const openChatbot = useCallback(
    async (moduleid: string) => {
      const createModulePermissionURL = `/programinstance/${programInstanceId}/modulepermission/${moduleid}`;
      const grantPermission = authorizedPost(createModulePermissionURL);
      await grantPermission();

      const sessionId = await createNewSession({
        moduleid: moduleid,
        language: intl.locale,
        programInstanceId,
      });
      if (sessionId !== undefined) {
        window.open(`${AppRoutes.COACHING}/${sessionId}`, '_blank');
      }
    },
    [intl.locale, programInstanceId]
  );

  const downloadAsset = useCallback(async (assetId: string) => {
    const presignedUrl = await resolveS3ApiCall(assetId);
    forceDownload(presignedUrl);
  }, []);

  // if linkType is Chatbot, then open a chatbot, else assume it's an assetId
  // and try to download the asset
  const getActionCallback = useCallback(
    (
      linkType: ProgramModuleActionLinkTypeEnum,
      moduleId?: string,
      assetId?: string
    ) => {
      return linkType === ProgramModuleActionLinkTypeEnum.CHATBOT
        ? () => openChatbot(moduleId!)
        : assetId !== undefined
        ? () => downloadAsset(assetId)
        : undefined;
    },
    [downloadAsset, openChatbot]
  );

  /**
   * triggered in TimeLineDot in VerticalTimeline
   * @param {number} id
   * @param {boolean} isSub => true = timeline for a sub-program, false otherwise
   * @returns void
   */
  const handleToggleProgramModule = (id: number, isSub: boolean = false) => {
    // what kind of session will be handled
    const localSession = isSub ? subSession : session;
    // prepare session state for update
    let updatedSessionState = Object.assign({}, localSession);

    if (localSession !== undefined) {
      updatedSessionState.session.completedModules =
        localSession.session.completedModules ?? [];
      const isModuleCompleted =
        localSession.session.completedModules.includes(id);
      if (isModuleCompleted) {
        updatedSessionState.session.completedModules =
          localSession.session.completedModules.filter(
            (mid: number) => mid !== id
          );
      } else {
        updatedSessionState.session.completedModules.push(id);
      }
      if (isSub) {
        setSubSession(updatedSessionState);
        subMutate(localSession.session);
      } else {
        setSession(updatedSessionState);
        mutate(localSession.session);
      }
    } else {
      return;
    }
  };

  const displayTexts = {
    markAsCompleted: intl.formatMessage({
      id: 'player.programinstance.module.markAsComplete',
      defaultMessage: 'Als "Erledigt" markieren',
    }),
    markAsUncompleted: intl.formatMessage({
      id: 'player.programinstance.module.markAsUncompleted',
      defaultMessage: 'Als "Zu Erledigen" markieren',
    }),
    additionalResourceText: intl.formatMessage({
      id: 'player.programinstance.module.additionalResourceText',
      defaultMessage: 'Weitere Ressourcen',
    }),
    readOn: intl.formatMessage({
      id: 'player.programinstance.module.readOn',
      defaultMessage: 'Weiterlesen',
    }),
    showLess: intl.formatMessage({
      id: 'player.programinstance.module.showLess',
      defaultMessage: 'Weniger anzeigen',
    }),
    startChatbot: intl.formatMessage({
      id: 'player.programinstance.module.start.chatbot',
      defaultMessage: 'Chatbot starten',
    }),
  };

  const timelineMapper = useCallback(
    (
      programInstance: ProgramInstance,
      instanceSession: ProgramInstanceSession
    ): VerticalTimelineItem[] => {
      return programInstance?.program.modules.map((module: any) => {
        const timelineItem = {} as VerticalTimelineItem;
        timelineItem.id = module?.id;
        timelineItem.title = module?.title;
        timelineItem.description = module?.description;
        timelineItem.duration = module?.duration;
        timelineItem.group = module?.group;
        timelineItem.cardSize = CardSize.SMALL;
        timelineItem.additionalResources = tail(
          module.actions as ProgramModuleAction[]
        ).map((action: ProgramModuleAction) => {
          // additional resources are all actions, except for the first action,
          // which is considered the "module action"
          const additionalRessource = {
            link: action.link,
            linkType: action.linkType as unknown as URLIconEnum,
            description: action.description,
            actionCallback: getActionCallback(
              action.linkType,
              action.moduleid,
              action.assetid
            ),
          } as AdditionalResource;
          return additionalRessource;
        });
        timelineItem.image = module?.image;
        timelineItem.resolvedSrc = module?.resolvedsrc
          ? module.resolvedsrc
          : module?.src
          ? module.src
          : mapImageToPublicAsset(module?.image, playerBasePath);
        timelineItem.timelineIcon = instanceSession
          ? instanceSession.session.completedModules?.includes(module?.id)
            ? TimelineIconEnum.CHECKED
            : TimelineIconEnum.UNCHECKED
          : TimelineIconEnum.UNCHECKED;
        timelineItem.timelineText = module?.startDate
          ? formatDate(module?.startDate?.toString(), intl.locale)
          : '';
        if (module.actions.length > 0) {
          timelineItem.url = module?.actions[0].link;
          timelineItem.iconDescription = module?.actions[0].description;
          timelineItem.urlIcon =
            URLIconEnum[
              module?.actions[0].linkType as keyof typeof URLIconEnum
            ];
          timelineItem.actionCallback = getActionCallback(
            module.actions[0].linkType,
            module.actions[0].moduleid,
            module.actions[0].assetid
          );
        }
        return timelineItem;
      });
    },
    [intl.locale, playerBasePath, getActionCallback]
  );

  // map ProgramModules to VerticalTimelineItem
  const timelineItems = useMemo(() => {
    return session
      ? timelineMapper(programinstance as ProgramInstance, session)
      : [];
  }, [programinstance, session, timelineMapper]);

  // map ProgramModules to VerticalTimelineItem
  const subTimelineItems = useMemo(() => {
    return subSession
      ? timelineMapper(subprograminstance as ProgramInstance, subSession)
      : [];
  }, [subprograminstance, subSession, timelineMapper]);

  if (isLoading) {
    return (
      <Typography>
        {intl.formatMessage({
          id: 'player.programinstance.isloading',
          defaultMessage: 'Programm wird geladen ...',
        })}
      </Typography>
    );
  }

  if (isError) {
    return (
      <>
        <ErrorAccordion
          readableErrorMessage="Error"
          error={error}
          additionalInfo={`useFetchProgramInstance in ProgramInstancePage with programInstanceId ${programInstanceId}`}
        />
        <EvoachButton
          onClick={() => {
            navigate(`${AppRoutes.RECEPTION}/programs`);
          }}
          sx={{ marginTop: '30px' }}
        >
          {intl.formatMessage({
            id: 'player.pages.programinstancepage.error.backtolistbutton',
            defaultMessage: 'Zurück zur Liste deiner Coaching Programme',
          })}
        </EvoachButton>
      </>
    );
  }

  return (
    <ErrorBoundary>
      <Container>
        <Grid container spacing={2}>
          <Grid
            item
            xs={2}
            sx={{
              paddingBottom: '20px',
            }}
          ></Grid>
          <Grid
            item
            xs={10}
            sx={{
              paddingBottom: '20px',
            }}
          >
            <Box width="88%">
              {keyVisualResolvedSrc && isProgramKeyVisualOnTop && (
                <ProgramKeyVisual
                  src={keyVisualResolvedSrc}
                  handleClick={() => setIsKeyVisualLinkModalOpen(true)}
                />
              )}
              <Typography
                sx={{ marginBottom: '20px' }}
                variant="h5"
                fontWeight={400}
                whiteSpace="pre-wrap"
              >
                {programinstance.program.title}
              </Typography>
              <Typography
                variant="body1"
                whiteSpace="pre-wrap"
                textAlign="justify"
              >
                <EvoachLinkify>
                  {programinstance.program.description}
                </EvoachLinkify>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              paddingBottom: '20px',
            }}
          ></Grid>
          <Grid
            item
            xs={10}
            sx={{
              paddingBottom: '20px',
            }}
          >
            <Box width="88%">
              {profile ? (
                <CoachCard
                  coachName={givenname + ' ' + familyname}
                  coachDescription={profile.description}
                  coachAvatar={profile.profilePictureResolvedUrl ?? ''}
                  coachLogo={profile.logoPictureResolvedUrl}
                  linkedInLink={profile.linkedinProfileUrl}
                  coachMail={
                    profile.coachingMail && profile.coachingMail !== ''
                      ? profile.coachingMail
                      : email
                  }
                  coachWebsite={profile.websiteUrl}
                  instagramLink={profile.instagramProfileUrl}
                  youtubeLink={profile.youtubeProfileUrl}
                  twitterLink={profile.twitterProfileUrl}
                />
              ) : (
                <Typography variant="body2" fontWeight={500}>
                  {intl.formatMessage({
                    id: 'player.programinstancepage.coach',
                    defaultMessage: 'Dein Coach:',
                  })}{' '}
                  <Link
                    color="secondary"
                    sx={{ cursor: 'pointer' }}
                    href={`mailto:${email}`}
                  >
                    {givenname + ' ' + familyname}
                  </Link>
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid
            item
            xs={2}
            sx={{
              paddingBottom: '20px',
            }}
          ></Grid>
          <Grid
            item
            xs={10}
            sx={{
              paddingBottom: '20px',
            }}
          >
            <Box display="flex" flexDirection="row">
              <Box>
                <Typography variant="body2" fontWeight={500}>
                  {intl.formatMessage({
                    id: 'player.programs.public.duration',
                    defaultMessage: 'Dauer:',
                  })}{' '}
                </Typography>
                <Typography variant="body2" fontWeight={500}>
                  {intl.formatMessage({
                    id: 'player.programs.public.languages',
                    defaultMessage: 'Sprache:',
                  })}{' '}
                </Typography>
              </Box>
              <Box paddingLeft="2em">
                <Typography variant="body2">
                  {programinstance.program.duration}
                  <br />
                </Typography>
                <Typography variant="body2">
                  {programinstance.program.language}
                </Typography>
              </Box>
            </Box>
          </Grid>
          {programinstance.programtype ===
            ProgramTypeEnum.PROGRAM_WITHOUT_SUBMODULES && (
            <>
              <Grid
                item
                xs={12}
                sx={{
                  paddingBottom: '20px',
                }}
              >
                <ErrorBoundary>
                  <VerticalTimeline
                    dotClickHandler={(id: number) =>
                      handleToggleProgramModule(id, false)
                    }
                    items={timelineItems}
                    displayTexts={displayTexts}
                  />
                  <Fab
                    sx={{ position: 'fixed', bottom: '2em', right: '2em' }}
                    color="secondary"
                    onClick={() =>
                      window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth',
                      })
                    }
                  >
                    <UpIcon />
                  </Fab>
                </ErrorBoundary>
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  paddingBottom: '20px',
                }}
              ></Grid>
              <Grid
                item
                xs={11}
                sx={{
                  paddingBottom: '20px',
                }}
              >
                {keyVisualResolvedSrc && !isProgramKeyVisualOnTop && (
                  <ProgramKeyVisual
                    src={keyVisualResolvedSrc}
                    handleClick={() => setIsKeyVisualLinkModalOpen(true)}
                  />
                )}
              </Grid>
            </>
          )}
          {
            // Show list of subprograms
          }
          {programinstance.programtype ===
            ProgramTypeEnum.PROGRAM_WITH_SUBMODULES &&
            backlink === '' && (
              <>
                <Grid
                  item
                  xs={2}
                  sx={{
                    paddingBottom: '20px',
                  }}
                ></Grid>
                <Grid
                  item
                  xs={10}
                  sx={{
                    paddingBottom: '20px',
                  }}
                >
                  <ErrorBoundary>
                    <ProgramList
                      programidlist={programinstance.programmodules}
                      parentprogramid={programInstanceId}
                    />
                  </ErrorBoundary>
                  <Box marginTop="20px">
                    {keyVisualResolvedSrc && !isProgramKeyVisualOnTop && (
                      <ProgramKeyVisual
                        src={keyVisualResolvedSrc}
                        handleClick={() => setIsKeyVisualLinkModalOpen(true)}
                      />
                    )}
                  </Box>
                </Grid>
              </>
            )}
          {
            // Show details of a single subprogram and a
            // back button to the list of subprograms
          }
          {programinstance.programtype ===
            ProgramTypeEnum.PROGRAM_WITH_SUBMODULES &&
            backlink !== '' &&
            !subIsLoading && (
              <>
                <ErrorBoundary>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={10}>
                    <Box width="88%">
                      <EvoachButton onClick={() => navigate(backlink)}>
                        <ArrowBack sx={{ marginRight: '0.5em' }} />
                        {intl.formatMessage({
                          id: 'player.programinstancepage.subprogram.backbutton',
                          defaultMessage: 'Zurück zur Programmübersicht',
                        })}
                      </EvoachButton>
                      <Typography
                        sx={{ marginBottom: '20px', marginTop: '20px' }}
                        variant="h5"
                        fontWeight={400}
                        whiteSpace="pre-wrap"
                      >
                        {subprograminstance.program.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        whiteSpace="pre-wrap"
                        textAlign="justify"
                      >
                        {subprograminstance.program.description}
                      </Typography>

                      <Fab
                        sx={{ position: 'fixed', bottom: '2em', right: '2em' }}
                        color="secondary"
                        onClick={() =>
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth',
                          })
                        }
                      >
                        <UpIcon />
                      </Fab>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      paddingBottom: '20px',
                    }}
                  >
                    <VerticalTimeline
                      items={subTimelineItems}
                      dotClickHandler={(id: number) =>
                        handleToggleProgramModule(id, true)
                      }
                      displayTexts={displayTexts}
                    />
                  </Grid>
                </ErrorBoundary>
              </>
            )}
        </Grid>
      </Container>

      <ProgramKeyVisualModal
        isOpen={isKeyVisualLinkModalOpen}
        text={programinstance.program?.keyvisuallinktext}
        handleClose={() => setIsKeyVisualLinkModalOpen(false)}
      />
    </ErrorBoundary>
  );
};
