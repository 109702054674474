import React from 'react';
import { useIntl } from 'react-intl';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog,
} from '@mui/material';
import { EvoachButton } from '@evoach/ui-components';

import { AssetType } from './assetHelper';
import { AssetSelector } from './AssetSelector';

interface AssetSelectorDialogProps {
  isOpen: boolean;
  /**
   * Returns the selected asset
   * Recommended signature: (asset: Asset) => {}
   */
  onSelect?: Function;
  onClose: Function;
  /**
   * asset type to be filtered
   */
  assetType: AssetType;
}

export const AssetSelectorDialog: React.FC<AssetSelectorDialogProps> = ({
  isOpen,
  onSelect,
  onClose,
  assetType,
}) => {
  const intl = useIntl();

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
    >
      <DialogTitle id="alert-dialog-title">
        {intl.formatMessage({
          id: 'player.editor.assets.selectdialog.title',
          defaultMessage: 'Datei auswählen',
        })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" component="div">
          <AssetSelector assetType={assetType} onAssetSelect={onSelect} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <EvoachButton onClick={() => onClose()} autoFocus>
          {intl.formatMessage({
            id: 'player.editor.assets.selectdialog.button.close',
            defaultMessage: 'Schließen',
          })}
        </EvoachButton>
      </DialogActions>
    </Dialog>
  );
};
