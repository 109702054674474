import { useContext, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import MemoryIcon from '@mui/icons-material/Memory';
import ChatIcon from '@mui/icons-material/Chat';

import { AccountContext, RoleEnum } from '../../../account';
import { authorizedGet } from '../../../api';
import { WindowContext } from '../../../window/WindowContext';
import { ChatMessage, ExportTypeEnum } from '../../../entities/ExportTypes';

import { SessionMemoryDialog } from './SessionMemoryDialog';

interface SessionMemoryButtonProps {
  sessionid: string;
  disabled?: boolean;
}

export const SessionMemoryButton: React.FC<SessionMemoryButtonProps> = ({
  sessionid,
  disabled,
}) => {
  const intl = useIntl();
  const { alert } = useContext(WindowContext);
  const { hasRole } = useContext(AccountContext);

  const [showMemory, setShowMemory] = useState(false);
  const [messages, setMessages] = useState<ChatMessage[]>([]);

  const getExportasMemory = () => {
    return getExportasOpenAI(ExportTypeEnum.MEMORY);
  };
  const getExportasOpenAI = (type: ExportTypeEnum = ExportTypeEnum.OPENAI) => {
    if (sessionid) {
      const printCall = authorizedGet(`/session/${sessionid}/export/${type}`);

      printCall()
        .then((response: any) => {
          response
            .json()
            .then((_res: any) => {
              // success
              // console.log(_res);
              setMessages(_res.export);
              setShowMemory(true);
            })
            .catch((_reason: unknown) => {
              alert(
                intl.formatMessage({
                  id: 'player.exportsession.error',
                  defaultMessage:
                    'Dein Export konnte leider nicht erstellt werden. (1)',
                })
              );
              console.error(_reason);
            });
        })
        .catch((_reason: unknown) => {
          alert(
            intl.formatMessage({
              id: 'player.exportsession.error2',
              defaultMessage:
                'Dein Export konnte leider nicht erstellt werden. (2)',
            })
          );
          console.error(_reason);
        });
    }
  };

  // do not show for non-evoach and do not show on production
  if (!!disabled || !hasRole(RoleEnum.EVOACHADMIN)) {
    return null;
  }

  return (
    <strong>
      <Tooltip title="Transform session information into a OpenAI Chat format">
        <IconButton onClick={() => getExportasOpenAI()}>
          <ChatIcon color="secondary" fontSize="medium" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Load last AI memory (if existing, Directed Agent Mode only)">
        <IconButton onClick={() => getExportasMemory()}>
          <MemoryIcon color="secondary" fontSize="medium" />
        </IconButton>
      </Tooltip>
      <SessionMemoryDialog
        isOpen={showMemory}
        onClose={() => setShowMemory(false)}
        messages={messages}
      />
    </strong>
  );
};
