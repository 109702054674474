import { FormControl } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { EvoachMenuItem, EvoachSelect } from '../customMaterialComponents';

export interface LanguageSelectionProps {
  defaultLanguage: string;
  onLanguageChange: Function;
  availableLanguages: string[];
}

export const LanguageSelection: React.FC<LanguageSelectionProps> = ({
  defaultLanguage,
  onLanguageChange,
  availableLanguages,
}) => {
  const displayLocales = availableLanguages;

  const [valueFrom, setValueFrom] = useState<string>(defaultLanguage);

  useEffect(() => {
    onLanguageChange(valueFrom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (newlocale: string) => {
    setValueFrom(newlocale);
    onLanguageChange(newlocale);
  };

  return (
    <div
      style={{
        display: 'inline-flex',
        verticalAlign: 'middle',
        justifyContent: 'left',
        marginLeft: '20px',
      }}
    >
      <FormControl>
        <EvoachSelect
          value={valueFrom}
          onChange={(event) => handleChange(event.target.value as string)}
          sx={{ height: '30px' }}
        >
          {displayLocales.map((slocale: string, index: number) => (
            <EvoachMenuItem key={`languages_${index}`} value={slocale}>
              {slocale}
            </EvoachMenuItem>
          ))}
        </EvoachSelect>
      </FormControl>
    </div>
  );
};
