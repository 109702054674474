import { MachineContext, translateWithFallback } from '@evoach/ui-components';
import { cloneDeep } from 'lodash';
import { IntlShape } from 'react-intl';
import { assign } from 'xstate';

import {
  authorizedGet,
  authorizedPost,
  unauthorizedGet,
  unauthorizedPost,
} from '../../api';
import {
  FINISH_MARKER,
  PromptDataMicrochat,
} from '../../entities/ExternalServicesTypes';
import { getHtmlFormatter } from '../formatter/HtmlFormatter';

//
// ! ###################################################################
//
// render actions for a micro-chat
//

/**
 * renderInitMicrochat re-sets all variables to start a new microchat.
 * Furthermore, it renders 3 dots to indicate that the initial AI request
 * is already sent.
 */

export const renderInitMicrochat = (
  intl: IntlShape,
  isEvoachAdmin: boolean
) => {
  return assign((context: MachineContext, _event: any, actionMetadata) => {
    const newContext = cloneDeep(context);
    newContext.widgetData = newContext.widgetData.filter(
      (element) => !element.temporary
    );

    const payload = actionMetadata.action.payload;

    const overwritePrompt =
      payload.message && payload.message !== ''
        ? translateWithFallback(intl, payload.message, undefined, false)
        : undefined;

    let promptVariables: Record<string, string> = payload.getStringValues
      ?.map((textVar: string) => {
        return { [textVar]: context.userData[textVar] };
      })
      .reduce((prev: any, curr: any) => ({ ...prev, ...curr }), {});

    // add maxTurns to promptVariables
    if (payload.maxTurns !== undefined) {
      promptVariables = {
        ...promptVariables,
        maxTurns: payload.maxTurns.toString(),
      };
    }

    // save variables for external call handling and reset old values if
    // existing. Translate overwrite prompt if existing
    newContext.userData['evoach.microchat.payload'] = {
      ...actionMetadata.action.payload,
      language: context.sessionData
        ? context.sessionData['evoach.sessionLanguage']
        : 'en',
      overwritePrompt: overwritePrompt,
      promptVariables: promptVariables,
      richUi: isEvoachAdmin && !!payload.richUi,
      assetids: payload.assetids ?? [],
      directedAgentMode: context.sessionData
        ? !!context.sessionData['evoach.directedAgentMode']
        : false,
      isPreview: context.sessionData
        ? !!context.sessionData['evoach.isPreview']
        : false,
      persona:
        context.userData &&
        payload.getPersonaFrom &&
        payload.getPersonaFrom !== ''
          ? context.userData[payload.getPersonaFrom]
          : '',
    } as PromptDataMicrochat;

    // show three dots as we fire up the first call immediatly
    newContext.widgetData.push({
      type: 'threeDots',
      temporary: true,
      props: {},
    });

    // reset polling counter
    newContext.userData['evoach.microchat.pollingCounter'] = 0;
    // reset number of turns
    newContext.userData['evoach.microchat.conversationTurns'] = 0;
    // reset task id
    newContext.userData['evoach.microchat.taskid'] = '';
    // reset user input
    newContext.userData['evoach.microchat.userinput'] = '';
    return newContext;
  });
};

/**
 * renderExternalMicrochatInput - proxy for adding the message input with
 * transaltions to widget data. Actual state and on.next transition is defined
 * via helperStates (_nextInputMicrochat).
 *
 * We do not use the native renderMessageInput function because we have to
 * adapt the parameters, e.g., by applying translations.
 */
export const renderExternalMicrochatInput = (intl: IntlShape) => {
  return assign((context: MachineContext, _event: any, _actionMetadata) => {
    const newContext = cloneDeep(context);
    newContext.widgetData = newContext.widgetData.filter(
      (element) => !element.temporary
    );

    const payload = newContext.userData[
      'evoach.microchat.payload'
    ] as PromptDataMicrochat;

    // this is calculated in the backend and returned in the payload
    const uiComponent =
      newContext.userData['evoach.microchat.nextCoacheeInputUiComponent'];

    if (uiComponent === 'scaleInput') {
      // show message input
      newContext.widgetData.push({
        type: 'scaleInput',
        temporary: true,
        props: {
          saveResultTo: 'evoach.microchat.userinput',
          buttonText: translateWithFallback(intl, 'aiscaleinput.buttonText'),
          scaleName: '',
          keyTexts: undefined,
          scaleSize: 10,
        },
      });
    } else {
      // show message input
      newContext.widgetData.push({
        type: 'messageInput',
        temporary: true,
        props: {
          autoFocus: true,
          emoticons: payload?.emoticons ?? true,
          milliSeconds: 0,
          placeholderText: payload?.placeholderText
            ? translateWithFallback(intl, payload.placeholderText)
            : translateWithFallback(intl, 'aimessageinput.placeholdertext'),
          saveResultTo: 'evoach.microchat.userinput',
          rowCount: 3,
          defaultValue: '',
        },
      });
    }

    // one more conversational turn, increase counter
    newContext.userData['evoach.microchat.conversationTurns'] =
      (newContext.userData['evoach.microchat.conversationTurns'] ?? 0) + 1;

    return newContext;
  });
};

/**
 * renderExternalCoachMessage is a wrapper for the coach message renderer.
 * We do not use its original renderer renderCoachMessage as we have to
 * adapt the message before pushing it on widgetData. We remove potential
 * FINISH_MARKER from the string before printing.
 */
export const renderExternalCoachMessage = (intl: IntlShape) => {
  return assign((context: MachineContext, _event: any, _actionMetadata) => {
    const newContext = cloneDeep(context);
    newContext.widgetData = newContext.widgetData.filter(
      (element) => !element.temporary
    );

    // for helper text, which is gloablly set for all messages
    const payload = newContext.userData['evoach.microchat.payload'];

    // for showAiBagde which is generally set to true, but we
    // leave that code here to rememaber how actionMetadata works
    // in case we need it in future.
    //const payloadAction = actionMetadata.action.payload;

    // show message output
    newContext.widgetData.push({
      type: 'coachMessage',
      temporary: false,
      props: {
        showAiBadge: true,
        aiTooltipText:
          payload?.helperText && false
            ? translateWithFallback(intl, payload.helperText)
            : translateWithFallback(intl, 'aicoachmessage.aitooltip'),
        message: getHtmlFormatter(
          (newContext.userData['evoach.microchat.result'] ?? 'AI').replaceAll(
            FINISH_MARKER,
            ''
          )
        ),
      },
    });

    // increase progress percentage every second conversation turn
    // this may be not correct!
    const numberOfStates = newContext.metaData?.numberOfStates ?? 0;
    const maxTurns = payload.maxTurns ?? 10;

    const increasePerStep = Math.round(100 / (maxTurns + numberOfStates - 1));

    if (newContext.metaData.currentModuleProgressPercent === undefined) {
      newContext.metaData.currentModuleProgressPercent = 0;
    }
    // do not move percentage value larger then 100 %
    if (
      newContext.metaData.currentModuleProgressPercent <
      100 - increasePerStep
    ) {
      newContext.metaData.currentModuleProgressPercent += increasePerStep;
    }

    return newContext;
  });
};

//
//! ###################################################################
//
// New services for the initial call and the polling call.
//
// initially, the callInitialExternalUrl service is triggered and comes back with
// a taskid. After a wait state, the callPollExternalUrl service is called
// and uses the task id to poll whether there is already a result in backend.
//

/**
 * This initiates the next step in a chat and sends an AI call to the backend.
 * The task id remains stable and is used for polling later (as well as for
 * memory in backend)
 *
 * Called as "src" in invoke state with suffix "_initialExternalMicrochatCal
 */

export const callInitialMicrochatExternalUrl = async (
  context: MachineContext
) => {
  if (!context.sessionData) return;
  const payload = context.userData['evoach.microchat.payload'];

  // pass task id if existing. This is different to other external calls
  // as we can re-use the task id here. This again is necessary to manage
  // the memory of the conversation in backend
  const url = `/external/${payload.externalService}/prompt/${payload.promptType}?sessionid=${context.sessionData['evoach.sessionId']}`;

  const postPayload: PromptDataMicrochat = {
    ...payload,
    input: context.userData['evoach.microchat.userinput'] ?? '',
    taskid: context.userData['evoach.microchat.taskid'] ?? '',
  };

  const newExternalCall = context.sessionData['evoach.isPublicModule']
    ? unauthorizedPost(url, postPayload)
    : authorizedPost(url, postPayload);
  const response = await newExternalCall();
  return await response.json();
};

/**
 * Check for a result of the AI call that belongs to the task id passed.
 * The task id is kept stable during the whole chat for all calls as it
 * is used to reference the chat memory in backend.
 *
 * Called as "src" in invoke state with suffix "_initialExternalCall"
 */

export const callPollMicrochatExternalUrl = async (context: MachineContext) => {
  if (!context.sessionData) return;

  // task id
  const taskid = context.userData['evoach.microchat.taskid'] ?? '';

  const url = `/external/${taskid}`;
  const newPolling = context.sessionData['evoach.isPublicModule']
    ? unauthorizedGet(url + `/${context.sessionData['evoach.sessionId']}`)
    : authorizedGet(url);
  const response = await newPolling();
  return await response.json();
};

/**
 * export services here for better readability of state machine
 * init in SessionPlayer
 */
export const newExternalMicrochatCallServices = {
  callPollMicrochatExternalUrl: (context: MachineContext) =>
    callPollMicrochatExternalUrl(context),
  callInitialMicrochatExternalUrl: (context: MachineContext) =>
    callInitialMicrochatExternalUrl(context),
};

//
//! ###################################################################
//
// Different actions that are hard coded in moduleUtils helper states
//

/**
 * saveMicrochatPollingResult is triggered in onDone of callPollExternalMicrochatUrl
 * service. It gets the result in _event.data and stores it in the
 * provided variablename.
 */
export const saveMicrochatPollingResult = assign(
  (context: MachineContext, _event: any) => {
    const newContext = cloneDeep(context);
    // save result as string
    newContext.userData['evoach.microchat.result'] = _event.data.output;
    newContext.userData['evoach.microchat.nextCoacheeInputUiComponent'] =
      _event.data.uiComponent ?? 'messageInput';
    // PROD-1993 - auto-set ai prop => for future reference, not needed today
    //newContext.userData['evoach.microchat.result' + '.isAi'] = true;

    return newContext;
  }
);

/**
 * increaseMicrochatPollingCounter is triggered in onError of
 * callPollExternalUrl service. It increases the polling counter by 1
 */
export const increaseMicrochatPollingCounter = assign(
  (context: MachineContext, _event: any) => {
    const newContext = cloneDeep(context);
    newContext.userData['evoach.microchat.pollingCounter'] =
      (newContext.userData['evoach.microchat.pollingCounter'] ?? 0) + 1;
    // TODO check polling counter and exit if polling counter is > 6 (=1 min)?
    // TODO may be implemented with special state and guards
    return newContext;
  }
);

/**
 * saveMicrochatTaskId is triggered in onDone of callInitialMicrochatExternalUrl
 * service. It gets the result in _event.data and stores the received taskid
 * for future calls of callInitialMicrochatExternalUrl service.
 *
 * The task id is essential as it is used in backend to reference the
 * memory of a chat.
 */
export const saveMicrochatTaskId = assign(
  (context: MachineContext, _event: any) => {
    const newContext = cloneDeep(context);
    // if there was no task id, it is initialize there
    // if there already exists a task id, it is overwritten by itself.
    newContext.userData['evoach.microchat.taskid'] = _event.data.taskid;

    return newContext;
  }
);

/**
 * ! ###################################################################
 *
 * -- sample states for external micro chat calls
 *
 * The follwing JSON is not used for any purpose but serves as an example
 * to document the state, service and action sequence in a state sequence
 * from a real state machine.
 *
 * All these states are generated by the Creator as helper states. Only the
 * first state (ncx4pnx8) is represented by a component in editor, all other
 * states are generated and not visible in the editor.
 *
 */
export const SampleStatesForExternalMicrochatCalls = {
  ncx4pnx8: {
    stateKey: 'ncx4pnx8', // this is the state of the component in the statemachine
    entry: [
      {
        version: 1,
        type: 'renderInitMicrochat', // init vars of chat and show 3 dots
        temporary: false,
        payload: {
          externalService: '7f48a312-0004-40a0-b4bc-e5bc0320c0c9',
          promptType: 'microchatSupervisor',
          maxTurns: 10,
        },
        displayName: 'Micro-Chat (AI)',
        nodeType: 'aiMicrochatStateEntry',
        nodeMiniMapColor: 'rgb(175, 132, 0)',
        handleOutCount: 1,
        description: 'builder.nodes.aimicrochat.description',
      },
    ],
    exit: [],
    on: {
      next: '6pf9d50t',
    },
    after: [
      {
        delay: 0,
        // start the first AI call that triggers the initial question by the AI
        target: 'ncx4pnx8_initialMicrochatExternalCall',
      },
    ],
  },
  ncx4pnx8_initialMicrochatExternalCall: {
    invoke: {
      // start the call and put a new or an existing task in the queue
      id: 'ncx4pnx8_initialMicrochatExternalCall',
      // make an async call and return promise
      src: 'callInitialMicrochatExternalUrl',
      onDone: {
        target: 'ncx4pnx8_waitForMicrochatPolling', // poll for result
        // save the task id for further reference. The task id is kept stable
        // during the session. In backend, it it used to manage chat memory
        actions: 'saveMicrochatTaskId',
      },
      onError: {
        // in case of any error, proceed with classic state machine and leave AI loop
        target: '6pf9d50t',
      },
    },
    stateKey: 'ncx4pnx8_initialMicrochatExternalCall',
  },
  ncx4pnx8_waitForMicrochatPolling: {
    entry: [],
    after: [
      {
        delay: 3000, // wait three seconds
        // and then poll whether AI already has a result in DB
        target: 'ncx4pnx8_pollExternalMicrochatCall',
      },
    ],
    stateKey: 'ncx4pnx8_waitForMicrochatPolling',
  },
  ncx4pnx8_pollExternalMicrochatCall: {
    invoke: {
      // poll for result from AI by task id
      id: 'ncx4pnx8_pollExternalMicrochatCall',
      // async call for result. returns promise
      src: 'callPollMicrochatExternalUrl',
      onDone: {
        // if result is there: print it out
        target: 'ncx4pnx8_printAssistantOutput',
        // save result !
        actions: 'saveMicrochatPollingResult',
      },
      onError: {
        // if there is an error (e.g. 404 => AI result not yet present), wait another 3 seconds
        target: 'ncx4pnx8_waitForMicrochatPolling',
        // increase polling counter that may server as exit condition
        actions: 'increaseMicrochatPollingCounter',
      },
    },
    stateKey: 'ncx4pnx8_pollExternalMicrochatCall',
  },
  ncx4pnx8_printAssistantOutput: {
    entry: [
      {
        // show the result of the AI as coach message
        type: 'renderExternalCoachMessage',
        temporary: false,
        payload: {
          helperText: 'AI',
        },
        nodeType: 'coachMessageStateEntry',
        handleOutCount: 1,
      },
    ],
    after: {
      delay: 1000, // wait for 1 second
      // and proceed to check whether the chat ended
      target: 'ncx4pnx8__checkMicrochatEndCheck',
    },
    exit: [],
    stateKey: 'ncx4pnx8_printAssistantOutput',
  },
  ncx4pnx8__checkMicrochatEndCheck: {
    entry: [],
    always: [
      {
        // if the finish marker _FINISHED_ is found => leave the AI loop
        // and hand control back to the state machine
        cond: 'microchatFinishMarkerGuard',
        target: '6pf9d50t',
      },
      {
        // if the maximum number of conversation turns is reached => leave
        // AI loop and hand control back to the state machine
        cond: 'microchatMaxTurnsGuard',
        target: '6pf9d50t',
      },
      {
        // if neither finish marker is detected nor the max number of conversation
        // turns is reached, display message input and allow user to answer the
        // question of the AI
        cond: 'microchatNextTurnGuard',
        target: 'ncx4pnx8_nextInputMicrochat',
      },
    ],
    stateKey: 'ncx4pnx8__checkMicrochatEndCheck',
  },
  ncx4pnx8_nextInputMicrochat: {
    entry: [
      {
        // render a message input but with a special render function.
        // this is to handle translations of placeholder texsts
        type: 'renderExternalMicrochatInput',
        temporary: true,
        payload: {
          rowCount: 3,
          milliSeconds: 0,
          saveResultTo: 'evoach.microchat.userinput',
          emoticons: true,
        },
        nodeType: 'messageInputStateEntry',
      },
    ],
    on: {
      // if coachee sends the input, send it to backend and proceed chat
      // based on memory
      next: 'ncx4pnx8_initialMicrochatExternalCall',
      writeToContext: {
        actions: 'writeToContext',
      },
    },
    exit: [
      {
        // when exiting the input: print what coachee wrote in message input ...
        type: 'renderCoacheeMessage',
        temporary: false,
        payload: {
          getValueFrom: 'evoach.microchat.userinput',
        },
      },
      {
        // ... and show three dots while waiting for the next ai response.
        type: 'renderThreeDots',
        temporary: true,
        payload: {},
      },
    ],
    stateKey: 'ncx4pnx8_nextInputMicrochat',
  },
};
