import {
  CardImageEnum,
  ProductTourStepContent,
  ImageDisplay,
} from '@evoach/ui-components';
import { useContext } from 'react';
import { useIntl } from 'react-intl';

//import { ProductTourStep } from '../producttour/ProductTourStep';
import { AccountContext } from '../../account';
import { mapImageToPublicAsset } from '../../components/PropertiesAssets';
import { useEnvironment } from '../../environment/useEnvironment';
//import {ProductTourContext} from "../producttour/ProductTourContext";

/**
 *
 * Tours for the recption page.
 * Copy this file and rename the hook to use the hook in other pages.
 *
 * Usage:
 * In your component write
 *    const { tourid, toursteps } = useReceptionTourPage('<variant>');
 * to include a tour or its variant. For each variant, write an if-branch.
 * Then add
 *    <ProductTourManager tourid={tourid} toursteps={toursteps} />
 * as first component of your returned JSX in your component.
 *
 */
export const ReceptionEvoachTour = (receptionTourId: string) => {
  const intl = useIntl();
  const { name } = useContext(AccountContext);
  const { playerBasePath } = useEnvironment();
  //const { closeTour } = useContext(ProductTourContext);

  const largePopoverWidth = '580px';
  const narrowPopoverWidth = '390px';

  const tourId = receptionTourId;

  const moduleImage = intl.formatMessage({
    id: 'player.pages.reception.tour.welcome.2.image',
    defaultMessage: '/assets/ReceptionTour/moduleoverview_de.png',
  });

  const sessionImage = intl.formatMessage({
    id: 'player.pages.reception.tour.welcome.3.image',
    defaultMessage: '/assets/ReceptionTour/sessionoverview_de.png',
  });

  const programImage = intl.formatMessage({
    id: 'player.pages.reception.tour.welcome.4.image',
    defaultMessage: '/assets/ReceptionTour/programoverview_en.png',
  });

  return {
    tourid: tourId,
    toursteps: [
      {
        selector: '', // no selector = center on screen
        content: (
          <ProductTourStepContent
            displayWide={true}
            title={intl.formatMessage(
              {
                id: 'player.pages.reception.tour.welcome.1.title',
                defaultMessage: 'Willkommen bei evoach{name}!',
              },
              { name: name ? ', ' + name : '' }
            )}
            descriptionIllustrationSrc={mapImageToPublicAsset(
              CardImageEnum.powerful,
              playerBasePath
            )}
            texts={[
              intl.formatMessage({
                id: 'player.pages.reception.tour.welcome.1.description.1',
                defaultMessage: 'Schön, dass du dabei bist.',
              }),
              intl.formatMessage({
                id: 'player.pages.reception.tour.welcome.1.description.2',
                defaultMessage:
                  'Lass uns herausfinden, wie du dich hier bei evoach einfach zurechtfindest.',
              }),
            ]}
          />
        ),
        style: {
          maxWidth: largePopoverWidth, // override reactour default setting of max-width: "331px"
          width: largePopoverWidth, // standard width for large popover to keep it from growing
        },
      },
      {
        selector: '[data-tut="reactour__moduletab"]',
        content: (
          <>
            <ProductTourStepContent
              title={intl.formatMessage({
                id: 'player.pages.reception.tour.welcome.2.title',
                defaultMessage: 'Übersicht über die Chatbot Themen',
              })}
              texts={[
                intl.formatMessage({
                  id: 'player.pages.reception.tour.welcome.2.description',
                  defaultMessage:
                    'Hier findest du alle verfügbaren Coaching-Chatbots, egal ob du eine persönliche Coaching-Session vor- oder nachbereiten oder eines der Selbstcoaching- oder Selbstbewertungs-Chatbots starten möchtest.',
                }),
              ]}
            />
            <ImageDisplay
              assetid="receptionModuleOverview"
              resolvedsrc={moduleImage}
              src={moduleImage}
              width={380}
            />
          </>
        ),
        style: {
          maxWidth: narrowPopoverWidth,
        },
      },
      {
        selector: '[data-tut="reactour__sessiontab"]',
        content: (
          <>
            <ProductTourStepContent
              title={intl.formatMessage({
                id: 'player.pages.reception.tour.welcome.3.title',
                defaultMessage: 'Chatbot Historie',
              })}
              texts={[
                intl.formatMessage({
                  id: 'player.pages.reception.tour.welcome.3.description',
                  defaultMessage:
                    'Alle persönlichen Chatbot-Protokolle, die du in einem unserer Coaching Chatbots begonnen oder abgeschlosen hast, sind hier aufgelistet. Von hier aus kannst du Coaching Chatbot Sessions fortsetzen und Session-Protokolle mit dem Coach teilen, der dich dazu eingeladen hat.',
                }),
              ]}
            />
            <ImageDisplay
              assetid="receptionModuleOverview"
              resolvedsrc={sessionImage}
              src={sessionImage}
              width={380}
            />
          </>
        ),
        style: {
          maxWidth: narrowPopoverWidth,
        },
      },
      {
        selector: '[data-tut="reactour__programtab"]',
        content: (
          <>
            <ProductTourStepContent
              title={intl.formatMessage({
                id: 'player.pages.reception.tour.welcome.4.title',
                defaultMessage: 'Coaching Programme',
              })}
              texts={[
                intl.formatMessage({
                  id: 'player.pages.reception.tour.welcome.4.description.1',
                  defaultMessage:
                    'Hier findest du alle verfügbaren Coaching Programme, die dein Coach mit dir geteilt hat.',
                }),
              ]}
            />
            <ImageDisplay
              assetid="receptionModuleOverview"
              resolvedsrc={programImage}
              src={programImage}
              width={380}
            />
          </>
        ),
        style: {
          maxWidth: narrowPopoverWidth,
        },
      },
      {
        selector: '[data-tut="reactour__avatar"]',
        content: (
          <ProductTourStepContent
            displayWide={true}
            title={intl.formatMessage({
              id: 'player.pages.reception.tour.welcome.5.title',
              defaultMessage: 'Sprache ändern & FAQs',
            })}
            descriptionIllustrationSrc={mapImageToPublicAsset(
              CardImageEnum.excitement,
              playerBasePath
            )}
            texts={[
              intl.formatMessage({
                id: 'player.pages.reception.tour.welcome.5.description.1',
                defaultMessage:
                  'Klicke auf dein Profil, um die Anzeigesprache zu ändern. ',
              }),
              intl.formatMessage({
                id: 'player.pages.reception.tour.welcome.5.description.2',
                defaultMessage:
                  'Außerdem kannst du dort unsere FAQs und weitere Hilfen finden oder diese Produkttour neu starten.',
              }),
            ]}
          />
        ),
        style: {
          maxWidth: largePopoverWidth,
          width: largePopoverWidth,
        },
      },
      {
        selector: '',
        content: (
          <ProductTourStepContent
            displayWide={true}
            title={intl.formatMessage({
              id: 'player.pages.reception.tour.welcome.6.title',
              defaultMessage: 'Wir schützen deine Privatsphäre!',
            })}
            descriptionIllustrationSrc={mapImageToPublicAsset(
              CardImageEnum.h_safe,
              playerBasePath
            )}
            texts={[
              intl.formatMessage({
                id: 'player.pages.reception.tour.welcome.6.description.1',
                defaultMessage:
                  "Everything you work on here is visible only to you. It's for your self-reflection alone, and only you can share your content with others if you choose to do so. You can learn more about how we value your privacy by chatting with our onboarding chatbot and checking in with our data policy:",
              }),
              intl.formatMessage({
                id: 'player.pages.reception.tour.welcome.6.description.2',
                defaultMessage: 'https://www.evoach.com/privacy-policy',
              }),
            ]}
          />
        ),
        style: {
          maxWidth: largePopoverWidth,
          width: largePopoverWidth,
        },
      },
      {
        selector: '',
        content: (
          <ProductTourStepContent
            displayWide={true}
            title={intl.formatMessage({
              id: 'player.pages.reception.tour.welcome.7.title',
              defaultMessage: 'Wir starten mit deinem ersten Chat!',
            })}
            descriptionIllustrationSrc={mapImageToPublicAsset(
              CardImageEnum.success,
              playerBasePath
            )}
            texts={[
              intl.formatMessage({
                id: 'player.pages.reception.tour.welcome.7.description.1',
                defaultMessage:
                  'Lass uns gleich mit unserem Onboarding-Chatbot "Bevor du startest" loslegen.',
              }),
              intl.formatMessage({
                id: 'player.pages.reception.tour.welcome.7.description.2',
                defaultMessage:
                  'Du wirst unseren Coach-Avatar und unsere Coaching-Mindest kennenlernen und mehr darüber erfahren, was mit deinen Daten geschieht. Du wirst sehen, wie einfach es ist, mit unserem Chatbot zu sprechen.',
              }),
            ]}
          />
        ),
        style: {
          maxWidth: largePopoverWidth,
          width: largePopoverWidth,
        },
      },
    ],
  };
};
