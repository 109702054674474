import { MachineContext, translateWithFallback } from '@evoach/ui-components';
import { cloneDeep } from 'lodash';
import { IntlShape } from 'react-intl';
import { assign } from 'xstate';

import { authorizedPost } from '../../api';

/**
 * renderShareSession renders
 * 1) an immutable message from protectedTexts.json
 * 2) an editable, user-defined message
 * 3) yes/no buttons
 *
 * see PROD-1010
 */
export const renderShareSession = (intl: IntlShape) => {
  return assign((context: MachineContext, _event: any, actionMetadata) => {
    const newContext = cloneDeep(context);
    const payload = actionMetadata.action.payload;
    const editableCoachMessage = translateWithFallback(intl, payload.message);

    if (editableCoachMessage !== '') {
      newContext.widgetData.push({
        type: 'coachMessage',
        temporary: false,
        props: {
          message: editableCoachMessage,
        },
      });
    }

    newContext.widgetData.push({
      type: 'coachMessage',
      temporary: false,
      props: {
        message: translateWithFallback(intl, 'shareSession.notifyCoachee'),
      },
    });

    newContext.widgetData.push({
      type: 'yesNoButton',
      temporary: true,
      props: {
        yesButtonText: translateWithFallback(intl, 'shareSession.yes'),
        noButtonText: translateWithFallback(intl, 'shareSession.no'),
        saveResultTo: 'evoachechokey.sharingDecision',
      },
    });

    return newContext;
  });
};

//
// setShareSession is the action for the "Share Session" node in creator
// It performs a backend call to share the current session with the inviting
// coach (PROD-1010).
//
export const setShareSession = (isPublicRoute: boolean, sessionId: string) => {
  return assign((context: MachineContext, _event: any) => {
    const newContext = cloneDeep(context);
    // call backend for sharing session
    // /session/:sessionid/sharing
    const shareUrl = `/session/${sessionId}/sharing`;
    if (!isPublicRoute) {
      const shareSession = authorizedPost(shareUrl);
      shareSession()
        .then()
        .catch(() =>
          console.error('Sharing the session ' + sessionId + ' failed.')
        );
    }
    return newContext;
  });
};
