// This is the only place in the application where we use
// the standard "useKeycloak" directly.
// In all other places, the improved "useEvoachKeycloak" (which
// includes updated tokens) should be used.
// eslint-disable-next-line no-restricted-imports
import { useKeycloak } from '@react-keycloak/web';
import { KeycloakInstance } from 'keycloak-js';

import { keycloak as keycloakSingleton } from '../keycloak';

import { AccountContextProps } from './AccountContextProps';

interface KeycloakTokens {
  login: KeycloakInstance['login'];
  logout: KeycloakInstance['logout'];
  isLoading: boolean;
  isAuthenticated: AccountContextProps['isAuthenticated'];
  token: AccountContextProps['token'];
}

export const useEvoachKeycloak = (): KeycloakTokens => {
  const { keycloak, initialized } = useKeycloak();

  return {
    login: keycloak.login,
    logout: keycloak.logout,
    isLoading: !initialized,
    isAuthenticated: keycloak.authenticated ?? false,
    token: keycloakSingleton.token ?? '',
  };
};
