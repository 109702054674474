import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Box, Slider, Typography } from '@mui/material';

import { AccountContext, TextSpeed } from '../../account';

export const UserSettings: React.FC = () => {
  const intl = useIntl();

  // TODO if there are new settings added, move this slide in an extra component
  const speedSettings = [
    {
      value: 0,
      label: intl.formatMessage({
        id: 'player.profile.usersettings.textspeed.slow',
        defaultMessage: 'Langsam',
      }),
    },
    {
      value: 1,
      label: intl.formatMessage({
        id: 'player.profile.usersettings.textspeed.medium',
        defaultMessage: 'Mittel',
      }),
    },
    {
      value: 2,
      label: intl.formatMessage({
        id: 'player.profile.usersettings.textspeed.fast',
        defaultMessage: 'Schnell',
      }),
    },
  ];

  const { preferences } = useContext(AccountContext);

  const onSpeedChange = (newVal: number) => {
    if (preferences) {
      let newSpeed = TextSpeed.MEDIUM;
      switch (newVal) {
        case 0:
          newSpeed = TextSpeed.SLOW;
          break;
        case 1:
          newSpeed = TextSpeed.MEDIUM;
          break;
        case 2:
          newSpeed = TextSpeed.FAST;
          break;
        default:
          newSpeed = TextSpeed.MEDIUM;
          break;
      }
      preferences.setTextSpeed(newSpeed);
      try {
        localStorage.setItem('evoach.player.textSpeed', newSpeed);
      } catch (reason: unknown) {
        alert(
          'Could not store your new setting because your browser prevented access to your localStorage'
        );
        console.error(reason);
      }
    }
  };

  const currentSpeed = useMemo(() => {
    if (preferences?.textSpeed) {
      switch (preferences.textSpeed) {
        case TextSpeed.SLOW:
          return 0;
        case TextSpeed.MEDIUM:
          return 1;
        case TextSpeed.FAST:
          return 2;
        default:
          return 1;
      }
    } else {
      return 1;
    }
  }, [preferences]);

  // render profile information
  return (
    <div
      className="profile"
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '5rem',
      }}
    >
      {' '}
      <Box sx={{ width: 600, marginLeft: '40px' }}>
        <Typography>
          {intl.formatMessage({
            id: 'player.profile.usersettings.textspeed.description',
            defaultMessage:
              'Hier kannst du die Geschwindigkeit einstellen, mit der der Chatbot Texte weiterscrollt. Normalerweise wartet der Chatbot bis du einen Text gelesen hast. Je mehr Text, desto länger wird gewartet. Wenn du die Geschwindigkeit erhöhst, scrollt der Chatbot schneller weiter. In der Einstellung "Schnell" scrollt der Chatbot sofort weiter, wenn du einen Text erhalten hast.',
          })}
        </Typography>

        <Slider
          style={{ marginTop: '20px' }}
          aria-label="Custom Speed"
          defaultValue={currentSpeed}
          step={1}
          min={0}
          max={2}
          valueLabelDisplay="off"
          marks={speedSettings}
          onChange={(_event, value) => onSpeedChange(value as number)}
        />
      </Box>
    </div>
  );
};
