import { useMutation } from 'react-query';

import { authorizedPost } from '../authorizedApi';

export const useShareSessionMutation = () => {
  return useMutation<any, Error, string>(
    async (sessionid: string) => {
      const shareSession = authorizedPost(
        `/session/${sessionid}/sharing`
      );
      const response = await shareSession();
      const data = await response.json();

      return data.sessionsharingid;
    },
    {
      retry: false,
    }
  );
};
