import { Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useStartSessionMutation } from '../api/session/useStartSessionMutation';
import { getBrowserLanguage } from '../intl/TranslationContext';
import { PublicCoachingRouteParams, AppRoutes } from '../routing/routes';

export const PublicPage: React.FC = () => {
  const { moduleId, language } = useParams<PublicCoachingRouteParams>();
  // prepare session start
  const navigate = useNavigate();

  const { mutate } = useStartSessionMutation();

  /*  console.log(moduleId);
  console.log(language);
 */
  const startchatbot = () => {
    // in this step, we get the modulid from the route as we have to create the module first
    if (!moduleId || moduleId === '') return;

    let modLanguage = language;
    // add special logic if language is 'detect' and try to detect browser language
    if (!modLanguage || modLanguage.toLowerCase() === 'detect') {
      modLanguage = getBrowserLanguage();
    }
    mutate(
      { moduleId: moduleId, language: modLanguage, publicRoute: true },
      {
        onSuccess: (sessionId: string) => {
          navigate(`${AppRoutes.PUBLICCOACHING}/${sessionId}`);
        },
      }
    );
  };
  return (
    <div
      style={{
        margin: 10,
        padding: 10,
      }}
    >
      <Typography component="span" variant="body2">
        Hier kommt ein Datenschutzhinweis:
        <ul>
          <li>
            wenn du diesen Chatbot benutzt, werden deine Daten anonym in der
            evoach-Plattform gespeichert.
          </li>
          <li>der Besitzer der Homepage kann deine Eingaben einsehen</li>
          <li>Du stimmst den AGB von evoach zu</li>
        </ul>
        Wenn das alles für dich ok ist, klicke auf "Start", um den Chat zu
        starten:
      </Typography>
      <p>
        <Button onClick={startchatbot}>Starte Chatbot</Button>
      </p>
    </div>
  );
};
