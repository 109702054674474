import { MachineContext } from '@evoach/ui-components';
import { cloneDeep } from 'lodash';
import { assign } from 'xstate';

enum VariableCheckResult {
  SET,
  UNSET,
}

const CheckVarHelperVar = 'variableToBeChecked';

/**
 * Helper function used in guard implementations
 */
const checkVariableSet = (
  context: MachineContext,
  _event: any
): VariableCheckResult => {
  // get name of variable to be checked
  const var2BeChecked = context.userData[CheckVarHelperVar];

  // if no variable is to check return 'unset'
  if (!var2BeChecked || var2BeChecked.trim() === '') {
    return VariableCheckResult.UNSET;
  }

  // get actual content
  const varContent = context.userData[var2BeChecked];

  return varContent !== undefined
    ? VariableCheckResult.SET
    : VariableCheckResult.UNSET;
};

/**
 * extract variable name used for checking
 * and add them to the context
 */
export const checkVariable = assign(
  (context: MachineContext, _event: any, actionMetadata) => {
    const newContext = cloneDeep(context);

    const payload = actionMetadata.action.payload;
    const firstValueToCompare = payload.getValueFrom;

    newContext.userData[CheckVarHelperVar] = firstValueToCompare;
    return newContext;
  }
);

export const setCheckGuard = (
  context: MachineContext,
  _event: any
): boolean => {
  return checkVariableSet(context, _event) === VariableCheckResult.SET
    ? true
    : false;
};

export const unSetCheckGuard = (
  context: MachineContext,
  _event: any
): boolean => {
  return !setCheckGuard(context, _event);
};

/**
 * check guards definition for xState State machine as used in
 * SessionPlayer.tsx
 */
export const checkGuards = {
  setCheckGuard: setCheckGuard,
  unSetCheckGuard: unSetCheckGuard,
};
