import { CreatorImageProps } from '@evoach/ui-components';
import { useContext } from 'react';
import { useQuery } from 'react-query';

import { AccountContext } from '../../account';
import { mapImageToPublicAsset } from '../../components/PropertiesAssets/assetHelper';
import { ModuleProps } from '../../entities/Module';
import { ModulePermission } from '../../entities/ModulePermissions';
import { useEnvironment } from '../../environment/useEnvironment';
import { TranslationContext } from '../../intl/TranslationContext';
import { getAssetUrl } from '../asset';
import { authorizedGet } from '../authorizedApi';

export const useFetchModulesQuery = () => {
  const { appendModuleMessages, locale } = useContext(TranslationContext);
  const { account } = useContext(AccountContext);

  const { playerBasePath } = useEnvironment();

  // set prefered language to current locale
  const languageForModuleFetching = locale.toUpperCase();

  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    `modules-${languageForModuleFetching}`,
    async () => {
      const getModules = authorizedGet(
        `/module?language=${languageForModuleFetching}&enforcefallback=true&issubmodule=false&withdefaultmodules=true`
      );
      const response = await getModules();
      const data: ModuleProps[] = await response.json();

      // resolve images for list
      for (let i = 0; i < data.length; i++) {
        if (data[i].metadata.assetid && data[i].metadata.assetid !== '') {
          data[i].metadata.resolvedsrc = await getAssetUrl(
            data[i].metadata.assetid ?? ''
          );
        } else {
          if (data[i].metadata.src && data[i].metadata.src !== '') {
            data[i].metadata.resolvedsrc = data[i].metadata.src;
          } else {
            if (data[i].metadata.image !== undefined) {
              data[i].metadata.resolvedsrc = mapImageToPublicAsset(
                data[i].metadata.image,
                playerBasePath
              );
            } else {
              data[i].metadata.resolvedsrc = '';
            }
          }
        }

        //
        // resolve coach logos
        //
        const providerAccounts = getModuleProviderAccounts(
          data[i].permissions,
          data[i].isdefaultmodule
        );
        let creatorImages: CreatorImageProps[] = [
          {
            imageURL: undefined,
            logoURL: undefined,
            firstName: '',
            lastName: '',
          },
        ];
        if (providerAccounts && Object.keys(providerAccounts).length !== 0) {
          const accountId = Object.keys(providerAccounts)[0];
          const firstInvitingAccount = providerAccounts[accountId];

          // if "invitingAccount" is the current user, then use account
          const profile =
            account?.accountid === accountId
              ? account?.metainfos?.profile
              : firstInvitingAccount?.metainfos?.profile;

          // Fallback logic for showing pictures of cards: if image exist, then use it.
          // If not, try the next.
          // logoPictureSrc > logoPictureAssetId > profilePictureSrc > profilePictureAssetId
          const coachLogoURL = profile?.logoPictureSrc
            ? profile?.logoPictureSrc
            : profile?.logoPictureAssetId
            ? await getAssetUrl(profile?.logoPictureAssetId)
            : undefined;

          const profileImageURL = profile?.profilePictureSrc
            ? profile?.profilePictureSrc
            : profile?.profilePictureAssetId
            ? await getAssetUrl(profile?.profilePictureAssetId)
            : undefined;

          creatorImages = [
            {
              imageURL: profileImageURL,
              logoURL: !data[i].isdefaultmodule ? coachLogoURL : undefined,
              firstName: firstInvitingAccount?.givenname,
              lastName: firstInvitingAccount?.familyname,
            },
          ];
        }

        data[i].metadata.resolvedCoachLogoSrc = {
          creatorImages: creatorImages,
        };
      }

      // get all translations, flat and make it a simple list of translation keys
      // assembled by all metadata translation
      const translationsOfAllModules = data
        .map((currentModule: ModuleProps) => {
          return currentModule.translations.map(
            (translation: any) => translation.metadatatranslation
          );
        })
        .flat()
        .reduce(
          (prev: any, metaTranslations: any) => ({
            ...prev,
            ...metaTranslations,
          }),
          {}
        );

      appendModuleMessages(translationsOfAllModules);

      return data;
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    modules: data,
    refetch,
  };
};

//
// get the account(s) who provide the module
//
const getModuleProviderAccounts = (
  permissions: ModulePermission[],
  isDefaultModule: boolean
) => {
  const coaches: Record<string, any> = {};
  permissions
    .filter(
      (perm: ModulePermission) =>
        perm.owns ||
        (perm.invitinginvitationid !== null &&
          perm.invitinginvitationid !== undefined &&
          perm.invitinginvitationid !== '') ||
        isDefaultModule
    )
    // PROD-1839
    .filter((perm: ModulePermission) =>
      permissions.some(
        (allPermissionsPerm: ModulePermission) => allPermissionsPerm.owns
      )
        ? perm.owns
        : true
    )
    .forEach((perm: ModulePermission) => {
      if (perm.owns) {
        coaches[perm.account.accountid] = perm.account;
      } else {
        coaches[perm.invitingaccount.accountid] = perm.invitingaccount;
      }
    });
  return coaches;
};
