import React from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  Stack,
} from '@mui/material';

import { LanguageSelection } from './LanguageSelection';
import { CoachSelection } from './CoachSelection';

interface StartSessionDialogProps {
  currentModuleLanguages: string[];
  currentLanguage: string;
  isOpen: boolean;
  onClose: Function;
  onLanguageChange: Function;
  onCoachChange: Function;
  invitingCoaches: Record<string, any>;
}

export const StartSessionDialog: React.FC<StartSessionDialogProps> = ({
  currentModuleLanguages,
  currentLanguage,
  isOpen,
  onClose,
  onLanguageChange,
  onCoachChange,
  invitingCoaches,
}) => {
  const intl = useIntl();

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog fullWidth open={isOpen}>
      <DialogTitle>
        {intl.formatMessage({
          id: 'player.sessionstart.langselection.title',
          defaultMessage: 'Coach und Sprache für neue Coaching-Session',
        })}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="body2" component="span">
            {intl.formatMessage({
              id: 'player.sessionstart.langselection.description',
              defaultMessage:
                'In welcher Sprache möchtest du den Chatbots starten? Der Chatbot ist in folgenden Sprachen verfügbar:',
            })}
            <LanguageSelection
              onLanguageChange={onLanguageChange}
              defaultLanguage={currentLanguage}
              availableLanguages={currentModuleLanguages}
            />
          </Typography>
          <Typography variant="body2" component="span">
            {Object.keys(invitingCoaches).length === 0 && (
              <>
                {intl.formatMessage({
                  id: 'player.sessionstart.coachselection.defaultmod',
                  defaultMessage:
                    'Dieser Chatbot wird allen Benutzern zur Verfügung gestellt und du bist keinem speziellen Coach zugeordnet.',
                })}
              </>
            )}
            {Object.keys(invitingCoaches).length === 1 && (
              <>
                {intl.formatMessage(
                  {
                    id: 'player.sessionstart.coachselection.coachobvious',
                    defaultMessage:
                      'Dein Coach für diese Chatbot-Session ist {firstname} {lastname}',
                  },
                  {
                    firstname:
                      invitingCoaches[Object.keys(invitingCoaches)[0]]
                        .givenname,
                    lastname:
                      invitingCoaches[Object.keys(invitingCoaches)[0]]
                        .familyname,
                  }
                )}
              </>
            )}
            {Object.keys(invitingCoaches).length > 1 && (
              <>
                {intl.formatMessage({
                  id: 'player.sessionstart.coachselection.description',
                  defaultMessage:
                    'Es gibt mehrere Coaches, die dich zu diesem Chatbot eingeladen haben. Wer soll dein Coach für diese Chatbot-Session sein?',
                })}
                <CoachSelection
                  coaches={invitingCoaches}
                  onCoachChange={onCoachChange}
                />
              </>
            )}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => onClose(false)}>
          {intl.formatMessage({
            id: 'player.sessionstart.langselection.cancel',
            defaultMessage: 'Abbrechen',
          })}
        </Button>
        <Button color="secondary" onClick={() => onClose(true)}>
          {intl.formatMessage({
            id: 'player.sessionstart.langselection.ok',
            defaultMessage: 'Chatbot starten',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
