import { forceDownload } from '@evoach/ui-components';
import { Download } from '@mui/icons-material';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { authorizedGet } from '../../api';
import { useFetchCoacheeDiaryEntries } from '../../api/coacheeDiary/useFetchCoacheeDiaries';
import {
  CoacheeDiaryEntryType,
  CoacheeDiaryPrintTaskEntry,
} from '../../entities/CoacheeDiaryTypes';
import { formatDate, useTableStyles } from '../tableHelper';

export type CertificatesProps = {
  accountId?: string;
};
export const Certificates: React.FC<CertificatesProps> = () => {
  const classes = useTableStyles();
  const intl = useIntl();

  const { coacheeDiaryEntries, isLoading } = useFetchCoacheeDiaryEntries(
    CoacheeDiaryEntryType.CERTIFICATE
  );

  const certificates = useMemo(() => {
    if (!isLoading) {
      return coacheeDiaryEntries?.map((entry: CoacheeDiaryPrintTaskEntry) => {
        return {
          issueDate: entry.tscreated,
          title: entry.variablevalue.document.title,
          coach:
            entry.variablevalue.coach?.givenname +
            ' ' +
            entry.variablevalue.coach?.familyname,
          coacheediaryid: entry.coacheediaryid,
        };
      });
    } else {
      return [];
    }
  }, [coacheeDiaryEntries, isLoading]);

  const triggerDownload = async (objectId: string, fileName: string) => {
    const getUrl = `/print/${objectId}`;
    const getDocumentCall = authorizedGet(getUrl);
    getDocumentCall().then((res: Response) => {
      res.json().then((obj: any) => {
        forceDownload(obj.presignedUrl, fileName);
      });
    });
  };

  return (
    <div
      className="profile"
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '5rem',
      }}
    >
      {certificates !== undefined && certificates.length > 0 ? (
        <TableContainer
          component={Paper}
          style={{
            minHeight: '100%',
            overflowX: 'initial',
          }}
        >
          <Table
            stickyHeader
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none',
                paddingBottom: '0.5rem',
              },
            }}
          >
            <colgroup>
              <col style={{ width: '15%', textAlign: 'left' }} />
              <col style={{ width: '40%', textAlign: 'left' }} />
              <col style={{ width: '30%', textAlign: 'left' }} />
              <col style={{ width: '15%', textAlign: 'left' }} />
            </colgroup>
            <TableHead className={classes.noWrapChildren}>
              <TableRow>
                <TableCell>
                  {intl.formatMessage({
                    id: 'player.certificatelist.table.header.issueddate',
                    defaultMessage: 'Ausgestellt am',
                  })}
                </TableCell>
                <TableCell>
                  {intl.formatMessage({
                    id: 'player.certificatelist.table.header.title',
                    defaultMessage: 'Titel',
                  })}
                </TableCell>
                <TableCell>
                  {intl.formatMessage({
                    id: 'player.certificatelist.table.header.coach',
                    defaultMessage: 'Coach',
                  })}
                </TableCell>
                <TableCell align="center">
                  {intl.formatMessage({
                    id: 'player.certificatelist.table.header.download',
                    defaultMessage: 'Download',
                  })}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {certificates !== undefined &&
                certificates?.map((row: any) => (
                  <TableRow
                    key={row.coacheediaryid}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    hover={true}
                  >
                    <TableCell>{formatDate(row.issueDate)}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.coach}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={() =>
                          triggerDownload(
                            row.coacheediaryid,
                            `${row.title}_${row.issueDate}`
                          )
                        }
                      >
                        <Download />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body2" sx={{ margin: '20px' }}>
          {intl.formatMessage({
            id: 'player.certificatelist.nocertificates',
            defaultMessage: 'Keine Zertifikate vorhanden.',
          })}
        </Typography>
      )}
    </div>
  );
};
