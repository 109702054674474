import { defineMessages } from '@formatjs/intl';
import { Typography } from '@mui/material';
import React, { useCallback, useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { AccountContext } from '../account';
import {
  ApiError,
  useAcceptInvitationMutation,
  useStartSessionMutation,
} from '../api';
import { ReceptionButton } from '../components/ReceptionButton';
import { Tenant } from '../entities';
import {
  AppRoutes,
  InvitationRouteParams,
  ReceptionRoutes,
} from '../routing/routes';

const invitationPageMessages = defineMessages({
  invitationFailedHint: {
    id: 'player.pages.invitation.failed.hint',
    defaultMessage: 'Akzeptieren der Einladung fehlgeschlagen',
  },
  invitationIdNoCorrect: {
    id: 'player.pages.invitation.failed.wrongid',
    defaultMessage: 'Dein Einladungscode scheint falsch zu sein.',
  },
  unknownError: {
    id: 'player.pages.invitation.failed.unknown',
    defaultMessage: 'Irgendetwas ist schiefgegangen.',
  },
  contactCoach: {
    id: 'player.pages.invitation.failed.contactCoach',
    defaultMessage:
      'Bitte kontaktiere den Coach, von dem du die Einladung erhalten hast.',
  },
  succesfullyAccepted: {
    id: 'player.pages.invitation.success.message',
    defaultMessage:
      'Du hast die Einladung erfolgreich akzeptiert. Du findest deine neuen Chatbots auf der Startseite.',
  },
  acceptanceInProgress: {
    id: 'player.pages.invitation.success.acceptanceInProgress',
    defaultMessage:
      'Deine Einladung wird bearbeitet. Bitte einen Augenblick Geduld ...',
  },
  autostartChatbot: {
    id: 'player.pages.invitation.success.autostartChatbot',
    defaultMessage: 'Dein Chatbot wird gestartet...',
  },
});

export const InvitationPage: React.FC = () => {
  const intl = useIntl();
  // prepare session start
  const navigate = useNavigate();

  const { search } = useLocation();

  const { setCurrentTenant } = useContext(AccountContext);

  const { mutate: startSessionMutation } = useStartSessionMutation();

  const {
    mutate: acceptInvitationMutation,
    isError,
    error,
    isLoading,
    data,
  } = useAcceptInvitationMutation();

  //
  // 1) get Invitation from URL
  const { invitationId: invitationIdByUrl } =
    useParams<InvitationRouteParams>();
  // 2) get invitation from localstorage
  const invitationIdByLocalStorage = localStorage.getItem(
    'evoachModuleInvitation'
  );

  // PROD-1945 -
  let autoStartChatBot = false;

  try {
    // ... either the autoStart value is set to true in local storage,
    // because the user wasn't signed-in/up when clicking on the invitation ...
    autoStartChatBot = JSON.parse(
      localStorage.getItem('evoachAutoStartChatbotAfterInvitation') ?? 'false'
    );
    // ... or she is logged in and gets the parameter in the URL.
    if (search) {
      const query = new URLSearchParams(search);
      // either/or ==> logical OR for actual value
      autoStartChatBot = autoStartChatBot || query.get('autostart') === 'true';
    }
  } catch (reason: unknown) {
    console.error(reason);
  }

  // if invitation is in URL, take it, if not, use localstorage
  const invitationId =
    invitationIdByUrl && invitationIdByUrl !== 'pending'
      ? invitationIdByUrl
      : invitationIdByLocalStorage;

  // createSessionInBackend
  // send moduleid, the accountid of the coach (if there are more than one
  // who granted access to the module) and the language that is requested
  //
  const createSessionInBackend = useCallback(
    (moduleid: string, language: string, coachAccountId: string) => {
      startSessionMutation(
        {
          moduleId: moduleid,
          language: language,
          publicRoute: false,
          coachAccountId: coachAccountId,
        },
        {
          onSuccess: (sessionId: string) => {
            // PROD-1945 - reset autostart
            localStorage.setItem(
              'evoachAutoStartChatbotAfterInvitation',
              JSON.stringify(false)
            );
            navigate(`${AppRoutes.COACHING}/${sessionId}`);
          },
          onError: (error: Error) => {
            console.error(error);
          },
        }
      );
    },
    [navigate, startSessionMutation]
  );

  // do things if invitationid changes
  useEffect(() => {
    localStorage.setItem('evoachModuleInvitation', '');
    if (invitationId) {
      acceptInvitationMutation(invitationId, {
        // write recently accepted modules to localStorage to show badge on card
        onSuccess: (data: any) => {
          // PROD-2010
          // set tenant based on data
          // invitingaccount: { accountid,firstname,lastname }
          if (true && data && data.length > 0 && data[0].invitingaccount) {
            setCurrentTenant({
              tenantid: data[0].invitingaccount.accountid,
              firstname: data[0].invitingaccount.firstname,
              lastname: data[0].invitingaccount.lastname,
            } as Tenant);
          }
          // set modules to be highligthed
          const previouslyHighlightedModules = JSON.parse(
            localStorage.getItem('evoach.player.modules.highlighted') ?? '[]'
          );
          const moduleIds = data.map((modulepermission: any) => {
            return {
              moduleid: modulepermission.module.moduleid,
              highlightedDate: Date.now(),
            };
          });

          // stringify module ids
          const stringifiedModuleIds = JSON.stringify(
            previouslyHighlightedModules.concat(moduleIds)
          );
          // set list of modules to be highlighted in localstorafe
          localStorage.setItem(
            'evoach.player.modules.highlighted',
            stringifiedModuleIds
          );

          // autostart if flag was set
          if (autoStartChatBot) {
            //
            // if we accepted an invitation with at least on chatbot,
            // choose the accountid of the coach of the first element
            // in the list of accepted invitations
            //
            const coachAccountId = data[0].invitingaccount?.accountid;
            // if we accepted more than 1 chatbot, take the first one
            // note: we allow the autostart feature in creator only
            // if there is only one chatbot in the list.
            // If we would change that, we would take the first in the list.

            createSessionInBackend(
              moduleIds[0].moduleid,
              intl.locale.toUpperCase(),
              coachAccountId
            );
          }
        },
      });
    }
  }, [
    invitationId,
    acceptInvitationMutation,
    invitationIdByLocalStorage,
    autoStartChatBot,
    createSessionInBackend,
    intl.locale,
    setCurrentTenant,
  ]);

  // print
  return (
    <Typography component="span" variant="h6">
      {isError && (
        <div>
          <FormattedMessage {...invitationPageMessages.invitationFailedHint} />
          {error instanceof ApiError && error.httpStatus === 400 ? (
            <div style={{ marginTop: 15 }}>
              <FormattedMessage
                {...invitationPageMessages.invitationIdNoCorrect}
              />
            </div>
          ) : (
            <div style={{ marginTop: 15 }}>
              <FormattedMessage {...invitationPageMessages.unknownError} />
            </div>
          )}

          <div style={{ marginTop: 15 }}>
            <FormattedMessage {...invitationPageMessages.contactCoach} />
          </div>
        </div>
      )}
      {isLoading && (
        <div>
          {autoStartChatBot ? (
            <FormattedMessage {...invitationPageMessages.autostartChatbot} />
          ) : (
            <FormattedMessage
              {...invitationPageMessages.acceptanceInProgress}
            />
          )}
        </div>
      )}
      {!isLoading &&
        !isError &&
        data !== undefined &&
        !autoStartChatBot && ( // prevents unmounting before data is received
          <Navigate to={AppRoutes.RECEPTION + '/' + ReceptionRoutes.MODULES} />
        )}
      {(isLoading || isError) && (
        <div style={{ marginTop: 15 }}>
          <ReceptionButton />
        </div>
      )}
    </Typography>
  );
};
