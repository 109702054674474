import { evoachDefaultTheme } from '@evoach/ui-components';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import { useFetchCoachData } from '../api/account/useFetchCoachDataQuery';
import { Account } from '../entities/Account';

interface CallACoachPopupProps {
  isOpen: boolean;
  setOpenState: Function;
  sessionId: string;
  isPublicRoute: boolean;
}

export const CallACoachPopup: React.FC<CallACoachPopupProps> = ({
  isOpen,
  setOpenState,
  sessionId,
  isPublicRoute,
}) => {
  const intl = useIntl();

  const { coaches, isError } = useFetchCoachData(sessionId, isPublicRoute);

  const onClose = () => {
    setOpenState(false);
  };

  return (
    <Dialog fullWidth onClose={onClose} open={isOpen}>
      <DialogTitle>
        {intl.formatMessage({
          id: 'player.chat.callacoach.title',
          defaultMessage: 'Kontaktiere deinen Coach',
        })}
      </DialogTitle>
      <DialogContent>
        <Typography>
          {isError && (
            <>
              {intl.formatMessage({
                id: 'player.chat.callacoach.error',
                defaultMessage:
                  'Ich konnte die Kontaktdaten des Coaches leider nicht ermitteln.',
              })}
            </>
          )}
          {!isError && (
            <Stack spacing={2}>
              <Box sx={{ marginBottom: '20px' }}>
                {intl.formatMessage({
                  id: 'player.chat.callacoach.info',
                  defaultMessage:
                    'Hier sind die Kontaktdaten für deinen Coach. Wenn du von mehreren Coaches zu diesem Chatbot eingeladen wurdest, werden mehrere Coaches angezeigt.',
                })}
              </Box>
              {coaches &&
                coaches.map((coach: Account, index: number) => (
                  <Link
                    key={`coach-${index}`}
                    href={`mailto:${coach.email}`}
                    sx={{
                      color: evoachDefaultTheme.palette.secondary.main,
                      textDecoration: 'underline',
                      textDecorationColor:
                        evoachDefaultTheme.palette.secondary.main,
                      cursor: 'pointer',
                    }}
                  >
                    {' ' +
                      coach.givenname +
                      ' ' +
                      coach.familyname +
                      ', ' +
                      coach.email}
                  </Link>
                ))}
            </Stack>
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          {intl.formatMessage({
            id: 'player.chat.callacoach.close',
            defaultMessage: 'Schließen',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
