import { evoachDefaultTheme } from '@evoach/ui-components';
import {
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  SxProps,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import LocalMoviesIcon from '@mui/icons-material/LocalMovies';
import ImageIcon from '@mui/icons-material/Image';

import { useFetchAssets } from '../../api';
import { Asset } from '../../entities';
import { TabPanelProps, getTabProps } from '../tabHelper';

import {
  AssetType,
  getFileExtension,
  isAudioExtension,
  isImageExtension,
  isVideoExtension,
} from './assetHelper';

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component="span" sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

interface AssetSelectorProps {
  /**
   * is used as sx of the envelopping Box in component
   */
  sx?: SxProps<Theme>;
  /**
   * click functino with sgnature (asset: Asset) => {}
   */
  onAssetSelect?: Function;
  /**
   * filters according to AssetType. If undefined, no filter is applied
   */
  assetType?: AssetType;
  /**
   * a text that is used as a filter on the asset keys. Not case-sensitive
   */
  assetTitleFilter?: string;
}

/**
 * Asset selector lets the user display and select her own assets as well
 * as the default assets.
 *
 * @param {AssetSelectorProps} AssetSelectorProps parameters to component
 */
export const AssetSelector: React.FC<AssetSelectorProps> = ({
  sx,
  onAssetSelect,
  assetType,
  assetTitleFilter = '',
}) => {
  const { assets } = useFetchAssets();
  const intl = useIntl();
  const [tabvalue, setTabValue] = React.useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const onPreviewImageClick = (asset: Asset) => {
    if (onAssetSelect) {
      onAssetSelect(asset);
    }
  };

  const getListJSX = (ownassets: boolean) => {
    return (
      <>
        {assets !== undefined && (
          <ImageList
            sx={{
              minWidth: '90%',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
            rowHeight={164}
          >
            {assets
              .filter((asset: Asset) => {
                return asset.isDefaultAsset === !ownassets;
              })
              .filter((asset: Asset) => {
                // ! apply type filter if present
                if (!assetType) return true;

                const extension = getFileExtension(asset.key);
                if (
                  assetType === AssetType.AUDIO &&
                  isAudioExtension(extension)
                ) {
                  return true;
                }

                if (
                  assetType === AssetType.IMAGE &&
                  isImageExtension(extension)
                ) {
                  return true;
                }

                if (
                  assetType === AssetType.VIDEO &&
                  isVideoExtension(extension)
                ) {
                  return true;
                }

                return false;
              })
              .filter((asset: Asset) => {
                // ! apply text filter if present
                if (!assetTitleFilter || assetTitleFilter === '') return true;
                return (asset.key + '')
                  .toLowerCase()
                  .includes(assetTitleFilter.toLowerCase());
              })
              .map((asset: Asset, index: number) => {
                const extension = getFileExtension(asset.key);
                //
                // check whether metadata is available. This is also a good
                // indicator whether thumbnail is available
                //
                const assetHasMetadata = Object.keys(asset.metadata).length > 0;

                // check subtitle
                const assetSubtitle =
                  assetHasMetadata && asset.metadata.width!
                    ? `${extension}, ${asset.metadata.width} x ${asset.metadata.height}`
                    : extension;

                return (
                  <ImageListItem
                    key={`imglist${asset.key}`}
                    sx={{
                      padding: '5px',
                      height: '160px',
                      overflow: 'hidden',
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor:
                          evoachDefaultTheme.palette.secondary.main,
                      },
                    }}
                  >
                    {asset.thumbnailUrl !== undefined &&
                    asset.thumbnailUrl !== '' &&
                    assetHasMetadata ? (
                      <img
                        style={{ width: '160px' }}
                        src={`${asset.thumbnailUrl}`}
                        alt={asset.key}
                        loading="lazy"
                        onClick={() => onPreviewImageClick(asset)}
                        onError={(event: any) => {
                          event.target.src = '/assets/noimg.png';
                        }}
                      />
                    ) : (
                      <Typography
                        sx={{
                          width: '160px',
                          height: '160px',
                          textAlign: 'center',
                          margin: 'auto',
                        }}
                        component="span"
                        onClick={() => onPreviewImageClick(asset)}
                        key={`${index}nopreview`}
                        variant="body2"
                      >
                        {isAudioExtension(getFileExtension(asset.key)) && (
                          <MusicNoteIcon
                            sx={{
                              color: 'rgba(0, 0, 0, 0.5)',
                              width: '50%',
                              marginTop: '20%',
                              fontSize: '300%',
                            }}
                          />
                        )}
                        {isVideoExtension(getFileExtension(asset.key)) && (
                          <LocalMoviesIcon
                            sx={{
                              color: 'rgba(0, 0, 0, 0.5)',
                              width: '50%',
                              marginTop: '20%',
                              fontSize: '300%',
                            }}
                          />
                        )}
                        {isImageExtension(getFileExtension(asset.key)) && (
                          <ImageIcon
                            sx={{
                              color: 'rgba(0, 0, 0, 0.5)',
                              width: '50%',
                              marginTop: '20%',
                              fontSize: '300%',
                            }}
                          />
                        )}
                      </Typography>
                    )}
                    <ImageListItemBar
                      title={asset.key}
                      subtitle={assetSubtitle}
                      sx={{
                        margin: '5px',
                        '.MuiImageListItemBar-title': {
                          fontVariant: 'body2',
                          fontSize: '12px',
                        },
                      }}
                    />
                  </ImageListItem>
                );
              })}
          </ImageList>
        )}
      </>
    );
  };

  // TODO remove height / adapt to available screen size
  return (
    <Box
      sx={{
        width: '100%',
        height: '600px',
        margin: '10px',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: '4px',
        boxShadow:
          '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        overflow: 'auto',
        ...sx,
      }}
    >
      <Box component="span" sx={{ width: '100%' }}>
        <Box component="span" sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabvalue} onChange={handleChange} aria-label="Reception">
            <Tab
              label={intl.formatMessage({
                id: 'player.reception.assets.assetselector.tab.own',
                defaultMessage: 'Eigene Dateien',
              })}
              {...getTabProps(0, 'asset')}
            />
            <Tab
              label={intl.formatMessage({
                id: 'player.reception.assets.assetselector.tab.evoach',
                defaultMessage: 'evoach Dateien',
              })}
              {...getTabProps(1, 'asset')}
            />
          </Tabs>
        </Box>
        <TabPanel value={tabvalue} index={0}>
          {getListJSX(true)}
        </TabPanel>
        <TabPanel value={tabvalue} index={1}>
          {getListJSX(false)}
        </TabPanel>
      </Box>
    </Box>
  );
};
