import React, { useMemo, useState } from 'react';
import { evoachDefaultTheme } from '@evoach/ui-components';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';

import { ThemeSwitchContext, ThemesEnum } from './ThemeSwitchContextProps';

export type ThemeSwitchContextProviderProps = {
  children?: React.ReactNode;
};

export const ThemeSwitchContextProvider: React.FC<
  ThemeSwitchContextProviderProps
> = ({ children }) => {
  // preferences
  const [currentTheme, setCurrentTheme] = useState<ThemesEnum>(
    ThemesEnum.DEFAULT
  );

  //SpecialTenantsEnum
  const selectedTheme = useMemo(() => {
    //
    // TODO implement theme in ui components and export it there
    // TODO with a recognizable name. Import here and set manually
    // TODO based on enum
    //
    if (currentTheme === ThemesEnum.ICF) {
      return evoachDefaultTheme;
    }

    return evoachDefaultTheme;
  }, [currentTheme]);

  return (
    <ThemeSwitchContext.Provider
      value={{
        currentTheme,
        setCurrentTheme,
        currentMuiTheme: selectedTheme,
      }}
    >
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={selectedTheme}>{children}</ThemeProvider>
      </StyledEngineProvider>
    </ThemeSwitchContext.Provider>
  );
};
