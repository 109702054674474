//import { makeStyles } from '@mui/styles';
import { useMachine, useSelector } from '@xstate/react'; // useService
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
//import { CSSTransition, TransitionGroup } from 'react-transition-group';
//import { useFirstMountState } from 'react-use';
import {
  AudioDisplay,
  BinarySorter,
  CalenderExportComposite,
  CanvasEditor,
  CoacheeMessage,
  CoachEnum,
  CoachMessage,
  Component,
  CopyLink,
  DateInput,
  DownloadButton,
  EditableMultiselect,
  EmotionIcon,
  EmotionInput,
  EmotionInputComposite,
  FormattedInput,
  HotOrNotSelection,
  ImageDisplay,
  ImageSelect,
  LinkButton,
  MachineContext,
  MessageInput,
  MoodIcon,
  MoodInput,
  MultiButton,
  MultipleDisplayComposite,
  MultipleInput,
  MultipleInputComposite,
  MultipleOutputComposite,
  MultiplePercentageScaleInput,
  NavigateButton,
  NextButton,
  PercentageScaleInput,
  PhaseAnchor,
  PolarchartDisplay,
  PolarchartInput,
  PostItDisplay,
  PostItInput,
  ProgressBar,
  RadarchartDisplay,
  RadioButton,
  RatingInput,
  renderAudioDisplayAction,
  renderBinarySorterAction,
  renderCalenderExportCompositeAction,
  renderCanvasEditorAction,
  renderCoacheeMessageAction,
  renderCoacheeMessagesAction,
  renderCoachMessageAction,
  renderCopyLinkAction,
  renderDateInputAction,
  renderDownloadButtonAction,
  renderEditableMultiselectAction,
  renderEmotionIconAction,
  renderEmotionIconsAction,
  renderEmotionInputAction,
  renderEmotionInputCompositeAction,
  renderFormattedInputAction,
  renderHotOrNotSelectionAction,
  renderImageDisplayAction,
  renderImageSelectAction,
  renderLinkButtonAction,
  renderMessageInputAction,
  renderMoodIconAction,
  renderMoodInputAction,
  renderMultiButtonAction,
  renderMultipleDisplayCompositeAction,
  renderMultipleInputAction,
  renderMultipleOutputCompositeAction,
  renderMultiplePercentageScaleInputAction,
  renderNavigateButtonAction,
  renderNextButtonAction,
  renderPercentageScaleInputAction,
  renderPolarchartDisplayAction,
  renderPolarchartInputAction,
  renderPostItDisplayAction,
  renderPostItInputAction,
  renderRadarchartDisplayAction,
  renderRadioButtonAction,
  renderRatingInputAction,
  renderScaleButtonAction,
  renderScaleInputAction,
  renderSceneCanvasAction,
  renderSceneCanvasCharacterSelectorAction,
  renderSelectionCardAction,
  renderSingleDisplayAction,
  renderTimeInputAction,
  renderValueCardListAction,
  renderVideoDisplayAction,
  renderYesNoButtonAction,
  ScaleButton,
  ScaleInput,
  SceneCanvas,
  SceneCanvasCharacterSelector,
  SelectionCard,
  SendContextProvider,
  setProgressPercentAction,
  setProgressPhaseAction,
  SingleDisplay,
  ThreeDots,
  TimeInput,
  ValueCardList,
  VideoDisplay,
  YesNoButton,
  renderThreeDots,
} from '@evoach/ui-components';
import { Box, Grid } from '@mui/material';
import { cloneDeep } from 'lodash';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { AnyEventObject, createMachine } from 'xstate'; // interpret

import { AccountContext } from '../account';
import { TextSpeed } from '../account/AccountContextProps';
import { useUpdateSessionStateMutation } from '../api';
import { useFetchCoachData } from '../api/account/useFetchCoachDataQuery';
import { resolveS3ApiCall } from '../api/asset';
import { DevToolsContext } from '../devtools/DevToolsContext';
import { MachineDebug } from '../devtools/MachineDebug';
import { Session } from '../entities/Session';
import { AppRoutes } from '../routing/routes';
import { WindowContext } from '../window/WindowContext';

import {
  compareGuards,
  sendToTargetAfterMultipleCompareNumbers,
  setCompareNumbers,
  setMultipleCompareNumbers,
} from './actions/compareActions';
import {
  increasePollingCounter,
  newExternalCallServices,
  renderExternalWait,
  savePollingResult,
  saveTaskId,
} from './actions/externalActions';
import {
  endLoopAction,
  loopGuards,
  startLoopAction,
} from './actions/loopActions';
import { renderSendMail, setSendMail } from './actions/mailActions';
import { setNavigation } from './actions/navigationActions';
import { renderShareSession, setShareSession } from './actions/shareActions';
import {
  newSessionServices,
  redirectToSession,
  renderGrantPermissionError,
  renderStartNewSession,
} from './actions/startNewSessionActions';
import {
  randomNodeGuards,
  renderRandomCoachMessageAction,
  setCombineToArray,
  setCombineToString,
  setDirectedAgentMode,
  setFormula,
  setStringArray,
  writeToContextAction,
} from './actions/variableActions';
import { CallACoachPopup } from './CallACoach';
import { ModuleMetadataContext } from './ModuleMetadataContext';
import { endOfChatAnchor, ScrollContainer } from './ScrollContainer';
//import { logSessionAnalytics } from './SessionAnalytics';
import { checkGuards, checkVariable } from './actions/checkVariableActions';
import {
  renderCreateCertificate,
  setCreateCertificate,
} from './actions/certificateActions';
import { classificationGuards } from './actions/aiClassificationGuards';
import { sentimentGuards } from './actions/aiSentimentGuards';
import { microchatGuards } from './actions/aiMicrochatGuards';
import {
  increaseMicrochatPollingCounter,
  newExternalMicrochatCallServices,
  renderExternalCoachMessage,
  renderExternalMicrochatInput,
  renderInitMicrochat,
  saveMicrochatPollingResult,
  saveMicrochatTaskId,
} from './actions/aiMicrochatActions';
import { getHtmlFormatter } from './formatter/HtmlFormatter';
import { setAnalytics } from './actions/analyticsActions';
import { setAvatarAction } from './actions/setAvatarAction';

// transition styles for react-transition-group
/* const useStyles = makeStyles({
  enter: {
    opacity: 0,
  },
  enterActive: {
    opacity: 1,
    transition: 'opacity 700ms',
  },
}); */

interface SessionPlayerProps {
  refetchSession: Function;
  session: Session;
  moduleTitle: string;
  isPlayground?: boolean;
}

export const SessionPlayer: React.FC<SessionPlayerProps> = ({
  refetchSession,
  session,
  moduleTitle,
  isPlayground = false,
}) => {
  //
  // prepare dedicated session paramerers for quick access
  //
  const sessionId = session?.sessionid ?? '';
  const machineDefinition = useMemo(
    () => session?.dereferencedstatemachine?.definition ?? {},
    [session?.dereferencedstatemachine?.definition]
  );
  const sessionState = session?.sessionstate ?? {};
  const sharedSession = session?.sharedSession ?? false;
  const showProgressbar = session?.publicsessionprogressbar ?? true;
  const sessionLanguage = session?.lang ?? ''; // needed for PROD-1124

  const context = sessionState.currentMachineState.context;

  const numStates = useMemo(() => {
    return machineDefinition ? Object.keys(machineDefinition.states).length : 0;
  }, [machineDefinition]);
  //
  // get the coachee data via the account as this is required for global vars
  //
  const {
    account,
    preferences: { textSpeed },
    isPublicRoute,
    //hasRole,
  } = useContext(AccountContext);

  /* const isEvoachAdmin = useMemo(() => {
    return hasRole(RoleEnum.EVOACHADMIN);
  }, [hasRole]);

   */
  const coacheeaccountid = useMemo(() => {
    if (!isPublicRoute && account && account.accountid) {
      return account?.accountid;
    } else {
      return '';
    }
  }, [account, isPublicRoute]);

  //
  // get the coach data via the account as this is required for global vars
  //
  const { coaches } = useFetchCoachData(sessionId, isPublicRoute);

  const coachaccountid = useMemo(() => {
    if (coaches && coaches.length > 0) {
      return coaches[0].accountid;
    }
    return '';
  }, [coaches]);

  // translation
  const intl = useIntl();

  // navigate is used as parameter for redirectToSession action
  // and for the endClickHandler in progress bar
  const navigate = useNavigate();

  // if we left off in the middle of a session or if we want to resume
  // a session, we should inform xState that we start in another state
  if (context && context.analyticsData && context.analyticsData.lastState) {
    machineDefinition.initial = context.analyticsData.lastState;
  }

  const { devToolsActivated } = useContext(DevToolsContext);

  const { modulePhases } = useContext(ModuleMetadataContext);

  // doc: https://www.npmjs.com/package/react-to-print
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  // handlePrint function can be triggered by a button or as
  // a state machine action

  const handlePrint = useReactToPrint({
    content: () =>
      scrollContainerRef && scrollContainerRef.current
        ? scrollContainerRef.current
        : null,
    documentTitle: moduleTitle,
  });

  const machineStub = useMemo(() => {
    return {
      state:
        context.widgetData && context.widgetData.length > 0
          ? sessionState.currentMachineState
          : undefined,
      context: {
        ...context,
        analyticsData: { ...context.analyticsData },
        userData: context.userData ?? {},
        widgetData: context.widgetData ?? [],
        metaData: context.metaData ?? {
          currentModulePhase: '',
          currentModuleProgressPercent: 0,
          currentCoachAvatarImage: '',
          currentCoachAvatarImageAssetId: '',
          numberOfStates: numStates, // number of states in the initial state machine
        },
        sessionData: {
          ...context.sessionData, // PROD-1857, pre-load required global variables
          'evoach.sessionId': sessionId,
          'evoach.sessionLanguage': sessionLanguage,
          'evoach.isPublicModule': isPublicRoute,
          'evoach.isPreview': session.previewsession ?? false,
        },
      },
      actions: {
        writeToContext: writeToContextAction(
          isPublicRoute,
          sessionId,
          moduleTitle,
          session?.originalmoduleid,
          coacheeaccountid,
          coachaccountid
        ), // PROD-1857 - all parameters required for global variables

        // send External is a complex combination of states that send a
        // request, get a task id and poll for the result
        sendExternal: renderExternalWait(intl),
        sendExternalSentiment: renderExternalWait(intl),
        increasePollingCounter: increasePollingCounter,
        saveTaskId: saveTaskId,
        savePollingResult: savePollingResult,

        // update analytics information
        setAnalytics: setAnalytics,

        // micro-chat actions
        renderInitMicrochat: renderInitMicrochat(intl, false),
        renderExternalMicrochatInput: renderExternalMicrochatInput(intl),
        renderExternalCoachMessage: renderExternalCoachMessage(intl),
        saveMicrochatPollingResult: saveMicrochatPollingResult,
        increaseMicrochatPollingCounter: increaseMicrochatPollingCounter,
        saveMicrochatTaskId: saveMicrochatTaskId,

        // player-internal actions not provided by a UI component
        setProgressPhase: setProgressPhaseAction,
        setProgressPercent: setProgressPercentAction,
        setAvatarImage: setAvatarAction(intl),
        setDirectedAgentMode: setDirectedAgentMode,
        setStringArray: setStringArray(
          intl,
          isPublicRoute,
          sessionId,
          moduleTitle,
          session?.originalmoduleid,
          coacheeaccountid,
          coachaccountid
        ),
        setCombineToArray: setCombineToArray(
          isPublicRoute,
          sessionId,
          moduleTitle,
          session?.originalmoduleid,
          coacheeaccountid,
          coachaccountid
        ),
        setCombineToString: setCombineToString(
          intl,
          isPublicRoute,
          sessionId,
          moduleTitle,
          session?.originalmoduleid,
          coacheeaccountid,
          coachaccountid
        ),
        setFormula: setFormula,
        setSendMail: setSendMail(isPublicRoute, sessionId),
        setCompareNumbers: setCompareNumbers,
        setMultipleCompareNumbers: setMultipleCompareNumbers,
        setShareSession: setShareSession(isPublicRoute, sessionId),
        setNavigation: setNavigation(navigate, isPublicRoute),
        checkVariable: checkVariable,
        printChat: handlePrint,
        setCreateCertificate: setCreateCertificate(intl),

        renderGrantPermissionError: renderGrantPermissionError(intl),
        redirectToSession: redirectToSession(navigate),
        sendToTargetAfterMultipleCompareNumbers:
          sendToTargetAfterMultipleCompareNumbers,

        loopStart: startLoopAction,
        loopEnd: endLoopAction,

        // actions that are provided by UI components
        renderCoachMessage: renderCoachMessageAction(intl),
        renderRandomCoachMessage: renderRandomCoachMessageAction(intl),
        renderCoacheeMessage: renderCoacheeMessageAction(intl),
        renderCoacheeMessages: renderCoacheeMessagesAction(intl),
        renderCalenderExportComposite:
          renderCalenderExportCompositeAction(intl),
        renderMessageInput: renderMessageInputAction(intl),
        renderMoodInput: renderMoodInputAction,
        renderMoodIcon: renderMoodIconAction,
        renderNextButton: renderNextButtonAction(intl),
        renderScaleInput: renderScaleInputAction(intl),
        renderPercentageScaleInput: renderPercentageScaleInputAction(intl),
        renderScaleInputMulti: renderScaleInputAction(intl),
        renderMultipleOutputComposite:
          renderMultipleOutputCompositeAction(intl),
        renderRatingInput: renderRatingInputAction(intl),
        renderYesNoButton: renderYesNoButtonAction(intl),
        renderPolarchartDisplay: renderPolarchartDisplayAction(intl),
        renderRadarchartDisplay: renderRadarchartDisplayAction(intl),
        renderPolarchartInput: renderPolarchartInputAction(intl),
        renderMultipleInput: renderMultipleInputAction(intl),
        renderMultipleInputSingle: renderMultipleInputAction(intl),
        renderMultipleDisplayComposite:
          renderMultipleDisplayCompositeAction(intl),
        renderPostItInput: renderPostItInputAction(intl),
        renderPostItDisplay: renderPostItDisplayAction(intl),
        renderRadioButton: renderRadioButtonAction(intl),
        renderVideoDisplay: renderVideoDisplayAction,
        renderCopyLink: renderCopyLinkAction(intl),
        renderAudioDisplay: renderAudioDisplayAction(intl),
        renderDateInput: renderDateInputAction(intl),
        renderTimeInput: renderTimeInputAction(intl),
        renderImageDisplay: renderImageDisplayAction(intl),
        renderImageSelector: renderImageSelectAction(intl),
        renderImageSelectorDisplay: renderImageSelectAction(intl),
        renderScaleButton: renderScaleButtonAction(intl),
        renderEditableMultiselect: renderEditableMultiselectAction(intl),
        renderNeedsInput: renderEditableMultiselectAction(intl),
        renderValueSelector: renderEditableMultiselectAction(intl),
        renderEmotionInput: renderEmotionInputAction(intl),
        renderEmotionInputComposite: renderEmotionInputCompositeAction(intl),
        renderEmotionIcons: renderEmotionIconsAction(intl), // translate inputs, intl
        renderEmotionIcon: renderEmotionIconAction, // without text, no intl
        renderMultiButton: renderMultiButtonAction(intl),
        renderBinarySorter: renderBinarySorterAction(intl),
        renderBinarySorterDisplay: renderBinarySorterAction(intl),
        renderLinkButton: renderLinkButtonAction(intl),
        renderSelectionCard: renderSelectionCardAction(intl),
        renderSceneCanvas: renderSceneCanvasAction(intl),
        renderCanvasEditor: renderCanvasEditorAction(intl),
        renderCanvasDisplay: renderCanvasEditorAction(intl),
        renderCharacterSelector: renderSceneCanvasCharacterSelectorAction(intl),
        renderSingleDisplay: renderSingleDisplayAction(intl),
        renderHotOrNotSelector: renderHotOrNotSelectionAction(intl),
        renderFormattedInput: renderFormattedInputAction(intl),
        renderShareSession: renderShareSession(intl),
        renderSendMail: renderSendMail(intl),
        renderStartNewSession: renderStartNewSession(intl), // PROD-1124
        renderValueCardList: renderValueCardListAction, // PROD-1371
        renderMultiplePercentageScaleInput:
          renderMultiplePercentageScaleInputAction(intl), // PROD-1393
        renderNavigateButton: renderNavigateButtonAction(intl), // PROD-1677
        renderDownloadButton: renderDownloadButtonAction(intl), // PROD-1751
        renderCreateCertificate: renderCreateCertificate(),
        renderThreeDots: renderThreeDots,
      },
      delays: {
        MESSAGE_LENGHT_BASED_DELAY: (
          context: MachineContext,
          _event: AnyEventObject
        ) => {
          if (textSpeed === TextSpeed.FAST) {
            return 0;
          }

          const lastWidget = cloneDeep(context.widgetData).pop() ?? {
            type: 'noneYet',
          };

          if (
            lastWidget.type === 'coachMessage' ||
            lastWidget.type === 'singleDisplay'
          ) {
            const minWaitTime = 1000;
            const maxWaitTime = 10000;

            const multiplier = textSpeed === TextSpeed.MEDIUM ? 25 : 40;

            if (
              lastWidget.props.message &&
              typeof lastWidget.props.message === 'string'
            ) {
              const messageLength = lastWidget.props.message.length;
              const messageWaitTime = multiplier * messageLength;

              const waitTime = Math.min(
                Math.max(minWaitTime, messageWaitTime),
                maxWaitTime
              );

              return waitTime;
            } else {
              return 0;
            }
          }

          return 0;
        },
      },
    };
  }, [
    coachaccountid,
    coacheeaccountid,
    context,
    handlePrint,
    intl,
    isPublicRoute,
    moduleTitle,
    navigate,
    numStates,
    session?.originalmoduleid,
    session?.previewsession,
    sessionId,
    sessionLanguage,
    sessionState.currentMachineState,
    textSpeed,
  ]);

  // info about guards, https://xstate.js.org/docs/guides/guards.html
  // useMemo is used to prevent warnings in player as described in PROD-949
  // see also https://github.com/statelyai/xstate/issues/1101
  const machine = useMemo(
    () =>
      createMachine<MachineContext>(machineDefinition, {
        guards: {
          ...loopGuards,
          ...compareGuards,
          ...randomNodeGuards,
          ...checkGuards,
          ...classificationGuards,
          ...sentimentGuards,
          ...microchatGuards,
        },
        services: {
          ...newSessionServices,
          ...newExternalCallServices,
          ...newExternalMicrochatCallServices,
        },
      }),
    [machineDefinition]
  );

  const [state, send, service] = useMachine(machine, machineStub);

  const currentMachineState = useSelector(service, (state) => state);
  const widgetData = useSelector(service, (state) => state.context.widgetData);
  const metaData = useSelector(service, (state) => state.context.metaData);

  const [avatarImage, setAvatarImage] = useState<string | undefined>(undefined);

  // if ImageAsset changes, update avatar image
  useEffect(() => {
    const setURL = async () => {
      metaData.currentCoachAvatarImage =
        metaData.currentCoachAvatarImage &&
        metaData.currentCoachAvatarImage.toLowerCase().startsWith('http')
          ? metaData.currentCoachAvatarImage
          : await resolveS3ApiCall(
              metaData.currentCoachAvatarImageAsset ?? '',
              isPublicRoute
            );
      // Mae, 26.10.2023 , use dedicated var for avatar Image as it seems to disappear
      // mail by Rebecca 25.10.
      setAvatarImage(metaData.currentCoachAvatarImage);
    };
    setURL();
    // disable that IDE asks for metaData as dependency
    // not needed here! It triggers too often if we add it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    metaData.currentCoachAvatarImage,
    metaData.currentCoachAvatarImageAsset,
    isPublicRoute,
  ]);

  //
  // update avatar image if async macheinDefinition changes
  //
  useEffect(() => {
    const states = machineDefinition?.states;

    if (states) {
      Object.keys(states).forEach((key) => {
        const e = states[key].entry;
        if (
          e &&
          Array.isArray(e) &&
          e.length > 0 &&
          e[0].payload &&
          e[0].type === 'setAvatarImage'
        ) {
          metaData.currentCoachAvatarImage = e[0].payload.resolvedsrc;
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineDefinition, metaData.currentCoachAvatarImage]);

  const { mutate } = useUpdateSessionStateMutation();

  //
  // every time any data changes, save it
  //
  useEffect(() => {
    // if is playeground: do not save
    if (isPlayground) {
      return;
    }

    // if session is viewed as shared session, watcher shouldn't be able to modify session
    if (sharedSession) {
      return;
    }

    //
    // check for xstate.stop
    // if we don't do that , the state is saved for every transition
    // and that is not necessary.
    //
    if (
      currentMachineState &&
      currentMachineState.actions &&
      currentMachineState.actions.length > 0 &&
      currentMachineState.actions[0].type !== 'xstate.stop' &&
      currentMachineState.actions[0].type !== 'xstate.cancel'
    ) {
      return;
    }

    //
    // PROD-1851 - we removed all the context parameters are they were stored
    // as duplicates in currentMachineState and (!) in the single context parameters.
    //
    const save = async () => {
      // save session state
      mutate({
        sessionId,
        body: {
          sessionstate: {
            currentMachineState, // contains all (!) context data
          },
        },
        isPublicRoute,
      });
    };

    // call save without waiting for the response
    save();
  }, [
    isPlayground,
    mutate,
    sessionId,
    currentMachineState,
    context,
    sharedSession,
    isPublicRoute,
  ]);

  /** check whether element is the last element in a shared session
   * @return {boolean} returns true if element is last element, false otherwise
   */
  const isLastElementInASharedSession = (curr: any, index: number): boolean => {
    // check whether last widget is temporary
    // if yes, we consider it to be an input request. But for a shared
    // session, we do not want the viewer to edit anything ==> do not display
    return sharedSession && curr.temporary && index === widgetData.length - 1;
  };

  const [refreshIntervalTimerId, setRefreshIntervalTimerId] =
    useState<any>(undefined);
  const refreshHandler = () => {
    if (refreshIntervalTimerId) {
      clearInterval(refreshIntervalTimerId);
      setRefreshIntervalTimerId(undefined);
    } else {
      // refresh session every 30 seconds

      setRefreshIntervalTimerId(
        setInterval(() => {
          refetchSession();
        }, 10000)
      );
    }
  };

  /** manage clicks in Progress Bar and scroll chat to start of clicked phase */
  const [phaseReference, setPhaseReference] = useState<string>('');
  const phaseClickHandler = (phaseReference: string, _index: number) => {
    setPhaseReference(phaseReference);
  };

  // click on "end/pause session" in progress bar

  const endClickHandler = () => {
    navigate(`${AppRoutes.RECEPTION}`);
  };

  const [callACoachOpen, setCallACoachOpen] = useState<boolean>(false);
  const callClickHandler = () => {
    setCallACoachOpen(true);
  };

  const endOfChatRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    endOfChatRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  }, [widgetData]);

  // scrolling also takes place in Scroll Container if you focus to an
  // editable object (s. also PROD-1081)
  useEffect(() => {
    if (scrollContainerRef && scrollContainerRef.current) {
      if (phaseReference) {
        if (document) {
          // get anchor of phase
          let anchor = document.getElementById(phaseReference);
          if (anchor === null) {
            // if anchor of phase does not exist use anchor for end of chat
            anchor = document.getElementById(endOfChatAnchor);
          }
          if (anchor !== null) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
          }
        }
      }
    }
  }, [scrollContainerRef, phaseReference]);

  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );

  // for the /publicplayer route, we want to preserve the header. This route
  // forwards to anozher public route and we recognize the origin / the request
  // to preserve the header by a ?h=true query parameter.
  const preserveHeader =
    document.location.search?.startsWith('?h=true') || !isPublicRoute;

  // adapt height accoding to phases, but 5 phases high and not higher than window height
  const phaseHeight = Math.min(
    Math.min(
      (modulePhases ? modulePhases.length * 60 : 2 * 60) -
        (isPublicRoute ? 200 : 0),
      5 * 60 // max 5 phases hight
    ),
    isPublicRoute ? vh - 450 : vh - 420
  );

  const { isMobile } = useContext(WindowContext);

  const gridStyles =
    isPublicRoute && !preserveHeader
      ? {
          backgroundColor: 'FFFFFF',
        }
      : // : { paddingTop: '20px' };
        {};

  const mobileStyles = isMobile ? { width: '100%' } : {};

  // Improve transitions, when new messages are added
  const nodeRef = useRef(null);
  //const classes = useStyles();

  return (
    <SendContextProvider send={send} context={state.context}>
      {devToolsActivated && <MachineDebug state={state} />}
      <CallACoachPopup
        isOpen={callACoachOpen}
        setOpenState={setCallACoachOpen}
        sessionId={sessionId}
        isPublicRoute={isPublicRoute}
      />
      <Grid
        container
        wrap="nowrap"
        justifyContent={!isMobile ? 'center' : 'none'}
        columnSpacing={!isMobile ? 10 : 0}
      >
        {showProgressbar && !isMobile && (
          <Grid item style={{ paddingTop: '10px' }}>
            <ProgressBar
              hideProgressRing={true}
              currPercent={metaData.currentModuleProgressPercent}
              currPhaseReference={metaData.currentModulePhase}
              coachingPhases={modulePhases ?? []}
              coachImage={CoachEnum.COACH_MO}
              coachAvatarImage={avatarImage}
              phaseClickHandler={phaseClickHandler}
              printClickHandler={handlePrint}
              endClickHandler={endClickHandler}
              callClickHandler={callClickHandler}
              phaseHeight={phaseHeight}
              autoRefreshHandler={sharedSession ? refreshHandler : undefined}
              hintMessage={intl.formatMessage({
                id: 'player.progressbar.hintmessage.text',
                defaultMessage:
                  'Die Liste der Phasen ist scrollbar. Außerdem kannst du mit einem Klick auf eine der Phasen direkt zur passenden Stelle im Chat springen.',
              })}
              printToolTip={intl.formatMessage({
                id: 'player.progressbar.printToolTip.text',
                defaultMessage: 'Drucke oder exportiere deinen Chat als PDF.',
              })}
              endToolTip={intl.formatMessage({
                id: 'player.progressbar.endToolTip.text',
                defaultMessage:
                  'Beende den Chatbot und springe zurück zur Liste der Chatbot Themen. Du kannst deine Chatbot-Session jederzeit fortsetzen und alte Chatbot-Protokolle einsehen.',
              })}
              callToolTip={intl.formatMessage({
                id: 'player.progressbar.callacoach.text',
                defaultMessage:
                  'Du brauchst Unterstützung? Nehme Kontakt zu deinem Coach auf.',
              })}
            />
          </Grid>
        )}
        <Grid item style={{ ...gridStyles, ...mobileStyles }}>
          <ScrollContainer
            ref={scrollContainerRef}
            isPublicRoute={isPublicRoute}
          >
            {widgetData.map((curr: Component, index: number) => {
              let component: any;
              const isCoacheeMessage =
                curr.type === 'coacheeMessage' ||
                curr.type === 'moodIcon' ||
                (curr.type === 'postItDisplay' &&
                  curr.props &&
                  curr.props.isEcho !== undefined &&
                  curr.props.isEcho === true) ||
                curr.type === 'emotionIcon';

              // This mapping from "type"->"React Component" could be moved to Component.
              // if this is a shared session and the last widget is a input widget, we do not render it
              // as the viewer of a shared session shouldn't be able to modify the session
              if (!curr || isLastElementInASharedSession(curr, index)) {
                component = null;
              } else {
                switch (curr.type) {
                  case 'coachMessage':
                    //
                    // distinuish classic text based props and HtmlFormatter
                    // messages for AI messages
                    //
                    // see CoachMessage UI component implementation for details
                    //
                    if (
                      typeof curr.props.message !== 'string' &&
                      !Array.isArray(curr.props.message)
                    ) {
                      component = (
                        <CoachMessage
                          {...curr.props}
                          message={getHtmlFormatter(
                            (curr.props.message as any).props.text as string
                          )}
                        />
                      );
                    } else {
                      component = <CoachMessage {...curr.props} />;
                    }

                    break;
                  case 'coacheeMessage':
                    component = <CoacheeMessage {...curr.props} />;
                    break;
                  case 'calenderExportComposite':
                    component = <CalenderExportComposite {...curr.props} />;
                    break;
                  case 'messageInput':
                    component = <MessageInput {...curr.props} />;
                    break;
                  case 'moodInput':
                    component = <MoodInput {...curr.props} />;
                    break;
                  case 'moodIcon':
                    component = <MoodIcon {...curr.props} />;
                    break;
                  case 'nextButton':
                    component = <NextButton {...curr.props} />;
                    break;
                  case 'scaleInput':
                    component = <ScaleInput {...curr.props} />;
                    break;
                  case 'percentageScaleInput':
                    component = <PercentageScaleInput {...curr.props} />;
                    break;
                  case 'multipleOutputComposite':
                    component = <MultipleOutputComposite {...curr.props} />;
                    break;
                  case 'ratingInput':
                    component = <RatingInput {...curr.props} />;
                    break;
                  case 'yesNoButton':
                    component = <YesNoButton {...curr.props} />;
                    break;
                  case 'polarchart':
                    component = <PolarchartDisplay {...curr.props} />;
                    break;
                  case 'radarchart':
                    component = <RadarchartDisplay {...curr.props} />;
                    break;
                  case 'polarchartInput':
                    component = <PolarchartInput {...curr.props} />;
                    break;
                  case 'multipleInputComposite':
                    component = <MultipleInputComposite {...curr.props} />;
                    break;
                  case 'multipleInput':
                    component = <MultipleInput {...curr.props} />;
                    break;
                  case 'postItInput':
                    component = <PostItInput {...curr.props} />;
                    break;
                  case 'postItDisplay':
                    component = <PostItDisplay {...curr.props} />;
                    break;
                  case 'multipleDisplayComposite':
                    component = <MultipleDisplayComposite {...curr.props} />;
                    break;
                  case 'radioButton':
                    component = <RadioButton {...curr.props} />;
                    break;
                  case 'videoDisplay':
                    component = <VideoDisplay {...curr.props} />;
                    break;
                  case 'copyLink':
                    component = <CopyLink {...curr.props} />;
                    break;
                  case 'audioDisplay':
                    component = <AudioDisplay {...curr.props} />;
                    break;
                  case 'dateInput':
                    component = <DateInput {...curr.props} />;
                    break;
                  case 'timeInput':
                    component = <TimeInput {...curr.props} />;
                    break;
                  case 'imageDisplay':
                    component = <ImageDisplay {...curr.props} />;
                    break;
                  case 'imageSelect':
                    component = <ImageSelect {...curr.props} />;
                    break;
                  case 'scaleButton':
                    component = <ScaleButton {...curr.props} />;
                    break;
                  case 'editableMultiselect': // applies also for NeedsInput!
                    component = <EditableMultiselect {...curr.props} />;
                    break;
                  case 'emotionInput':
                    component = <EmotionInput {...curr.props} />;
                    break;
                  case 'emotionInputComposite':
                    component = <EmotionInputComposite {...curr.props} />;
                    break;
                  case 'emotionIcon':
                    component = <EmotionIcon {...curr.props} />;
                    break;
                  case 'multiButton':
                    component = <MultiButton {...curr.props} />;
                    break;
                  case 'binarySorter':
                    component = <BinarySorter {...curr.props} />;
                    break;
                  case 'linkButton':
                    component = <LinkButton {...curr.props} />;
                    break;
                  case 'selectionCard':
                    component = <SelectionCard {...curr.props} />;
                    break;
                  case 'phaseAnchor':
                    component = <PhaseAnchor {...curr.props} />;
                    break;
                  case 'sceneCanvasCharacterSelector':
                    component = (
                      <SceneCanvasCharacterSelector {...curr.props} />
                    );
                    break;
                  case 'sceneCanvas':
                    component = <SceneCanvas {...curr.props} />;
                    break;
                  case 'canvasEditor':
                    component = <CanvasEditor {...curr.props} />;
                    break;
                  case 'singleDisplay':
                    component = <SingleDisplay {...curr.props} />;
                    break;
                  case 'hotOrNotSelection':
                    component = <HotOrNotSelection {...curr.props} />;
                    break;
                  case 'formattedInput':
                    component = <FormattedInput {...curr.props} />;
                    break;
                  case 'valueCardList':
                    component = <ValueCardList {...curr.props} />;
                    break;
                  case 'multiplePercentageScaleInput':
                    component = (
                      <MultiplePercentageScaleInput {...curr.props} />
                    );
                    break;
                  case 'navigateButton':
                    component = <NavigateButton {...curr.props} />;
                    break;
                  case 'downloadButton':
                    component = <DownloadButton {...curr.props} />;
                    break;
                  case 'threeDots':
                    component = <ThreeDots {...curr.props} />;
                    break;
                  default:
                    component = null;
                    break;
                }
              }
              return (
                <div
                  ref={nodeRef}
                  style={{
                    marginBottom:
                      index !== widgetData.length - 1 &&
                      curr.type !== 'phaseAnchor'
                        ? '1rem'
                        : '',
                    maxWidth: '80%',
                    width:
                      curr.type === 'coachMessage' || isCoacheeMessage
                        ? 'fit-content'
                        : '100%',
                    marginLeft: isCoacheeMessage ? 'auto' : 'none',
                  }}
                  key={`${index}`}
                >
                  {component}
                </div>
              );
            })}
            <PhaseAnchor phaseReference={endOfChatAnchor} />
            <div ref={endOfChatRef} />
            <Box height={1} />.
          </ScrollContainer>
        </Grid>
      </Grid>
    </SendContextProvider>
  );
};
