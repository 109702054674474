import { defineMessages } from '@formatjs/intl';
import { Typography } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, useParams } from 'react-router-dom';

import { AccountContext } from '../account';
import { ApiError } from '../api';
import { useAcceptProgramInvitationMutation } from '../api/program/useAcceptProgramInvitationMutation';
import { ReceptionButton } from '../components/ReceptionButton';
import { ProgramInstancePermission, Tenant } from '../entities';
import {
  AppRoutes,
  InvitationRouteParams,
  ReceptionRoutes,
} from '../routing/routes';

const invitationPageMessages = defineMessages({
  invitationFailedHint: {
    id: 'player.pages.programinvitation.failed.hint',
    defaultMessage: 'Akzeptieren der Einladung fehlgeschlagen',
  },
  invitationIdNoCorrect: {
    id: 'player.pages.programinvitation.failed.wrongid',
    defaultMessage: 'Dein Einladungscode scheint falsch zu sein.',
  },
  unknownError: {
    id: 'player.pages.programinvitation.failed.unknown',
    defaultMessage: 'Irgendetwas ist schiefgegangen.',
  },
  contactCoach: {
    id: 'player.pages.programinvitation.failed.contactCoach',
    defaultMessage:
      'Bitte kontaktiere den Coach, von dem du die Einladung erhalten hast.',
  },
  succesfullyAccepted: {
    id: 'player.pages.programinvitation.success.message',
    defaultMessage:
      'Du hast die Einladung erfolgreich akzeptiert. Du findest dein neues Coaching Programm auf der Startseite.',
  },
  acceptanceInProgress: {
    id: 'player.pages.programinvitation.success.acceptanceInProgress',
    defaultMessage:
      'Deine Einladung wird akzeptiert. Bitte einen Augenblick Geduld ...',
  },
});

export const ProgramInvitationPage: React.FC = () => {
  // 1) get programinvitation from URL
  const { invitationId: invitationIdByUrl } =
    useParams<InvitationRouteParams>();

  const { setCurrentTenant, account } = useContext(AccountContext);

  // if programinvitation is in URL, take it, if not, use localstorage
  const invitationId = useMemo(() => {
    // 2) get programinvitation from localstorage
    const invitationIdByLocalStorage = localStorage.getItem(
      'evoachProgrammInvitation'
    );

    return invitationIdByUrl && invitationIdByUrl !== 'pending'
      ? invitationIdByUrl
      : invitationIdByLocalStorage;
  }, [invitationIdByUrl]);

  const { mutate, isError, error, isLoading, data } =
    useAcceptProgramInvitationMutation();

  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  useEffect(() => {
    localStorage.setItem('evoachProgrammInvitation', '');
    if (invitationId && !isUpdating && !isLoading && account) {
      setIsUpdating(true);

      mutate(invitationId, {
        onSuccess: async (data: any) => {
          // PROD-2010
          // set tenant based on data
          // invitingaccount: { accountid,firstname,lastname }
          if (data && data.length > 0 && data[0].invitingaccount) {
            setCurrentTenant({
              tenantid: data[0].invitingaccount.accountid,
              firstname: data[0].invitingaccount.firstname,
              lastname: data[0].invitingaccount.lastname,
            } as Tenant);
          }

          const previouslyHighlightedPrograms = JSON.parse(
            localStorage.getItem('evoach.player.programs.highlighted') ?? '[]'
          );
          const programInstanceIds = data.map(
            (programinstancepermission: ProgramInstancePermission) => {
              return {
                programInstanceId:
                  programinstancepermission.programinstance.programinstanceid,
                highlightedDate: Date.now(),
              };
            }
          );
          const stringifiedProgramInstanceIds = JSON.stringify(
            previouslyHighlightedPrograms.concat(programInstanceIds)
          );
          localStorage.setItem(
            'evoach.player.programs.highlighted',
            stringifiedProgramInstanceIds
          );
        },
      });
    }
  }, [account, invitationId, isLoading, isUpdating, mutate, setCurrentTenant]);

  //

  return (
    <Typography component="span" variant="h6">
      {isError && (
        <div>
          <FormattedMessage {...invitationPageMessages.invitationFailedHint} />
          {error instanceof ApiError && error.httpStatus === 400 ? (
            <div style={{ marginTop: 15 }}>
              <FormattedMessage
                {...invitationPageMessages.invitationIdNoCorrect}
              />
            </div>
          ) : (
            <div style={{ marginTop: 15 }}>
              <FormattedMessage {...invitationPageMessages.unknownError} />
            </div>
          )}

          <div style={{ marginTop: 15 }}>
            <FormattedMessage {...invitationPageMessages.contactCoach} />
          </div>
        </div>
      )}
      {isLoading && (
        <div>
          <FormattedMessage {...invitationPageMessages.acceptanceInProgress} />
        </div>
      )}
      {!isLoading &&
        !isError &&
        data !== undefined && ( // prevents unmounting before data is received
          <Navigate
            to={AppRoutes.RECEPTION + '/' + ReceptionRoutes.PROGRAMS}
          ></Navigate>
        )}
      {(isError || isLoading) && (
        <div style={{ marginTop: 15 }}>
          <ReceptionButton />
        </div>
      )}
    </Typography>
  );
};
