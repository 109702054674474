import { ImageDisplayProps } from '@evoach/ui-components';
import { useQuery } from 'react-query';
import { IntlShape } from 'react-intl';
import { translateWithFallback } from '@evoach/ui-components';

import { authorizedGet, unauthorizedGet } from '../authorizedApi';

// reduce API and S3 traffic by caching pre-signed GET Urls
const cachedAssets: Record<string, string> = { '': '' };
cachedAssets[' '] = '';

export const useFetchAssetUrl = (assetid: string) => {
  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    `asseturl-${assetid}`,
    async () => {
      // if already cached, return cache
      if (Object.keys(cachedAssets).includes(assetid)) {
        return cachedAssets[assetid];
      }

      const assetURL: RequestInfo = `/asset/${assetid}`;

      const getAssetURL = await authorizedGet(assetURL);
      const response = await getAssetURL();

      const url = (await response.json()).url;

      if (url) {
        cachedAssets[assetid] = url;
      }

      return url;
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    url: data,
    refetch,
  };
};

export const resolveS3ApiCall = async (
  assetid: string,
  publicRoute: boolean = false
): Promise<string> => {
  // if already cached, return cache
  if (Object.keys(cachedAssets).includes(assetid)) {
    return cachedAssets[assetid];
  }

  const assetURL: RequestInfo = `/asset/${assetid}`;

  const getAssetURL = !publicRoute
    ? authorizedGet(assetURL)
    : unauthorizedGet(assetURL);
  const response = await getAssetURL();

  const url = (await response.json()).url;

  if (url) {
    cachedAssets[assetid] = url;
  }

  return url;
};

export const resolveS3Url = (props: ImageDisplayProps, intl: IntlShape) => {
  // we use the translate mechanism to choose different pictures per language
  let imgsrc = props.src;
  // old version of the component considers src without translation. In that case,
  // the url would start with http and wouldn't be a translation key
  //console.log(imgsrc);

  if (!imgsrc.toLowerCase().startsWith('http')) {
    // new components use translation keys; we also distinguish between
    // external URL (src) and internal URL (assetid) - internal has higher
    // priority
    //console.log('.'+translateWithFallback(intl, props.assetid).trim()+'');

    const srcToResolve =
      translateWithFallback(intl, props.assetid).trim() !== ''
        ? translateWithFallback(intl, props.assetid)
        : translateWithFallback(intl, props.src);

    console.log(srcToResolve);

    // if srcToResolve is external URL (comes from translated src)
    // then use it. Otherwise, we have an assetid that has to be resolved
    if (!srcToResolve.toLowerCase().startsWith('http')) {
      resolveS3ApiCall(srcToResolve)
        .then((result: any) => {
          props.resolvedsrc = result;
        })
        .finally(() => {
          return props;
        });
    } else {
      props.resolvedsrc = srcToResolve;
    }
    return props;
  } else {
    props.resolvedsrc = imgsrc;
    return props;
  }
};

/**
 * get asset URL from backend
 *
 * @param {string} assetid
 * @returns {Promise<string>} pre-signed AWS URL to asset
 */
export const getAssetUrl = async (assetid: string): Promise<string> => {
  // if already cached, return cache
  if (Object.keys(cachedAssets).includes(assetid)) {
    return cachedAssets[assetid];
  }

  try {
    const assetURL: RequestInfo = `/asset/${assetid}`;
    const getAssetURL = authorizedGet(assetURL);
    const response = await getAssetURL();

    const url = (await response.json()).url;

    if (url) {
      cachedAssets[assetid] = url;
    }

    return url;
  } catch (e) {
    return '';
  }
};
