import { useMutation } from 'react-query';

import { authorizedPost, unauthorizedPost } from '../authorizedApi';

export const useStartSessionMutation = () => {
  // mutations documentation: https://react-query.tanstack.com/guides/mutations
  return useMutation<any, Error, any>(
    async ({ moduleId, language, publicRoute = false, coachAccountId }) => {
      // the routes differ for the public-API and the token secured API
      // because we parse the moduleid from the route in the authorizer-lambda
      // of the public API
      const url = !publicRoute
        ? `/session/?language=${language}&moduleid=${moduleId}&coachid=${coachAccountId}`
        : `/session/${moduleId}?language=${language}`;

      const startNewSession = !publicRoute
        ? authorizedPost(url)
        : unauthorizedPost(url);
      const response = await startNewSession();

      const data = await response.json();

      return data.sessionid;
    },
    {
      retry: 5, //  exponential backoff
      // if we run into an 403, it may happen because the coach misconfigured
      // the settings of the embedded chatbot in the Creator. In this case,
      // we want to fail fast and not retry.
      retryDelay: (failureCount: number, error: any): any => {
        // fail fast in case of 403
        if (error.httpStatus === 403) {
          // retry can't be switched of, so we at least retry immediately
          // to come to an end as fast as possible
          return 0;
        }
        // if retry is fine, we use exponential backoff
        return (2 ^ failureCount) * 1000;
      },
    }
  );
};
