import { MachineContext } from '@evoach/ui-components';

/**
 * @returns true
 */
export const classificationGuard = (
  context: MachineContext,
  _event: any,
  index: number
): boolean => {
  const payload = context.userData['evoach.externalCall.payload'];
  const classificationKeys: string[] = payload.classificationKeys ?? [];

  const classificationIndex =
    context.userData['evoach.externalCall.classificationIndex'];

  if (classificationIndex !== undefined) {
    //
    // new generic classification
    //
    return classificationIndex === index;
  } else {
    //
    // old legacy classification - not used any more in new chatbots
    //
    const keyWithHighestValue =
      context.userData['evoach.externalCall.classificationKeyWithHighestValue'];

    // if there are no classification results, return true for first out handle
    if (keyWithHighestValue === '' || classificationKeys.length === 0) {
      return index === 0;
    }

    // find the index of that key in our initial keyTexts ...
    // if this key is the reequested index, return true
    return classificationKeys.indexOf(keyWithHighestValue) === index;
  }
};

/**
 * @returns true index of best result is 0
 */
export const classificationBranchGuard0 = (
  context: MachineContext,
  _event: any
): boolean => {
  return classificationGuard(context, _event, 0);
};
export const classificationBranchGuard1 = (
  context: MachineContext,
  _event: any
): boolean => {
  return classificationGuard(context, _event, 1);
};
export const classificationBranchGuard2 = (
  context: MachineContext,
  _event: any
): boolean => {
  return classificationGuard(context, _event, 2);
};
export const classificationBranchGuard3 = (
  context: MachineContext,
  _event: any
): boolean => {
  return classificationGuard(context, _event, 3);
};
export const classificationBranchGuard4 = (
  context: MachineContext,
  _event: any
): boolean => {
  return classificationGuard(context, _event, 4);
};
export const classificationBranchGuard5 = (
  context: MachineContext,
  _event: any
): boolean => {
  return classificationGuard(context, _event, 5);
};
export const classificationBranchGuard6 = (
  context: MachineContext,
  _event: any
): boolean => {
  return classificationGuard(context, _event, 6);
};
export const classificationBranchGuard7 = (
  context: MachineContext,
  _event: any
): boolean => {
  return classificationGuard(context, _event, 6);
};
export const classificationBranchGuard8 = (
  context: MachineContext,
  _event: any
): boolean => {
  return classificationGuard(context, _event, 6);
};
export const classificationBranchGuard9 = (
  context: MachineContext,
  _event: any
): boolean => {
  return classificationGuard(context, _event, 6);
};
export const classificationBranchGuard10 = (
  context: MachineContext,
  _event: any
): boolean => {
  return classificationGuard(context, _event, 6);
};

/** classification guards */
export const classificationGuards = {
  classificationBranchGuard0,
  classificationBranchGuard1,
  classificationBranchGuard2,
  classificationBranchGuard3,
  classificationBranchGuard4,
  classificationBranchGuard5,
  classificationBranchGuard6,
  classificationBranchGuard7,
  classificationBranchGuard8,
  classificationBranchGuard9,
  classificationBranchGuard10,
};
