import { defineMessages } from '@formatjs/intl';
import React, { useCallback, useState } from 'react'; // useContext
import { FormattedMessage } from 'react-intl';
import { useDropzone } from 'react-dropzone';
import { CircularProgress, Typography } from '@mui/material';
import { evoachDefaultTheme } from '@evoach/ui-components';

//import { AccountContext } from '../../../account';
import { authorizedPost, authorizedPut } from '../../api/authorizedApi';

const dropzoneMessages = defineMessages({
  dndhint: {
    id: 'player.reception.assests.dropzone.hint',
    defaultMessage:
      'Bitte ziehe deine Dateien in diesen Bereich oder klicke in die Fläche, um eine Datei auszuwählen und hochzuladen.',
  },
});

interface DropzoneProps {
  /**
   * Function returns new asset from database.
   * Handler signature should be something like (asset: Asset) => {}
   */
  onUpdate: Function;
}

export const Dropzone: React.FC<DropzoneProps> = ({ onUpdate }) => {
  //const { account } = useContext(AccountContext);
  //const { uploadUrl } = useCreateAssetQuery(assetKey);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInDragZone, setIsInDragZone] = useState<boolean>(false);

  const getUploadUrl = async (assetkey: string) => {
    // folder "accountid" for assetkey is automatically added in backend
    const getS3UploadUrl = authorizedPost(`/asset/uploadurl/${assetkey}`);
    const getUploadUrlReponse = await getS3UploadUrl();
    const data = await getUploadUrlReponse.json();
    return data;
  };

  const saveAssetinDB = async (assetkey: string) => {
    const body = {
      assetfilename: assetkey,
    };
    const saveAssetInDB = authorizedPost('/asset/', body);
    const saveAssetInDBResponse = await saveAssetInDB();
    const response = await saveAssetInDBResponse.json();

    return response;
  };

  const setIsPresent = async (assetid: any) => {
    const body = {
      ispresent: true,
    };
    const updateAssetInDB = authorizedPut(`/asset/${assetid}`, body);
    const updateAsset = await updateAssetInDB();
    const data = await updateAsset.json();

    return data;
  };

  const uploadFile = async (
    uploadUrl: string,
    binaryStr: string | ArrayBuffer | null
  ) => {
    const result = await fetch(uploadUrl, {
      method: 'PUT',
      body: binaryStr,
    });
    return result;
  };

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      setIsLoading(true);
      setIsInDragZone(false);
      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = async () => {
          // 1. Create asset key from aacountid + filename
          const filesplit = file.path.split('/');

          const assetKey =
            filesplit.length > 0 ? filesplit[filesplit.length - 1] : file.path;

          // 2. Save asset in DB
          const dbasset = await saveAssetinDB(assetKey);

          // 3. Get upload URL
          const uploadUrl = await getUploadUrl(assetKey);

          // 4. Upload to s3
          const binaryStr = reader.result;
          const fileUploaded = await uploadFile(uploadUrl.assetUrl, binaryStr);

          if (fileUploaded.ok) {
            // 5. When sccessfully uploaded, update DB entry and set isPresent = true
            await setIsPresent(dbasset.assetId);
          }
          onUpdate(dbasset);
        };
        reader.readAsArrayBuffer(file);
      });
    },
    [onUpdate]
  );

  function dragEnter () {
    setIsInDragZone(true);
  }

  function dragLeave () {
    setIsInDragZone(false);
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop, onDragEnter: () => dragEnter(), onDragLeave: () => dragLeave() });

  return (
    <div 
      {...getRootProps()}
      style={{
        width: '98%',
        height: '110px',
        borderWidth: '3px',
        borderStyle: 'dashed',
        borderRadius: '0.5rem',
        borderColor: evoachDefaultTheme.palette.secondary.main,
        backgroundColor: isInDragZone? evoachDefaultTheme.palette.secondary.light : 'white',
      }}
    >
      <input {...getInputProps()} />
        <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1em',
        }}>
          <Typography variant="body2" component="span" style={{marginTop: '10px', marginLeft: '10px'}}>
            <FormattedMessage {...dropzoneMessages.dndhint} />
          </Typography>
          {(isLoading) && (<CircularProgress />)} 
        </div>
    </div>
  );
};
