import { useMutation } from 'react-query';

import { authorizedPut } from '../authorizedApi';

export const useUpdateProgramInstanceSession = (
  programInstanceId: string | undefined
) => {
  const { isLoading, data, isError, error, mutate } = useMutation(
    async (session: any) => {
      const url = `/programinstance/${programInstanceId}/session`;
      const updateProgramInstanceSesssionCall = authorizedPut(url, {
        session: session,
      });
      const response = await updateProgramInstanceSesssionCall();
      const responseData = await response.json();
      return responseData;
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    mutate,
    updatedSession: data,
  };
};
