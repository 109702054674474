import { useQuery } from 'react-query';

import {
  Account,
  AccountProfile,
  ProgramInstancePermission,
} from '../../entities';
import {
  Program,
  ProgramModule,
  ProgramPermission,
} from '../../entities/Program';
import { ProgramInstance } from '../../entities/ProgramInstance';
import { ProgramRouteTypeEnum } from '../../routing/routes';
import { getAssetUrl, resolveS3ApiCall } from '../asset/useFetchAssetUrl';
import { authorizedGet, unauthorizedGet } from '../authorizedApi';

/**
 * get a program depending
 * @param {string} programId to be fetched
 * @returns isLoading, isError, error, program, refetch
 */
export const useFetchProgram = (programId: string = '') => {
  return useFetchProgramOrInstance(programId, ProgramRouteTypeEnum.PROGRAM);
};

/**
 * useFetchProgramOrInstance gets a program from a different endpoint, depending on programType
 *
 * ! this useFetchProgramOrInstance differs from the one in Creator!
 *
 * as we have a public page for programs, we always get programs via the public API
 * and use unauthorizedGet for that! Instances are always retrieved authorized
 *
 * @param {string} programId
 * @param {ProgramRouteTypeEnum} programRouteType
 * @returns
 */
export const useFetchProgramOrInstance = (
  programId: string = '',
  programRouteType: ProgramRouteTypeEnum | undefined
) => {
  // read https://react-query.tanstack.com/guides/caching for understanding
  // the caching behavior of this query

  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    `program-${programId}-${programRouteType}`,
    async () => {
      if (!programRouteType || !programId || programId.trim() === '')
        return undefined;

      let url = '';
      let fetchProgramCall;

      // ! difference to Creator implementation!
      if (programRouteType === ProgramRouteTypeEnum.INSTANCE) {
        url = `/programinstance/${programId}`;
        fetchProgramCall = authorizedGet(url);
      } else {
        url = `/program/${programId}`;
        fetchProgramCall = unauthorizedGet(url);
      }

      const response = await fetchProgramCall();
      const data: Program | ProgramInstance = await response.json();

      //
      // resolve assets
      //
      // resolve assets of program modules ==> for timeline
      //    ==> editor resolves it in ProhgramModuleEditor.tsx
      for (let i = 0; i < data.program.modules.length; i++) {
        data.program.modules[i] = await resolveProgramModuleAssets(
          data.program.modules[i]
        );
      }

      let profile;

      if (programRouteType === ProgramRouteTypeEnum.INSTANCE) {
        // program instance
        const invitingaccounts = (
          data.permissions as ProgramInstancePermission[]
        ).filter((perm: any) => perm.invitingaccount);

        if (invitingaccounts.length > 0) {
          profile = invitingaccounts[0].invitingaccount?.metainfos?.profile;
        }
      } else {
        // program
        profile = data.permissions[0].account?.metainfos?.profile;
      }

      if (profile) {
        // profile picture if available
        profile.profilePictureResolvedUrl =
          profile.profilePictureSrc && profile.profilePictureSrc !== ''
            ? profile.profilePictureSrc
            : profile.profilePictureAssetId &&
              profile.profilePictureAssetId !== ''
            ? await resolveS3ApiCall(profile.profilePictureAssetId, true)
            : '';
        // logo if available
        profile.logoPictureResolvedUrl =
          profile.logoPictureSrc && profile.logoPictureSrc !== ''
            ? profile.logoPictureSrc
            : profile.logoPictureAssetId && profile.logoPictureAssetId !== ''
            ? await resolveS3ApiCall(profile.logoPictureAssetId, true)
            : '';
      }

      return data;
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    programinstance:
      programRouteType === ProgramRouteTypeEnum.INSTANCE
        ? (data as ProgramInstance)
        : (data as Program),
    refetch,
  };
};

/**
 * resolve a image of a single program module
 * @param {ProgramModule} cmod
 * @returns {Promise<ProgramModule>} resolved program module
 */
export const resolveProgramModuleAssets = async (cmod: ProgramModule) => {
  if (cmod.assetid && cmod.assetid !== '') {
    cmod.resolvedsrc = await getAssetUrl(cmod.assetid);
  } else {
    if (cmod.src && cmod.src !== '') {
      cmod.resolvedsrc = cmod.src;
    } else {
      // TODO map .image prop to player path
      // function can be fodu in AssetHelper  mapImageToPublicAsset
      cmod.resolvedsrc = '';
    }
  }
  return cmod;
};

/**
 *
 * @param program
 * @param isLoading
 * @returns
 */
export const getCoachData = (
  program: Program | ProgramInstance,
  isLoading: boolean,
  account?: Account | null
): {
  givenname: string;
  familyname: string;
  email: string;
  profile: AccountProfile | undefined;
} => {
  if (isLoading || program === undefined) {
    return {
      givenname: '',
      familyname: '',
      email: '',
      profile: undefined,
    };
  }

  if (program && Object.keys(program).includes('programinstanceid')) {
    const programInstance = program as ProgramInstance;
    const permissions =
      programInstance.permissions as ProgramInstancePermission[];

    let coachAccount = permissions.find(
      (perm) => perm.invitingaccount
    )?.invitingaccount;

    if (!coachAccount) {
      const ownsPerm = permissions.find((perm) => perm.owns);
      if (ownsPerm) {
        // current user is owner of the instance, .i.e., the currenlty logged-in
        // coach should be displayed
        if (account) {
          coachAccount = account;
        }
      }
    }

    return {
      givenname: (coachAccount?.givenname as string) ?? '',
      familyname: (coachAccount?.familyname as string) ?? '',
      email: (coachAccount?.email as string) ?? '',
      profile: coachAccount?.metainfos?.profile,
    };
  } else {
    // PROD-1192 - do not access account in program but in ProgramPermission

    const programObj = program as Program;
    const permissions = programObj.permissions as ProgramPermission[];
    const account = permissions.find((perm) => perm.account !== null)?.account;

    return {
      givenname: (account?.givenname as string) ?? '',
      familyname: (account?.familyname as string) ?? '',
      email: (account?.email as string) ?? '',
      profile: account?.metainfos?.profile,
    };
  }
};
