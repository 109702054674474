import React, { useCallback, useContext, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import makeStyles from '@mui/styles/makeStyles';
import { AppHeader } from '@evoach/ui-components';
import { Divider } from '@mui/material';

import { LanguageSwitch } from '../components/LanguageSwitch';
import { AppVersion } from '../components/AppVersion';
import { DevToggle } from '../devtools/DevToggle';
import { AccountContext, RoleEnum } from '../account';
import { useEvoachKeycloak } from '../account/useEvoachKeycloak';
import { DisclaimerLink } from '../components/DisclaimerLink';
import { ProfileLink } from '../components/ProfileLink';
import { AccountProfile, EMPTY_PROFILE } from '../entities';
import { ProductTourMenuLink } from '../components/ProductTourMenuLink';
import { DevToolsContext } from '../devtools/DevToolsContext';
import { getEnvironment } from '../environment/useEnvironment';
import { WindowContext } from '../window/WindowContext';
import { ThemeSwitchContext } from '../theme';

import { AppHeaderContext } from './AppHeaderContext';

const appLayoutMessages = defineMessages({
  header: {
    id: 'player.layout.app.header',
    defaultMessage: 'Coaching Chatbot',
  },
  loginLabel: {
    id: 'player.layout.app.label.login',
    defaultMessage: 'Anmelden',
  },
  logoutLabel: {
    id: 'player.layout.app.label.logout',
    defaultMessage: 'Abmelden',
  },
});

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    marginTop: '80px',
  },
});

export type AppLayoutProps = {
  children?: React.ReactNode;
};
export const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  //console.log('React in player: ' + React.version);

  const classes = useStyles();
  const intl = useIntl();

  const { l } = useContext(DevToolsContext);

  const { currentMuiTheme } = useContext(ThemeSwitchContext);

  const { playerBasePath } = getEnvironment();

  const { login, logout, isAuthenticated } = useEvoachKeycloak();
  const { account, name, hasRole, isPublicRoute } = useContext(AccountContext);

  const { appHeaderTitle, appHeaderHomeroute } = useContext(AppHeaderContext);

  const handleLogin = useCallback(() => {
    login();
  }, [login]);

  const handleLogout = useCallback(() => {
    logout({
      redirectUri: playerBasePath,
    });
  }, [logout, playerBasePath]);

  const additionalMenuItems = [<LanguageSwitch />, <ProductTourMenuLink />];

  const additionalProgramMenu = [<></>];

  additionalMenuItems.push(<ProfileLink />);

  additionalMenuItems.push(<Divider />);
  additionalMenuItems.push(<DisclaimerLink />);

  if (hasRole(RoleEnum.EVOACHADMIN) || hasRole(RoleEnum.SUPPORT)) {
    additionalMenuItems.push(<DevToggle />);
  }

  if (hasRole(RoleEnum.EVOACHADMIN)) {
    additionalMenuItems.push(<AppVersion />);
  }

  additionalMenuItems.push(<Divider />);

  const isProgramRoute =
    document.location?.pathname?.startsWith('/program/') ?? false;

  l('AppLayout: Conditions to display header ....');
  l(
    document.location.search?.startsWith('?h=true') +
      '___' +
      !isPublicRoute +
      '___' +
      (isProgramRoute && isPublicRoute) +
      '___' +
      (isAuthenticated && !isPublicRoute) +
      '___'
  );

  const preserveHeader =
    document.location.search?.startsWith('?h=true') ||
    !isPublicRoute ||
    (isProgramRoute && isPublicRoute) ||
    (isAuthenticated && !isPublicRoute);

  const { isMobile } = useContext(WindowContext);

  // save in state
  const [coachProfile, setCoachProfile] = useState<AccountProfile>(
    account?.metainfos?.profile ?? EMPTY_PROFILE
  );

  // update state if account is changing (e.g. if loading takes time)
  useEffect(() => {
    if (!account) return;
    // always set empty profile to avoid textfields toggling between
    // unconrtrolled / controlled state
    setCoachProfile({
      ...EMPTY_PROFILE,
      ...(account?.metainfos?.profile ?? {}),
    });
  }, [account]);

  const profileSrc =
    coachProfile.profilePictureResolvedUrl &&
    coachProfile.profilePictureResolvedUrl !== ''
      ? coachProfile.profilePictureResolvedUrl
      : name === '? ?'
      ? '/logo192.png'
      : undefined;

  // TODO add theme über ThemeSwitchContext

  return (
    <div className={classes.container}>
      {preserveHeader && (
        <AppHeader
          appheadertitle={
            appHeaderTitle
              ? `${appHeaderTitle}`
              : intl.formatMessage(appLayoutMessages.header)
          }
          loginLogoutLabel={
            isAuthenticated
              ? intl.formatMessage(appLayoutMessages.logoutLabel)
              : intl.formatMessage(appLayoutMessages.loginLabel)
          }
          handleLoginLogout={isAuthenticated ? handleLogout : handleLogin}
          additionalMenuItems={
            !isProgramRoute || isAuthenticated
              ? additionalMenuItems
              : additionalProgramMenu
          }
          avatarName={name === '? ?' ? 'evoach' : name}
          avatarSrc={profileSrc}
          showMenu={!isPublicRoute || isProgramRoute}
          homeRoute={appHeaderHomeroute}
          theme={currentMuiTheme}
        />
      )}
      <div
        style={{
          marginTop: '1rem',
          padding: isMobile ? '0px' : '1rem',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {children}
      </div>
    </div>
  );
};
