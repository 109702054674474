import { FormControl } from '@mui/material';
import React, { useState } from 'react';

import { EvoachMenuItem, EvoachSelect } from '../customMaterialComponents';

export interface CoachSelectionProps {
  onCoachChange: Function;
  coaches: Record<string, any>;
}

export const CoachSelection: React.FC<CoachSelectionProps> = ({
  onCoachChange,
  coaches,
}) => {
  const coachIds = Object.keys(coaches);

  const [valueFrom, setValueFrom] = useState<string>(coachIds[0]);

  const handleChange = (newcoachid: string) => {
    setValueFrom(newcoachid);
    onCoachChange(newcoachid);
  };

  return (
    <div
      style={{
        display: 'inline-flex',
        verticalAlign: 'middle',
        justifyContent: 'left',
        marginLeft: '20px',
      }}
    >
      <FormControl>
        <EvoachSelect
          value={valueFrom}
          onChange={(event) => handleChange(event.target.value as string)}
          sx={{ height: '30px' }}
        >
          {coachIds.map((coachid: string, index: number) => (
            <EvoachMenuItem key={`coaches_${index}`} value={coachid}>
              {`${coaches[coachid].givenname} ${coaches[coachid].familyname}`}
            </EvoachMenuItem>
          ))}
        </EvoachSelect>
      </FormControl>
    </div>
  );
};
