import React from 'react';
import { useIntl } from 'react-intl';
import {
  DialogActions,
  DialogContent,
  Dialog,
  Typography,
} from '@mui/material';
import { EvoachButton } from '@evoach/ui-components';

interface AlertProps {
  message: string;
  isOpen: boolean;
  onClose: Function;
}

export const Alert: React.FC<AlertProps> = ({ message, isOpen, onClose }) => {
  const intl = useIntl();

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog fullWidth onClose={() => onClose()} open={isOpen}>
      <DialogContent>
        <Typography variant="body2">{message}</Typography>
      </DialogContent>
      <DialogActions>
        <EvoachButton
          color="secondary"
          onClick={() => {
            onClose();
          }}
        >
          {intl.formatMessage({
            id: 'builder.alert.button.close',
            defaultMessage: 'Schließen',
          })}
        </EvoachButton>
      </DialogActions>
    </Dialog>
  );
};
