import CryptoJS from 'crypto-js';

import { getEnvironment } from '../environment/useEnvironment';
import { keycloak } from '../keycloak';

import { ApiError } from './ApiError';

const TOKEN_EXPIRY_BUFFER_SECONDS = 30;

// encryption of a text
// used for encryptiing the x-evoach header when using the public API.
// Decryption takes place in the backend
// s. https://www.npmjs.com/package/crypto-js
const encryptText = (text: string, encpass: string) => {
  return CryptoJS.AES.encrypt(text, encpass).toString();
};

type HttpMethod =
  | 'GET'
  | 'HEAD'
  | 'POST'
  | 'PUT'
  | 'DELETE'
  | 'CONNECT'
  | 'OPTIONS'
  | 'TRACE'
  | 'PATCH';

const authorizedApiCall = (
  path: string,
  method: HttpMethod,
  body?: Record<string, any>,
  publicRoute = false
) => {
  const { apiBasePath, publicApiBasePath } = getEnvironment();
  const url = `${!publicRoute ? apiBasePath : publicApiBasePath}${path}`;

  const apiCallFn = async () => {
    let origRef = '';
    // if we call the token.-secured API, update token in keycloak
    if (!publicRoute) {
      await keycloak.updateToken(TOKEN_EXPIRY_BUFFER_SECONDS);
    } else {
      // of we call the public API, set x-evoach header and encrypt it here
      // decryption takes place in backend
      const toBeEnc =
        document.location.ancestorOrigins &&
        document.location.ancestorOrigins.length > 0
          ? document.location.ancestorOrigins[0]
          : document.referrer && document.referrer + '' !== ''
          ? document.referrer + ''
          : document.location.href;

      origRef = encryptText(toBeEnc, 'px' + apiBasePath);
    }

    const response = await fetch(url, {
      method,
      headers: !publicRoute
        ? {
            Authorization: `Bearer ${keycloak.token}`,
            'Content-Type': 'application/json',
          }
        : {
            'x-evoach': origRef, // encrypted
            'Content-Type': 'application/json',
          },
      body: body ? JSON.stringify(body) : undefined,
    });

    // fetch does not throw on non-2XX errors, so we have to do it on our own in order to
    // notify react-query of an error
    if (!response.ok) {
      throw new ApiError(
        response.status,
        `Recevied non-2XX status code for ${response.url}: ${response.statusText}`,
        await response.json()
      );
    }

    return response;
  };

  return apiCallFn;
};

export const authorizedGet = (path: string) => authorizedApiCall(path, 'GET');
export const unauthorizedGet = (path: string) =>
  authorizedApiCall(path, 'GET', undefined, true);

// authoirzed and unauthoirzed Post ==> unauthorized automatically goes to public API
export const authorizedPost = (path: string, body?: Record<string, any>) =>
  authorizedApiCall(path, 'POST', body);
export const unauthorizedPost = (path: string, body?: Record<string, any>) =>
  authorizedApiCall(path, 'POST', body, true);

export const authorizedPut = (path: string, body?: Record<string, any>) =>
  authorizedApiCall(path, 'PUT', body);
export const unauthorizedPut = (path: string, body?: Record<string, any>) =>
  authorizedApiCall(path, 'PUT', body, true);

export const authorizedPatch = (path: string, body?: Record<string, any>) =>
  authorizedApiCall(path, 'PATCH', body);

export const authorizedDelete = (path: string) =>
  authorizedApiCall(path, 'DELETE');
