import { useContext } from 'react';
import { useQuery } from 'react-query';

import { TranslationContext } from '../../intl/TranslationContext';
import { authorizedGet } from '../authorizedApi';

export type SessionQueryParam = 'moduleid';

export const useFetchSessionsQuery = (
  queryParams?: Record<SessionQueryParam, string>
) => {
  const { appendModuleMessages } = useContext(TranslationContext);

  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    ['sessions', queryParams?.moduleid ?? ''],
    async () => {
      const getSessions = authorizedGet(
        `/session?moduleid=${queryParams?.moduleid ?? ''}`
      );
      const response = await getSessions();
      let data = await response.json();

      // mix coach data in sessions => they were extracted in backend to save
      // data while transfering again and again the same coach data
      data = data.sessions.map((sess: any) => {
        return {
          ...sess,
          metadata:
            typeof sess.metadata === 'string'
              ? JSON.parse(sess.metadata)
              : sess.metadata,
          metadatatranslation:
            typeof sess.metadatatranslation === 'string'
              ? JSON.parse(sess.metadatatranslation)
              : sess.metadatatranslation,
          coach: {
            ...data.coaches[sess.coachaccountaid],
          },
        };
      });

      // prepare all translations
      const translationsOfAllSessions = data
        .map((currentSession: any) => currentSession.metadatatranslation)
        .reduce(
          (prev: any, metaTranslations: any) => ({
            ...prev,
            ...metaTranslations,
          }),
          {}
        );

      // provide translations for list
      appendModuleMessages(translationsOfAllSessions);

      // return "old" format and not the deconstructed
      return data;
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    sessions: data,
    refetch,
  };
};
