import { Switch, Typography } from '@mui/material';
import React, { useContext } from 'react';

import { DevToolsContext } from './DevToolsContext';

export const DevToggle: React.FC = () => {
  const { devToolsActivated, setDevToolsActivated } =
    useContext(DevToolsContext);

  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <Typography variant="body2" component="span">
        <div>Dev tools</div>
        <Switch
          checked={devToolsActivated}
          onChange={() => {
            setDevToolsActivated(!devToolsActivated);
          }}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Typography>
    </div>
  );
};
