import { MachineContext } from '@evoach/ui-components';
import { cloneDeep } from 'lodash';
import { IntlShape } from 'react-intl';
import { assign } from 'xstate';

import { authorizedPost } from '../../api';
import {
  PrintDocument,
  PrintDocumentTypeEnum,
} from '../../entities/PrintTaskTypes';
import { SupportedModuleLocales } from '../../intl/SupportedLocales';

export const renderCreateCertificate = () => {
  return assign((context: MachineContext, _event: any, actionMetadata) => {
    const newContext = cloneDeep(context);
    newContext.widgetData = newContext.widgetData.filter(
      (element) => !element.temporary
    );
    // save variables for create certificate call
    newContext.userData['evoach.createCertificate.payload'] = {
      ...actionMetadata.action.payload,
    };
    return newContext;
  });
};

export const setCreateCertificate = (intl: IntlShape) => {
  return assign((context: MachineContext, _event, _actionMetadata) => {
    if (!context.sessionData) return context;

    const payload = context.userData['evoach.createCertificate.payload'];

    const programOrModuleIdRecord =
      payload.programId !== undefined
        ? { programid: payload.programId }
        : payload.moduleId !== undefined
        ? { moduleid: payload.moduleId }
        : {};

    const url = `/print`;
    const postPayload: PrintDocument = {
      type: PrintDocumentTypeEnum.CERTIFICATE,
      templateid: payload.templateId ?? '',
      language:
        context.sessionData['evoach.sessionLanguage'] ??
        SupportedModuleLocales.EN,
      sessionid: context.sessionData['evoach.sessionId'],
      title: payload.title
        ? intl.formatMessage({
            id: payload.title,
            defaultMessage: payload.title,
          })
        : '',
      points1: payload.points1 ?? '',
      points2: payload.points2 ?? '',
      points3: payload.points3 ?? '',
      ...programOrModuleIdRecord,
    };
    const createCertificate = authorizedPost(url, postPayload);
    createCertificate();
    return context;
  });
};
