import { FormControl, Typography } from '@mui/material';
import { useContext, useState } from 'react';

import { SupportedLocales } from '../intl/SupportedLocales';
import { TranslationContext } from '../intl/TranslationContext';

import { EvoachMenuItem, EvoachSelect } from './customMaterialComponents';

const mapLocaleToFlag = (locale: SupportedLocales) => {
  switch (locale) {
    case SupportedLocales.DE:
      return 'Sprache: Deutsch';
    case SupportedLocales.EN:
      return 'Language: English';
    case SupportedLocales.FR:
      return 'Langue: Français';
    case SupportedLocales.ES:
      return 'Lenguaje: Español';
    case SupportedLocales.NL:
      return 'Taal: Nederlands';
    case SupportedLocales.RO:
      return 'Limba: Română';
    default:
      return 'Language: English';
  }
};

export type LanguageSwitchProps = {
  onChangeLanguage?: Function;
};

export const LanguageSwitch: React.FC<LanguageSwitchProps> = ({
  onChangeLanguage,
}) => {
  const { updateLocale, locale } = useContext(TranslationContext); // locale: currentlocale

  const [valueFrom, setValueFrom] = useState<SupportedLocales>(locale);

  const handleChange = (lang: SupportedLocales) => {
    setValueFrom(lang);
    updateLocale(lang);
    if (onChangeLanguage) {
      onChangeLanguage(lang);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <FormControl sx={{ fontVariant: 'body2' }}>
        <EvoachSelect
          value={valueFrom}
          onChange={(event) =>
            handleChange(event.target.value as SupportedLocales)
          }
          sx={{ height: '30px' }}
        >
          {Object.values(SupportedLocales)
            .filter((_locale: any) => {
              /*
              return hasRole(RoleEnum.EVOACHADMIN) ||
                hasRole(RoleEnum.BETATESTER)
                ? true
                : locale + '' === SupportedLocales.DE + '' ||
                    locale + '' === SupportedLocales.EN + '';
                    */
              return true;
            })
            .map((locale, index) => (
              <EvoachMenuItem
                key={`language-switch-button-${index}-${locale}`}
                value={locale}
              >
                <Typography variant="body2">
                  {mapLocaleToFlag(locale)}
                </Typography>
              </EvoachMenuItem>
            ))}
        </EvoachSelect>
      </FormControl>
    </div>
  );
};
