//import { EvoachButton } from '@evoach/ui-components';

import { Box, Button, Checkbox, Modal, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Tour, { ReactourStep } from 'reactour';

import { AppRoutes } from '../routing/routes';
import { EnvironmentName, useEnvironment } from '../environment/useEnvironment';
import { receptionTourId } from '../pages/ReceptionPageTour/ReceptionPage.tour';
import { AccountContext, SpecialTenantsEnum } from '../account';
import { WindowContext } from '../window/WindowContext';

import { ProductTourButton } from './ProductTourButton';
import { ProductTourContext } from './ProductTourContext';

interface ProductTourManagerProps {
  tourid?: string;
  toursteps?: ReactourStep[];
}

/**
 * Component to display a product tour
 *
 * The tourid and the toursteps are required parameters.
 *
 * This component is a wrapper around ReactTour. @see https://github.com/elrumordelaluz/reactour
 * It provides additional functionality:
 * - Button configuration
 * - central change management
 * - central "skip this tour forever" checkbox
 *
 */
export const ProductTourManager: React.FC<ProductTourManagerProps> = ({
  tourid,
  toursteps,
}) => {
  const intl = useIntl();

  const navigate = useNavigate();
  const { name } = useEnvironment();

  // use context to read/write user preference about displaying this tour
  const { isTourClosed, getAllowDisplayTour, setAllowDisplayTour, openTours } =
    useContext(ProductTourContext);
  const { currentTenant } = useContext(AccountContext);

  // check whether device is mobile an do not display product tours on mobile devices
  const { isMobile } = useContext(WindowContext);

  useEffect(() => {
    if (openTours.indexOf(tourid!) !== -1) {
      setIsTourOpen(true);
    }
  }, [openTours, isTourClosed, tourid]);

  // remember if current step is last step
  const [isLastStep, setIsLastStep] = useState(false);

  // remember toursteps in state
  const [tourSteps] = useState<ReactourStep[]>(toursteps ?? []);

  // show onClose dialog
  const [closeQuestionDialogIsOpen, setCloseQuestionDialogIsOpen] =
    useState<boolean>(false);

  // the tour is displayed if it wasn't globally set to "never show again"
  const [isTourOpen, setIsTourOpen] = useState<boolean>(
    getAllowDisplayTour(tourid) === 'true' && !isTourClosed(tourid)
  );

  // toggle checkbox for "never show again"
  const [hideProductTour, setHideProductTour] = useState<boolean>(
    getAllowDisplayTour(tourid) === 'true'
  );

  const handleWelcomeChatbotClick = () => {
    setCloseQuestionDialogIsOpen(false);
    if (currentTenant?.tenantid === SpecialTenantsEnum.ICF) {
      // close only for ICF
      // do not navigate anywhere
    } else {
      if (name === EnvironmentName.PRODUCTION) {
        const beforeYouStartInvitationId =
          '3742ec41-9a96-4f6f-b93c-215d50f942dd?autostart=true&c=producttour';
        navigate(`${AppRoutes.INVITATION}/${beforeYouStartInvitationId}`);
      } else if (name === EnvironmentName.STAGING) {
        const beforeYouStartInvitationId =
          'e7d160b2-5330-4787-970e-f7d80c5e243b?autostart=true';
        navigate(`${AppRoutes.INVITATION}/${beforeYouStartInvitationId}`);
      } else {
        alert(
          'This would start the "Before you start" chatbot, but only works on production / staging'
        );
      }
    }
  };

  // if no tour steps are available, do not render anything
  if (
    isMobile ||
    !tourSteps ||
    (Array.isArray(tourSteps) && tourSteps.length === 0)
  ) {
    return <></>;
  }

  return (
    <>
      <Tour
        key={tourid}
        isOpen={isTourOpen && !isTourClosed(tourid)}
        onRequestClose={() => {
          setIsTourOpen(false);
          if (
            isLastStep &&
            tourid !== undefined &&
            [receptionTourId].includes(tourid)
          ) {
            handleWelcomeChatbotClick();
          }
        }}
        steps={tourSteps ?? []}
        getCurrentStep={(currentStep: number) =>
          setIsLastStep(currentStep === tourSteps.length - 1)
        }
        accentColor="#4199AC"
        nextButton={<ProductTourButton buttonType="next" />}
        prevButton={<ProductTourButton buttonType="previous" />}
        lastStepNextButton={<ProductTourButton buttonType="laststepnext" />}
        showCloseButton
        onBeforeClose={() => {
          setCloseQuestionDialogIsOpen(true);
          setAllowDisplayTour(!isLastStep, tourid);
        }}
        rounded={4}
        showNumber={false}
        disableDotsNavigation={false}
        showNavigation={true}
      />
      <Modal
        open={closeQuestionDialogIsOpen && !isLastStep}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={() => {
          setCloseQuestionDialogIsOpen(false);
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: '4px',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box>
            <Typography component="span">
              <Checkbox
                id="closeThisDialog"
                key="embeddableModuleCheckboxForProgressbar"
                checked={!hideProductTour}
                onChange={() => {
                  setAllowDisplayTour(!hideProductTour, tourid);
                  setHideProductTour(!hideProductTour);
                }}
              />
              {intl.formatMessage({
                id: 'player.producttour.closeforeverdialog.checkbox.text',
                defaultMessage: 'Diese Produkttour nicht mehr anzeigen.',
              })}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Button
              onClick={() => setCloseQuestionDialogIsOpen(false)}
              color="inherit"
            >
              {intl.formatMessage({
                id: 'player.producttour.closeforeverdialog.button.text',
                defaultMessage: 'Produkttour schließen',
              })}
            </Button>
            {currentTenant?.tenantid !== SpecialTenantsEnum.ICF && (
              <Button onClick={() => handleWelcomeChatbotClick()}>
                {intl.formatMessage({
                  id: 'player.productttour.button.laststepnext',
                  defaultMessage: 'Lass uns chatten!',
                })}
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};
