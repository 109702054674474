import { CircularProgress } from '@mui/material';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'; // Navigate, Route,
import { useContext } from 'react';

import { useEvoachKeycloak } from '../account/useEvoachKeycloak';
import { ModuleListWithCards } from '../components/reception/ModuleListWithCards';
import { ProgramList } from '../components/reception/ProgramList';
import { SessionList } from '../components/reception/SessionList';
import { AppLayout } from '../layout/AppLayout';
import { DisclaimerPage } from '../pages/DisclaimerPage';
import { DisplayPublicProgram } from '../pages/DisplayPublicProgram';
import { InvitationPage } from '../pages/InvitationPage';
import { ModulePage } from '../pages/ModulePage';
import { NotLoggedInPage } from '../pages/NotLoggedInPage';
import { ProgramInstancePage } from '../pages/ProgramInstancePage';
import { ProgramInvitationPage } from '../pages/ProgramInvitationPage';
import { PublicPage } from '../pages/PublicPage';
import { PublicRedirectPage } from '../pages/PublicRedirectPage';
// eslint-disable-next-line import/order
import { ReceptionPage } from '../pages/ReceptionPage';
//import { PrivateRoute } from './PrivateRoute';
import { ProfilePage } from '../pages/ProfilePage';
import ErrorBoundary from '../components/ErrorBoundary';
import { AccountContext } from '../account/AccountContext';

import {
  AppRoutes,
  CoachingRouteParamName,
  InvitationRouteParamName,
  ProgramInstanceRouteParamName,
  ProgramInstanceRouteSubParamName,
  PublicCoachingRouteParamDateNOW,
  PublicCoachingRouteParamLanguageName,
  PublicCoachingRouteParamModuleName,
  PublicProgramRouteParamName,
  PublicProgramRouteSubParamName,
  ReceptionRoutes,
} from './routes';

export const AppRouter: React.FC = () => {
  const { isLoading, isAuthenticated } = useEvoachKeycloak();

  // PROD-1736 - try to proceed with routes for public routes and to not
  // wait for authentication
  const { isPublicRoute } = useContext(AccountContext);

  if (isLoading && !isPublicRoute) {
    return <CircularProgress />;
  }

  return (
    <BrowserRouter>
      <AppLayout>
        <ErrorBoundary>
          <Routes>
            <Route
              path={AppRoutes.PROFILE}
              element={
                !isAuthenticated ? (
                  <Navigate to={AppRoutes.LOGIN} />
                ) : (
                  <ProfilePage />
                )
              }
            />
            <Route
              path={AppRoutes.DISCLAIMER}
              element={
                !isAuthenticated ? (
                  <Navigate to={AppRoutes.LOGIN} />
                ) : (
                  <DisclaimerPage />
                )
              }
            />
            <Route
              path={AppRoutes.LOGIN}
              element={
                isAuthenticated ? (
                  <Navigate to={AppRoutes.RECEPTION + '/modules'} />
                ) : (
                  <NotLoggedInPage />
                )
              }
            />
            <Route
              path={`${AppRoutes.LOGIN}/:${InvitationRouteParamName}`}
              element={<NotLoggedInPage />}
            />
            <Route
              path={AppRoutes.SIGNUP}
              element={
                isAuthenticated ? (
                  <Navigate to={AppRoutes.RECEPTION} />
                ) : (
                  <NotLoggedInPage isSignupPage />
                )
              }
            />
            <Route
              path={AppRoutes.RECEPTION}
              element={
                !isAuthenticated ? (
                  <Navigate to={AppRoutes.LOGIN} />
                ) : (
                  <ReceptionPage />
                )
              }
            >
              <Route
                path="*"
                element={
                  !isAuthenticated ? (
                    <Navigate to={AppRoutes.LOGIN} />
                  ) : (
                    <ProgramList />
                  )
                }
              />
              <Route
                index
                element={
                  !isAuthenticated ? (
                    <Navigate to={AppRoutes.LOGIN} />
                  ) : (
                    <ProgramList />
                  )
                }
              />
              <Route
                path={ReceptionRoutes.MODULES}
                element={
                  !isAuthenticated ? (
                    <Navigate to={AppRoutes.LOGIN} />
                  ) : (
                    <ModuleListWithCards />
                  )
                }
              />
              <Route
                path={ReceptionRoutes.SESSIONS}
                element={
                  !isAuthenticated ? (
                    <Navigate to={AppRoutes.LOGIN} />
                  ) : (
                    <SessionList />
                  )
                }
              />
              <Route
                path={ReceptionRoutes.PROGRAMS}
                element={
                  !isAuthenticated ? (
                    <Navigate to={AppRoutes.LOGIN} />
                  ) : (
                    <ProgramList />
                  )
                }
              />
            </Route>
            <Route
              path={`${AppRoutes.INVITATION}/:${InvitationRouteParamName}`}
              element={
                !isAuthenticated ? <NotLoggedInPage /> : <InvitationPage />
              }
            />
            <Route
              path={`${AppRoutes.PROGRAMINVITATION}/:${InvitationRouteParamName}`}
              element={
                !isAuthenticated ? (
                  <NotLoggedInPage />
                ) : (
                  <ProgramInvitationPage />
                )
              }
            />
            <Route
              path={`${AppRoutes.COACHING}/:${CoachingRouteParamName}`}
              element={
                !isAuthenticated ? (
                  <Navigate to={AppRoutes.LOGIN} />
                ) : (
                  <ModulePage />
                )
              }
            />
            <Route
              path={`${AppRoutes.PROGRAMINSTANCE}/:${ProgramInstanceRouteParamName}`}
              element={
                !isAuthenticated ? (
                  <Navigate to={AppRoutes.LOGIN} />
                ) : (
                  <ProgramInstancePage />
                )
              }
            />
            <Route
              path={`${AppRoutes.PROGRAMINSTANCE}/:${ProgramInstanceRouteParamName}/subprograminstance/:${ProgramInstanceRouteSubParamName}`}
              element={
                !isAuthenticated ? (
                  <Navigate to={AppRoutes.LOGIN} />
                ) : (
                  <ProgramInstancePage />
                )
              }
            />
            <Route
              path={`${AppRoutes.PUBLICCOACHING}/:${CoachingRouteParamName}`}
              element={<ModulePage />}
            />
            <Route
              path={`${AppRoutes.PUBLIC}/:${PublicCoachingRouteParamModuleName}/:${PublicCoachingRouteParamLanguageName}`}
              element={<PublicPage />}
            />
            <Route
              path={`${AppRoutes.PUBLICREDIRECT}/:${PublicCoachingRouteParamModuleName}/:${PublicCoachingRouteParamLanguageName}/:${PublicCoachingRouteParamDateNOW}`}
              element={<PublicRedirectPage />}
            />
            <Route
              path={`${AppRoutes.PUBLICPLAYER}/:${PublicCoachingRouteParamModuleName}/:${PublicCoachingRouteParamLanguageName}`}
              element={<PublicRedirectPage preserveHeader />}
            />
            <Route
              path={`${AppRoutes.PUBLICPROGRAM}/:${PublicProgramRouteParamName}`}
              element={<DisplayPublicProgram />}
            />
            <Route
              path={`${AppRoutes.PUBLICPROGRAM}/:${PublicProgramRouteParamName}/subprogram/:${PublicProgramRouteSubParamName}`}
              element={<DisplayPublicProgram />}
            />
            <Route
              path={AppRoutes.BLANK}
              element={
                isAuthenticated ? (
                  <Navigate to={AppRoutes.RECEPTION} />
                ) : (
                  <NotLoggedInPage />
                )
              }
            />
          </Routes>
        </ErrorBoundary>
      </AppLayout>
    </BrowserRouter>
  );
};
