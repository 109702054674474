// locales for UI
export enum SupportedLocales {
  DE = 'DE',
  EN = 'EN',
  NL = 'NL',
  RO = 'RO',
  FR = 'FR',
  ES = 'ES',
}

// locales for modules are independent from UI locales!
//
// supported locales for module translations
// we should use ISO codes for languages. Pls refer to this list
// https://de.wikipedia.org/wiki/Liste_der_ISO-639-1-Codes
//
// ! if you add a value here, you have to add it in SupportedLocales.ts in backend, too!
// ! if you add a value here, add it in creator, too
//
export enum SupportedModuleLocales {
  DE = 'DE',
  EN = 'EN',
  FR = 'FR',
  NL = 'NL',
  IT = 'IT',
  ES = 'ES',
  CN = 'CN',
  FI = 'FI',
  SV = 'SV',
  PL = 'PL',
  PT = 'PT',
  BG = 'BG',
  AR = 'AR', // PROD-1642 - Arabic
  RO = 'RO', // PROD-1642 - Romanian, Moldavian, Moldovan
}

/**
 * checks whether a language is a supported locale
 * @param {string} locale
 * @returns {boolean} true = is supported locale, false = not a supported locale
 */
export const isSupportedLocale = (locale: string) => {
  return Object.keys(SupportedLocales).includes(locale);
};

/**
 * checks whether a language is a supported module locale
 * @param {string} locale
 * @returns {boolean} true = is supported module locale, false = not a supported module locale
 */
export const isSupportedModuleLocale = (locale: string) => {
  return Object.keys(SupportedModuleLocales).includes(locale);
};
