/**
 * When calling an AI, the response is not always a valid JSON string. The JSON
 * is sometimes somewhere in the response text. This function extracts the JSON
 * from the response text.
 *
 * @param {string} responseText as received by an AI call
 * @returns {string} JSON string
 */
export const getJsonFromAiResponse = (responseText: string): any => {
  const startIndex = responseText.indexOf('{');
  const endIndex = responseText.lastIndexOf('}');
  if (!(startIndex >= 0 && endIndex > startIndex)) {
    console.error(`Response is not JSON:\n${responseText}`);
    return '{}';
  }
  return responseText.slice(startIndex, endIndex + 1);
};
