import {
  CardImageSize,
  CardSize,
  ObjectCard,
  ObjectCardHeaderMenu,
} from '@evoach/ui-components';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useFetchProgramInstancesQuery } from '../../api/program';
import { ProgramInstance } from '../../entities';
import { AppRoutes } from '../../routing/routes';

interface ProgramListProps {
  programidlist?: string[];
  parentprogramid?: string;
}

export const ProgramList: React.FC<ProgramListProps> = ({
  programidlist,
  parentprogramid,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { programinstances } = useFetchProgramInstancesQuery(programidlist);

  const rows = useMemo(() => {
    const getBadgeText = (programInstanceId: string): string => {
      const newBadgeText = intl.formatMessage({
        id: 'player.reception.programlist.cards.newbadgetext',
        defaultMessage: 'Neu',
      });
      const highlightedProgramInstances = JSON.parse(
        localStorage.getItem(`evoach.player.programs.highlighted`) ?? '[]'
      );
      // if program is in localStorage & new -> add badge text
      return Array.isArray(highlightedProgramInstances)
        ? highlightedProgramInstances.some((programInstance: any) => {
            const isHighlighted =
              programInstance.programInstanceId === programInstanceId;
            const expirationTime = 86400000; // 1 day
            const isRecentlyAdded =
              Date.now() - programInstance.highlightedDate < expirationTime;
            return isHighlighted && isRecentlyAdded;
          })
          ? newBadgeText
          : ''
        : '';
    };
    return programinstances
      ? programinstances
          .filter(
            (programinstances: ProgramInstance) =>
              programinstances &&
              !!programinstances.program &&
              !!programinstances.program.title
          )
          .map((programinstance: ProgramInstance, mindex: number) => {
            return {
              id: mindex,
              programname: programinstance.program.title,
              programinstanceid: programinstance.programinstanceid,
              programdescription: programinstance.program.description,
              programimage: programinstance.program.image,
              programresolvedsrc: programinstance.program.resolvedsrc,
              programduration: programinstance.program.duration,
              programstartdate: programinstance.program.startDate,
              programlang: programinstance.program.language,
              badgetext: getBadgeText(programinstance.programinstanceid),
              ordernumber: programinstance.program.ordernumber ?? 0,
            };
          })
          .sort((a: any, b: any) => {
            return (b.ordernumber ?? 0) - (a.ordernumber ?? 0);
          })
      : [];
  }, [programinstances, intl]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      {rows.map((row: any, index: never) => {
        return (
          <Box
            style={{
              marginTop: '20px',
              marginLeft:
                programidlist || (parentprogramid && parentprogramid !== '')
                  ? '10px'
                  : '20px',
            }}
            key={'objectcardlistdiv' + row.moduleid + '_' + index}
          >
            <ObjectCard
              title={row.programname}
              createdBy={row.programprovider}
              description={row.programdescription}
              duration={row.programduration}
              id={row.programinstanceid}
              image={row.programimage}
              imageSrc={row.programresolvedsrc}
              imageSize={
                programidlist ? CardImageSize.MEDIUM : CardImageSize.LARGE
              }
              cardSize={programidlist ? CardSize.SMALL : CardSize.LARGE}
              badgeText={row.badgetext}
              headermenu={
                <>
                  <ObjectCardHeaderMenu
                    id={row.programinstanceid}
                    buttonTitle={intl.formatMessage({
                      id: 'player.reception.programlist.cards.openprogram',
                      defaultMessage: 'Öffnen',
                    })}
                    onButtonClick={() => {
                      navigate(
                        parentprogramid && parentprogramid !== ''
                          ? `${AppRoutes.PROGRAMINSTANCE}/${parentprogramid}/subprograminstance/${row.programinstanceid}`
                          : `${AppRoutes.PROGRAMINSTANCE}/${row.programinstanceid}`
                      );
                    }}
                  />
                </>
              }
            />
          </Box>
        );
      })}
      {rows.length === 0 && (
        <Box sx={{ marginTop: '30px', minWidth: '320px', maxWidth: '600px' }}>
          <Typography>
            {intl.formatMessage({
              id: 'player.reception.modulelist.noprograms.hint',
              defaultMessage:
                'Du siehst keine Coaching Programme? Normalerweise bekommst du von deinem Coach eine Einladung, mit der du für Coaching Programme freigeschaltet wirst.',
            })}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
