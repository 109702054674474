import { MachineContext } from '@evoach/ui-components';
import { cloneDeep } from 'lodash';
import { assign } from 'xstate';

let lastState: string = '';

/**
 *
 * setAnalytics is a new function that became necessary after we found
 * that anaylticsData wasn't written since 9.2.23. It is not clear what
 * caused this, but it seems that the analyticsData was not written.
 *
 * This new action is called on every state change and writes the
 * analyticsData to the context. We add that action as additional action
 * to every state when loading the session in useFetchSessionQuery.ts
 *
 */
export const setAnalytics = assign(
  (context: MachineContext, _event: any, actionMetadata: any) => {
    const newContext = cloneDeep(context);

    //let analyticsData = newContext.analyticsData;

    const statekey = actionMetadata.action?.payload?.statekey ?? '';

    // initialize last state when re-loading a session
    if (
      (newContext.analyticsData?.lastState ?? '') !== '' &&
      lastState === ''
    ) {
      lastState = newContext.analyticsData.lastState;
    }

    // if state didn't change, do nothing (see also comment below)
    if (lastState === statekey) return newContext;

    if (
      newContext.analyticsData === undefined ||
      newContext.analyticsData.lastState === undefined
    ) {
      newContext.analyticsData = {
        lastState: '',
        stateSeries: {},
      };
    }

    // if state changes, remember current state
    // important: we have to use a local variable lastState to track that change
    // as .lastState is updated very often through several re-render events of
    // the statemachine. Thus, you CAN'T check for analyticsData.lastState===state.value
    // in the previous if-clause
    lastState = statekey;
    newContext.analyticsData.lastState = statekey;

    // save timestamp when state was met first
    // we push that in an array, allowing multiple entries per state! This is necessary due to loops
    if (Array.isArray(newContext.analyticsData.stateSeries)) {
      //! format before 09.09.2023
      newContext.analyticsData.stateSeries.push({
        [statekey]: { ts: Date.now() },
      });
    } else {
      // ! new format after 09.09.2023
      // we keep the object with ts instead of assigning ts-value directly
      // to be prepared for future extensions
      newContext.analyticsData.stateSeries[statekey] = { ts: Date.now() };
      //
      // why this change?
      //
      // 1) we can quickly access a state by its key without iterating thorugh the array
      // 2) we can directly query the value of a statekey in DB (!) without loading the whole stateSeries as array
      //
    }
    return newContext;
  }
);
