import { useContext } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import { Download } from '@mui/icons-material';

import { AccountContext, RoleEnum } from '../../../account';
import { authorizedGet } from '../../../api';
import { WindowContext } from '../../../window/WindowContext';
import { ExportTypeEnum } from '../../../entities/ExportTypes';

interface ExportSessionButtonProps {
  sessionid: string;
  disabled?: boolean;
}

export const ExportSessionButton: React.FC<ExportSessionButtonProps> = ({
  sessionid,
  disabled,
}) => {
  const intl = useIntl();
  const { alert } = useContext(WindowContext);
  const { hasRole } = useContext(AccountContext);

  const getExport = () => {
    if (sessionid) {
      const printCall = authorizedGet(
        `/session/${sessionid}/export/${ExportTypeEnum.TEXT}`
      );

      printCall()
        .then((response: any) => {
          response
            .json()
            .then((_res: any) => {
              // success
              console.log(_res);
            })
            .catch((_reason: unknown) => {
              alert(
                intl.formatMessage({
                  id: 'player.exportsession.error',
                  defaultMessage:
                    'Dein Export konnte leider nicht erstellt werden. (1)',
                })
              );
              console.error(_reason);
            });
        })
        .catch((_reason: unknown) => {
          alert(
            intl.formatMessage({
              id: 'player.exportsession.error2',
              defaultMessage:
                'Dein Export konnte leider nicht erstellt werden. (2)',
            })
          );
          console.error(_reason);
        });
    }
  };

  // do not show for non-evoach and do not show on production
  if (!!disabled || !hasRole(RoleEnum.EVOACHADMIN)) {
    return null;
  }

  return (
    <strong>
      <Tooltip
        title={intl.formatMessage({
          id: 'player.exportsession.tooltip',
          defaultMessage:
            '(NOT YET RELEASED) Hier kannst du deine Session exportieren.',
        })}
      >
        <IconButton onClick={() => getExport()}>
          <Download color="secondary" fontSize="medium" />
        </IconButton>
      </Tooltip>
    </strong>
  );
};
