import { useQuery } from 'react-query';
import CryptoJS from 'crypto-js';

import { ApiError } from '../ApiError';
import { authorizedGet, authorizedPost } from '../authorizedApi';
import { keycloak } from '../../keycloak';
import { getEnvironment } from '../../environment/useEnvironment';
import { FeatureEnum } from '../../entities/subscriptionTypes';
import { Account } from '../../entities/Account';
import { resolveS3ApiCall } from '../asset';

// decrption of a text
// used for decrypting features that are encrypryted in GET /account in backend
// s. https://www.npmjs.com/package/crypto-js
const decryptText = (text: string, encpass: string) => {
  const b = CryptoJS.AES.decrypt(text, encpass);
  return b.toString(CryptoJS.enc.Utf8);
};

export const useFetchOrCreateAccountQuery = (
  isPublicRoute: boolean = false
) => {
  const { apiBasePath } = getEnvironment();
  // for non-public routes, handle user management
  const { isLoading, data, isError, error, failureCount, refetch } = useQuery<
    Account & { features: Record<FeatureEnum, boolean | number> },
    Error
  >(
    'userAccount',
    async () => {
      // for public routes, we do not handle account management
      if (isPublicRoute) {
        return {
          isLoading: false,
          data: null,
          isError: false,
          error: null,
          failureCount: 0,
        };
      }

      try {
        const getAccount = authorizedGet('/account');
        const getAccountResponse = await getAccount();

        let res = await getAccountResponse.json();

        res = {
          ...res,
          features: JSON.parse(
            decryptText(
              res.features,
              'px' +
                (apiBasePath === 'http://localhost:3001'
                  ? apiBasePath
                  : 'https://api.evoach.de')
            )
          ),
        };

        // resolve profile pictures
        if (res.metainfos?.profile) {
          // profile picture if available
          res.metainfos.profile.profilePictureResolvedUrl =
            res.metainfos.profile.profilePictureSrc &&
            res.metainfos.profile.profilePictureSrc !== ''
              ? res.metainfos.profile.profilePictureSrc
              : res.metainfos.profile.profilePictureAssetId &&
                res.metainfos.profile.profilePictureAssetId !== ''
              ? await resolveS3ApiCall(
                  res.metainfos.profile.profilePictureAssetId,
                  true
                )
              : '';
          // logo if available
          res.metainfos.profile.logoPictureResolvedUrl =
            res.metainfos.profile.logoPictureSrc &&
            res.metainfos.profile.logoPictureSrc !== ''
              ? res.metainfos.profile.logoPictureSrc
              : res.metainfos.profile.logoPictureAssetId &&
                res.metainfos.profile.logoPictureAssetId !== ''
              ? await resolveS3ApiCall(
                  res.metainfos.profile.logoPictureAssetId,
                  true
                )
              : '';
        }

        return res;
      } catch (error) {
        if (error instanceof ApiError) {
          if (error.httpStatus === 404) {
            const body = localStorage.getItem('evoachSignUpParameters');
            const createAccount = authorizedPost(
              '/account',
              body ? { params: body } : {}
            );
            const createAccountResponse = await createAccount();

            return await createAccountResponse.json();
          }
        }
        throw error;
      }
    },
    {
      retry: 8, // PROD-1289 retry while database is starting
      // only load when we have an auth token
      enabled: !!keycloak.token,
      // disable refetching on moung and on window focus
      // to avoid multiple calls to the same information. This also
      // prevents the backend from logging several events to be logged
      // s. https://tanstack.com/query/v4/docs/reference/useQuery
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    isLoading,
    account: data ?? null,
    isError,
    error,
    failureCount,
    refetch,
  };
};
