import { MachineContext } from '@evoach/ui-components';

import { FINISH_MARKER } from '../../entities/ExternalServicesTypes';

/**
 * microchatFinishMarkerGuard checks whether the finish marker is
 * contained in the last response of the AI. The finish marker itself
 * is defined in the role=system part of the prompt of the microchat.
 *
 * @returns true if finish marker is find in last response of AI
 */
const microchatFinishMarkerGuard = (
  context: MachineContext,
  _event: any
): boolean => {
  // finish marker is defined in backend in prompt, role system !!
  return (context.userData['evoach.microchat.result'] + '').includes(
    FINISH_MARKER
  );
};
/**
 * microchatMaxTurnsGuard checks for number of conversational turns so
 * far and exists of max number reached
 *
 * @returns {boolean} true if max numer of turns reached
 */
const microchatMaxTurnsGuard = (
  context: MachineContext,
  _event: any
): boolean => {
  return (
    context.userData['evoach.microchat.conversationTurns'] ===
    (context.userData['evoach.microchat.payload'].maxTurns - 0 ?? 5)
  );
};
/**
 * microchatNextTurnGuard proceeds to next step
 *
 * @returns {boolean} true if neither finish-marker is found
 * nor the max number of turns is reached
 */
const microchatNextTurnGuard = (
  context: MachineContext,
  _event: any
): boolean => {
  return !(
    microchatFinishMarkerGuard(context, _event) ||
    microchatMaxTurnsGuard(context, _event)
  );
};

/** classification guards */
export const microchatGuards = {
  microchatFinishMarkerGuard,
  microchatMaxTurnsGuard,
  microchatNextTurnGuard,
};
