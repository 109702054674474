import { useQuery } from 'react-query';

import { unauthorizedGet } from '../authorizedApi';

// TODO implement caching for pre-signed URLs?

export const useFetchAsset = (assetid: string | undefined) => {
  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    `asset-${assetid}`,
    async () => {
      if (assetid === undefined || assetid === '' || assetid === 'undefined') {
        return undefined;
      }
      const assetURL: RequestInfo = `/asset/${assetid}`;
      // Mae, 28.04.2023 - requires public API as this is function is used
      // in the acquisition view that has to be also available for non-registered users.
      const getAsset = unauthorizedGet(assetURL);
      const response = await getAsset();
      const data = await response.json();

      return data;
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    asset: data,
    refetch,
  };
};
