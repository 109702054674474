import { Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import { SubscriptionType } from '../../../entities/subscriptionTypes';

interface SubscriptionTypeTextProps {
  type: SubscriptionType;
}

export const SubscriptionTypeText: React.FC<SubscriptionTypeTextProps> = ({
  type,
}) => {
  const intl = useIntl();

  let typeText = '';

  switch (type) {
    case SubscriptionType.FREE_TIER:
      typeText = intl.formatMessage({
        id: 'player.subscriptiontype.FREE_TIER',
        defaultMessage: 'Free Tier',
      });
      break;
    case SubscriptionType.TRIAL_30_DAYS:
      typeText = intl.formatMessage({
        id: 'player.subscriptiontype.TRIAL_30_DAYS',
        defaultMessage: '30 Tage Test',
      });
      break;
    case SubscriptionType.BASIC:
      typeText = intl.formatMessage({
        id: 'player.subscriptiontype.BASIC',
        defaultMessage: 'Basic Lizenz',
      });
      break;
    case SubscriptionType.GROW:
      typeText = intl.formatMessage({
        id: 'player.subscriptiontype.GROW',
        defaultMessage: 'Grow Lizenz',
      });
      break;
    case SubscriptionType.PRO:
      typeText = intl.formatMessage({
        id: 'player.subscriptiontype.PRO',
        defaultMessage: 'Pro Lizenz',
      });
      break;
    case SubscriptionType.STARTER:
      typeText = intl.formatMessage({
        id: 'player.subscriptiontype.STARTER',
        defaultMessage: 'Starter Lizenz',
      });
      break;
    default:
      typeText = intl.formatMessage(
        {
          id: 'player.subscriptiontype.none',
          defaultMessage: 'Unbekannter Typ: {typ}',
        },
        { typ: type }
      );
  }

  return <Typography>{typeText}</Typography>;
};
