import { PrintTask } from './PrintTaskTypes';

/**
 * CoacheeDiaryEntryType
 * Used as entrytype in backend
 */
export enum CoacheeDiaryEntryType {
  /**
   * used for variables that are stored for the coachee and are visible in her diary
   */
  COACHEE_DIARY_VARIABLE = 0,
  /**
   * used for global variables that are passed from one session to the other
   */
  GLOBAL_VARIABLE = 1,
  /**
   * used for manual entries, e.g. if a coachee writes some kind of note in her diary
   */
  MANUAL_ENTRY = 2,
  /**
   * information about a certificate that was requested
   */
  CERTIFICATE = 3,
}

/**
 * read only the values of the enum, i.e., 0, 1, ...
 * @returns {number[]} array of numbers as values
 */
export const getCoacheeDiaryEntryTypeValues = () =>
  Object.values(CoacheeDiaryEntryType)
    .filter((entry: any) => typeof entry === 'number')
    .map((entry: any) => entry as number);

/**
 * CoacheeDiaryEntry is a helper type to create a diary entry without using
 * the entity props used for Typeorm
 */
export type CoacheeDiaryEntry =
  | CoacheeDiaryVariableEntry
  | CoacheeDiaryPrintTaskEntry;

export type CoacheeDiaryVariableEntry = {
  coacheediaryid: string | undefined | null;
  coacheeaccountid: string;
  coachaccountid: string;
  variablename: string;
  variablevalue: PrintTask;
  tscreated: Date;
  moduletitle?: string;
  moduleid: string;
  sessionid: string;
  entrytype: CoacheeDiaryEntryType;
  nodetype: string;
};

export type CoacheeDiaryPrintTaskEntry = {
  coacheediaryid: string | undefined | null;
  coacheeaccountid: string;
  coachaccountid: string;
  variablevalue: PrintTask;
  tscreated: Date;
  entrytype: CoacheeDiaryEntryType.CERTIFICATE;
};
