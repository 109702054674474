import { MachineContext } from '@evoach/ui-components';
import { cloneDeep } from 'lodash';
import { NavigateFunction } from 'react-router-dom';
import { assign } from 'xstate';
import { NavigateTargetEnum } from '@evoach/ui-components';

import { AppRoutes } from '../../routing/routes';

const enum ReceptionRoutes {
  MODULES = 'modules',
  SESSIONS = 'sessions',
  PROGRAMS = 'programs',
}

export const setNavigation = (
  navigate: NavigateFunction,
  isPublicRoute: boolean
) => {
  return assign((context: MachineContext, _event, _actionMetadata) => {
    const newContext = cloneDeep(context);

    //read target
    const navigationTarget = context.userData['current.navigatetarget'];

    // if public route, do not use react router for player!
    // simply ignore command
    if (!isPublicRoute) {
      if (navigationTarget === NavigateTargetEnum.MODULELIST) {
        navigate(`${AppRoutes.RECEPTION}/${ReceptionRoutes.MODULES}`);
      }

      if (navigationTarget === NavigateTargetEnum.PROGRAMLIST) {
        navigate(`${AppRoutes.RECEPTION}/${ReceptionRoutes.PROGRAMS}`);
      }

      if (navigationTarget === NavigateTargetEnum.SESSIONLIST) {
        navigate(`${AppRoutes.RECEPTION}/${ReceptionRoutes.SESSIONS}`);
      }
    }

    // reset target
    newContext.userData['current.navigatetarget'] = undefined;

    return newContext;
  });
};
