import { evoachDefaultTheme } from '@evoach/ui-components';
import { MenuItem, Select, styled } from '@mui/material';

export const EvoachSelect = styled(Select)(() => ({
  '&.MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: evoachDefaultTheme.palette.secondary.main,
    },
  },
}));

export const EvoachMenuItem = styled(MenuItem)(() => ({
  '&.Mui-selected, &:hover, &.Mui-selected.Mui-focusVisible': {
    backgroundColor: evoachDefaultTheme.palette.secondary.light,
  },
  '&.Mui-selected': {
    '&:hover': {
      backgroundColor: evoachDefaultTheme.palette.secondary.main,
      color: evoachDefaultTheme.palette.secondary.contrastText,
    },
  },
}));
