import { useMutation } from 'react-query';

import { authorizedPatch } from '../authorizedApi';

export const useAcceptInvitationMutation = () => {
  return useMutation<any, Error, string>(
    async (invitationId: string) => {
      const acceptInivitation = authorizedPatch(`/invitation/${invitationId}`);
      const response = await acceptInivitation();

      const data = await response.json();
      return data;
    },
    {
      retry: false,
    }
  );
};
