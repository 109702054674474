import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { keycloak } from '../keycloak';
import { useFetchOrCreateAccountQuery } from '../api';
import { FeatureEnum } from '../entities/subscriptionTypes';
//import { useFetchTenantsQuery } from '../api/account/useFetchTenantsQuery';
import { Tenant } from '../entities/Account';

import {
  AccountContextProps,
  TextSpeed,
  RoleEnum,
} from './AccountContextProps';
import { useEvoachKeycloak } from './useEvoachKeycloak';

export const AccountContext = React.createContext<AccountContextProps>({
  isLoading: true,

  isError: false,
  error: null,

  isAuthenticated: false,
  token: null,

  account: null,

  name: '',
  email: '',

  preferences: {
    textSpeed: TextSpeed.MEDIUM,
    setTextSpeed: () => {},
  },
  roles: [],

  hasRole: () => {},

  isPublicRoute: false,
  children: undefined,

  failureCount: 0,

  hasFeature: () => {},
  getFeature: () => {},

  refetchAccount: () => {},

  currentTenant: undefined,
  setCurrentTenant: () => {},
});

export type AccountContextProviderProps = {
  children?: React.ReactNode;
};

export const AccountContextProvider: React.FC<AccountContextProviderProps> = ({
  children,
}) => {
  // get tokens from keycloak
  const {
    isLoading: keycloakIsLoading,
    isAuthenticated,
    token,
  } = useEvoachKeycloak();

  // we check the url to determine whether we are on a public route, i.e.,
  // a route without login. We do not have access to the Router here!
  const isPublicRoute = useMemo(() => {
    return (
      (document.location?.pathname?.startsWith('/public') ||
        document.location?.pathname?.startsWith('/program/')) ??
      false
    );
  }, []);

  // remeber in localstorage of the user logged in at least once
  useEffect(() => {
    if (isAuthenticated) {
      try {
        localStorage.setItem('evoach.atleastonceloggedininthisbrowser', 'true');
      } catch (reason: unknown) {
        console.error(reason);
      }
    }
  }, [isAuthenticated]);

  // get account from backend
  const {
    isLoading: backendIsLoading,
    account,
    isError,
    error,
    failureCount,
    refetch,
  } = useFetchOrCreateAccountQuery(isPublicRoute);

  // const { tenants, refetch: refetchTenants } = useFetchTenantsQuery();

  const [currenttenant, setcurrenttenant] = useState<Tenant | undefined>();

  useEffect(() => {
    let ls: string | undefined | null = undefined;
    try {
      ls = localStorage.getItem('evoach.currentTenant');
    } catch (reason: unknown) {
      console.error(reason);
    }
    if (ls === null || ls === undefined) {
      setcurrenttenant(undefined);
      return;
    }
    try {
      if (ls === undefined) {
        setcurrenttenant(undefined);
      } else {
        const t = JSON.parse(ls + '');
        setcurrenttenant(t);
      }
    } catch (reason: unknown) {
      console.error(reason);
      setcurrenttenant(undefined);
    }
  }, []);

  const setCurrentTenant = useCallback((tenant: Tenant) => {
    if (tenant) {
      try {
        localStorage.setItem('evoach.currentTenant', JSON.stringify(tenant));
        setcurrenttenant(tenant);
      } catch (reason: unknown) {
        console.error(reason);
      }
    }
    try {
      localStorage.setItem('evoach.currentTenant', JSON.stringify(undefined));
    } catch (reason: unknown) {
      console.error(reason);
    }
    setcurrenttenant(undefined);
  }, []);

  //console.log(tenants);
  //console.log('currenttenant', currenttenant);

  // get cumulative loading state
  const isLoading = useMemo(() => {
    return keycloakIsLoading || backendIsLoading;
  }, [backendIsLoading, keycloakIsLoading]);

  // get name (from backend account object)
  const name = useMemo(() => {
    if (account?.givenname && account?.familyname) {
      return `${account.givenname} ${account.familyname}`;
    } else {
      return '? ?';
    }
  }, [account?.familyname, account?.givenname]);

  // create an array with roles from the token
  const roles = useMemo(() => {
    if (!token) {
      return [];
    }
    let roles = keycloak.tokenParsed?.realm_access?.roles
      .filter((role: string) =>
        Object.values(RoleEnum).includes(role as RoleEnum)
      )
      .map((role: string) => role as RoleEnum);
    if (roles === undefined) {
      roles = [];
    }
    return roles;
  }, [token]);

  // provide a function to check whether the current account is in the corresponding role
  const hasRole = (role: RoleEnum): boolean => {
    // if (role === RoleEnum.EVOACHADMIN) return false;
    return roles.includes(role);
  };

  // get all features when account loads and memoize them
  const features = useMemo(() => {
    if (!account?.features) {
      return undefined;
    } else {
      return account?.features;
    }
  }, [account?.features]);

  /**
   * provide a function to check whether the current account has a specific feature
   * @param {FeatureEnum} feature of user to be checked
   * @returns {boolean} true = has feature (i.e. bool value is true, number value exists), false otherwise
   */
  const hasFeature = (feature: FeatureEnum): boolean => {
    // for bool, check true false, for number, check existence,
    // for both check !== undefined

    // no features available
    if (!features) return false;

    // features available, but specific feature undefined
    if (!features[feature]) return false;

    // sprecific feature available
    return typeof features[feature] === 'boolean'
      ? (features[feature] as boolean) // return bool value
      : true; // for numbers, return true because its available
  };

  /**
   * provide a function to return the value of a feature
   * @param {FeatureEnum} feature of user to be checked
   * @returns {boolean | number | undefined} undefined, if feature is not available, bool or number value otherwise, depending on feature type
   */
  const getFeature = (feature: FeatureEnum): boolean | number | undefined => {
    // no features available
    if (!features) return undefined;

    // features available, but specific feature undefined
    if (!features[feature]) return undefined;

    // sprecific feature available
    return features[feature];
  };

  // get email (from backend account object)
  const email = useMemo(() => {
    return account?.email ?? '';
  }, [account?.email]);

  const textSpeedFromLocalStorage = localStorage.getItem(
    'evoach.player.textSpeed'
  ) as TextSpeed | null;
  // preferences
  const [textSpeed, setTextSpeed] = useState<TextSpeed>(
    textSpeedFromLocalStorage ?? TextSpeed.MEDIUM
  );

  return (
    <AccountContext.Provider
      value={{
        isLoading,

        isError,
        error,

        isAuthenticated,
        token,

        account,

        name,
        email,

        preferences: {
          textSpeed,
          setTextSpeed,
        },

        roles,

        hasRole,

        isPublicRoute,

        failureCount,
        hasFeature,
        getFeature,

        refetchAccount: refetch,

        currentTenant: currenttenant,
        setCurrentTenant: setCurrentTenant,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

/**
 * required to distinguish special tenants, e.g. for specific hand-taylored
 * product tours. See also PROD-2009
 */
export enum SpecialTenantsEnum {
  ICF = '0157692a-2fd2-48af-80ab-7c1cdbc043a2',
  LOET = '5c4218fd-50ee-42c6-a8c6-c130b797902d', // loet@biggerontheinside.nl
}
