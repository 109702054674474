import { SupportedModuleLocales } from '../intl/SupportedLocales';

/**
 * definition of a PrintTask
 */
export type PrintTask = {
  /**
   * describe document to print
   */
  document: PrintDocument;
  coach?: PrintCoachData;
  coachee: PrintCoacheeData;
};

/**
 * what types of printing templates are available
 */
export enum PrintDocumentTypeEnum {
  CERTIFICATE = 'certificate',
  // CHAT = 'chat',
  // WORKBOOK = 'workbook'
}

export type PrintDocument = {
  /**
   * used to read the correct template type
   */
  type: PrintDocumentTypeEnum;
  /**
   * date is used to extract month and year. This information
   * is printed in the document - not as printing date but as
   * completion date of the session or the program
   */
  date?: Date;
  /**
   * language to be used for printing
   */
  language: SupportedModuleLocales;
  /**
   * mandatory titel of the program / chat for which the print task / certficate is issued
   */
  title?: string;
  /**
   * @ptional units for a coaching program, e.g. coreCCEUs for ICF
   */
  points1?: string;
  /**
   * @optional second type of units for a coaching program, e.g. ressourceCCEUs for ICF
   */
  points2?: string;
  /**
   * @optional third type of units for a coaching program, e.g. ressourceCCEUs for ICF
   */
  points3?: string;
  /**
   * @optional coach data passed via API if the coach isn't part of evoach account
   */
  coachData?: PrintCoachData;
  /**
   * @optional reference to a module, if available
   */
  moduleid?: string;
  /**
   * @optional reference to a sessionid, if available
   */
  sessionid?: string;
  /**
   * @optional reference to a program, if available
   */
  programid?: string;
  /**
   * @optional reference to a program instance, if available
   */
  programinstanceid?: string;
  /**
   * @optional account id of the coach data to be used for the printing task
   */
  coachAccountId?: string;
  /**
   * @optional account aid of the coach data to be used for the printing task.
   * The aid is used in sessions for instance.
   */
  coachAccountAid?: number;
  /**
   * @optional template id for printing template
   */
  templateid?: string;
  /**
   * @optional validation code - is generated in printRenderHandler when storing the PrintTask in the diary
   */
  validationCode?: string;
};

export type PrintCoachData = {
  /**
   * the account if of a coach is required to find the correct template
   */
  accountid?: string;
  givenname?: string;
  familyname?: string;
};

export type PrintCoacheeData = {
  /**
   * account id of coachee is needed to access the correct S3 bucket
   * to put the SSR generated HTML file of the print task and the
   * printing result
   */
  accountid: string;
  givenname: string;
  familyname: string;
};
