import { useMutation } from 'react-query';

import { authorizedDelete } from '../authorizedApi';

export const useDeleteSessionMutation = () => {
  return useMutation<any, Error, string>(
    async (sessionId: string) => {
      const deleteSession = authorizedDelete(`/session/${sessionId}`);
      await deleteSession();
    },
    {
      retry: false,
    }
  );
};
