import { useContext, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { EvoachButton } from '@evoach/ui-components';

import { WindowContext } from '../../window/WindowContext';

export const NotOptimizedForMobile = () => {
  const { isMobile } = useContext(WindowContext);
  const [open, setOpen] = useState(true);
  const intl = useIntl();

  const handleClose = () => {
    setOpen(false);
  };

  if (!isMobile || !open) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {intl.formatMessage({
            id: 'player.notoptimizedformobile.description.',
            defaultMessage:
              'Du rufst die Seite auf einem mobilen Gerät auf. Bitte beachte, dass die Seite nicht für mobile Geräte optimiert ist. Die beste Erfahrung hast du auf einem Desktop-Computer oder Laptop.',
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <EvoachButton onClick={handleClose} autoFocus>
          {intl.formatMessage({
            id: 'player.notoptimizedformobile.button',
            defaultMessage: 'Ok, verstanden.',
          })}
        </EvoachButton>
      </DialogActions>
    </Dialog>
  );
};
