import {
  AudioFileExtensions,
  ImageFileExtensions,
  VideoFileExtensions,
} from './assetHelper';

export const getComponentType = (extension: string) => {
  if (Object.values<string>(ImageFileExtensions).includes(extension))
    return 'img';
  if (Object.values<string>(AudioFileExtensions).includes(extension))
    return 'audio';
  if (Object.values<string>(VideoFileExtensions).includes(extension))
    return 'video';
  return 'iframe';
};
