import { useQuery } from 'react-query';

import { authorizedGet } from '../authorizedApi';

export const useFetchAssets = () => {
  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    'assetList',
    async () => {
      const assetURL: RequestInfo = `/asset?addpreviewsrc=true`;

      const getAssetList = authorizedGet(assetURL);
      const response = await getAssetList();

      const data = await response.json();

      return data;
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    assets: data,
    refetch,
  };
};
