import { translateWithFallback } from '@evoach/ui-components';
import { MachineContext } from '@evoach/ui-components';
import { IntlShape } from 'react-intl';
import { cloneDeep } from 'lodash';
import { assign } from 'xstate';

import { authorizedPost, unauthorizedPost } from '../../api';

//
// Used in backend for retrieving mail from session.
// Explain user what's happening and prepare sending the mail.
//
const backendMailVariable = 'currentEvoachMail.unique';
const backendEmailAddress = 'currentEmailAddress.unique';

export const renderSendMail = (intl: IntlShape) => {
  return assign((context: MachineContext, _event: any, actionMetadata) => {
    const newContext = cloneDeep(context);
    const payload = actionMetadata.action.payload;
    const getValuesFrom = payload.getValuesFrom ?? [];
    const editableCoachMessage = translateWithFallback(intl, payload.message);

    newContext.userData[backendMailVariable] = getValuesFrom;
    newContext.userData[backendEmailAddress] = payload.email;

    if (editableCoachMessage !== '') {
      newContext.widgetData.push({
        type: 'coachMessage',
        temporary: false,
        props: {
          message: editableCoachMessage,
        },
      });
    }
    newContext.widgetData.push({
      type: 'coachMessage',
      temporary: false,
      props: {
        message: translateWithFallback(intl, 'sendMail.notifyCoachee'),
      },
    });
    newContext.widgetData.push({
      type: 'yesNoButton',
      temporary: true,
      props: {
        yesButtonText: translateWithFallback(intl, 'sendMail.yes'),
        noButtonText: translateWithFallback(intl, 'sendMail.no'),
        saveResultTo: 'evoachechokey.sendMailDecision',
      },
    });

    return newContext;
  });
};

//
// setSendMail is the action for the "Send Mail" node in creator
// It performs a backend call to notify inviting coach of the current session
// that the node was passed. (PROD-1274)
//
export const setSendMail = (isPublicRoute: boolean, sessionId: string) => {
  return assign((context: MachineContext, _event: any) => {
    // call backend for mail send
    // /mail/:mailid/:sessionid
    const payload = {
      getValuesFrom: context.userData[backendMailVariable],
      email: context.userData[backendEmailAddress],
    };
    const mailUrl = `/mail/affe1223-4711-3e3f-af1f-98b1c001adc2/${sessionId}`;
    const sendMailNodeRequest = !isPublicRoute
      ? authorizedPost(mailUrl, payload)
      : unauthorizedPost(mailUrl, payload);

    // TODO is async! .then() for result and potential output
    sendMailNodeRequest();
    //const data = await response.json();
    return context;
  });
};
