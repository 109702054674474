import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog,
  TextField,
  CardMedia,
  Typography,
} from '@mui/material';
import { EvoachButton } from '@evoach/ui-components';

import { AssetType } from './assetHelper';
import { getComponentType } from './AssetPreview';

interface AssetExternalSourceDialogProps {
  src: string;
  /**
   * returns the new source, signature: (source: string) => {}
   */
  onUpdate?: Function;
  onClose: Function;
  isOpen: boolean;
  assetType: AssetType;
}

export const AssetExternalSourceDialog: React.FC<
  AssetExternalSourceDialogProps
> = ({ src, onUpdate, onClose, isOpen, assetType }) => {
  const intl = useIntl();

  const [srcEntry, setSrcEntry] = useState<string>(src);

  let extension: string | undefined = '';
  if (assetType !== AssetType.VIDEO) {
    const s = srcEntry.split('.');
    extension = s.pop();
  }

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {intl.formatMessage({
          id: 'player.editor.assets.sourcedialog.title',
          defaultMessage: 'Externe URL auswählen',
        })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" component="span">
          {srcEntry.startsWith('http') && extension !== undefined ? (
            <>
              <Typography variant="body1" component="span">
                {intl.formatMessage({
                  id: 'player.editor.assets.sourcedialog.message.url',
                  defaultMessage:
                    'Die Vorschau schneidet die Datei aus Platzgründen unten ab, sie wird aber später vollständig angezeigt.',
                })}
              </Typography>
              {assetType !== AssetType.VIDEO ? (
                <CardMedia
                  component={getComponentType(extension)}
                  controls={assetType === AssetType.AUDIO}
                  src={srcEntry}
                  sx={{
                    width: '100%',
                    marginTop: '10px',
                    height: '100px',
                    overflow: 'hidden',
                  }}
                />
              ) : (
                <CardMedia
                  component="video"
                  src={srcEntry}
                  sx={{
                    width: '100%',
                    marginTop: '10px',
                    height: '100px',
                    overflow: 'hidden',
                  }}
                  controls
                />
              )}
            </>
          ) : (
            <Typography component="span">
              {intl.formatMessage({
                id: 'player.editor.assets.sourcedialog.message.nourl',
                defaultMessage:
                  'Der eingegebene Text ist keine URL. Wenn du eine gültige URL angibst, siehst du über dem Eingabefeld eine Vorschau. ',
              })}
            </Typography>
          )}
          <TextField
            id="donotcatchpasteevent_externalurlforasset"
            value={srcEntry}
            sx={{ width: '100%', marginTop: '20px' }}
            onChange={(event: any) => {
              if (event) {
                setSrcEntry(event.target.value);
              }
            }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <EvoachButton onClick={() => onClose()} autoFocus>
          {intl.formatMessage({
            id: 'player.editor.assets.sourcedialog.button.cancel',
            defaultMessage: 'Abbrechen',
          })}
        </EvoachButton>
        <EvoachButton
          onClick={() => {
            if (onUpdate) onUpdate(srcEntry);
          }}
          autoFocus
        >
          {intl.formatMessage({
            id: 'player.editor.assets.sourcedialog.button.ok',
            defaultMessage: 'Übernehmen',
          })}
        </EvoachButton>
      </DialogActions>
    </Dialog>
  );
};
