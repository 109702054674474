//
// Type Definitions for Subscriptions / SubscriptionInfo
//

/**
 *
 * note that each Subscription Type should gave a subscription definition
 * towards the included features. If a type has not definition, no features
 * are returned by @see SubscriptionService.getListOfActiveFeatures.
 * @see subscriptionDefinitions.ts for definitions.
 *
 */
export enum SubscriptionType {
  FREE_TIER = 0,
  TRIAL_30_DAYS = 1,
  BASIC = 2,
  GROW = 3,
  PRO = 4,
  STARTER = 5,
}

/**
 * array of strings like "FREE_TIER" and "TRIAL_30_DAYS"
 */
export const SubscriptionTypeKeys = Object.keys(SubscriptionType).filter(
  (key: string) => isNaN(parseInt(key))
);

/**
 * array of strings containing the values as strings like "0" or "1"
 */
export const SubscriptionTypeValues = Object.keys(SubscriptionType)
  .filter((key: string) => parseInt(key) + '' === key)
  .map((k: string) => parseInt(k));

/**
 * helper enum to facilitate millisecond calcualtions
 */
export enum SubscriptionDurationInMs {
  DAYS_30 = 30 * 24 * 60 * 60 * 1000, // 30 days * 24 h * 60 min * 60 sec * 1000 ms
}

/**
 * subscription info is not a datatype in database but in code, only
 */
export interface SubscriptionInfo {
  type: SubscriptionType;
  features?: Features;
  /**
   * true if subscription is created via /subscribe self-service
   */
  isselfservice?: boolean;
}

/**
 * Type Definitions for Features
 *
 * Features should have a format like
 *
 * {
 *    key: value,
 *    key: value,
 * }
 *
 * To easily access a Feature by Feature[FeatureType.TYPE].
 * The suffix indicates the data type:
 * - _B = boolean
 * - _N = number
 */
export type Features = Record<string | FeatureEnum, boolean | number>;

/**
 * list of features and their parameter names, e.g. CREATOR_B = 'creatorapp',
 */
export enum FeatureEnum {
  CREATOR_B = 'creatorapp',
  PLAYER_B = 'playerapp',
  PROGRAMS_B = 'creatorprograms',
  EMBED_B = 'embedfunctionality',
  NUM_SESSION_N = 'numsession',
  NUM_COACHEES_N = 'numcoachees',
  NUM_ADMINS_N = 'numadmins',
  DEEPL_B = 'deepl', // PROD-1921
  GLOBALVARS_B = 'globalvars', // PROD-1921
  AI_B = 'aielements',
}
