import { useContext } from 'react';

import {
  AccountContext,
  SpecialTenantsEnum,
} from '../../account/AccountContext';

import { ReceptionEvoachTour } from './evoachTour';
import { ReceptionICFTour } from './ICFTour';

export const receptionTourId = 'reception';
/**
 *
 * Tours for the recption page.
 * Copy this file and rename the hook to use the hook in other pages.
 *
 * Usage:
 * In your component write
 *    const { tourid, toursteps } = useReceptionTourPage('<variant>');
 * to include a tour or its variant. For each variant, write an if-branch.
 * Then add
 *    <ProductTourManager tourid={tourid} toursteps={toursteps} />
 * as first component of your returned JSX in your component.
 *
 */
export const useReceptionTourPage = () => {
  const { currentTenant } = useContext(AccountContext);

  if (currentTenant?.tenantid === SpecialTenantsEnum.ICF) {
    return ReceptionICFTour(receptionTourId);
  } else {
    if (currentTenant?.tenantid === SpecialTenantsEnum.LOET) {
      return { tourid: receptionTourId, toursteps: [] };
    } else {
      return ReceptionEvoachTour(receptionTourId);
    }
  }
};
