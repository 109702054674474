import { Typography } from '@mui/material';

export const AppVersion: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <Typography variant="body2">
        Version: {process.env.REACT_APP_VERSION ?? 'not set'}
      </Typography>
    </div>
  );
};
