import * as React from 'react';

interface HtmlFormatterProps {
  text: string;
}

/**
 * This components gets a string that contains unformatted text and tries to
 * return a JSX element with formatted text. One important step is to split the
 * text by newline \n and return each "line" within a <p> tag.
 *
 * @param {string} text that will be formatted
 * @returns {JSX.Element} formatted text
 */
export const HtmlFormatter: React.FC<HtmlFormatterProps> = ({ text }) => {
  // Split text by newline
  const textArr = text.split('\n');

  // Map through array and return each text within a <p> tag
  return (
    <span>
      {textArr.map((item, index) => (
        <span key={index}>
          {parseTextAndReplaceURLs(item)}
          <br />
          &nbsp;
        </span>
      ))}
    </span>
  );
};

/**
 * This function gets a string that contains unformatted text and tries to
 * return a JSX element with formatted text. One important step is to split the
 * text by newline \n and return each "line" within a <p> tag.
 *
 * @param {string} text that will be formatted
 * @returns {JSX.Element} formatted text (HtmlFormatter)
 */
export const getHtmlFormatter = (text: string): JSX.Element => {
  return <HtmlFormatter text={text}></HtmlFormatter>;
};

/**
 * regex to find URLs in a string
 */
const urlRegex = /(https?:\/\/[^\s]+)/g;
/**
 * regex to find markdown links in a string
 */
const markdownLinkRegex = /\[([^[]+)\]\(([^)]+)\)/g;

/**
 * Parses a given text and returns a JSX element with formatted text. The
 * function tries to find URLs and markdown links and replaces them with
 * <a> tags.
 *
 * @param {string} text to be parsed
 * @returns {JSX.Element} ready to use JSX fragment
 */
export const parseTextAndReplaceURLs = (text: string): JSX.Element => {
  // if we find markdown links, we need to parse them first
  if (markdownLinkRegex.test(text)) {
    return parseMarkdownLink(text);
  } else {
    // normal links are parsed here
    return parseURLs(text);
  }
};

/**
 * Parse a text and return a JSX element with formatted URLs. Keep all other
 * parts of the text untouched.
 * The text cotains not a markup code for a link but a textual link/URL.
 *
 * @param {string} text
 * @returns {JSX.Element} ready to use JSX fragment
 */
const parseURLs = (text: string): JSX.Element => {
  const parts = text.split(new RegExp(urlRegex, 'g'));
  return (
    <span>
      {parts.map((part, index) =>
        urlRegex.test(part) ? (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        ) : (
          part
        )
      )}
    </span>
  );
};

/**
 * Parse a markdown link and return a JSX element with a formatted link.
 * Keep all other parts of the text untouched.
 *
 * @param {string} markdownLink is the comlete text to be parsed
 * @returns {JSX.Element} ready to use JSX fragment
 */
const parseMarkdownLink = (markdownLink: string): JSX.Element => {
  //
  // as the regex expression is global, we have to reset the search index for
  // subsequent calls
  //
  markdownLinkRegex.lastIndex = 0;
  let match = markdownLinkRegex.exec(markdownLink);

  // as long as we find a link, we process the string
  while (match) {
    const wholeLink = match[0];
    const linkText = match[1];
    const linkUrl = match[2];

    //
    // in a frist step, we replace the markdown link with a placeholder
    // as string! This is necessary to keep the correct order of the text
    // parts. Otherwise, the text would be mixed up.
    // Aftwareds, the valid HTML is contained in a string. If would be very
    // difficult to parse this string and replace the placeholder with the
    // correct JSX element. Therefore, we use the dangerouslySetInnerHTML
    // attribute to set the HTML string as HTML.
    //
    markdownLink = markdownLink.replace(
      wholeLink,
      `<a href=${linkUrl} target="_blank" rel="noopener noreferrer">${linkText}</a>`
    );

    match = markdownLinkRegex.exec(markdownLink);
  }
  // return the JSX element with the formatted text
  return <span dangerouslySetInnerHTML={{ __html: markdownLink }}></span>;
};
