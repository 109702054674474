import { Theme } from '@mui/material';
import React from 'react';

/**
 * Enum of all themes which are used to set the currentMuiTheme value
 * in the ThemeSwitch context
 */
export enum ThemesEnum {
  DEFAULT = 'evoach',
  EVOACH = 'evoach',
  ICF = 'icf',
}

export type ThemeSwitchContextProps = {
  /**
   * Current enum of selected theme
   */
  currentTheme: ThemesEnum;
  /**
   * set the current theme by enum. the currentMuiTheme will be updated automatically
   * @param {ThemesEnum} theme
   */
  setCurrentTheme: (theme: ThemesEnum) => void;
  /**
   * Current MUI theme, actual theme, not enum
   */
  currentMuiTheme: Theme;
};

/**
 * create empty context
 */
export const ThemeSwitchContext = React.createContext<ThemeSwitchContextProps>({
  currentTheme: ThemesEnum.DEFAULT,
  setCurrentTheme: () => {},
  currentMuiTheme: {} as Theme,
});
