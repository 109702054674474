import { useContext } from 'react';
import { IntlProvider } from 'react-intl';

import { TranslationContext } from './TranslationContext';
import { SupportedLocales } from './SupportedLocales';

export type CustomIntlProviderProps = {
  children?: React.ReactNode;
};

/**
 * global velements to format text
 * ! if you add something here, add it in the same file in the builder repo, too
 */
export const GLOBAL_DEFAULT_RICH_TEXT_ELEMENTS = {
  p: (e: any) => <p>{e}</p>,
  b: (e: any) => <strong>{e}</strong>,
  strong: (e: any) => <strong>{e}</strong>,
  ol: (e: any) => <ol>{e}</ol>,
  ul: (e: any) => <ul>{e}</ul>,
  li: (e: any) => <li>{e}</li>,
  i: (e: any) => <i>{e}</i>,
  u: (e: any) => <u>{e}</u>,
  h1: (e: any) => <h1>{e}</h1>,
  h2: (e: any) => <h2>{e}</h2>,
  h3: (e: any) => <h3>{e}</h3>,
  h4: (e: any) => <h4>{e}</h4>,
  h5: (e: any) => <h5>{e}</h5>,
  h6: (e: any) => <h6>{e}</h6>,
};

export const CustomIntlProvider: React.FC<CustomIntlProviderProps> = ({
  children,
}) => {
  const { locale, messages } = useContext(TranslationContext);

  return (
    <IntlProvider
      defaultLocale={SupportedLocales.DE}
      locale={locale}
      messages={messages}
      defaultRichTextElements={GLOBAL_DEFAULT_RICH_TEXT_ELEMENTS}
    >
      {children}
    </IntlProvider>
  );
};
