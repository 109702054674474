import React from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
} from '@mui/material';
import { EvoachButton } from '@evoach/ui-components';

import { ChatMessage } from '../../../entities/ExportTypes';

import { ChatPrint } from './ChatPrint';

interface SessionMemoryDialogProps {
  isOpen: boolean;
  onClose: Function;
  messages: ChatMessage[];
}

export const SessionMemoryDialog: React.FC<SessionMemoryDialogProps> = ({
  isOpen,
  onClose,
  messages,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '800px', maxWidth: 'none' } }}
      onClose={() => onClose()}
      open={isOpen}
    >
      <DialogTitle>Session as OpenAI chat</DialogTitle>
      <DialogContent sx={{ width: '800px' }}>
        <Typography variant="body2">
          <ChatPrint messages={messages} />
        </Typography>
      </DialogContent>
      <DialogActions>
        <EvoachButton
          color="secondary"
          onClick={() => {
            onClose();
          }}
        >
          Close
        </EvoachButton>
      </DialogActions>
    </Dialog>
  );
};
