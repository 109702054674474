import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ApiError } from '../api/ApiError';

interface ErrorAccordionProps {
  readableErrorMessage: string;
  error: any;
  additionalInfo?: string;
}

export const ErrorAccordion: React.FC<ErrorAccordionProps> = ({
  readableErrorMessage,
  error,
  additionalInfo,
}) => {
  const intl = useIntl();

  if (error?.message.startsWith('Failed to fetch')) {
    readableErrorMessage = intl.formatMessage({
      id: 'player.components.ErrorAccordion.failedtofetch',
      defaultMessage:
        'Möglicherweise ist unsere API zur Zeit nicht verfügbar. Versuche es bitte später noch einmal oder lade die Seite neu.',
    });
  }

  return (
    <Typography variant="body2" component="span">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            {intl.formatMessage({
              id: 'player.components.ErrorAccordion.error',
              defaultMessage:
                'Hoppla, da ist was schiefgelaufen. Klicke auf diese Meldung, um mehr Informationen zum Fehler zu erhalten. Sollte der Fehler weiter bestehen, schicke die Fehlermeldung bitte an deinen Kontakt bei evoach oder an deinen Coach. Wir kümmern uns darum!',
            })}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: '#DDDDDD' }}>
          <Typography variant="body2">
            {readableErrorMessage}
            <br />
            &nbsp;
            <br />
            {error?.message}
            <br />
            &nbsp;
            <br />
            HTTP Status:{' '}
            {error instanceof ApiError ? error?.details?.code ?? '' : ''}
            <br />
            &nbsp;
            <br />
            API Message:{' '}
            {error instanceof ApiError ? error?.details?.message ?? '' : ''}
            {additionalInfo && (
              <>
                <br />
                &nbsp;
                <br />
                Additional info: {additionalInfo ?? ''}
              </>
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Typography>
  );
};
