import { MachineContext, translateWithFallback } from '@evoach/ui-components';
import { cloneDeep } from 'lodash';
import { IntlShape } from 'react-intl';
import { assign } from 'xstate';

/** set avatar image to value triggered by statemachine
 * @param {string} payload.resolvedsrc is a presigned S3 Get URL
 */
export const setAvatarAction = (intl: IntlShape) => {
  return assign((context: MachineContext, _event, actionMetadata) => {
    const newContext = cloneDeep(context);
    const payload = actionMetadata.action.payload;

    // take it as it is
    // if image is not resolved / empty, the component automatically uses the default coach avatar
    // assign the whole object in order to keep the assetid for future updates of pre-signed URL

    const externalSrc = payload.src
      ? translateWithFallback(intl, payload.src)
      : '';

    const avatarImage = payload.resolvedsrc ?? externalSrc ?? '';

    // assign URL if available
    newContext.metaData.currentCoachAvatarImage = avatarImage;
    // remember already resolved assetid; resolve assetkey as pre-signed
    // AWS S3 GET URL in SessionPlayer when assetkey changes
    newContext.metaData.currentCoachAvatarImageAsset = translateWithFallback(
      intl,
      payload.assetid
    );

    return newContext;
  });
};
