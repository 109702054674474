import { defineMessages } from 'react-intl';

export enum ModuleCategoryEnum {
  selfcoaching = 'selfcoaching',
  selfassessment = 'selfassessment',
  before = 'before',
  after = 'after',
  other = 'other',
}

export const categoryTranslations = defineMessages({
  [ModuleCategoryEnum.selfcoaching]: {
    id: 'player.modules.metadata.category.selfcoaching',
    defaultMessage: 'Selbst-Coaching',
  },
  [ModuleCategoryEnum.before]: {
    id: 'player.modules.metadata.category.before',
    defaultMessage: 'Vorbereitung',
  },
  [ModuleCategoryEnum.after]: {
    id: 'player.modules.metadata.category.after',
    defaultMessage: 'Nachbereitung',
  },
  [ModuleCategoryEnum.selfassessment]: {
    id: 'player.modules.metadata.category.selfassessment',
    defaultMessage: 'Selbst-Evaluation',
  },
  [ModuleCategoryEnum.other]: {
    id: 'player.modules.metadata.category.other',
    defaultMessage: 'Andere',
  },
});
