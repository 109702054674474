import { useQuery } from 'react-query';

import { authorizedGet, unauthorizedGet } from '../authorizedApi';
import { keycloak } from '../../keycloak';
import { Account } from '../../entities/Account';

export const useFetchCoachData = (
  sessionid: string,
  isPublicRoute: boolean = false
) => {
  // for non-public routes, handle user management
  const { isLoading, data, isError, error } = useQuery<Account[], Error>(
    `useFetchCoachData-${sessionid}`,
    async () => {
      const getAccount = isPublicRoute
        ? unauthorizedGet(`/session/${sessionid}/coach`)
        : authorizedGet(`/session/${sessionid}/coach`);
      const getAccountResponse = await getAccount();
      return await getAccountResponse.json();
    },
    {
      retry: false,
      // only load when we have an auth token
      enabled: !!keycloak.token,
    }
  );

  if (!data) {
    //
    return {
      isLoading: false,
      isError: true,
      error: new Error('no coach data available'),
      coaches: [],
    };
  } else {
    return {
      isLoading,
      coaches: data,
      isError,
      error,
    };
  }
};
