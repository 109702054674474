import { useMutation } from 'react-query';

import { authorizedPut, unauthorizedPut } from '../authorizedApi';

interface UpdateSessionStateMutationPayload {
  sessionId: string;
  body: Record<string, any>;
  isPublicRoute: boolean;
}
export const useUpdateSessionStateMutation = () => {
  return useMutation<any, Error, UpdateSessionStateMutationPayload>(
    async ({
      sessionId,
      body,
      isPublicRoute = false,
    }: UpdateSessionStateMutationPayload) => {
      // send session state only
      const mbody = { sessionstate: { ...body.sessionstate } };

      try {
        const saveSessionState = !isPublicRoute
          ? authorizedPut(`/session/${sessionId}/sessionstate`, mbody)
          : unauthorizedPut(`/session/${sessionId}/sessionstate`, mbody);

        await saveSessionState();
      } catch (reason: unknown) {
        console.error(reason);
      }
    },
    {
      retry: false,
    }
  );
};
