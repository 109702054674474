//import React from 'react';
import ReactDOM from 'react-dom/client';

import { App } from './App';
//import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<App />);

/*
Mae, 17.05.2022

The normal code would look like this. But this leads to infinite redirects with keycloak.js
When migrating to React 18:
s. also https://github.com/react-keycloak/react-keycloak/issues/182


root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>

TODO check for new https://github.com/react-keycloak/react-keycloak (at time of this comment, it was "@react-keycloak/web": "^3.4.0",)

Mae, 20.03.2023

Added StrictMode to App.tsx
*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
