import { MachineContext } from '@evoach/ui-components';
import { cloneDeep } from 'lodash';
import { assign, send } from 'xstate';

enum Result {
  Smaller,
  Equal,
  Bigger,
  Invalid,
}

/**
 * Helper function used in guard implementations
 */
const compareValues = (context: MachineContext, _event: any): Result => {
  const firstKeyToCompare = context.userData['firstValueToCompare'];
  const secondKeyToCompare = context.userData['secondValueToCompare'];
  const firstValueToCompare = parseInt(context.userData[firstKeyToCompare]);
  const secondValueToCompare = parseInt(context.userData[secondKeyToCompare]);

  if (
    [firstValueToCompare, secondValueToCompare].every(
      (val) => val !== undefined
    )
  ) {
    if (firstValueToCompare > secondValueToCompare) {
      return Result.Bigger;
    } else if (firstValueToCompare < secondValueToCompare) {
      return Result.Smaller;
    } else {
      return Result.Equal;
    }
  } else {
    return Result.Invalid;
  }
};

/**
 * extract variable names used for comparison and add them to the context
 */
export const setCompareNumbers = assign(
  (context: MachineContext, _event: any, actionMetadata) => {
    const newContext = cloneDeep(context);

    const payload = actionMetadata.action.payload;
    const firstValueToCompare = payload.firstValueToCompare ?? '';
    const secondValueToCompare = payload.secondValueToCompare ?? '';

    newContext.userData['firstValueToCompare'] = firstValueToCompare;
    newContext.userData['secondValueToCompare'] = secondValueToCompare;
    return newContext;
  }
);

export const smaller = (context: MachineContext, _event: any): boolean => {
  return compareValues(context, _event) === Result.Smaller ? true : false;
};

export const equal = (context: MachineContext, _event: any): boolean => {
  return compareValues(context, _event) === Result.Equal ? true : false;
};

export const bigger = (context: MachineContext, _event: any): boolean => {
  return compareValues(context, _event) === Result.Bigger ? true : false;
};

/**
 * compare guards definition for xState State machine as used in
 * SessionPlayer.tsx
 */
export const compareGuards = {
  biggerCompareGuard: bigger,
  equalCompareGuard: equal,
  smallerCompareGuard: smaller,
};

/**
 * compare a number with intervals and save the new target node in
 * newContext.userData['lastmulticomparetargetgeneratedbyaction']
 *
 * the action expects a payload with
 *  series = array of numbers for the intervals
 *  keyTexts = array of targetNode IDs
 *  getValueFrom = variable name that contains the value that is checked for
 *  being in the intervals.
 *
 * The compare values are in payload.series which is an array
 * of numbers. The targets are in keyTexts[index].
 * Let n be the number of elements in series. Then the calcualtion works
 * with these intervals:
 *
 * index=0    =>  -infinity < value < series [0]      => target: keyTexts[0]
 * index=1    =>  series [0] < value < series [1]     => target: keyTexts[1]
 * ...
 * index=n-1  =>  series [n-2] < value < series [n-1] => target: keyTexts[n-1]
 * index=n    =>  series [n-1] < value < infinity     => target: 'mulitcompare.final'
 *
 */
export const setMultipleCompareNumbers = assign(
  (context: MachineContext, _event: any, actionMetadata) => {
    const newContext = cloneDeep(context);

    const payload = actionMetadata.action.payload;

    const valueToCompare =
      parseInt(newContext.userData[payload?.getValueFrom ?? '']) ?? 0;

    let targetNode = 'mulitcompare.final';

    const series = payload.series;
    const keyTexts = payload.keyTexts;

    // we need a series and keytexts and they have to have the same length
    if (series && keyTexts && series.length === keyTexts.length) {
      series.forEach((val: number, index: number) => {
        if (index === 0) {
          if (valueToCompare <= val) {
            targetNode = keyTexts[index];
          }
        } else {
          if (valueToCompare > series[index - 1] && valueToCompare <= val) {
            targetNode = keyTexts[index];
          }
        }
      });
    }

    newContext.userData['lastmulticomparetargetgeneratedbyaction'] = targetNode;

    return newContext;
  }
);

/**
 * Send to targetNode after choosing it in the action setMultipleCompareNumbers
 * targetNode is stored in setMultipleCompareNumbers in the variable
 * context.userData['lastmulticomparetargetgeneratedbyaction']
 */
export const sendToTargetAfterMultipleCompareNumbers = send(
  (context: MachineContext, _event: any) => ({
    type: context.userData['lastmulticomparetargetgeneratedbyaction'],
  })
);
