import { useQuery } from 'react-query';

import { ProgramInstance } from '../../entities';
import { useEnvironment } from '../../environment/useEnvironment';
import { getAssetUrl } from '../asset';
import { authorizedGet } from '../authorizedApi';
import { mapImageToPublicAsset } from '../../components/PropertiesAssets/assetHelper';

export const useFetchProgramInstancesQuery = (programidlist?: string[]) => {
  const { playerBasePath } = useEnvironment();

  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    `programinstance`,
    async () => {
      const getProgramInstances = authorizedGet(
        `/programinstance?programidlist=${programidlist ?? ''}`
      );
      const response = await getProgramInstances();
      const data: ProgramInstance[] = await response.json();

      // resolve images for list
      for (let i = 0; i < data.length; i++) {
        if (data[i].program.assetid) {
          data[i].program.resolvedsrc = await getAssetUrl(
            data[i].program.assetid ?? ''
          );
        } else {
          if (data[i].program.src) {
            data[i].program.resolvedsrc = data[i].program.src;
          } else {
            if (data[i].program.image !== undefined) {
              data[i].program.resolvedsrc = mapImageToPublicAsset(
                data[i].program.image,
                playerBasePath
              );
            } else {
              data[i].program.resolvedsrc = '';
            }
          }
        }
      }

      return data;
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    programinstances: data,
    refetch,
  };
};
