import { Account } from './Account';
import { SessionSharing } from './SessionSharing';

//
// Session entity as defined in backend
// Here a copy and manually adapted version as type interface for player develeopment
//
export interface Session {
  seid: number;
  sessionid: string;
  originalmoduleid: string;
  tscreated: Date;
  tsupdated: Date;
  account: Account;
  dereferencedstatemachine: Record<string, any>;
  globalvariables: Record<string, any>;
  metadata: Record<string, any>;
  uiTheme: string;
  sessionsharings: SessionSharing[];
  statemachinetranslation: Record<string, any>;
  metadatatranslation: Record<string, any>;
  lang: string;
  sessionstate: Record<string, any>;
  previewsession: boolean;
  progress: number;
  sessiontype: SessionType;
  coachAccountAid: number;
  coacheenote: string;
  sharedSession?: boolean;
  publicsessionprogressbar?: boolean;
  publicsession?: boolean;
  machineDefintion?: any; // where is this coming from? => s. ModulePage.tsx
}

//
// 0 = unknown
// 1 = preview
// 2 = default without invitation
// 3 = by standard invitation
// 4 = public
//    may use it later for
// 5 = via program
// 6 = via in-module-invitation
//    others?
//
export enum SessionType {
  UNKNOWN = 0,
  PREVIEW = 1,
  DEFAULT_WITHOUT_INVITATION = 2,
  BY_INVITATION = 3,
  PUBLIC = 4,
  BY_PROGRAM = 5,
  BY_IN_MODULE_INVITATION = 6,
}
