export enum EnvironmentName {
  LOCAL = 'LOCAL',
  STAGING = 'STAGING',
  PRODUCTION = 'PRODUCTION',
}

//
// ! IMPORTANT NOTES
//
// ! 1) api path
// if you change the apiBasePath entries for staging or production
// please adapt referer-authorizer-hander.ts in backend (fct getApiEnvironment)
//
// ! 2) general changes
// if you do changes here, consider adapting the same file in the other apps as well as in ui-components!
//

interface EnvironmentFeatures {
  showDevToolsToggle: boolean;
}

interface Environment {
  name: EnvironmentName;
  apiBasePath: string;
  publicApiBasePath: string;
  feautures: EnvironmentFeatures;
  playerBasePath: string;
  builderBasePath: string;
}

const localEnvironment: Environment = {
  name: EnvironmentName.LOCAL,
  apiBasePath: 'http://localhost:3001',
  publicApiBasePath: 'http://localhost:3003',
  feautures: {
    showDevToolsToggle: true,
  },
  playerBasePath: 'http://localhost:3000',
  builderBasePath: 'http://localhost:3002',
};

const stagingEnvironment: Environment = {
  name: EnvironmentName.STAGING,
  apiBasePath: 'https://staging.api.evoach.de', // https://l65hd7gzl3.execute-api.eu-central-1.amazonaws.com
  publicApiBasePath: 'https://staging.api.evoach.de/public',
  feautures: {
    showDevToolsToggle: true,
  },
  playerBasePath: 'https://staging.player.evoach.de',
  builderBasePath: 'https://staging.creator.evoach.de', // rename subdomain PROD-1262
};

const productionEnvironment: Environment = {
  name: EnvironmentName.PRODUCTION,
  apiBasePath: 'https://api.evoach.de', // https://q2rb7qrvl9.execute-api.eu-central-1.amazonaws.com
  publicApiBasePath: 'https://api.evoach.de/public',
  feautures: {
    showDevToolsToggle: false,
  },
  playerBasePath: 'https://player.evoach.de',
  builderBasePath: 'https://creator.evoach.de',
};

export const getEnvironment = (): Environment => {
  const host = window.location.host;

  const runLocalWithStaging =
    process.env.REACT_APP_RUN_LOCAL_WITH_STAGING_BACKEND;

  switch (host) {
    case 'localhost:3000':
      return runLocalWithStaging === 'true'
        ? stagingEnvironment
        : localEnvironment;
    case 'staging.player.evoach.de':
      return stagingEnvironment;
    case 'player.evoach.de':
      return productionEnvironment;
    default:
      // if none of the above match, default to the staging environment
      return stagingEnvironment;
  }
};

export const useEnvironment = (): Environment => {
  return getEnvironment();
};
