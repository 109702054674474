import { evoachDefaultTheme } from '@evoach/ui-components';
import { ArrowForwardIos } from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import { AccountContext, SpecialTenantsEnum } from '../account';

interface ProductTourButtonProps {
  text?: string;
  buttonType?: string;
}

export const ProductTourButton: React.FC<ProductTourButtonProps> = ({
  text,
  buttonType,
}) => {
  const intl = useIntl();

  const { currentTenant } = useContext(AccountContext);

  let internalText = text ?? '';
  if (buttonType === 'next') {
    return (
      <Box
        sx={{
          transform: 'scale(1.4)',
          color: evoachDefaultTheme.palette.secondary.main,
        }}
      >
        <ArrowForwardIos />
      </Box>
    );
  }
  if (buttonType === 'laststepnext') {
    if (currentTenant?.tenantid === SpecialTenantsEnum.ICF) {
      internalText = intl.formatMessage({
        id: 'player.productttour.icf.button.laststepnext',
        defaultMessage: "Los geht's!",
      });
    } else {
      internalText = intl.formatMessage({
        id: 'player.productttour.button.laststepnext',
        defaultMessage: 'Lass uns chatten!',
      });
    }
  }

  if (buttonType === 'previous') {
    return (
      <Box
        sx={{
          transform: 'scale(1.4)',
          color: evoachDefaultTheme.palette.secondary.main,
        }}
      >
        <ArrowBackIosIcon />
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          backgroundColor: evoachDefaultTheme.palette.secondary.main,
          padding: '10px',
          typography: 'body2',
          color: '#FFFFFF',
          fontWeight: '500',
          borderRadius: '4px',
          fontVariant: 'button',
        }}
      >
        {internalText}
      </Box>
    );
  }
};
