import { Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../routing/routes';

export const DisclaimerLink: React.FC = () => {
  const intl = useIntl();
  let navigate = useNavigate();

  const onclick = () => {
    navigate(`${AppRoutes.DISCLAIMER}`);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <Typography variant="body2" component="span">
        <div
          key="disclaimerlink"
          onClick={() => {
            onclick();
          }}
        >
          {intl.formatMessage({
            id: 'player.headermenu.menuitem.disclaimerlink',
            defaultMessage: 'Open Source Lizenzen',
          })}
        </div>
      </Typography>
    </div>
  );
};
