import { CardImageEnum } from '@evoach/ui-components';
import { defineMessages } from 'react-intl';

import { SupportedModuleLocales } from '../intl/SupportedLocales';

import { Account } from './Account';

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
/**
 * entity Program in DB
 *
 */
export interface Program {
  [index: string]:
    | number
    | string
    | boolean
    | Date
    | Account
    | ProgramProps
    | ProgramPermission[]
    | string[];

  /**
   * pid is the internal DB id of a program
   */
  pid: number;
  /**
   * programid is the public id (UUID) used in links
   */
  programid: string;
  tscreated: Date;
  tsupdated: Date;
  /**
   * the program is a JSON in DB and is described by ProgramProps internally
   */
  program: ProgramProps;
  /**
   * DB FK to account to whom the program belongs to
   */
  account: Account;
  /**
   * list of permissions for this program
   */
  permissions: ProgramPermission[];
  /**
   *  describes type of a program
   *  1 = program without sub-modules (default) (linear)
   *  2 = program with sub-modules (linear)
   */
  programtype: ProgramTypeEnum;
  /**
   * if programtype === 1, programmmodules = [], otherwise it contains
   * a list of programids that are used as sub-programs
   */
  programmodules: string[];
  /**
   * is true if this program is created as sub-program of another program,
   * false otherweise
   */
  issubprogram: boolean;
}

/**
 * type of a program - introduced to distinguish programs and sub-programs
 */
export enum ProgramTypeEnum {
  PROGRAM_WITHOUT_SUBMODULES = 1,
  PROGRAM_WITH_SUBMODULES = 2,
}

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
/**
 * type definition for programs and program instances
 */
export interface ProgramProps {
  [index: string]:
    | number
    | string
    | Date
    | boolean
    | ProgramImageEnum
    | ProgramModule[]
    | undefined
    | string[];

  /**
   * title of a program or program instance
   */
  title: string;
  /**
   * description of a program / program instance. May be localized! Refer to optional "language" prop
   */
  description: string;
  /**
   * image to be displayed in a card view of the program (instance). Can be of Type CreatorImageEnum or CardImageEnum
   */
  image: ProgramImageEnum;
  /**
   * duration as string to enable "manual"/textual description of a duration for a program, something like '6 months'
   */
  duration: string;
  /**
   * array of {ProgramModule} modules within the program. May be empty!
   */
  modules: ProgramModule[];
  /**
   * startDate is only set for program instances. Programs are an abstract definitions and do not have an explicit start date.
   */
  startDate?: Date; // for program instances only!
  /**
   * the language is of type SupportedModuleLocales. Each program has only one translation. If you want
   * to provide a program in several languages, you have to duplicate the program and translate all texts in its properties
   * manually. There is no such thing like ProgramTranslations in the backend (as we have for modules for instance).
   * Duplicated programs have no reference to each other and work as independent programs! That means that you can't
   * get a list of all languages of a program but only a list of programs with languages.
   */
  language: SupportedModuleLocales;
  /**
   * labels of a program set by the coach
   */
  labels?: string[];
  /**
   * external URL to an image that is displayed in the ObjectCard of a program
   */
  src?: string;
  /**
   * assetid of an image that is displayed in the ObjectCard of a program. Has to
   * be resolved  when loading a module and before displaying.
   */
  assetid?: string;
  /**
   * depending on what image, src and assetid contains, the resolvedsrc is set.
   * Priority: assetid before src before image
   */
  resolvedsrc?: string;
  /**
   * internal number that is used to sort a list of programs / instances on the screen
   */
  ordernumber?: number;
  /**
   * assetid of key visual
   */
  keyvisualassetid?: string;
  /**
   * external src url of key visual
   */
  keyvisualsrc?: string;
  /**
   * position of key visual
   */
  keyvisualposition?: KeyVisualPositionEnum;
  /**
   * position of key visual
   */
  keyvisuallinktext?: string;
}

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
/**
 * type definition for programs permissions
 */
export interface ProgramPermission {
  CoachPermission_Edit: boolean;
  owns: boolean;
  invitationid: string | null;
  invitinginvitationid: string | null;
  account: Account;
}

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
/**
 *
 */
export interface ProgramModule {
  [index: string]:
    | number
    | string
    | Date
    | boolean
    | ProgramImageEnum
    | ProgramModuleAction[]
    | undefined;

  /**
   * id is used to order the sequence of the modules
   */
  id: number;
  /**
   * ??? do we need header ???
   */
  header: string;
  title: string;
  description: string;
  /**
   * duration as string to enable "manual"/textual description of a duration
   */
  duration: string;
  /**
   * date offset defines the offset of this module as a number of days after the start of the program (instance).
   * Used in programs, copied to program instances. Stays there for reference. In instances, startDate prop is set.
   */
  dateOffset: number; // offset in days
  /**
   * defines the explicit start date of a module - it can be derived by startDate of the program (instance) and the
   * dateOffset prop. Nevertheless, it can be manually edited in Creator to adapt automatically calcualted dates that
   * fall into holidays, to weekends, etc.
   */
  startDate?: Date; // for program instances only!
  /**
   * image to be dispayed in cards / module lists when displaying a program
   */
  image: ProgramImageEnum;
  /**
   * actions that are associated with that program. For programs, there may only be action types, for instances
   * there may also be action links.
   */
  actions: ProgramModuleAction[];
  /**
   * used to group program modules
   */
  group?: string;
  /**
   * external URL to an image that is displayed in the ObjectCard of a program module
   */
  src?: string;
  /**
   * assetid of an image that is displayed in the ObjectCard of a program module.
   * Has to be resolved  when loading a module and before displaying.
   */
  assetid?: string;
  /**
   * depending on what image, src and assetid contains, the resolvedsrc is set.
   * Priority: assetid before src before image
   */
  resolvedsrc?: string;
}

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
/**
 * Program module actions define all the actions a coachee has to perform within a module of a given program (instance)
 */
export interface ProgramModuleAction {
  [index: string]: string | ProgramModuleActionLinkTypeEnum | undefined;

  /**
   * Links are provided for instances, only. For a generic program, you don't
   * have to provide concrete Zoom links etc..
   */
  link?: string;
  /**
   * Link types may be used in programs and instances. For programs, they may
   * indicate what type of action will be required when joining an instance
   * without actually linking an action to it.
   */
  linkType: ProgramModuleActionLinkTypeEnum; // this may be interesting for programs, too
  /**
   * If linkType is chatbot, this prop contains the moduleid of the module to
   * be started.
   */
  moduleid?: string;
  /**
   * assetid of an image that is displayed in the ObjectCard of a program module.
   * Has to be resolved  when loading a module and before displaying.
   */
  assetid?: string;
  /**
   * In addition to a link or a selected module, we introduce a textual
   * description of an action
   */
  description?: string;
}

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
/**
 * this ENUM defines the different actions. We can use this type to render a proper icon in the action part.
 *
 * It is important that the keys of the ENUM are exactly the same as the values because we need that for selection lists.
 */
export enum ProgramModuleActionLinkTypeEnum {
  WORKSHEET = 'WORKSHEET',
  VIDEOCALL = 'VIDEOCALL',
  PERSONALMEETING = 'PERSONALMEETING',
  CHATBOT = 'CHATBOT',
  VIDEO = 'WATCHVIDEO',
  AUDIO = 'LISTENAUDIO',
  COMMUNITY = 'COMMUNITY',
  WHITEBOARD = 'WHITEBOARD',
}

// defines the position of the key visual
export enum KeyVisualPositionEnum {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

// add translations for the enum to display this stuff as text
defineMessages({
  download: {
    id: 'builder.programs.actionedit.DOWNLOAD',
    defaultMessage: 'Download bearbeiten',
  },
  WORKSHEET: {
    id: 'builder.programs.actionedit.WORKSHEET',
    defaultMessage: 'Arbeitsblatt',
  },
  zoom: {
    id: 'builder.programs.actionedit.ZOOMSESSION',
    defaultMessage: 'Zoom Session',
  },
  video: {
    id: 'builder.programs.actionedit.VIDEOCALL',
    defaultMessage: 'Video Call',
  },
  FACE2FACE: {
    id: 'builder.programs.actionedit.FACE2FACE',
    defaultMessage: 'Persönliches Treffen',
  },
  PERSONALMEETING: {
    id: 'builder.programs.actionedit.PERSONALMEETING',
    defaultMessage: 'Persönliches Treffen',
  },
  CHATBOT: {
    id: 'builder.programs.actionedit.CHATBOT',
    defaultMessage: 'Coaching Chatbot',
  },
  VIDEO: {
    id: 'builder.programs.actionedit.VIDEO',
    defaultMessage: 'Video anschauen',
  },
  AUDIO: {
    id: 'builder.programs.actionedit.AUDIO',
    defaultMessage: 'Audio anhören',
  },
  COMMUNITY: {
    id: 'builder.programs.actionedit.COMMUNITY',
    defaultMessage: 'Community',
  },
  WHITEBOARD: {
    id: 'builder.programs.actionedit.WHITEBOARD',
    defaultMessage: 'Whiteboard',
  },
});

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
/**
 * if we intend to use images for icons that are not part of UI-components, we have to define them in creator.
 * This enum contains all images/icons that are not part of UI-components CardImageEnum but will be part of
 * ProgramImageEnum.
 */
export enum CreatorImageEnum {
  ZOOM = 'zoom',
}

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
/**
 * ProgramImageEnum is a combination of the two image enums that can be used to display icons
 */
export type ProgramImageEnum = CardImageEnum; // CreatorImageEnum

// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #
/**
 * initial demo program to show the structure
 */
export const initialProgramTemplate: ProgramProps = {
  title: 'Demo Program',
  description: 'Initial demo program',
  image: CardImageEnum.acceptance,
  language: SupportedModuleLocales.EN,
  duration: '6 months',
  modules: [
    {
      id: 0,
      header: 'Module header',
      title: 'Module title',
      description: 'Module description',
      duration: '1 hour',
      dateOffset: 0,
      image: CardImageEnum.balloons,
      actions: [
        {
          link: 'https://jumptozoom',
          linkType: ProgramModuleActionLinkTypeEnum.VIDEOCALL,
        },
      ],
    },
  ],
};
