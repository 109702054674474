import { useMutation, useQueryClient } from 'react-query';

import { authorizedPut, unauthorizedPut } from '../authorizedApi';

interface UpdateSessionCoacheeNotePayload {
  sessionId: string;
  body: Record<string, any>;
  isPublicRoute: boolean;
}
export const useUpdateSessionCoacheeNote = () => {
  const queryClient = useQueryClient();

  return useMutation<any, Error, UpdateSessionCoacheeNotePayload>(
    async ({
      sessionId,
      body,
      isPublicRoute = false,
    }: UpdateSessionCoacheeNotePayload) => {
      const saveCoacheeNote = !isPublicRoute
        ? authorizedPut(`/session/${sessionId}/coacheenote`, body)
        : unauthorizedPut(`/session/${sessionId}/coacheenote`, body);

      await saveCoacheeNote();
    },
    {
      retry: false,
      onSuccess: () => {
        queryClient.refetchQueries({ queryKey: ['sessions'] });
      },
    }
  );
};
