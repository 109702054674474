import React, { useContext, useState } from 'react';
import { defineMessages } from '@formatjs/intl';
import { Visibility } from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import StopScreenShareOutlinedIcon from '@mui/icons-material/StopScreenShareOutlined';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import {
  useDeleteSessionMutation,
  useShareSessionMutation,
  useStartSessionMutation,
  useStopSharingSessionMutation,
} from '../../api';
import { AppRoutes } from '../../routing/routes';
import { WindowContext } from '../../window/WindowContext';

const listButtonTexts = defineMessages({
  moduleliststartsessionbuttontext: {
    id: 'player.components.modulelist.startsessionbutton.text',
    defaultMessage: 'Chatbot starten',
  },
  sessionlistresumesessionbuttontext: {
    id: 'player.components.modulelist.resumesessionbutton.text',
    defaultMessage: 'Chatbot-Session fortsetzen',
  },
  sessionlistviewsessionbuttontext: {
    id: 'player.components.modulelist.viewsessionbutton.text',
    defaultMessage: 'Chatbot-Protokoll anschauen',
  },
  sessionlistdeletesessionbuttontext: {
    id: 'player.components.modulelist.deletesessionbutton.text',
    defaultMessage: 'Chatbot-Protokoll löschen',
  },
  sharesessionbutton: {
    id: 'player.components.modulelist.sharesessionbutton.text',
    defaultMessage: 'Chatbot-Protokoll teilen',
  },
  deletesharesessionbutton: {
    id: 'player.components.modulelist.disablesharesessionbutton.text',
    defaultMessage: 'Chatbot-Protokoll nicht mehr teilen',
  },
  sharedSessionViewButton: {
    id: 'player.components.modulelist.sharedsessionviewbutton.text',
    defaultMessage: 'Chatbot-Protokoll anschauen',
  },
});

const alertDeleteMessages = defineMessages({
  sessiondeletedsuccesfultext: {
    id: 'player.components.modulelist.deletesession.alert.deletsuccesful',
    defaultMessage: 'Das Chatbot-Protokoll wurde erfolgreich gelöscht.',
  },
  sessiondeletederrortext: {
    id: 'player.components.modulelist.deletesession.alert.deleteerror',
    defaultMessage: 'Das Chatbot-Protokoll konnte nicht gelöscht werden.',
  },
});

/*
    ! Button to start a session
    creates a session and starts chatbot with sessionId
*/

export const StartSessionButton: React.FC = (params: any) => {
  const navigate = useNavigate();
  const intl = useIntl();

  const { mutate } = useStartSessionMutation();

  const startSession = (moduleId: string) => {
    mutate(moduleId, {
      onSuccess: (sessionId: string) => {
        navigate(`${AppRoutes.COACHING}/${sessionId}`);
      },
    });
  };

  // params.row.moduleid
  return (
    <strong>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={() => startSession(params.row.moduleid)}
      >
        {intl.formatMessage(listButtonTexts.moduleliststartsessionbuttontext)}
      </Button>
    </strong>
  );
};

/*
    ! Button to resume a session
    resumes a session with the sessionId
*/

export const ResumeOrViewSessionButton = ({
  sessionProgress,
  sessionId,
}: {
  sessionProgress: number;
  sessionId: string;
}) => {
  const navigate = useNavigate();
  const intl = useIntl();

  const isSessionComplete = sessionProgress === 100;

  const startSessionLink = (sessionId: string) => {
    if (sessionId && typeof sessionId === 'string') {
      navigate(`${AppRoutes.COACHING}/${sessionId}`);
    }
  };

  // params.row.moduleid
  return (
    <Tooltip
      title={
        isSessionComplete
          ? intl.formatMessage(listButtonTexts.sessionlistviewsessionbuttontext)
          : intl.formatMessage(
              listButtonTexts.sessionlistresumesessionbuttontext
            )
      }
      arrow
      placement="top"
    >
      <IconButton onClick={() => startSessionLink(sessionId)}>
        {isSessionComplete ? (
          <Visibility color="secondary" fontSize="medium" />
        ) : (
          <PlayCircleIcon color="secondary" fontSize="medium" />
        )}
      </IconButton>
    </Tooltip>
  );
};

/*
    ! Button to resume a session
    resumes a session with the sessionId
*/

export const ViewSessionButton: React.FC = (params: any) => {
  const navigate = useNavigate();
  const intl = useIntl();

  const startSessionLink = (sessionId: string) => {
    if (sessionId && typeof sessionId === 'string') {
      navigate(`${AppRoutes.COACHING}/${sessionId}`);
    }
  };

  // params.row.moduleid
  return (
    <Tooltip
      title={intl.formatMessage(listButtonTexts.sharedSessionViewButton)}
      arrow
      placement="top"
    >
      <IconButton onClick={() => startSessionLink(params.row.sessionid)}>
        <PlayCircleIcon color="secondary" fontSize="medium" />
      </IconButton>
    </Tooltip>
  );
};

/*
    ! Button to delete a session
    delete a session

   TODO display confirmation "bist du sicher? Unwiederruflich und so"
*/

interface DeleteSessionButtonProps {
  sessionId: string;
  refetchHandler: Function;
  disabled?: boolean;
}

export const DeleteSessionButton: React.FC<DeleteSessionButtonProps> = ({
  sessionId,
  refetchHandler,
}) => {
  const intl = useIntl();

  const { alert } = useContext(WindowContext);
  const { mutate } = useDeleteSessionMutation();
  const [isAreYouSureMessageOpen, setIsAreYouSureMessageOpen] =
    useState<boolean>(false);

  const deleteSessionHandler = async (sessionId: string) => {
    if (sessionId) {
      mutate(sessionId, {
        onError: () => {
          alert(
            intl.formatMessage(alertDeleteMessages.sessiondeletederrortext)
          );
        },
        onSuccess: () => {
          alert(
            intl.formatMessage(alertDeleteMessages.sessiondeletedsuccesfultext)
          );
        },
      });
      refetchHandler();
    }
  };

  // params.row.moduleid
  return (
    <strong>
      <Tooltip
        title={intl.formatMessage(
          listButtonTexts.sessionlistdeletesessionbuttontext
        )}
        arrow
        placement="top"
      >
        <IconButton
          onClick={() => setIsAreYouSureMessageOpen(!isAreYouSureMessageOpen)}
        >
          <DeleteForeverIcon color="secondary" fontSize="medium" />
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        onClose={() => setIsAreYouSureMessageOpen(!isAreYouSureMessageOpen)}
        open={isAreYouSureMessageOpen}
      >
        <DialogContent sx={{ maxHeight: '50px', minHeight: '30px' }}>
          <div style={{ paddingBottom: '15px' }}>
            <Typography component="span">
              {intl.formatMessage({
                id: 'backend.sessions.deleteSessionButton.areyousuremessage',
                defaultMessage:
                  'Bist du dir sicher, dass du das Chatbot-Protokoll löschen möchtest? Es ist danach unwideruflich gelöscht!',
              })}
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              deleteSessionHandler(sessionId);
              setIsAreYouSureMessageOpen(!isAreYouSureMessageOpen);
            }}
          >
            <Typography component="span">
              {intl.formatMessage({
                id: 'backend.sessions.deleteSessionButton.yesdelete',
                defaultMessage: 'Ja',
              })}
            </Typography>
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              setIsAreYouSureMessageOpen(!isAreYouSureMessageOpen);
            }}
          >
            <Typography component="span">
              {intl.formatMessage({
                id: 'backend.sessions.deleteSessionButton.nodelete',
                defaultMessage: 'Nein',
              })}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </strong>
  );
};

/**
 * button to share a session
 * */
interface ShareSessionButtonProps {
  sessionId: string;
  refetchHandler: Function;
  disabled?: boolean;
}

export const ShareSessionButton: React.FC<ShareSessionButtonProps> = ({
  sessionId,
  refetchHandler,
  disabled = false,
}) => {
  const intl = useIntl();
  const { alert } = useContext(WindowContext);
  const { mutate } = useShareSessionMutation();

  const shareSessionHandler = async (sessionId: string) => {
    if (sessionId) {
      mutate(sessionId, {
        onSuccess: () => {
          alert(
            intl.formatMessage({
              id: 'player.sessions.sharebutton.success',
              defaultMessage:
                'Dein Coach kann das Chatbot-Protokoll jetzt sehen.',
            })
          );
        },
        onError: () => {
          alert(
            intl.formatMessage({
              id: 'player.sessions.sharebutton.error',
              defaultMessage:
                'Beim Teilen des Chatbot-Protokolls ist ein Fehler aufgetreten. Das Chatbot-Protokoll konnte nicht mit deinem Coach geteilt werden.',
            })
          );
        },
      });
    }
    refetchHandler();
  };

  // params.row.moduleid
  return (
    <Tooltip
      arrow
      placement="top"
      title={
        disabled
          ? intl.formatMessage({
              id: 'player.sessions.sharebutton.disabled',
              defaultMessage:
                'Chatbot-Protokolle, die mit frei verfügbaren Chatbots gestartet wurden, können nicht automatisch mit einem Coach geteilt werden.',
            })
          : intl.formatMessage({
              id: 'player.sessions.sharebutton.enabled',
              defaultMessage: 'Chatbot-Protokoll mit deinem Coach teilen.',
            })
      }
    >
      <span>
        <IconButton
          disabled={disabled}
          onClick={() => shareSessionHandler(sessionId)}
        >
          <ScreenShareIcon color="secondary" fontSize="medium" />
        </IconButton>
      </span>
    </Tooltip>
  );
};

/**
 * button to disable sharing of a session
 * */

export const DoNotShareSessionButton: React.FC<ShareSessionButtonProps> = ({
  sessionId,
  refetchHandler,
}) => {
  const intl = useIntl();
  const { alert } = useContext(WindowContext);
  const { mutate } = useStopSharingSessionMutation();

  const stopSharingSessionHandler = async (sessionId: string) => {
    if (sessionId) {
      mutate(sessionId, {
        onSuccess: () => {
          alert(
            intl.formatMessage({
              id: 'player.sessions.stopsharebutton.success',
              defaultMessage:
                'Dein Coach kann das Chatbot-Protokoll jetzt nicht mehr sehen.',
            })
          );
        },
        onError: () => {
          alert(
            intl.formatMessage({
              id: 'player.sessions.stopsharebutton.error',
              defaultMessage:
                'Es ist ein Fehler aufgetreten. Das Teilen des Chatbot-Protokolls konnte nicht beendet werden und dein Coach kann das Protokoll weiterhin sehen.',
            })
          );
        },
      });
    }
    refetchHandler();
  };

  // params.row.moduleid
  return (
    <>
      <Tooltip
        title={intl.formatMessage(listButtonTexts.deletesharesessionbutton)}
        arrow
        placement="top"
      >
        <IconButton onClick={() => stopSharingSessionHandler(sessionId)}>
          <StopScreenShareOutlinedIcon color="secondary" fontSize="medium" />
        </IconButton>
      </Tooltip>
    </>
  );
};
