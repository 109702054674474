import React, { useState } from 'react';

import { useEnvironment } from '../environment/useEnvironment';

import { ProductTourContextProps } from './ProductTourContextProps';

export const ProductTourContext = React.createContext<ProductTourContextProps>({
  addPageVisit: () => {},
  getPageVisits: () => {},
  resetPageVisits: () => {},
  setAllowDisplayTour: () => {},
  getAllowDisplayTour: () => {},
  closeTour: () => {},
  openTour: () => {},
  isTourClosed: () => {},
  openTours: [],
});

export type ProductTourContextProviderProps = {
  children?: React.ReactNode;
};

/**
 * Context Provider to manage counters for pages used via localstorage
 * 
 * How to use? Example:
 *  
  
  const { getPageVisit, addPageVisit } = useContext(ProductTourContext);

  console.log('pagevists: ' + getPageVisits('myname'));

  // increase counter only, if component loads, not on mounts
  useEffect(() => {
    addPageVisit('myname');
  }, [addPageVisit]);

 *
 */

export const ProductTourContextProvider: React.FC<
  ProductTourContextProviderProps
> = ({ children }) => {
  const env = useEnvironment();
  const [closedTours, setClosedTours] = useState([] as string[]);
  const [openTours, setOpenTours] = useState([] as string[]);

  // generate item name in correkt format
  // if you change the format here, consider changing it in Creator app, too
  const getItemName = (pagename: string, suffix?: string): string => {
    return `evoach.producttour.${pagename}.${suffix}.${env.name}`;
  };

  /**
   * get number of page visits on pagename in this browser
   * @param {string} pagename
   * @returns {number} number of vists in this browser so far
   */
  const getPageVisits = (pagename: string): number => {
    // get storage item
    const item = localStorage.getItem(getItemName(pagename, 'visits'));
    return item === null ? 0 : parseInt(item);
  };

  /**
   * increase number of page visits by one
   * @param {string} pagename
   * @returns {number} number of vists in this browser so far
   */
  const addPageVisit = (pagename: string): number => {
    const newVal = getPageVisits(pagename) + 1;
    localStorage.setItem(getItemName(pagename, 'visits'), newVal + '');
    return newVal;
  };

  /**
   * reset number of page visits to 0
   * @param {string} pagename
   * @returns {void}
   */
  const resetPageVisits = (pagename: string): void => {
    localStorage.setItem(getItemName(pagename, 'visits'), '0');
    return;
  };

  /**
   * set the global setting whether to display any hints at all
   * @param {boolean} onoff
   * @param {string} pagename
   * @returns {void}
   */
  const setAllowDisplayTour = (onoff: boolean, tourname?: string): void => {
    localStorage.setItem(getItemName(tourname ?? 'app', 'global'), onoff + '');
    return;
  };

  /**
   * get allow display tour for a certain page or for anything if pagename is not provided
   *
   * @param {string} tourname @optional
   * @returns {string} "true" or "false"
   */
  const getAllowDisplayTour = (tourname?: string): string => {
    const i = localStorage.getItem(getItemName(tourname ?? 'app', 'global'));
    return i === null || i === undefined ? 'true' : i;
  };

  /**
   * allow components down the tree to close a specific tour
   * @param {string} tourname
   * @returns {void}
   */
  const closeTour = (tourname: string): void => {
    if (closedTours.indexOf(tourname) === -1) {
      setClosedTours(closedTours.concat([tourname]));
    }
    return;
  };

  /**
   * allow components down the tree to open a specific tour
   * @param {string} tourname
   * @returns {void}
   */
  const openTour = (tourname: string): void => {
    setAllowDisplayTour(true, tourname);
    setOpenTours(openTours.concat(tourname));
    return;
  };
  /**
   * check if tour has been closed manually by user
   * @param {string} tourname
   * @returns {void}
   */
  const isTourClosed = (tourname: string): boolean => {
    return closedTours.indexOf(tourname) === -1 ? false : true;
  };

  return (
    <ProductTourContext.Provider
      value={{
        addPageVisit,
        getPageVisits,
        setAllowDisplayTour,
        getAllowDisplayTour,
        resetPageVisits,
        closeTour,
        openTour,
        isTourClosed,
        openTours,
      }}
    >
      {children}
    </ProductTourContext.Provider>
  );
};
