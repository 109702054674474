import React from 'react';
import { Link, Typography } from '@mui/material';
import { EvoachButton } from '@evoach/ui-components';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

export type DataPrivacyProps = {
  name: string;
  email: string;
  isCoach: boolean;
};
export const DataPrivacy: React.FC = () => {
  const intl = useIntl();
  const maxWidth = '400px';
  const messages = defineMessages({
    privacyStatement: {
      id: 'player.profile.privacyStatement.text',
      defaultMessage:
        'Alle Informationen zu unseren Datenschutzrichtlinien findest du { PrivacyPolicyLink }.',
    },
    privacyPolicies: {
      id: 'player.profile.privacyPolicies.text',
      defaultMessage: 'hier',
    },
    privacyPoliciesLink: {
      id: 'player.profile.privacyPolicies.link',
      defaultMessage: 'https://www.evoach.com/privacy-policy?lang=de',
    },
  });

  // render profile information
  return (
    <div
      className="dataPrivacy"
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: '5rem',
        margin: '0 10rem',
        maxWidth: maxWidth,
      }}
    >
      <div
        className="privacyStatement"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          width: '100%',
        }}
      >
        <Typography variant="h6">
          {intl.formatMessage({
            id: 'player.profile.privacyStatement.title',
            defaultMessage: 'Datenschutzrichtlinie',
          })}
        </Typography>
        <Typography>
          <FormattedMessage
            {...messages.privacyStatement}
            values={{
              PrivacyPolicyLink: (
                <Link
                  target="_blank"
                  href={intl.formatMessage(messages.privacyPoliciesLink)}
                >
                  {intl.formatMessage(messages.privacyPolicies)}
                </Link>
              ),
            }}
          />
        </Typography>
      </div>

      <div
        className="userDataRequest"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          width: '100%',
        }}
      >
        <Typography variant="h6">
          {intl.formatMessage({
            id: 'player.profile.storedDataRequest.title',
            defaultMessage: 'Meine Daten anfordern',
          })}
        </Typography>
        <Typography>
          {intl.formatMessage({
            id: 'player.profile.storedDataRequest.text.1',
            defaultMessage:
              'Du kannst alle Daten anfordern, die wir über sich gespeichert haben, indem du über Button unten eine Anfrage stellst.',
          })}
        </Typography>
        <Typography>
          {intl.formatMessage({
            id: 'player.profile.storedDataRequest.text.2',
            defaultMessage:
              'Die Bearbeitung deiner Daten kann bis zu fünf Werktage dauern. Sobald die alle Daten gesammelt sind, erhältst du diese per Email.',
          })}
        </Typography>
        <Typography>
          {intl.formatMessage({
            id: 'player.profile.storedDataRequest.text.3',
            defaultMessage:
              'Sollte der Button nicht funktionieren, schreibe bitte eine Mail an team@evoach.de.',
          })}
        </Typography>

        <Link
          target="_blank"
          href={intl.formatMessage({
            id: 'player.profile.storedDataRequest.message',
            defaultMessage:
              'mailto:team@evoach.com?subject=Meine Daten anfordern&body=Ich möchte hiermit meine alle meine Daten bei evoach anfordern.',
          })}
          sx={{ width: 'fit-content' }}
        >
          <EvoachButton>
            {intl.formatMessage({
              id: 'player.profile.storedDataRequest.button',
              defaultMessage: 'Meine Daten anfordern',
            })}
          </EvoachButton>
        </Link>
      </div>

      <div
        className="userData"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          width: '100%',
        }}
      >
        <Typography variant="h6">
          {intl.formatMessage({
            id: 'player.profile.deleteDataRequest.title',
            defaultMessage: 'Meine Daten löschen',
          })}
        </Typography>
        <Typography>
          {intl.formatMessage({
            id: 'player.profile.deleteDataRequest.text.1',
            defaultMessage:
              'Du kannst deinen Account und alle zugehörigen Daten löschen, indem du über Button unten eine Anfrage stellst.',
          })}
        </Typography>
        <Typography>
          {intl.formatMessage({
            id: 'player.profile.deleteDataRequest.text.2',
            defaultMessage:
              'Die Bearbeitung der Löschung deiner Daten kann bis zu fünf Werktage dauern. Sobald die alle Daten gelöscht sind, erhältst du eine Bestätigung per Email.',
          })}
        </Typography>
        <Typography>
          {intl.formatMessage({
            id: 'player.profile.deleteDataRequest.text.3',
            defaultMessage:
              'Nachdem deine Daten gelöscht sind, können diese nicht wiederhergestellt werden.',
          })}
        </Typography>
        <Typography>
          {intl.formatMessage({
            id: 'player.profile.deleteDataRequest.text.4',
            defaultMessage:
              'Sollte der Button nicht funktionieren, schreibe bitte eine Mail an team@evoach.de.',
          })}
        </Typography>

        <Link
          href={intl.formatMessage({
            id: 'player.profile.deleteDataRequest.message',
            defaultMessage:
              'mailto:team@evoach.com?subject=Meine Daten löschen&body=Ich möchte hiermit meinen Account bei evoach schließen und alle meine Daten bei evoach löschen.',
          })}
          sx={{ width: 'fit-content' }}
        >
          <EvoachButton>
            {intl.formatMessage({
              id: 'player.profile.deleteDataRequest.button',
              defaultMessage: 'Meine Daten löschen',
            })}
          </EvoachButton>
        </Link>
      </div>
    </div>
  );
};
