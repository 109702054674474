import { EvoachButton, EvoachLinkify } from '@evoach/ui-components';
import { Box, Modal } from '@mui/material';
import { useIntl } from 'react-intl';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '4px',
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  outline: 'none',
  typography: 'body1',
  whiteSpace: 'pre-wrap',
};

export interface ProgramKeyVisualModalProps {
  isOpen: boolean;
  text: string | undefined;
  handleClose: () => void;
}

const ProgramKeyVisualModal = ({
  isOpen,
  text,
  handleClose,
}: ProgramKeyVisualModalProps) => {
  const intl = useIntl();
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Box>
          <EvoachLinkify>{text ?? ''}</EvoachLinkify>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <EvoachButton onClick={handleClose}>
            {intl.formatMessage({
              id: 'player.programs.keyvisual.dialog.closebutton',
              defaultMessage: 'Schließen',
            })}
          </EvoachButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProgramKeyVisualModal;
