import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ForwardedRef, forwardRef, useContext } from 'react';
import { useInView } from 'react-intersection-observer';

import { AccountContext } from '../account/AccountContext';
import { WindowContext } from '../window/WindowContext';

const useStyles = makeStyles({
  outer: {
    minWidth: '500px',
    maxWidth: '700px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    scrollBehavior: 'smooth',
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '30vh',
    height: '95%', // 95vh ==> 95% ; with 95vH, the div is not prpoperly printed with useReactToPrint in SessionPlayer.tsx
    width: '500px',
    minWidth: '500px',
    maxWidth: '700px',
    scrollBehavior: 'smooth',
    padding: '0 1rem',
    overflow: 'scroll', // overflow=scroll is mandatory for useReactToPrint in SessionPlayer.tsx
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': { width: 0 },
    pageBreakBefore: 'always',
  },
  outerMobile: {
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    scrollBehavior: 'smooth',
  },
  innerMobile: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '30vh',
    height: '95%',
    scrollBehavior: 'smooth',
    padding: '0 1rem',
    overflow: 'auto',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': { width: 0 },
  },
});

/* orig before 20.12.

outer: {
    height: '500px',
    minWidth: '500px',
    maxWidth: '700px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column-reverse',
    scrollBehavior: 'smooth',
  },
  */
//  borderStyle: 'solid',
// borderWidth: '2px',
/*  
    NOTE:

    die aktuelle CSS only lösung für scrollen funktioniert (auch beim Tippen für 
    SnippetInput & Co), hat aber 2 nachteile:

        - es ist kein "smooth" scrolling
    
        - man bleibt immer am "boden" des divs, egal wie viele neue elemente dazu gekommen sind.
        beispiel lange multiple output list:  --> wenn es viele  elemente in der liste sind, sieht
        man die darüberstehende coach-message nicht.

    
    Wenn man diese Beiden Nachteile beheben möchte, muss man die folgende 
    scroll logik (irgendwie mit TypeScript) implementieren:

    IF:
        die summe der höhe aller neu hinzugefügten widgets überschreitet die container-höhe

    THEN:
        scrolle bis zur oberkante (+ bisschen padding) des ERSTEN neu dazu gekommenen
        widgets --> da sonst der nutzer nicht alle widgets sieht (besonders das erste
        nicht, wo oft eine erklärung des nächsten schritts drin steht)

    ELSE:
        (ALLE neuen widgets passen in den container)
        wir können "einfach" den container ganz nach unten scrollen 
*/
// (props, printableAreaRef: ForwardedRef<HTMLDivElement>

// s. here: https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/forward_and_create_ref/
type DefaultProps = {
  children: React.ReactNode;
  isPublicRoute: boolean;
};
export type PrintableRef = HTMLDivElement;

// provide id for last element in chat. Is also used by SessionPlayer!
export const endOfChatAnchor = 'endofevoachchatscrollcontainer';

// ScrollContainer requires forwardRef to get reference for printable area
// as parent Element SessionPlayer contains the print button
export const ScrollContainer = forwardRef<PrintableRef, DefaultProps>(
  (props, phaseRef: ForwardedRef<HTMLDivElement>) => {
    const classes = useStyles();
    const { isPublicRoute } = useContext(AccountContext);
    const { isMobile } = useContext(WindowContext);

    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );

    const preserveHeader =
      document.location.search?.startsWith('?h=true') || !isPublicRoute;

    const maxHeight = vh - (preserveHeader ? (isMobile ? 92 : 200) : 35) + 'px';

    // check if referenced element is visible to user
    const { ref, inView } = useInView({
      threshold: 0,
    });

    // PROD-1081 - auto-scroll to end if focus is set to an editable object
    // after scrolling in chat
    const scrollLastElementIntoView = () => {
      if (!inView) {
        document
          .getElementById(endOfChatAnchor)
          ?.scrollIntoView({ block: 'nearest' });
      }
    };

    const onFocus = () => {
      scrollLastElementIntoView();
    };

    return (
      <Box display="flex" alignItems="end">
        <div
          id="outerchatbotdiv"
          className={isMobile ? classes.outerMobile : classes.outer}
          style={
            isPublicRoute || isMobile
              ? { maxHeight: maxHeight, backgroundColor: '#FFFFFF' }
              : {
                  maxHeight: '80vh',
                }
          }
        >
          <div
            id="innerchatbotdiv"
            ref={phaseRef}
            className={isMobile ? classes.innerMobile : classes.inner}
            onFocus={onFocus}
          >
            {props.children}
            <div ref={ref}></div>
          </div>
        </div>
      </Box>
    );
  }
);
