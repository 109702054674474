import { MachineContext } from '@evoach/ui-components';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import React, { useContext } from 'react';
import { State, AnyEventObject, TypegenDisabled } from 'xstate';

import { AccountContext } from '../account';
import { TextSpeed } from '../account/AccountContextProps';

import { Collapsible } from './Collapsible';

interface MachineDebugProps {
  state: State<
    MachineContext,
    AnyEventObject,
    any,
    any,
    TypegenDisabled & {
      missingImplementations: {
        actions: never;
        delays: never;
        guards: never;
        services: never;
      };
    } & any
  >;
}

interface JSONDisplayProps {
  jsonString: string;
}

const JSONDisplay: React.FC<JSONDisplayProps> = ({ jsonString }) => {
  return (
    <pre
      style={{
        backgroundColor: '#eeeeee',
        whiteSpace: 'break-spaces',
      }}
    >
      <code>{jsonString}</code>
    </pre>
  );
};

export const MachineDebug: React.FC<MachineDebugProps> = ({ state }) => {
  const {
    preferences: { textSpeed, setTextSpeed },
  } = useContext(AccountContext);

  return (
    <div
      style={{
        border: '3px dotted red',
        padding: '0.5rem',
        background: 'rgba(255,0,0,0.2)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'start',
        gap: '0.5rem',
        marginBottom: '1rem',
      }}
    >
      <strong>Current State:</strong>
      <pre></pre>
      <Collapsible title="User Data">
        <JSONDisplay
          jsonString={JSON.stringify(state.context.userData, null, 4)}
        />
      </Collapsible>
      <Collapsible title="Widget Data">
        <JSONDisplay
          jsonString={JSON.stringify(state.context.widgetData, null, 4)}
        />
      </Collapsible>
      <Collapsible title="Meta Data">
        <JSONDisplay
          jsonString={JSON.stringify(state.context.metaData, null, 4)}
        />
      </Collapsible>
      <FormControl component="fieldset">
        <FormLabel component="legend">Text Speed: {textSpeed}</FormLabel>
        <RadioGroup
          row
          name="row-radio-buttons-group"
          value={textSpeed}
          onChange={(e) => {
            setTextSpeed(e.target.value as TextSpeed);
          }}
        >
          <FormControlLabel
            value={TextSpeed.SLOW}
            control={<Radio />}
            label={TextSpeed.SLOW}
          />
          <FormControlLabel
            value={TextSpeed.MEDIUM}
            control={<Radio />}
            label={TextSpeed.MEDIUM}
          />
          <FormControlLabel
            value={TextSpeed.FAST}
            control={<Radio />}
            label={TextSpeed.FAST}
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};
