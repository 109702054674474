import { getStringValuesParam, MachineContext } from '@evoach/ui-components';
import { IntlShape } from 'react-intl';
import { cloneDeep } from 'lodash';
import { assign } from 'xstate';
import { Parser } from 'expr-eval';

import { authorizedPost } from '../../api/authorizedApi';
import { CoacheeDiaryEntryType } from '../../entities/CoacheeDiaryTypes';

/**
 * setStringArray action to set a variable with an array of translated string
 *
 * @returns array of translated texts is stored in new context
 */
export const setStringArray = (
  intl: IntlShape,
  isPublicRoute: boolean,
  sessionId: string,
  moduletitle: string,
  moduleid: string,
  coacheeaccountid: string,
  coachaccountid: string
) => {
  return assign((context: MachineContext, _event, actionMetadata) => {
    const newContext = cloneDeep(context);
    newContext.widgetData = newContext.widgetData.filter(
      (element) => !element.temporary
    );

    const payload = actionMetadata.action.payload;
    const saveResultTo = payload.saveResultTo ?? '';

    const stringArray = payload.labels
      ? payload.labels.map((label: string) =>
          intl.formatMessage({
            id: label,
            defaultMessage: label,
          })
        )
      : [''];

    newContext.userData[saveResultTo] = stringArray;

    // for PROD-2074
    const globalVariables = getGlobalVariables(context);

    //
    // do not write variables for public routes
    // or if no globalVariables are provided
    //
    if (!isPublicRoute && globalVariablesExist(globalVariables)) {
      const gVarIndex = globalVariables.indexOf(saveResultTo);
      // variable in payload is global variable => write back to diary
      if (gVarIndex > -1) {
        writeGlobalVariableContent(
          saveResultTo,
          {
            ...context.sessionData?.providedglobalvariables[gVarIndex],
            value: stringArray,
          },
          sessionId,
          moduletitle,
          moduleid,
          coacheeaccountid,
          coachaccountid
        );
      }
    }
    return newContext;
  });
};

/**
 * writeToContextAction
 *
 * Central function for ui-components that writes a Record<string, any> to the
 * userData context. This function is called any time, a component stores a
 * variable in the context of the session. Most of the parameters in this
 * function are solely used for the API call to store the global variable in
 * the coachee diary.
 *
 * event.payload contains the actual data and is triggered by a function call
 * in the ui-component like this one:
 *
 *  send({
 *     type: 'writeToContext',
 *     payload: { [saveResultTo]: message },
 *   });
 *
 * @returns new context of the state machine.
 */
export const writeToContextAction = (
  isPublicRoute: boolean,
  sessionId: string,
  moduletitle: string,
  moduleid: string,
  coacheeaccountid: string,
  coachaccountid: string
) => {
  return assign((context: MachineContext, event: any, _actionMetadata) => {
    const newContext = cloneDeep(context);
    //
    // Loop ? then handle the loop vars
    //
    const loopName = context.userData['loopName'] ?? '';
    if (loopName !== '') {
      // the event.payload contains the variable and its new value. It is send like this
      //    send ({ [saveResultTo]: newValue })
      // we are in a loop! save this variable also in an array, dedicated to this loop
      Object.keys(event.payload).forEach((payloadKey: string) => {
        if (newContext.userData[loopName + '_' + payloadKey] === undefined) {
          newContext.userData[loopName + '_' + payloadKey] = [];
        }
        newContext.userData[loopName + '_' + payloadKey].push(
          event.payload[payloadKey]
        );
      });
    }
    newContext.userData = { ...newContext.userData, ...event.payload };

    // PROD-1857 - if the variable is a global variable, write it back as entry
    const globalVariables = getGlobalVariables(context);

    //
    // do not write variables for public routes
    // or if no globalVariables are provided
    //
    if (!isPublicRoute && globalVariablesExist(globalVariables)) {
      const payloadkeys = Object.keys(event.payload);
      if (payloadkeys.length > 0) {
        // assume that is only one variable sent as payload
        const varname = payloadkeys[0];
        const varvalue = event.payload[varname];
        const gVarIndex = globalVariables.indexOf(varname);
        // variable in payload is global variable => write back to diary
        if (gVarIndex > -1) {
          writeGlobalVariableContent(
            varname,
            {
              ...context.sessionData?.providedglobalvariables[gVarIndex],
              value: varvalue,
            },
            sessionId,
            moduletitle,
            moduleid,
            coacheeaccountid,
            coachaccountid
          );
        }
      }
    }

    return newContext;
  });
};

/**
 * writeGlobalVariableContent
 *
 * It writes the varvalue to the coachee diary and stores a global variable
 *
 * @param varname
 * @param varvalue
 * @param sessionid
 * @param moduletitle
 * @param moduleid
 * @param coacheeaccountid
 * @param coachaccountid
 */
const writeGlobalVariableContent = (
  varname: string,
  varvalue: any,
  sessionid: string,
  moduletitle: string,
  moduleid: string,
  coacheeaccountid: string,
  coachaccountid: string
) => {
  // ! important
  // We do not check whether there are several variables
  // with the same name but different node types!
  const newDiaryEntry = {
    coachaccountid: coachaccountid,
    coacheeaccountid: coacheeaccountid,
    moduletitle: moduletitle,
    moduleid: moduleid,
    entrytype: CoacheeDiaryEntryType.GLOBAL_VARIABLE,
    sessionid: sessionid,
    variablename: varname,
    variablevalue: varvalue,
    nodetype: varvalue.nodeType,
  };

  authorizedPost('/coacheediary', newDiaryEntry)()
    .then()
    .catch((reason: any) => {
      console.error(`Saving the global variable ${varname} failed`);
      console.error(reason);
    });
};

/**
 * render coach message for RandomCoachMessage node
 *
 * @param {IntlShape} intl for translations / L10N
 * @returns
 */
export const renderRandomCoachMessageAction = (intl: IntlShape) => {
  return assign((context: MachineContext, _event, actionMetadata) => {
    const newContext = cloneDeep(context);
    newContext.widgetData = newContext.widgetData.filter(
      (element) => !element.temporary
    );

    const payload = actionMetadata.action.payload;
    const saveResultTo = payload.saveResultTo ?? '';

    // PROD-1401 , add support for variables in Random Messages
    const varsForString: Record<string, any> | undefined = getStringValuesParam(
      payload,
      context
    );

    if (payload.messages !== undefined && payload.messages !== null) {
      const randomSelection = Math.floor(
        Math.random() * payload.messages.length
      );
      newContext.userData[saveResultTo + '.randomSelection'] =
        payload.keyTexts[randomSelection];

      const message = Array.isArray(payload.messages)
        ? intl.formatMessage(
            {
              id: payload.messages[randomSelection],
              defaultMessage: payload.messages[randomSelection],
            },
            varsForString
          )
        : [];
      newContext.userData[saveResultTo] = message;
    }

    return newContext;
  });
};

/**
 * xState guard for random node transitions.
 *
 * For custom xState guards, see here: https://xstate.js.org/docs/guides/guards.html#custom-guards
 * @param context
 * @param _event
 * @param cond
 * @returns
 */
export const randomNodeTransitionGuard = (
  context: MachineContext,
  _event: any,
  cond: any
): boolean => {
  // for multi output:  evaluate first part of OR clause
  // for single output: evaluate whether source hanlde is randommessageouthandle which only exists for single
  return (
    context.userData[cond.cond.saveResultTo + '.randomSelection'] ===
      cond.cond.sourceHandle ||
    cond.cond.sourceHandle === 'randommessageouthandle'
  );
};

/**
 * guard definition for automatic random node transition as used in
 * SessionPlayer for initializing guards.
 */
export const randomNodeGuards = {
  randomNodeTransition: randomNodeTransitionGuard,
};

/**
 * Set combineToString action combines single values to a string and store in new variable
 * It specificially takes the message and replaces the placeholders with the values
 * contents of the corresponding variables from the payload.getStringValues array
 *
 * Function implemented by request of Sascha Geßler and Heiko ...
 */
export const setCombineToString = (
  intl: IntlShape,
  isPublicRoute: boolean,
  sessionId: string,
  moduletitle: string,
  moduleid: string,
  coacheeaccountid: string,
  coachaccountid: string
) => {
  return assign((context: MachineContext, _event: any, actionMetadata) => {
    const newContext = cloneDeep(context);

    const payload = actionMetadata.action.payload;
    const saveResultTo = payload.saveResultTo ?? '';
    const getStringValues = payload.getStringValues ?? [];
    const messageId = payload.message ?? '';

    const varsForString: Record<string, any> = {};
    getStringValues.forEach((varname: string) => {
      const varValue = context.userData[varname];
      if (typeof varValue === 'string' || typeof varValue === 'number') {
        varsForString[varname] = varValue;
      } else {
        if (Array.isArray(varValue)) {
          // any content in array?
          if (varValue.length > 0) {
            // array of strings or numbers? then join by ,
            if (
              typeof varValue[0] === 'string' ||
              typeof varValue[0] === 'number'
            ) {
              varsForString[varname] = varValue.join(', ');
            } else {
              // array of objects? take values and join by ,
              varsForString[varname] = varValue
                .map((arrayelem: any) => arrayelem?.value ?? '')
                .join(', ');
            }
          }
        } else {
          // assuming an object
          varsForString[varname] = varValue?.value ?? '';
        }
      }
    });

    const combinedText = intl.formatMessage(
      {
        id: messageId,
      },
      varsForString
    );

    // save in new variable
    newContext.userData[saveResultTo] = combinedText ?? '';

    // consider global variables
    const globalVariables = getGlobalVariables(context);

    //
    // do not write variables for public routes
    // or if no globalVariables are provided
    //
    if (!isPublicRoute && globalVariablesExist(globalVariables)) {
      const gVarIndex = globalVariables.indexOf(saveResultTo);
      // variable in payload is global variable => write back to diary
      if (gVarIndex > -1) {
        writeGlobalVariableContent(
          saveResultTo,
          {
            ...context.sessionData?.providedglobalvariables[gVarIndex],
            value: combinedText,
          },
          sessionId,
          moduletitle,
          moduleid,
          coacheeaccountid,
          coachaccountid
        );
      }
    }

    return newContext;
  });
};
/**
 * setCombineToArray action combines single values to an array and store in new variable
 *
 * This action works for arrayCombineNumberNodeStateEntry and arrayCombineStringNodeStateEntry
 * in creator! But:
 * - as input, it accepts single numbers and combines them to a number array
 * - for all other datatypes, it combines to a string array
 *      - single strings => string array
 *      - string arrays => string array
 *      - typedObject arrays => string array of .value prop
 *      - other data types => ignored
 *
 * see also PROD-1375
 *
 * @returns new context
 */
export const setCombineToArray = (
  isPublicRoute: boolean,
  sessionId: string,
  moduletitle: string,
  moduleid: string,
  coacheeaccountid: string,
  coachaccountid: string
) => {
  return assign((context: MachineContext, _event: any, actionMetadata) => {
    const newContext = cloneDeep(context);

    const payload = actionMetadata.action.payload;
    const saveResultTo = payload.saveResultTo ?? '';
    const getValuesFrom = payload.getValuesFrom ?? [];

    let newList;

    if (getValuesFrom.length > 0) {
      newList = getValuesFrom
        .map((varname: string) => {
          let varContent = newContext.userData[varname];

          // combine single string or single numbers
          if (
            typeof varContent === 'string' ||
            typeof varContent === 'number'
          ) {
            return varContent;
          }

          if (Array.isArray(varContent)) {
            varContent = varContent.flat();
          }

          // all other datatypes combine to string and never to number
          if (Array.isArray(varContent) && varContent.length > 0) {
            // combine array of strings
            if (typeof varContent[0] === 'string') {
              return varContent;
            } else {
              // combine array of objects as string array of .value props
              return varContent.map((arrayelem: any) => arrayelem.value);
            }
          }
          // other types are not processed
          return undefined;
        })
        .filter((val: any) => val !== undefined)
        .flat();
    } else {
      newList = [];
    }

    newContext.userData[saveResultTo] = newList;

    // for PROD-2078
    const globalVariables = getGlobalVariables(context);

    //
    // do not write variables for public routes
    // or if no globalVariables are provided
    //
    if (!isPublicRoute && globalVariablesExist(globalVariables)) {
      const gVarIndex = globalVariables.indexOf(saveResultTo);
      // variable in payload is global variable => write back to diary
      if (gVarIndex > -1) {
        writeGlobalVariableContent(
          saveResultTo,
          {
            ...context.sessionData?.providedglobalvariables[gVarIndex],
            value: newList,
          },
          sessionId,
          moduletitle,
          moduleid,
          coacheeaccountid,
          coachaccountid
        );
      }
    }

    return newContext;
  });
};

/**
 * setFormula calculates a formula and evaluates it with variables
 */
export const setFormula = assign(
  (context: MachineContext, _event: any, actionMetadata) => {
    const newContext = cloneDeep(context);

    const payload = actionMetadata.action.payload;
    const saveResultTo = payload.saveResultTo ?? '';
    let formula = payload.formula ?? '';
    const getValuesFrom = payload.getValuesFrom ?? [];
    let calculatedValue = 0;

    let varobject: Record<string, number> = {};
    getValuesFrom.forEach((varname: string) => {
      let varvalue = newContext.userData[varname];
      if (varvalue === undefined || varvalue === null || isNaN(varvalue)) {
        varvalue = 0;
      }
      // check whether varname contains spaces; if yes, replace in varname
      // and in formular
      let origvar = varname;
      if (varname.indexOf(' ') > 0) {
        varname = varname.replace(/ /g, '_'); // .replaceAll(' ', '_');
        formula = formula.replace(new RegExp(origvar, 'g'), varname); //replaceAll(origvar, varname);
      }
      varobject = { ...varobject, [varname]: newContext.userData[origvar] * 1 };
    });

    // see https://www.npmjs.com/package/expr-eval for docs
    if (formula && formula !== '') {
      try {
        const parser = new Parser();
        const expr = parser.parse(formula);
        calculatedValue = expr.evaluate(varobject);
      } catch {
        calculatedValue = 0;
      }
    }

    newContext.userData[saveResultTo] = isNaN(calculatedValue)
      ? 0
      : calculatedValue;

    return newContext;
  }
);

/**
 * helper function to extract global variable names from context
 * @param {MachineContext} context is used to extract list from sessionData.providedglobalvariables
 * @returns {string[]} list of global variable names or empty array
 */
const getGlobalVariables = (context: MachineContext) => {
  return context.sessionData?.providedglobalvariables
    ? context.sessionData?.providedglobalvariables.map(
        (gvars: any) => gvars.name
      )
    : [];
};

/**
 * checks whether any global variable is defined
 * @param {string[]} globalVariables
 * @returns {boolean} true if globalVariables is not undefined, is an array and has at least one element
 */
const globalVariablesExist = (globalVariables: string[]): boolean =>
  globalVariables &&
  Array.isArray(globalVariables) &&
  globalVariables.length > 0;

/**
 * setDirectedAgentMode action to set a global session specific variable
 * for toggling the mode in which AI components use the state as chat
 * history
 */
export const setDirectedAgentMode = assign(
  (context: MachineContext, _event: any, actionMetadata) => {
    const newContext = cloneDeep(context);
    const payload = actionMetadata.action.payload;
    if (newContext.sessionData) {
      newContext.sessionData['evoach.directedAgentMode'] =
        !!payload.directedAgentMode;
    }

    return newContext;
  }
);
