import { Account, Tenant } from '../entities/Account';

export enum RoleEnum {
  COACH = 'Coach',
  COACHEE = 'Coachee',
  HR = 'HR',
  EVOACHADMIN = 'evoachAdmin',
  SUPPORT = 'Support', // PROD-1290
  BETATESTER = 'BetaTester', // PROD-1761
}

export enum TextSpeed {
  FAST = 'FAST',
  MEDIUM = 'MEDIUM',
  SLOW = 'SLOW',
}

export interface Preferences {
  textSpeed: TextSpeed;
  setTextSpeed: (textSpeed: TextSpeed) => void;
}

export type AccountContextProps = {
  isLoading: boolean;

  isError: boolean;
  error: Error | null;

  isAuthenticated: boolean;
  token: string | null;

  account: Account | null;

  name: string;
  email: string;

  preferences: Preferences;

  roles: RoleEnum[];
  /**
   * provide a function to check whether the current account is in the corresponding role.
   * Has signature (role: RoleEnum):boolean
   */
  hasRole: Function;

  /**
   * provide a function to check whether the current account has a specific feature or
   * what value a specific feature has.
   *
   * Has signature (feature: FeatureEnum):boolean
   */
  hasFeature: Function;

  /**
   * provide a function to return the value of a feature flag
   *
   * Has signature (feature: FeatureEnum): boolean | number | undefined
   */
  getFeature: Function;

  isPublicRoute: boolean;

  children?: React.ReactNode;
  /**
   * number of retrys that failed
   */
  failureCount: number;

  /**
   * auto refetch for window foxus changes and component re-mount is disabled.
   * If we update a profile, we have to update re-load the account information
   * to update the application
   */
  refetchAccount: Function;
  /**
   * current tenant of the user as set in localstorage
   * PROD-2010
   */
  currentTenant: Tenant | undefined;
  /**
   * (re-)set the current tenant in localstorage an Account
   * PROD-2010
   */
  setCurrentTenant: Function;
};
