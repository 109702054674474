import { EvoachButton } from '@evoach/ui-components';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog,
} from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import { Asset } from '../../entities';

import { Dropzone } from './Dropzone';

interface AssetUploadDialogProps {
  /**
   * Function returns new asset from database.
   * Handler signature should be something like (asset: Asset) => {}
   */
  onUpload?: Function;
  isOpen?: boolean;
  onClose: Function;
}

export const AssetUploadDialog: React.FC<AssetUploadDialogProps> = ({
  onUpload,
  isOpen = false,
  onClose,
}) => {
  const intl = useIntl();

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {intl.formatMessage({
          id: 'player.reception.assets.uploaddialog.title',
          defaultMessage: 'Datei hochladen',
        })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" component="span">
          <Dropzone
            onUpdate={(asset: Asset) => {
              if (onUpload) onUpload(asset);
            }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <EvoachButton onClick={() => onClose()} autoFocus>
          {intl.formatMessage({
            id: 'player.reception.assets.uploaddialog.button.close',
            defaultMessage: 'Schließen',
          })}
        </EvoachButton>
      </DialogActions>
    </Dialog>
  );
};
