import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Tabs, Tab, Typography } from '@mui/material';
import { WorkspacePremium } from '@mui/icons-material';

import { Profile } from '../components/Profile/Profile';
import { Subscriptions } from '../components/Profile/Subscriptions';
import { DataPrivacy } from '../components/Profile/DataPrivacy';
import { Certificates } from '../components/Profile/Certificates';
import { UserSettings } from '../components/Profile/UserSettings';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component="span" sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const getTabProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const ProfilePage: React.FC = () => {
  const intl = useIntl();

  const [tabvalue, setTabValue] = React.useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box component="span" sx={{ width: '100%' }}>
      <Box component="span" sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabvalue} onChange={handleChange} aria-label="Reception">
          <Tab
            label={intl.formatMessage({
              id: 'player.profilePage.tabs.myProfile',
              defaultMessage: 'Mein Profil',
            })}
            {...getTabProps(0)}
          />
          <Tab
            label={intl.formatMessage({
              id: 'player.profilePage.tabs.mySubscriptions',
              defaultMessage: 'Meine Abos',
            })}
            {...getTabProps(1)}
          />
          <Tab
            label={
              <Box display="flex" alignItems="center">
                <WorkspacePremium sx={{ marginRight: '10px' }} />
                {intl.formatMessage({
                  id: 'player.profilePage.tabs.myCertificates',
                  defaultMessage: 'Meine Zertifikate',
                })}
              </Box>
            }
            {...getTabProps(2)}
          />
          <Tab
            label={intl.formatMessage({
              id: 'player.profilePage.tabs.myDataPrivacy',
              defaultMessage: 'Meine Daten',
            })}
            {...getTabProps(3)}
          />
          <Tab
            label={intl.formatMessage({
              id: 'player.profilePage.tabs.mySettings',
              defaultMessage: 'Meine Einstellungen',
            })}
            {...getTabProps(4)}
          />
        </Tabs>
      </Box>
      <TabPanel value={tabvalue} index={0}>
        <Profile />
      </TabPanel>
      <TabPanel value={tabvalue} index={1}>
        <Subscriptions />
      </TabPanel>
      <TabPanel value={tabvalue} index={2}>
        <Certificates />
      </TabPanel>
      <TabPanel value={tabvalue} index={3}>
        <DataPrivacy />
      </TabPanel>
      <TabPanel value={tabvalue} index={4}>
        <UserSettings />
      </TabPanel>
    </Box>
  );
};
