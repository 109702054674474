import {
  formatDate,
  getTranslatedText,
  InlineTextEdit,
} from '@evoach/ui-components';
import { defineMessages } from '@formatjs/intl';
import { Box } from '@mui/material';
import {
  DataGrid,
  GridCellValue,
  GridColumns,
  GridSortCellParams,
  GridSortDirection,
  GridSortModel,
} from '@mui/x-data-grid';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { AccountContext, RoleEnum } from '../../account';
import { useFetchSessionsQuery } from '../../api';
import { useUpdateSessionCoacheeNote } from '../../api/session/useUpdateSessionCoacheeNote';
import { SessionType } from '../../entities/Session';

import { ExportSessionButton } from './customInputs/ExportSessionButton';
import { PrintCertificateButton } from './customInputs/PrintCertificateButton';
import {
  DeleteSessionButton,
  DoNotShareSessionButton,
  ResumeOrViewSessionButton,
  ShareSessionButton,
} from './ListButtons';
import { SessionMemoryButton } from './customInputs/SessionMemoryButton';

const sessionListTexts = defineMessages({
  sessionnameheader: {
    id: 'player.components.reception.sessionlistheader.sessionname',
    defaultMessage: 'Protokoll',
  },
  sessionidheader: {
    id: 'player.components.reception.sessionlistheader.sessionid',
    defaultMessage: 'Thema',
  },
  sessiondescrheader: {
    id: 'player.components.reception.sessionlistheader.descrheader',
    defaultMessage: 'Chatbot-Beschreibung',
  },
  sessionlastupdateheader: {
    id: 'player.components.reception.sessionlistheader.lastupdate',
    defaultMessage: 'Zuletzt bearbeitet',
  },
});

/*
    ! SessionList 
    
*/

export const SessionList: React.FC = () => {
  const intl = useIntl();

  // hide evoach only columns
  const { hasRole } = useContext(AccountContext);
  const columnVisibilityModel = {
    exportsession: hasRole(RoleEnum.EVOACHADMIN),
    printcertificate: hasRole(RoleEnum.EVOACHADMIN),
  };

  const [sessionid, setSessionId] = useState<string>('');

  const { sessions, refetch: refetchSessions } = useFetchSessionsQuery();

  // if session list is reloaded, re-generate mapping for rows
  // rows is also used by child components
  const rows = useMemo(() => {
    return sessions
      ? sessions
          .filter((session: any) => session.sessionid)
          .map((session: any, mindex: number) => {
            return {
              id: mindex,
              sessionname: getTranslatedText(session.metadata.title, intl),
              sessionid: session.sessionid,
              sessionnotes: session.coacheenote,
              sessioncreated: formatDate(session.tscreated, intl.locale),
              sessioncreatedRaw: new Date(session.tscreated), // for proper sorting, not displayed
              sessionlastupdated: formatDate(session.tsupdated, intl.locale),
              sessionlastupdatedRaw: new Date(session.tsupdated),
              sessionsharings: session.sessionsharings_sharingid ? 1 : 0, // for proper sorting, not displayed
              sessiontype: session.sessiontype,
              coach: session.coach, // PROD-1670,
              sessionprogress: session.progress,
            };
          })
      : [];
  }, [sessions, intl]);

  useEffect(() => {
    if (sessions === undefined) {
      setSessionId('');
    } else {
      let modincl = false;
      sessions.forEach((module: any) => {
        modincl = modincl || module.moduleid === sessionid;
      });
      modincl ? (modincl = false) : setSessionId('');
    }
  }, [sessions, setSessionId, sessionid]);

  // by defining the sort model, we can use a custom comparer
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'invitationcreated',
      sort: 'asc' as GridSortDirection,
    },
  ]);

  // edit mode for notes
  const [currentlyEditedCell, setCurrentlyEditedCell] = useState({
    rowId: -1,
    field: '',
    sessionId: '',
  });

  const { mutate } = useUpdateSessionCoacheeNote();

  const columns = useMemo<GridColumns>(() => {
    // update logic for coachee notes
    const updateSessionNotes = (sessionId: string, updatedNotes: string) => {
      mutate({
        sessionId,
        body: { coacheenote: updatedNotes },
        isPublicRoute: false,
      });
    };

    const refechtHandler = () => {
      setTimeout(refetchSessions, 500);
    };

    return [
      {
        field: 'resumesessionbutton',
        headerName: '',
        renderCell: (cellValues: any) => {
          return (
            <ResumeOrViewSessionButton
              sessionProgress={cellValues.row.sessionprogress}
              sessionId={cellValues.row.sessionid}
            />
          );
        },
        disableClickEventBubbling: true,
        width: 50,
      },
      {
        field: 'sharesessionbutton',
        headerName: '',
        renderCell: (cellValues: any) =>
          cellValues.row.sessionsharings === 0 ? (
            <ShareSessionButton
              refetchHandler={refechtHandler}
              sessionId={cellValues.row.sessionid}
              disabled={
                cellValues.row.sessiontype ===
                SessionType.DEFAULT_WITHOUT_INVITATION
              }
            />
          ) : (
            <DoNotShareSessionButton
              refetchHandler={refechtHandler}
              sessionId={cellValues.row.sessionid}
            />
          ),
        disableClickEventBubbling: true,
        width: 50,
      },
      {
        field: 'sessionname',
        headerName: intl.formatMessage(sessionListTexts.sessionidheader),
        editable: false,
        width: 300,
        sortable: true,
      },
      {
        field: 'sessionlastupdated',
        sortComparator: (
          _v1: GridCellValue,
          _v2: GridCellValue,
          param1: GridSortCellParams,
          param2: GridSortCellParams
        ) => {
          // this is necessary to have a proper sorting of dates
          // without this comparator, dates would be sorted
          // as formatted string, i.e., alphabetically.
          // see also https://mui.com/components/data-grid/sorting/#custom-comparator
          return (
            (param1.api.getCellValue(
              param1.id,
              'sessionlastupdatedRaw' // use a UNIX time stamp
            ) as number) -
            (param2.api.getCellValue(
              param2.id,
              'sessionlastupdatedRaw'
            ) as number)
          );
        },
        headerName: intl.formatMessage(
          sessionListTexts.sessionlastupdateheader
        ),
        editable: false,
        width: 200,
        sortable: true,
      },
      {
        field: 'progress',
        headerName: intl.formatMessage({
          id: 'player.components.reception.sessionlistheader.sessionprogress',
          defaultMessage: 'Fortschritt',
        }),
        renderCell: (cellValues: any) => (
          <>{cellValues.row.sessionprogress ?? 0}%</>
        ),
        disableClickEventBubbling: true,
        width: 100,
      },
      {
        field: 'notes',
        headerName: intl.formatMessage({
          id: 'player.components.reception.sessionlistheader.sessionnotes',
          defaultMessage: 'Eigene Notizen',
        }),
        // editable: true,
        disableClickEventBubbling: true,
        width: 400,
        renderCell: (cellValues: any) => {
          const isCellInEditMode =
            currentlyEditedCell.rowId !== undefined &&
            currentlyEditedCell.rowId === cellValues.id &&
            currentlyEditedCell.field === 'notes';
          const isCellDisabled = currentlyEditedCell.rowId !== -1;
          return (
            <Box width="100%" paddingTop="5px" paddingBottom="5px">
              <InlineTextEdit
                text={cellValues.row.sessionnotes ?? ''}
                editMode={isCellInEditMode}
                disabled={isCellDisabled}
                onStartEdit={() => {
                  setCurrentlyEditedCell({
                    rowId: cellValues.id,
                    field: 'notes',
                    sessionId: cellValues.row.sessionid,
                  });
                }}
                onCancel={() => {
                  setCurrentlyEditedCell({
                    rowId: -1,
                    field: '',
                    sessionId: '',
                  });
                }}
                onSave={(updatedText: string) => {
                  updateSessionNotes(cellValues.row.sessionid, updatedText);
                  setCurrentlyEditedCell({
                    rowId: -1,
                    field: '',
                    sessionId: '',
                  });
                }}
                tooltipTexts={{
                  edit: intl.formatMessage({
                    id: 'player.components.reception.sessionlist.coacheenote.edit',
                    defaultMessage: 'Bearbeiten',
                  }),
                  save: intl.formatMessage({
                    id: 'player.components.reception.sessionlist.coacheenote.save',
                    defaultMessage: 'Speichern',
                  }),
                  cancel: intl.formatMessage({
                    id: 'player.components.reception.sessionlist.coacheenote.cancel',
                    defaultMessage: 'Verwerfen',
                  }),
                }}
              />
            </Box>
          );
        },
      },
      {
        field: 'coach',
        headerName: intl.formatMessage({
          id: 'player.components.reception.sessionlistheader.sessioncoach',
          defaultMessage: 'Coach',
        }),
        renderCell: (cellValues: any) => (
          <>
            {cellValues.row.coach.givenname
              ? `${cellValues.row.coach.givenname} ${cellValues.row.coach.familyname}`
              : cellValues.row.sessiontype === 2
              ? 'Öffentlicher Chatbot'
              : intl.formatMessage({
                  id: 'player.components.reception.sessionlistheader.unknowncoach',
                  defaultMessage: 'Unbekannt',
                })}
          </>
        ),
        disableClickEventBubbling: true,
        width: 200,
      },
      {
        field: 'deletesessionbutton',
        headerName: '',
        renderCell: (cellValues: any) => (
          <DeleteSessionButton
            sessionId={cellValues.row.sessionid}
            refetchHandler={refechtHandler}
          />
        ),
        disableClickEventBubbling: true,
        width: 50,
      },
      {
        field: 'printcertificate',
        headerName: '',
        renderCell: (cellValues: any) => (
          <PrintCertificateButton sessionid={cellValues.row.sessionid} />
        ),
        disableClickEventBubbling: true,
        width: 120,
      },
      {
        field: 'exportsession',
        headerName: '',
        renderCell: (cellValues: any) => (
          <ExportSessionButton sessionid={cellValues.row.sessionid} />
        ),
        disableClickEventBubbling: true,
        width: 100,
      },
      {
        field: 'exportsessionasopenai',
        headerName: '',
        renderCell: (cellValues: any) => (
          <SessionMemoryButton sessionid={cellValues.row.sessionid} />
        ),
        disableClickEventBubbling: true,
        width: 100,
      },
    ];
  }, [intl, refetchSessions, currentlyEditedCell, mutate]);

  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );
  const maxHeight = vh - 300 + 'px';

  return (
    <div
      style={{
        marginTop: 20,
        width: '100%',
        height: maxHeight,
        maxHeight: maxHeight,
      }}
    >
      <DataGrid
        pagination
        rows={rows}
        getRowHeight={() => 'auto'}
        columns={columns}
        rowsPerPageOptions={[5, 100]}
        checkboxSelection={false}
        disableSelectionOnClick
        onRowClick={(params: any) => {
          setSessionId(params.row.sessionid);
        }}
        columnVisibilityModel={columnVisibilityModel}
        onCellKeyDown={(_, event) => {
          // default behaviour (key short cuts) conflicts with edit mode
          event.defaultMuiPrevented = true;
        }}
        style={{ minHeight: 400 }}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        sortingOrder={['desc', 'asc']} // enforce only these two states for sorting
      />
    </div>
  );
};
