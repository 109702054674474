import React from 'react';
import { evoachDefaultTheme } from '@evoach/ui-components';

import { ChatMessage } from '../../../entities/ExportTypes';

interface ChatPrintProps {
  messages: ChatMessage[];
}

export const ChatPrint: React.FC<ChatPrintProps> = ({ messages }) => {
  if (messages === undefined || messages === null)
    return <div>No messages</div>;
  return (
    <table style={{ borderCollapse: 'collapse', verticalAlign: 'top' }}>
      {messages.map((message, index) => {
        return (
          <tr key={index} style={{ borderTop: '2px solid black' }}>
            <td style={{ verticalAlign: 'top' }}>
              <span
                style={{
                  padding: '2px',
                  backgroundColor: evoachDefaultTheme.palette.primary.main,
                }}
              >
                {message.role}
              </span>
            </td>
            <td style={{ verticalAlign: 'top' }}>
              <pre
                style={{
                  marginLeft: '10px',
                  maxWidth: '700px',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                }}
              >
                {message.content}
              </pre>
            </td>
          </tr>
        );
      })}
    </table>
  );
};
