/**
 * helper function for creating tab props
 */
export const getTabProps = (index: number, area?: string) => {
  return {
    id: `simple-${area}-sub-tab-${index}`,
    'aria-controls': `simple-sub-tabpanel-${index}`,
  };
};

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
