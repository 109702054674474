import { ProductTourStepContent, ImageDisplay } from '@evoach/ui-components';
import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { AccountContext } from '../../account';

/**
 *
 * Tours for the reception page - especially for tenant ICF
 * Copy this file and rename the hook to use the hook in other pages.
 *
 * Usage:
 * In your component write
 *    const { tourid, toursteps } = useReceptionTourPage('<variant>');
 * to include a tour or its variant. For each variant, write an if-branch.
 * Then add
 *    <ProductTourManager tourid={tourid} toursteps={toursteps} />
 * as first component of your returned JSX in your component.
 *
 */
export const ReceptionICFTour = (receptionTourId: string) => {
  const intl = useIntl();
  const { name } = useContext(AccountContext);

  const largePopoverWidth = '580px';
  const narrowPopoverWidth = '390px';

  const tourId = receptionTourId;

  return {
    tourid: tourId,
    toursteps: [
      {
        selector: '', // no selector = center on screen
        content: (
          <ProductTourStepContent
            displayWide={true}
            title={intl.formatMessage({
              id: 'player.pages.reception.icf.tour.welcome.1.title',
              defaultMessage:
                'Willkommen auf der Coach-IT Learning & Coaching Platform!',
            })}
            descriptionIllustrationSrc="/assets/ReceptionTour/icf/blue_hero.png"
            texts={[
              intl.formatMessage(
                {
                  id: 'player.pages.reception.icf.tour.welcome.1.description.1',
                  defaultMessage: 'Schön, dass du dabei bist{name}',
                },
                { name: name ? ', ' + name : '' }
              ),
              intl.formatMessage({
                id: 'player.pages.reception.icf.tour.welcome.1.description.2',
                defaultMessage:
                  'Lass uns herausfinden, wie du dich hier einfach zurechtfindest.',
              }),
            ]}
          />
        ),
        style: {
          maxWidth: largePopoverWidth, // override reactour default setting of max-width: "331px"
          width: largePopoverWidth, // standard width for large popover to keep it from growing
        },
      },
      {
        selector: '[data-tut="reactour__programtab"]',
        content: (
          <>
            <ProductTourStepContent
              title={intl.formatMessage({
                id: 'player.pages.reception.icf.tour.welcome.4.title',
                defaultMessage:
                  'So navigierst du durch die Coach IT Coaching Programme',
              })}
              texts={[
                intl.formatMessage({
                  id: 'player.pages.reception.icf.tour.welcome.4.description.1',
                  defaultMessage:
                    'Hier findest du alle verfügbaren Inhalte. Du kannst den gesamten Lehrplan durcharbeiten oder die Module auswählen, die dich am meisten interessieren.',
                }),
              ]}
            />
            <ImageDisplay
              assetid="receptionModuleOverview"
              resolvedsrc="/assets/ReceptionTour/icf/coachitprg.png"
              src="/assets/ReceptionTour/icf/coachitprg.png"
              width={380}
            />
          </>
        ),
        style: {
          maxWidth: narrowPopoverWidth,
        },
      },
      {
        selector: '[data-tut="reactour__avatar"]',
        content: (
          <ProductTourStepContent
            displayWide={true}
            title={intl.formatMessage({
              id: 'player.pages.reception.icf.tour.welcome.5.title',
              defaultMessage: 'Sprache ändern & FAQs',
            })}
            descriptionIllustrationSrc="/assets/ReceptionTour/icf/parac.png"
            texts={[
              intl.formatMessage({
                id: 'player.pages.reception.icf.tour.welcome.5.description.1',
                defaultMessage:
                  'Wir bieten das Programm in fünf Sprachen an: Englisch, Französisch, Deutsch, Rumänisch und Spanisch. Solltest du das Programm in einer anderen Sprache absolvieren wollen, rufe bitte den entsprechenden Link auf http://coachit.online auf.',
              }),
              intl.formatMessage({
                id: 'player.pages.reception.icf.tour.welcome.5.description.2',
                defaultMessage: 'FAQ sind auf Deutsch und Englisch verfügbar.',
              }),
            ]}
          />
        ),
        style: {
          maxWidth: largePopoverWidth,
          width: largePopoverWidth,
        },
      },
      {
        selector: '', // no selector = center on screen
        content: (
          <ProductTourStepContent
            displayWide={true}
            title={intl.formatMessage({
              id: 'player.pages.reception.icf.tour.welcome.cc.title',
              defaultMessage: 'Creative Commons Licence',
            })}
            descriptionIllustrationSrc="/assets/ReceptionTour/icf/icfcclic.png"
            texts={[
              intl.formatMessage({
                id: 'player.pages.reception.icf.tour.welcome.cc.description.1',
                defaultMessage:
                  'Creative Commons Lizenz: Die Inhalte der Plattform werden kostenlos unter Attribution-NonCommercial-NoDerivatives 4.0 International Creative Commons Licence zur Verfügung gestellt.',
              }),
            ]}
          />
        ),
        style: {
          maxWidth: largePopoverWidth, // override reactour default setting of max-width: "331px"
          width: largePopoverWidth, // standard width for large popover to keep it from growing
        },
      },
      {
        selector: '[data-tut="reactour__sessiontab"]',
        content: (
          <>
            <ProductTourStepContent
              title={intl.formatMessage({
                id: 'player.pages.reception.icf.tour.welcome.3.title',
                defaultMessage: 'Verfügbarkeit und Chatbot Historie',
              })}
              texts={[
                intl.formatMessage({
                  id: 'player.pages.reception.icf.tour.welcome.3.description',
                  defaultMessage:
                    'Alle Inhalte werden mindestens zwei Jahre lang verfügbar sein. Während dieser Zeit kannst du hier auf deine Chatbot-Sessions zugreifen.',
                }),
              ]}
            />
            <ImageDisplay
              assetid="receptionModuleOverview"
              resolvedsrc="/assets/ReceptionTour/sessionoverview_en.png"
              src="/assets/ReceptionTour/sessionoverview_en.png"
              width={380}
            />
          </>
        ),
        style: {
          maxWidth: narrowPopoverWidth,
        },
      },
      {
        selector: '', // no selector = center on screen
        content: (
          <ProductTourStepContent
            displayWide={true}
            title={intl.formatMessage({
              id: 'player.pages.reception.icf.tour.welcome.cceu.title',
              defaultMessage: 'ICF CCEUs/EMCC CPDs',
            })}
            descriptionIllustrationSrc="/assets/ReceptionTour/icf/icfcert.png"
            texts={[
              intl.formatMessage({
                id: 'player.pages.reception.icf.tour.welcome.cceu.description.1',
                defaultMessage:
                  'Um deine CCEU-Zertifikate zu erhalten, fülle bitte den Postreflexions-Chatbot am Ende eines jeden Lernmoduls aus. Du kannst dann dein Zertifikat jeweils herunterladen oder ausdrucken.',
              }),
            ]}
          />
        ),
        style: {
          maxWidth: largePopoverWidth, // override reactour default setting of max-width: "331px"
          width: largePopoverWidth, // standard width for large popover to keep it from growing
        },
      },
      {
        selector: '', // no selector = center on screen
        content: (
          <ProductTourStepContent
            displayWide={true}
            title={intl.formatMessage({
              id: 'player.pages.reception.icf.tour.welcome.comm.title',
              defaultMessage: 'Werde Teil der Coach-IT Community',
            })}
            descriptionIllustrationSrc="/assets/ReceptionTour/icf/icfcomm.png"
            texts={[
              intl.formatMessage({
                id: 'player.pages.reception.icf.tour.welcome.comm.description.1',
                defaultMessage:
                  'Die Lernplattform ist ausschließlich digital und asynchron gestaltet. Um Austausch und Mastermind-Gruppen zu ermöglichen, trete bitte unserem entsprechenden Slack-Channel bei.',
              }),
            ]}
          />
        ),
        style: {
          maxWidth: largePopoverWidth, // override reactour default setting of max-width: "331px"
          width: largePopoverWidth, // standard width for large popover to keep it from growing
        },
      },
      {
        selector: '',
        content: (
          <ProductTourStepContent
            displayWide={true}
            title={intl.formatMessage({
              id: 'player.pages.reception.icf.tour.welcome.6.title',
              defaultMessage: 'Du bist startklar!',
            })}
            descriptionIllustrationSrc="/assets/ReceptionTour/icf/icfready.png"
            texts={[
              intl.formatMessage({
                id: 'player.pages.reception.icf.tour.welcome.6.description.1',
                defaultMessage:
                  'Wie versprochen haben wir es kurz und einfach gehalten. Viel Spaß beim Lernen und Nachdenken!',
              }),
            ]}
          />
        ),
        style: {
          maxWidth: largePopoverWidth,
          width: largePopoverWidth,
        },
      },
    ],
  };
};
