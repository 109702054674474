import { MachineContext } from "@evoach/ui-components";
import { cloneDeep } from "lodash";
import { assign } from 'xstate';

/** startLoop
 * start a loop or iterate to next element
 */
export const startLoopAction = assign((context: MachineContext, _event, actionMetadata) => {
    const newContext = cloneDeep(context);

    const payload= actionMetadata.action.payload
    const loopName = payload.saveResultTo+'';
    let currentLoopIndex = context.userData[loopName+'.loopIndex'];

    // setting a loopname is interpreted as "we are in a loop"
    // other components check for that variable to detect whether they are 
    // in a loop. If value is set to '' in endLoopAction, this indicates
    // that the loop ends
    newContext.userData['loopName'] = loopName+'';

    // check loop index and set to 0 or increase
    if(currentLoopIndex===undefined || typeof context.userData[loopName+'.loopIndex'] !== 'number') {
        currentLoopIndex = 0;
    } else {
        currentLoopIndex = currentLoopIndex +1
    }

    newContext.userData[loopName+'.loopIndex'] = currentLoopIndex;

    // get new value
    const arrayValues = context.userData[payload.getValueFrom];
    if(arrayValues!==undefined && Array.isArray(arrayValues) && currentLoopIndex<arrayValues.length) {
        newContext.userData[loopName+'.arrayValues'] = arrayValues;
        newContext.userData[loopName+'.loopValue'] = arrayValues[currentLoopIndex];
        newContext.userData[loopName+'.loopMaxIndex'] = arrayValues.length-1;
    }
    
    return newContext;
});

/** endLoop - end a loop or jump to start of loop
 * The actual state transition is handled by xState state machine "guards"
 * defined in SessionPlayer.tsx when initializing the state machine. 
 * 
 * Setting a loopname is interpreted as "we are in a loop"
 * other components check for that variable to detect whether they are 
 * in a loop. If value is set to '' in this functions, this indicates
 * that the loop has ended
*/
 export const endLoopAction = assign((context: MachineContext, _event, _actionMetadata) => {
    const newContext = cloneDeep(context); 
    const loopName = context.userData['loopName'];
    // set loop name to empty if we're at the end of the loop, indicating for other
    // components that loop exited
    if(context.userData[loopName + '.loopIndex'] === context.userData[loopName + '.loopMaxIndex']) {
        newContext.userData['loopName'] = '';
    }
    return newContext;
});

/** loopEnd guard for xState state machine
 * @returns true if not loopNext, i.e., if there is no next value, then loop exists
 */
export const loopEnd = (context: MachineContext, _event: any): boolean => {
    return !loopNext(context, _event);
};

/** loopNext guard for xState state machine
 * @returns true if loopIndex < loopMaxIndex
 */
export const loopNext = (context: MachineContext, _event: any): boolean => {
    const loopName = context.userData['loopName'];
    // check the current loop is correct
    if(loopName===undefined) return false;
    return (context.userData[loopName + '.loopIndex'] !== context.userData[loopName + '.loopMaxIndex'])
};

/** loop guards for xState State machine as used in SessionPlayer.tsx */
export const loopGuards = {
    loopNextGuard: loopNext,
    loopEndGuard: loopEnd,
  };

  