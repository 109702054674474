import React, { useEffect, useMemo, useState } from 'react';

import { getLocaleMessages } from './getLocaleMessages';
import { isSupportedLocale, SupportedLocales } from './SupportedLocales';

export type Messages = Record<string, string>;

interface TranslationContextProps {
  /**
   * set the locale of the ui without affecting the users default setting.
   * @param {SupportedLocales} newLocale
   */
  setLocale: (newLocale: SupportedLocales) => void;
  locale: SupportedLocales;
  /**
   * update locale is provided to context. It also sets the value in
   * local storage of the browser and for the local state as a user setting!
   * @param {SupportedLocales} newLocale
   */
  updateLocale: (newLocale: SupportedLocales) => void;

  messages: Messages;
  appendModuleMessages: (messages: Messages) => void;
  getBrowserLanguage: () => void;
}

export const TranslationContext = React.createContext<TranslationContextProps>({
  setLocale: () => null,
  locale: SupportedLocales.DE,
  updateLocale: () => null,

  messages: {},
  appendModuleMessages: () => null,
  getBrowserLanguage: () => null,
});

/**
 * tries to determine the browser language
 * @returns browser language like 'de' or 'en' if supported, otherwise 'en' as default
 */
export const getBrowserLanguage = () => {
  const navigatorLocale = (navigator.language ?? 'en-GB')
    .split('-')[0]
    .toUpperCase();
  return isSupportedLocale(navigatorLocale)
    ? (navigatorLocale as SupportedLocales)
    : SupportedLocales.EN;
};

/**
 * get user language checks prefered language in several steps and falls back to default if necessary
 * @returns the user defined language (found in local storage of browser) or the browser language or the default ('en')
 */
export const getUserLanguage = () => {
  const browserSetting = getBrowserLanguage();

  let languageByLocalStorage;
  try {
    languageByLocalStorage = localStorage
      .getItem('evoachUILanguageSetting')
      ?.toUpperCase();
  } catch (_e) {}

  //console.log(browserSetting);
  //console.log(languageByLocalStorage);
  return (languageByLocalStorage ?? browserSetting) as SupportedLocales;
};

export type TranslationContextProviderProps = {
  children?: React.ReactNode;
};

export const TranslationContextProvider: React.FC<
  TranslationContextProviderProps
> = ({ children }) => {
  // static messages (based on locale)

  const [locale, setLocale] = useState(getUserLanguage());

  // set these messages as they are used before the locale is reset by useEffect
  // works without these dummies but with the dummies, we prevent console errors in browser
  const [staticMessages, setStaticMessages] = useState<Messages>({});

  const setMessages = async (locale: SupportedLocales) => {
    const messages = await getLocaleMessages(locale);
    setStaticMessages({ ...staticMessages, ...messages });
  };

  useEffect(() => {
    setMessages(locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  // messages dynamically loaded (based on module / session)
  const [moduleMessages, setModuleMessages] = useState<Messages>({});

  const appendModuleMessages = (newModuleMessages: Messages) => {
    setModuleMessages((oldModuleMessages: Messages) => {
      return { ...oldModuleMessages, ...newModuleMessages };
    });
  };

  /**
   * update locale is provided to context. It sets the value in local storage of the browser and for the local state
   * @param {SupportedLocales} locale
   */
  const updateLocale = (locale: SupportedLocales) => {
    try {
      localStorage.setItem('evoachUILanguageSetting', locale as string);
    } catch (_e) {}

    setLocale(locale);
  };

  // combine all messages
  const allMessages = useMemo(() => {
    return { ...staticMessages, ...moduleMessages };
  }, [moduleMessages, staticMessages]);

  // wait until initial messages are loaded
  if (Object.keys(staticMessages).length === 0) {
    return <></>;
  }

  return (
    <TranslationContext.Provider
      value={{
        setLocale: setLocale,
        locale: locale,
        messages: allMessages,
        updateLocale: (newLocale) => {
          updateLocale(newLocale);
        },
        appendModuleMessages,
        getBrowserLanguage,
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
};
