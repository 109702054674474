import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useIntl } from 'react-intl';

import { useFetchSubscriptions } from '../../api/subscription/useFetchSubscriptions';
import { Subscription } from '../../entities/Subscription';
import { SubscriptionTypeText } from '../reception/subscriptions/SubscriptionTypeText';
import { formatDate, useTableStyles } from '../tableHelper';

export type SubscriptionsProps = {
  accountId?: string;
};
export const Subscriptions: React.FC<SubscriptionsProps> = ({ accountId }) => {
  const { subscriptions } = useFetchSubscriptions(accountId);

  const classes = useTableStyles();
  const intl = useIntl();
  const planIsActive = (enddate: any) =>
    enddate === null ||
    (enddate !== null && new Date(enddate) > new Date(Date.now()));

  const activeSubscriptions = subscriptions?.filter((s: Subscription) =>
    planIsActive(s.enddate)
  );

  return (
    <div
      className="profile"
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '5rem',
      }}
    >
      {activeSubscriptions && activeSubscriptions.length > 0 ? (
        <TableContainer
          component={Paper}
          style={{
            minHeight: '100%',
            overflowX: 'initial',
          }}
        >
          <Table
            stickyHeader
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none',
                paddingBottom: '0.5rem',
              },
            }}
          >
            <colgroup>
              <col style={{ width: '10%', textAlign: 'left' }} />
              <col style={{ width: '10%', textAlign: 'left' }} />
              <col style={{ width: '10%', textAlign: 'left' }} />
            </colgroup>
            <TableHead className={classes.noWrapChildren}>
              <TableRow>
                <TableCell>
                  {intl.formatMessage({
                    id: 'player.subscriptionlist.table.header.type',
                    defaultMessage: 'Typ',
                  })}
                </TableCell>
                <TableCell>
                  {intl.formatMessage({
                    id: 'player.subscriptionlist.table.header.start',
                    defaultMessage: 'Aktiviert am',
                  })}
                </TableCell>
                <TableCell>
                  {intl.formatMessage({
                    id: 'player.subscriptionlist.table.header.end',
                    defaultMessage: 'Enddatum',
                  })}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activeSubscriptions.map((row: Subscription) => (
                <TableRow
                  key={'subsclistrow' + row.subscriptionid}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  hover={true}
                >
                  <TableCell>
                    <SubscriptionTypeText type={row.subscriptioninfo.type} />
                  </TableCell>
                  <TableCell>{formatDate(row.startdate)}</TableCell>
                  <TableCell>{formatDate(row.enddate)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body2" sx={{ marginTop: '20px' }}>
          {intl.formatMessage({
            id: 'player.subscriptionlist.nosubscriptions',
            defaultMessage: 'Keine Subscriptions vorhanden.',
          })}
        </Typography>
      )}
    </div>
  );
};
