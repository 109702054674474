import React, { useContext, useState } from 'react';

import { AccountContext, RoleEnum } from '../account';

interface DevToolsContextProps {
  devToolsActivated: boolean;
  setDevToolsActivated: (newValue: boolean) => void;
  l: (message: any) => void;
}

export const DevToolsContext = React.createContext<DevToolsContextProps>({
  devToolsActivated: false,
  setDevToolsActivated: () => null,
  l: () => null,
});

/**
 * This is never set by software, but you can manually set in in the browser
 * in the localstorage. manually! If you reload the page (also on customer-side!),
 * the DEVTOOLS are automatically activated, without Support Role, etc.
 */
const EVOACHDEVTOOLS = false; // localStorage.getItem('EVOACHDEVTOOLS') === 'true';

export type DevToolsContextProviderProps = {
  children?: React.ReactNode;
};
export const DevToolsContextProvider: React.FC<
  DevToolsContextProviderProps
> = ({ children }) => {
  const { hasRole } = useContext(AccountContext);

  const [devToolsActivatedState, setDevToolsActivatedState] =
    useState(EVOACHDEVTOOLS);

  const l = (message: any) => {
    if (devToolsActivatedState || hasRole(RoleEnum.SUPPORT)) {
      console.info(message);
    }
  };

  return (
    <DevToolsContext.Provider
      value={{
        devToolsActivated: devToolsActivatedState,
        setDevToolsActivated: setDevToolsActivatedState,
        l: l,
      }}
    >
      {children}
    </DevToolsContext.Provider>
  );
};
