import { defineMessages } from '@formatjs/intl';
import React, { useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

import { SessionPlayer } from '../components/SessionPlayer';
import { useFetchSessionQuery } from '../api';
import { CoachingRouteParams } from '../routing/routes';
import { AppHeaderContext } from '../layout/AppHeaderContext';
import ErrorBoundary from '../components/ErrorBoundary';
import { ErrorAccordion } from '../components/ErrorAccordion';

const moduelPageMessages = defineMessages({
  nomoduleorempty: {
    id: 'player.components.modulepage.nomoduleorempty',
    defaultMessage:
      'Der Chatbot ist nicht richtig konfiguriert oder hat keinen Startpunkt. Es könnte auch sein, dass der Chatbot keine Chat-Komponenten enthält.',
  },
  talktocoach: {
    id: 'player.components.modulepage.talkcoach',
    defaultMessage:
      'Bitte sprich dazu mit dem Coach, von dem du die Einladung zu diesem Chatbot erhalten hast.',
  },
});

export const ModulePage: React.FC = () => {
  const intl = useIntl();
  const { sessionId } = useParams<CoachingRouteParams>();
  const { setAppHeaderTitle, setAppHeaderHomeroute } =
    useContext(AppHeaderContext);
  useEffect(() => {
    setAppHeaderHomeroute('/reception/sessions');
  });

  const { isLoading, isError, error, session, refetch } = useFetchSessionQuery(
    sessionId ?? ''
  );

  const moduleTitle = useMemo(() => {
    if (session && session.metadata) {
      return session.metadata?.title
        ? intl.formatMessage({ id: session.metadata?.title })
        : undefined;
    } else {
      return undefined;
    }
  }, [intl, session]);

  // set header title to program title
  useEffect(() => {
    if (moduleTitle) {
      setAppHeaderTitle(moduleTitle ?? '');
    }
  }, [moduleTitle, setAppHeaderTitle]);

  if (isError) {
    return (
      <ErrorAccordion
        readableErrorMessage="Error"
        error={error}
        additionalInfo={`useFetchSessionQuery in ModulePage with sessionId ${sessionId}`}
      />
    );
  }

  if (
    !isLoading &&
    session &&
    session.dereferencedstatemachine &&
    (session.dereferencedstatemachine.definition.initial === undefined ||
      session.dereferencedstatemachine.definition.initial === '')
  ) {
    return (
      <Typography component="span" variant="body2">
        <div style={{ padding: 20 }}>
          <FormattedMessage {...moduelPageMessages.nomoduleorempty} />
        </div>
        <div style={{ padding: 20 }}>
          <FormattedMessage {...moduelPageMessages.talktocoach} />
        </div>
      </Typography>
    );
  }

  return (
    <div>
      {isError && <div>Error: {error?.message}</div>}

      {(isLoading || !sessionId) && <div>Loading</div>}
      {!isLoading && !isError && (
        <ErrorBoundary>
          <SessionPlayer
            refetchSession={refetch}
            session={session}
            moduleTitle={moduleTitle ?? ''}
          />
        </ErrorBoundary>
      )}
    </div>
  );
};
