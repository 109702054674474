import {
  CardImageEnum,
  EvoachButton,
  ImageDisplay,
  ProductTourStepContent,
} from '@evoach/ui-components';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';

import { mapImageToPublicAsset } from '../components/PropertiesAssets';
import { useEnvironment } from '../environment/useEnvironment';

//import { ProductTourStep } from '../producttour/ProductTourStep';
//import {ProductTourContext} from "../producttour/ProductTourContext";

/**
 *
 * Tours for the public program tour page
 * We enable this tour by an URL query param, e.g., after campaigns to showcase
 * demo programs and explain its capabilities
 *
 */
export const useDisplayPublicProgramTour = () => {
  const intl = useIntl();
  const { playerBasePath } = useEnvironment();
  //const { closeTour } = useContext(ProductTourContext);

  const largePopoverWidth = '580px';
  const narrowPopoverWidth = '390px';

  const tourId = 'displaypublicprogram1';

  const moduleimg = '/assets/DisplayPublicProgramTour/democoaching.png';

  const getCallToActionJSX = () => (
    <Box
      width="570px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ marginBottom: '20px' }}
    >
      <EvoachButton
        onClick={() =>
          (document.location =
            'https://creator.evoach.de/signup?signup_redirect=true&c=coachingprogramtour')
        }
      >
        {intl.formatMessage({
          id: 'player.pages.displaypublicprograms.tour.welcome.5.exit',
          defaultMessage: 'Jetzt kostenlos evoach ausprobieren',
        })}
      </EvoachButton>

      <EvoachButton
        sx={{ marginLeft: '20px' }}
        onClick={() => (document.location = 'https://bit.ly/3VX5oFg')}
      >
        {intl.formatMessage({
          id: 'player.pages.displaypublicprograms.tour.welcome.5.exitdemo',
          defaultMessage: 'Buche eine Demo!',
        })}
      </EvoachButton>
    </Box>
  );

  return {
    tourid: tourId,
    toursteps: [
      {
        selector: '', // no selector = center on screen
        content: (
          <>
            <ProductTourStepContent
              displayWide={true}
              title={intl.formatMessage({
                id: 'player.pages.displaypublicprograms.tour.welcome.1.title',
                defaultMessage: 'Willkommen bei evoach!',
              })}
              descriptionIllustrationSrc={mapImageToPublicAsset(
                CardImageEnum.powerful,
                playerBasePath
              )}
              texts={[
                intl.formatMessage({
                  id: 'player.pages.displaypublicprograms.tour.welcome.1.description.1',
                  defaultMessage:
                    'Willkommen zu unserem Coaching Program Creator.',
                }),
                intl.formatMessage({
                  id: 'player.pages.displaypublicprograms.tour.welcome.1.description.2',
                  defaultMessage:
                    'Schau dir diese kurze Einführung an, um zu verstehen, welche Möglichkeiten du hast, bei uns Coaching Programme zu entwerfen.',
                }),
              ]}
            />
            {getCallToActionJSX()}
          </>
        ),
        style: {
          maxWidth: largePopoverWidth, // override reactour default setting of max-width: "331px"
          width: largePopoverWidth, // standard width for large popover to keep it from growing
        },
      },
      {
        selector: '', // no selector = center on screen
        content: (
          <>
            <ProductTourStepContent
              displayWide={true}
              title={intl.formatMessage({
                id: 'player.pages.displaypublicprograms.tour.welcome.2.title',
                defaultMessage: 'Übersicht',
              })}
              descriptionIllustrationSrc={mapImageToPublicAsset(
                CardImageEnum.powerful,
                playerBasePath
              )}
              texts={[
                intl.formatMessage({
                  id: 'player.pages.displaypublicprograms.tour.welcome.2.description.1',
                  defaultMessage:
                    'Du siehst hier ein Demo Coaching Programm. Wir werden dir die einzelnen Elemente schrittweise erklären.',
                }),
                intl.formatMessage({
                  id: 'player.pages.displaypublicprograms.tour.welcome.2.description.2',
                  defaultMessage:
                    'Mit unserem Editor kannst du die Inhalte einfach selbst erstellen und ändern und so automatisch diese Ansicht erzeugen.',
                }),
                intl.formatMessage({
                  id: 'player.pages.displaypublicprograms.tour.welcome.2.description.3',
                  defaultMessage:
                    'Du siehst hier übrigens die "Akquise-Ansicht", die du deinen Kunden schickst. Wenn ein Programm startet, gibt es eine spezielle Ansicht für Coachees, in der die Coachees auch Links anklicken und einzelne Programmmodule als erledigt kennzeichnen können.',
                }),
              ]}
            />
            {getCallToActionJSX()}
          </>
        ),
        style: {
          maxWidth: largePopoverWidth, // override reactour default setting of max-width: "331px"
          width: largePopoverWidth, // standard width for large popover to keep it from growing
        },
      },
      {
        selector: '[data-tut="reactour__programtitle"]',
        content: (
          <>
            <ProductTourStepContent
              title={intl.formatMessage({
                id: 'player.pages.displaypublicprograms.tour.welcome.3.title',
                defaultMessage: 'Programmübersicht',
              })}
              texts={[
                intl.formatMessage({
                  id: 'player.pages.displaypublicprograms.tour.welcome.3.description',
                  defaultMessage:
                    'Jedes Programm hat einen Titel und eine Beschreibung, die du frei wählen kannst.',
                }),
              ]}
            />
          </>
        ),
        style: {
          maxWidth: narrowPopoverWidth,
        },
      },
      {
        selector: '[data-tut="reacttour__languages"]',
        content: (
          <>
            <ProductTourStepContent
              descriptionIllustrationSrc={mapImageToPublicAsset(
                CardImageEnum.achievement,
                playerBasePath
              )}
              title={intl.formatMessage({
                id: 'player.pages.displaypublicprograms.tour.welcome.4.title',
                defaultMessage: 'Programmdetails',
              })}
              texts={[
                intl.formatMessage({
                  id: 'player.pages.displaypublicprograms.tour.welcome.4.description',
                  defaultMessage:
                    'Es wird dein Name mit deiner E-Mail-Adresse angezeigt. Die Dauer kannst du frei eingeben.',
                }),
                intl.formatMessage({
                  id: 'player.pages.displaypublicprograms.tour.welcome.4.description.2',
                  defaultMessage:
                    'Wenn du dein Program in mehreren Sprachen anbieten möchtest, kannst du es im Editor einfach duplizieren und übersetzen.',
                }),
              ]}
            />
          </>
        ),
        style: {
          maxWidth: narrowPopoverWidth,
        },
      },
      {
        selector: '',
        content: (
          <>
            <ProductTourStepContent
              descriptionIllustrationSrc={mapImageToPublicAsset(
                CardImageEnum.success,
                playerBasePath
              )}
              displayWide={true}
              title={intl.formatMessage({
                id: 'player.pages.displaypublicprograms.tour.welcome.5.title',
                defaultMessage: 'Programm-Module',
              })}
              texts={[
                intl.formatMessage({
                  id: 'player.pages.displaypublicprograms.tour.welcome.5.description',
                  defaultMessage:
                    'Programm-Module enthalten eine Beschreibung und verschiedene Aktionen, die du frei wählen kannst. Eine Aktion kann ein Link zu einem Video sein, ein Link zu einer Online-Session oder ein Coaching Chatbot, den du auch bei evoach bauen kannst.',
                }),
                intl.formatMessage({
                  id: 'player.pages.displaypublicprograms.tour.welcome.5.description.2',
                  defaultMessage:
                    'Programm-Module können gruppiert werden. Das ist v.a. sinnvoll, wenn dein Programm in verschiedene Phasen gegliedert ist, in denen mehrere Interkationen mit dem Coachee stattfinden sollen.',
                }),
              ]}
            />
            {getCallToActionJSX()}
            <ImageDisplay
              assetid="none"
              src={moduleimg}
              resolvedsrc={moduleimg}
              width={570}
            />
          </>
        ),
        style: {
          maxWidth: largePopoverWidth, // override reactour default setting of max-width: "331px"
          width: largePopoverWidth, // standard width for large popover to keep it from growing
        },
      },
    ],
  };
};
