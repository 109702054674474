import React, { useState } from 'react';

export type AppHeaderContextProps = {
  appHeaderTitle: string | undefined;
  setAppHeaderTitle: Function;
  appHeaderHomeroute: string;
  setAppHeaderHomeroute: Function;
};

export const AppHeaderContext = React.createContext<AppHeaderContextProps>({
  appHeaderTitle: '',
  setAppHeaderTitle: () => {},
  appHeaderHomeroute: '',
  setAppHeaderHomeroute: () => {},
});

/**
 * This window context provider provides...
 */
export type AppHeaderContextProviderProps = {
  children?: React.ReactNode;
};
export const AppHeaderContextProvider: React.FC<
  AppHeaderContextProviderProps
> = ({ children }) => {
  const [appHeaderTitle, setAppHeaderTitle] = useState<string | undefined>();

  const [appHeaderHomeroute, setAppHeaderHomeroute] = useState<string>('/');

  return (
    <AppHeaderContext.Provider
      value={{
        appHeaderTitle,
        setAppHeaderTitle,
        appHeaderHomeroute,
        setAppHeaderHomeroute,
      }}
    >
      {children}
    </AppHeaderContext.Provider>
  );
};
