import { defineMessages } from 'react-intl';

defineMessages({
  m1: {
    id: 'shareSession.notifyCoachee',
    defaultMessage:
      'Möchtest Du die aktuelle Coaching Session mit deinem Coach teilen?',
  },
  m2: {
    id: 'shareSession.yes',
    defaultMessage: 'Ja, Session teilen',
  },
  m3: {
    id: 'shareSession.no',
    defaultMessage: 'Nein, nicht teilen',
  },
  m4: {
    id: 'sendMail.notifyCoachee',
    defaultMessage:
      'Möchtest Du die bisher eingegebenen Informationen mit deinem Coach teilen?',
  },
  m5: {
    id: 'sendMail.yes',
    defaultMessage: 'Yes, share information',
  },
  m6: {
    id: 'sendMail.no',
    defaultMessage: "No, don't share",
  },
  m7: {
    id: 'aimessageinput.placeholdertext',
    defaultMessage: 'Deine Eingabe ...',
  },
  m8: {
    id: 'aicoachmessage.aitooltip',
    defaultMessage: 'Dieser Inhalt wurde von einer KI generiert.',
  },
  m9: {
    id: 'aiscaleinput.buttonText',
    defaultMessage: 'Weiter',
  },
});

export const ProtextedTextKeys = [
  'shareSession.notifyCoachee',
  'shareSession.yes',
  'shareSession.no',
  'sendMail.notifyCoachee',
  'sendMail.yes',
  'sendMail.no',
  'aimessageinput.placeholdertext',
  'aicoachmessage.aitooltip',
  'aiscaleinput.buttonText',
];
