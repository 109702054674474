import { useContext } from 'react';
import { Button, IconButton, Stack, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { forceDownload } from '@evoach/ui-components';

import { AccountContext, RoleEnum } from '../../../account';
import { authorizedGet, authorizedPost } from '../../../api';
import {
  useEnvironment,
  EnvironmentName,
} from '../../../environment/useEnvironment';
import { WindowContext } from '../../../window/WindowContext';

interface PrintCertificateButtonProps {
  sessionid: string;
  disabled?: boolean;
}

export const PrintCertificateButton: React.FC<PrintCertificateButtonProps> = ({
  sessionid,
  disabled,
}) => {
  const intl = useIntl();

  const { hasRole } = useContext(AccountContext);
  const { name } = useEnvironment();
  const { alert } = useContext(WindowContext);

  // TODO get certificate: auto gen => if available, allow download

  const printCertificate = () => {
    if (sessionid) {
      const printCall = authorizedPost(
        `/print/sessioncertificate/${sessionid}`
      );

      printCall()
        .then((response: any) => {
          response
            .json()
            .then((_res: any) => {
              // success
              alert(
                intl.formatMessage({
                  id: 'player.printcertfifcatebutton.success',
                  defaultMessage:
                    'Dein Zertifikat wird jetzt erstellt. Du kannst es in weniger Minuten herunterladen.',
                })
              );
            })
            .catch((_reason: unknown) => {
              alert(
                intl.formatMessage({
                  id: 'player.printcertfifcatebutton.error',
                  defaultMessage:
                    'Dein Zertifikat konnte leider nicht erstellt werden. (1)',
                })
              );
              console.error(_reason);
            });
        })
        .catch((_reason: unknown) => {
          alert(
            intl.formatMessage({
              id: 'player.printcertfifcatebutton.error2',
              defaultMessage:
                'Dein Zertifikat konnte leider nicht erstellt werden. (2)',
            })
          );
          console.error(_reason);
        });
    }
  };

  const getCertificate = () => {
    const getCall = authorizedGet(`/print/sessioncertificate/${sessionid}`);
    getCall()
      .then((response: any) => {
        response
          .json()
          .then((res: { presignedUrl: string }) => {
            // TODO open in new windows
            if (res.presignedUrl && res.presignedUrl !== '') {
              forceDownload(res.presignedUrl);
            } else {
              alert('error: ' + res.presignedUrl);
            }
          })
          .catch((_reason: unknown) => {
            // TODO auto generate?
            console.error(_reason);
          });
      })
      .catch((_reason: unknown) => {
        // TODO auto-generate?
        console.error(_reason);
      });
  };
  // do not show for non-evoach and do not show on production
  if (
    !!disabled ||
    !hasRole(RoleEnum.EVOACHADMIN) ||
    name === EnvironmentName.PRODUCTION
  ) {
    return null;
  }

  return (
    <strong>
      <Stack flexDirection="row">
        <Tooltip
          title={intl.formatMessage({
            id: 'player.printcertfifcatebutton.tooltip',
            defaultMessage:
              'Hier kannst du dir ein Zertifikat für das Chatbot Thema erstellen lassen.',
          })}
        >
          <IconButton onClick={() => printCertificate()}>
            <WorkspacePremiumIcon color="secondary" fontSize="medium" />
          </IconButton>
        </Tooltip>
        <Button onClick={getCertificate}>D</Button>
      </Stack>
    </strong>
  );
};
