import { ReactKeycloakProvider } from '@react-keycloak/web';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { AccountContextProvider } from './account';
import { ModuleMetadataContextProvider } from './components/ModuleMetadataContext';
import { DevToolsContextProvider } from './devtools/DevToolsContext';
import { CustomIntlProvider } from './intl/CustomIntlProvider';
import { TranslationContextProvider } from './intl/TranslationContext';
import { keycloak } from './keycloak';
import { AppHeaderContextProvider } from './layout/AppHeaderContext';
import { ProductTourContextProvider } from './producttour/ProductTourContext';
import { AppRouter } from './routing/AppRouter';
import { WindowContextProvider } from './window/WindowContext';
import { ThemeSwitchContextProvider } from './theme/ThemeSwitchContext';

const queryClient = new QueryClient();

export const App: React.FC = () => {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ checkLoginIframe: false }}
    >
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <TranslationContextProvider>
            <CustomIntlProvider>
              <AccountContextProvider>
                <ThemeSwitchContextProvider>
                  <ProductTourContextProvider>
                    <AppHeaderContextProvider>
                      <ModuleMetadataContextProvider>
                        <DevToolsContextProvider>
                          <WindowContextProvider>
                            <AppRouter />
                          </WindowContextProvider>
                        </DevToolsContextProvider>
                      </ModuleMetadataContextProvider>
                    </AppHeaderContextProvider>
                  </ProductTourContextProvider>
                </ThemeSwitchContextProvider>
              </AccountContextProvider>
            </CustomIntlProvider>
          </TranslationContextProvider>
        </QueryClientProvider>
      </React.StrictMode>
    </ReactKeycloakProvider>
  );
};
