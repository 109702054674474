import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Menu, MenuItem } from '@mui/material';
import { EvoachButton } from '@evoach/ui-components';

import { Asset } from '../../entities';

import { AssetUploadDialog } from './AssetUploadDialog';
import { AssetSelectorDialog } from './AssetSelectorDialog';
import { AssetExternalSourceDialog } from './AssetExternalSourceDialog';
import { AssetType } from './assetHelper';

interface AssetMenuProps {
  /**
   * filter for the asset type like image, audio or video
   */
  assetType: AssetType;
  /**
   * updatePayloadValue is called when external url src or
   * assetid are changed. It is calling a function with a signature like
   * this:
   *
   * (val: string, key: 'assetid' | 'src') => {}
   *
   * If assetid is updated, the function sends the new assetid as val and key = 'assetid'
   * If src is updated, the function sends the new external URL src as val and key = 'src'
   */
  updateAssetValue?: Function;
  /**
   * existing externalUrl, may be empty
   */
  externalUrl: string;
}

export const AssetMenu: React.FC<AssetMenuProps> = ({
  assetType,
  updateAssetValue,
  externalUrl = '',
}) => {
  const intl = useIntl();

  // manage asset menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const assetMenuOpen = Boolean(anchorEl);

  const handleMenuButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const [isAssetUploadOpen, setIsAssetUploadOpen] = useState<boolean>(false);
  const [isAssetSelectOpen, setIsAssetSelectOpen] = useState<boolean>(false);
  const [isExternalUrlSelectOpen, setIsExternalUrlSelectOpen] =
    useState<boolean>(false);

  const handleMenuClose = (menuItem: number | undefined) => {
    if (menuItem !== undefined) {
      if (menuItem === 1) {
        setIsAssetUploadOpen(true);
      }
      if (menuItem === 2) {
        setIsAssetSelectOpen(true);
      }
      if (menuItem === 3) {
        setIsExternalUrlSelectOpen(true);
      }
    }
    setAnchorEl(null);
  };

  //
  // asset was set by upload or by asset selector
  //
  const onUpdateAsset = (asset: Asset) => {
    if (!asset) {
      return;
    }
    // set assetid
    localUpdatePayloadValue(asset.assetId, 'assetid');
  };

  //
  // if the payload update sequence is defined, call it with key+val
  // key  = assetid | src, val = new value
  //
  const localUpdatePayloadValue = (val: string, key: string) => {
    if (updateAssetValue) {
      updateAssetValue(val, key);
    }
  };

  return (
    <>
      <EvoachButton
        id="basic-button"
        aria-controls={assetMenuOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={assetMenuOpen ? 'true' : undefined}
        onClick={handleMenuButtonClick}
        sx={{ marginTop: '20px' }}
      >
        {intl.formatMessage({
          id: 'player.editor.assetselectinput.menubutton',
          defaultMessage: 'Datei ändern',
        })}
        &nbsp;&nbsp;&nbsp;&#x25BC;
      </EvoachButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={assetMenuOpen}
        onClose={() => handleMenuClose(undefined)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleMenuClose(1)}>
          {intl.formatMessage({
            id: 'player.editor.assetselectinput.menu.item1',
            defaultMessage: 'Datei hochladen',
          })}
        </MenuItem>
        <MenuItem onClick={() => handleMenuClose(2)}>
          {intl.formatMessage({
            id: 'player.editor.assetselectinput.menu.item2',
            defaultMessage: 'Datei aus Liste wählen',
          })}
        </MenuItem>
        <MenuItem onClick={() => handleMenuClose(3)}>
          {intl.formatMessage({
            id: 'player.editor.assetselectinput.menu.item3',
            defaultMessage: 'Externe URL angeben',
          })}
        </MenuItem>
      </Menu>
      <AssetUploadDialog
        isOpen={isAssetUploadOpen}
        onClose={() => setIsAssetUploadOpen(false)}
        onUpload={(asset: Asset) => {
          setIsAssetUploadOpen(false);
          onUpdateAsset(asset);
        }}
      />
      <AssetSelectorDialog
        isOpen={isAssetSelectOpen}
        onClose={() => setIsAssetSelectOpen(false)}
        onSelect={(asset: Asset) => {
          setIsAssetSelectOpen(false);
          onUpdateAsset(asset);
        }}
        assetType={assetType}
      />
      <AssetExternalSourceDialog
        isOpen={isExternalUrlSelectOpen}
        src={externalUrl}
        onClose={() => setIsExternalUrlSelectOpen(false)}
        onUpdate={(newurl: string) => {
          setIsExternalUrlSelectOpen(false);
          localUpdatePayloadValue(newurl, 'src');
        }}
        assetType={assetType}
      />
    </>
  );
};
