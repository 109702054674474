import { Component, ImageItem, ValueCardProps } from '@evoach/ui-components';
import { useContext } from 'react';
import { useQuery } from 'react-query';

import { AccountContext } from '../../account/AccountContext';
import { ModuleMetadataContext } from '../../components/ModuleMetadataContext';
import { mapImageToPublicAsset } from '../../components/PropertiesAssets';
import { Session } from '../../entities/Session';
import { getEnvironment } from '../../environment/useEnvironment';
import { Messages, TranslationContext } from '../../intl/TranslationContext';
import { resolveS3ApiCall } from '../asset';
import { authorizedGet, unauthorizedGet } from '../authorizedApi';
import { getLocaleMessages } from '../../intl/getLocaleMessages';
import { ProtextedTextKeys } from '../../intl/ProtectedTexts';

export const useFetchSessionQuery = (sessionId: string) => {
  const { appendModuleMessages } = useContext(TranslationContext);
  const { setMetadata } = useContext(ModuleMetadataContext);
  const { isPublicRoute } = useContext(AccountContext);
  const { playerBasePath } = getEnvironment();

  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    `session-${sessionId}`,
    async () => {
      if (sessionId.length > 0) {
        const getSessionById = !isPublicRoute
          ? authorizedGet(`/session/${sessionId}`)
          : unauthorizedGet(`/session/${sessionId}`);
        const response = await getSessionById();
        const data = await response.json();

        appendModuleMessages({
          ...data.metadatatranslation,
          ...data.statemachinetranslation,
        });

        setMetadata(data.metadata);

        //console.log(data);
        const originalProtectedMessages = await getLocaleMessages(data.lang);

        Object.keys(originalProtectedMessages).forEach((key: string) => {
          const protectedMessages: Messages = {};
          if (ProtextedTextKeys.includes(key)) {
            protectedMessages[key] = originalProtectedMessages[key];
          }
          appendModuleMessages(protectedMessages);
        });

        // all S3 URLs (=assets) have to be resolved for proper usage in chatbot

        // TODO resolves3urls muss noch mit public umgehen können!
        // 1/2 => state machine
        data.dereferencedstatemachine.definition =
          await resolveS3UrlsForStateMachine(
            data.dereferencedstatemachine.definition,
            data.statemachinetranslation,
            isPublicRoute
          );

        // map image to path for dereferencedstatemachine
        Object.keys(data.dereferencedstatemachine.definition.states).forEach(
          (statekey: string) => {
            const state =
              data.dereferencedstatemachine.definition.states[statekey];
            if (state.entry && state.entry[0]) {
              state.entry[0] = resolveImagePaths(
                state.entry[0],
                playerBasePath
              );
            }
            return state;
          }
        );

        //
        // add analytics events, similar to the progress (setPercentage) action
        // that is executed in each step, we exceute a analytics step in each step
        //
        // we add it here for these reasons:
        // 1) we that approach, we can add analytics to every existing chatbot
        // 2) we do not have to store large amounts of data and add special
        //    handling in Creator
        //
        Object.keys(data.dereferencedstatemachine.definition.states).forEach(
          (statekey: string) => {
            const state =
              data.dereferencedstatemachine.definition.states[statekey];
            if (state.entry && Array.isArray(state.entry)) {
              state.entry.push({
                type: 'setAnalytics',
                payload: {
                  statekey: statekey,
                },
              });
            }
            return state;
          }
        );

        // use context data!
        let widgetData = data.sessionstate?.currentMachineState?.context
          ? data.sessionstate?.currentMachineState.context?.widgetData ?? []
          : [];

        // 2/2 ==> session with already process widget data => resolved URLs were saved!
        widgetData = await resolveS3UrlsForWidgetData(
          widgetData,
          data.statemachinetranslation,
          isPublicRoute
        );

        // if session starts for the first time, widgetData is undefined => no imageSrc resolution
        if (widgetData) {
          // PROD-1773 mapImagetoImageSrc for widgetData
          widgetData = widgetData
            .filter((widget: Component) => Object.keys(widget).length > 0)
            .map((widget: Component) =>
              resolveImagePaths(widget, playerBasePath)
            );
        }

        // use context data!
        let userData = data.sessionstate?.currentMachineState?.context
          ? data.sessionstate.currentMachineState.context.userData ?? {}
          : {};

        //
        // PROD-1656 - add global variables
        //
        if (!data.sharedSession) {
          // add if not shared session and not a public session (we do not have info there)
          if (!isPublicRoute) {
            userData = {
              ...userData,
              COACHEE_FIRSTNAME: data.account?.givenname,
              COACHEE_LASTNAME: data.account?.familyname,
            };
          } else {
            // for public routes, set variables to empty
            userData = {
              ...userData,
              COACHEE_FIRSTNAME: '',
              COACHEE_LASTNAME: '',
            };
          }
        }

        //
        // map image to imageSrc in variables that are contained in old sessions
        //
        Object.keys(userData).forEach((varName: string) => {
          if (userData[varName]) {
            // variables contains an array
            if (Array.isArray(userData[varName])) {
              userData[varName].forEach((object: any) => {
                if (
                  object.image !== undefined &&
                  object.imageSrc === undefined
                ) {
                  object.imageSrc = mapImageToPublicAsset(
                    object.image,
                    playerBasePath
                  );
                }
              });
            }
          }
        });

        // adapt data after modification
        if (!data.sessionstate?.currentMachineState?.context) {
          data.sessionstate.currentMachineState = {
            ...data.sessionstate.currentMachineState,
            context: data.sessionstate.currentMachineState?.context ?? {},
          };
        }
        data.sessionstate.currentMachineState.context = {
          ...data.sessionstate.currentMachineState.context,
          widgetData: widgetData,
          userData: userData,
        };

        return data;
      } else {
        return {};
      }
    },
    {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      cacheTime: 1000,
    }
  );

  return {
    isLoading,
    isError,
    error,
    session: data as Session,
    refetch,
  };
};

/**
 * check whether a widget contains a property with an image prop
 * @param {string} widgetType - widgetType may be the rendered type or the type as referenced in statemachine
 * @returns {boolean} true = contains image prop, otherwise false
 */
const containsImageProp = (widgetType: string) =>
  [
    'hotOrNotSelection',
    'renderHotOrNotSelector',
    'valueCardList',
    'renderValueCardList',
  ].includes(widgetType);

/**
 * map image enums to player path of static images
 *
 * @param {any} widget
 * @param {string} playerBasePath - to be retrieved from a hook
 * @returns {any} adapted widget
 *
 * **/
const resolveImagePaths = (widget: any, playerBasePath: string) => {
  // .props for widgetdata, .payload for dereferenced statemachine
  const props = widget.props ?? widget.payload;

  // Hot-Or-Not-Selecor
  if (containsImageProp(widget.type) && props.cards) {
    const cardsWithImageSrc: ValueCardProps[] = props.cards.map((card: any) => {
      return {
        ...card,
        imageSrc: mapImageToPublicAsset(card.image, playerBasePath),
      };
    });
    props.cards = cardsWithImageSrc;
    // Value Card List
  }

  return widget;
};

/** check whether element contains asset URLs */
const containsAsset = (entryType: string) => {
  // check for two variants:
  // renderImageDisplay and imageDisplay
  const t = (entryType ?? '').toLowerCase();
  return (
    t.includes('downloadbutton') ||
    t.includes('imageselect') ||
    t.includes('imagedisplay') ||
    t.includes('videodisplay') ||
    t.includes('audiodisplay') ||
    t.includes('avatarimage')
  );
};

/**
 * resolve all S3 URLs within a statemachine
 * the statemachine comes with assets which are not usable as URL in S3
 * we have to retrieve a pre-signed S3 URL first
 */
const resolveS3UrlsForStateMachine = (
  statemachine: any,
  translation: any,
  publicRoute: boolean = false
) => {
  // if statemachine is empoty, return immediatly
  if (statemachine.initial === undefined || statemachine.initial === '')
    return statemachine;

  const states = statemachine.states;

  const getTranslation = (key: string) => {
    if (!key) return '';
    if (Object.keys(translation).includes(key)) {
      return translation[key];
    } else {
      return '';
    }
  };

  // check all states
  Object.keys(states).forEach((statekey: any) => {
    // check all entries per state
    const state = states[statekey];
    if (state.entry) {
      state.entry
        .filter((entry: any) => {
          if (!entry || !entry.type) {
            console.info('undefined entry information (useFetchSessionQuery)');
            console.info(entry);
          }
          return containsAsset(entry.type);
        })
        .forEach(async (entry: any, entryindex: number) => {
          // if there is a src parameter that starts with an URL (instead of a
          // translation key) then take the src and do not resolve. Needed
          // for legacy modules
          if (
            entry.payload.src &&
            entry.payload.src.toLowerCase().startsWith('http')
          ) {
            // no assetid? ==> old statemachine, do not resolve
            statemachine.states[statekey].entry[
              entryindex
            ].payload.resolvedsrc =
              statemachine.states[statekey].entry[entryindex].payload.src;
          } else {
            if (entry.type === 'imageSelect') {
              // for image selector, we have to iterate the images object in payload
              const images: ImageItem[] =
                statemachine.states[statekey].entry[entryindex].payload[
                  'images'
                ];
              // images
              for (let i = 0; i < images.length; i++) {
                // for each image check whether we have to resolve an asset
                // or return the external url

                const srcToResolve =
                  getTranslation(images[i].assetid).trim() !== ''
                    ? getTranslation(images[i].assetid).trim()
                    : getTranslation(images[i].src).trim();

                if (srcToResolve.toLowerCase().startsWith('http')) {
                  images[i]['resolvedsrc'] = srcToResolve;
                } else {
                  const resolveUrl = await resolveS3ApiCall(
                    srcToResolve,
                    publicRoute
                  );
                  images[i]['resolvedsrc'] = resolveUrl;
                }
              }
            } else {
              // special handling for setAvatarImage
              if (entry.type === 'setAvatarImage') {
                const externalSrc = (
                  entry.payload.src ? getTranslation(entry.payload.src) : ''
                ).trim();

                // if avatar asset was provided and successfully resolved, take it. Leave empty otherwise
                const srcToResolve = (
                  entry.payload.assetid && entry.payload.assetid.trim() !== ''
                    ? await resolveS3ApiCall(
                        getTranslation(entry.payload.assetid)
                      )
                    : ''
                ).trim();

                statemachine.states[statekey].entry[entryindex].payload[
                  'resolvedsrc'
                ] = srcToResolve !== '' ? srcToResolve : externalSrc;
              } else {
                const srcToResolve =
                  getTranslation(entry.payload.assetid).trim() !== ''
                    ? getTranslation(entry.payload.assetid).trim()
                    : getTranslation(entry.payload.src).trim();

                if (srcToResolve.toLowerCase().startsWith('http')) {
                  statemachine.states[statekey].entry[entryindex].payload[
                    'resolvedsrc'
                  ] = srcToResolve;
                } else {
                  const resolveUrl = await resolveS3ApiCall(
                    srcToResolve,
                    publicRoute
                  );
                  statemachine.states[statekey].entry[entryindex].payload[
                    'resolvedsrc'
                  ] = resolveUrl;
                }
              } // special case avatarImage?
            }
          }
        });
    }
  });

  return statemachine;
};

/*
  ! resolveS3URLs for widget data, i.e. for already performed sessions
  the previously stored sessions comes with previoulsy retrieved pre-signed URLs
  ==> when re-calling a session, we have to retrieve new pre-signed S3 URLs

  widgetData comes as an array of objects like this: 
  {
      "type": "imageDisplay",
      "temporary": false,
      "props": {
        "src": "926482be-5ade-4fa0-9563-9201474c6707.src",
        "resolvedsrc": "https://stagingbackend-assetbucket1d02...",
        "width": 200,
        "height": 100,
        "alt": "Alternativer Text",
        "assetid": "926482be-5ade-4fa0-9563-9201474c6707.assetid"
      }
  },
*/
const resolveS3UrlsForWidgetData = async (
  sessionWidgetData: any,
  translation: any,
  publicRoute: boolean
) => {
  // if widgtedata is empty, return immediatly
  if (
    sessionWidgetData === undefined ||
    (Array.isArray(sessionWidgetData) && sessionWidgetData.length === 0)
  )
    return sessionWidgetData;

  const getTranslation = (key: string) => {
    if (!key) return '';
    if (Object.keys(translation).includes(key)) {
      return translation[key];
    } else {
      return '';
    }
  };

  //
  // Check each widget for adaptation
  // Use for next instead of forEach or map because async calls are handled
  // better for subsequent processing
  //
  for (
    let widgetIndex = 0;
    widgetIndex < sessionWidgetData.length;
    widgetIndex++
  ) {
    const widget = sessionWidgetData[widgetIndex];
    if (containsAsset(widget.type)) {
      // if there is an assetid available, resolve
      if (
        widget.props &&
        widget.props.src &&
        widget.props.src.toLowerCase().startsWith('http')
      ) {
        // no assetid? ==> old statemachine, do not resolve
        widget.props.resolvedsrc = widget.props.src;
      } else {
        if (widget.type === 'setAvatarImage') {
          // if avatar asset was provided and successfully resolved, take it. Leave empty otherwise
          // avatar has no src indicator ... assets only
          resolveS3ApiCall(
            getTranslation(widget.props.assetid),
            publicRoute
          ).then((url: string) => (widget.props.resolvedsrc = url));
        } else {
          const srcToResolve =
            getTranslation(widget.props.assetid).trim() !== ''
              ? getTranslation(widget.props.assetid).trim()
              : getTranslation(widget.props.src).trim();

          if (srcToResolve.toLowerCase().startsWith('http')) {
            widget.props.resolvedsrc = srcToResolve;
          } else {
            const resolveUrl = await resolveS3ApiCall(
              srcToResolve,
              publicRoute
            );
            widget.props.resolvedsrc = resolveUrl;
          }
        }
      }
    } // if asset to be resolved
  }

  return sessionWidgetData;
};
