import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { useStartSessionMutation } from '../api/session/useStartSessionMutation';
import {
  TranslationContext,
  getBrowserLanguage,
} from '../intl/TranslationContext';
import {
  PublicCoachingRouteParams,
  AppRoutes,
  PublicRedirectRouteParams,
} from '../routing/routes';
import { SupportedLocales } from '../intl/SupportedLocales';

//
// same as PublicPage but with auto-start of chat and without additional text
//

export interface PublicRedirectPageProps {
  preserveHeader?: boolean;
}

export const PublicRedirectPage: React.FC<PublicRedirectPageProps> = ({
  preserveHeader = false,
}) => {
  // set locale based on requested session
  const { setLocale } = useContext(TranslationContext);

  // get locale from route
  const { language: requestedLocale } = useParams<PublicRedirectRouteParams>();

  const { moduleId, language } = useParams<PublicCoachingRouteParams>();
  // prepare session start
  const navigate = useNavigate();

  const { mutate, isLoading, isError, failureCount, error } =
    useStartSessionMutation();

  const intl = useIntl();

  const [errorMessage, setErrorMessage] = useState<any>({});

  // set locale of requested session already here because we need it
  // to properly display error messages
  useEffect(() => {
    // set locale to requested locale
    setLocale(requestedLocale as SupportedLocales);
  });
  //
  // check origin of the page. The origin has to be part of the
  // allowed origins of the chatbot as defined in the embedding
  // dialog in Creator
  //
  const isInIframe = useMemo(() => {
    // check whether we're in an iframe
    // use try catch in case of browser security exception
    try {
      return window.location !== window.parent.location;
    } catch (e) {
      return true;
    }
  }, []);

  const startchatbot = useCallback(() => {
    // in this step, we get the modulid from the route as we have to create the module first
    if (!moduleId || moduleId === '') return;

    let modLanguage = language;
    // add special logic if language is 'detect' and try to detect browser language
    if (!modLanguage || modLanguage.toLowerCase() === 'detect') {
      modLanguage = getBrowserLanguage();
    }
    mutate(
      { moduleId: moduleId, language: modLanguage, publicRoute: true },
      {
        onSuccess: (sessionId: string) => {
          navigate(
            `${AppRoutes.PUBLICCOACHING}/${sessionId}?h=${preserveHeader}`
          );
        },
        onError: (
          error: any,
          _variables: Record<string, string>,
          _context: unknown
        ) => {
          // this is for debugging purposes only.
          console.error(error);
          let mes = 'Info: ';
          Object.keys(_variables).forEach((varname: string) => {
            mes = mes + varname + ' = ' + _variables[varname] + '\n\r';
          });
          mes = mes + ` HTTPStatus = ${error.httpStatus}`;

          setErrorMessage(mes);
        },
      }
    );
  }, [language, moduleId, mutate, navigate, preserveHeader]);

  useEffect(() => {
    setTimeout(() => {
      startchatbot();
    }, 200);
  }, [startchatbot]);

  return (
    <Box
      sx={{
        marginLeft: '10%',
      }}
    >
      {isLoading && (
        <>
          <CircularProgress />
          <br />
        </>
      )}

      {isLoading && failureCount > 0 && failureCount <= 3 && (
        <Typography>
          {intl.formatMessage({
            id: 'player.publicredirectpage.wait.message1',
            defaultMessage: 'Ich starte den Chatbot ...',
          })}
          <br />
        </Typography>
      )}
      {isLoading && failureCount > 3 && failureCount <= 5 && (
        <Typography>
          {intl.formatMessage({
            id: 'player.publicredirectpage.wait.message2',
            defaultMessage: 'Es dauert etwas länger als gedacht ...',
          })}
          <br />
        </Typography>
      )}
      {isLoading && failureCount > 5 && failureCount <= 10 && (
        <Typography>
          {intl.formatMessage({
            id: 'player.publicredirectpage.wait.message3',
            defaultMessage:
              'Es ist scheinbar viel los bei evoach. Ich versuche es noch eine Minute lang. Normalerweise geht das viel schneller. Danke für deine Geduld.',
          })}
          <br />
        </Typography>
      )}
      {isError && (
        <Typography component="div">
          {intl.formatMessage({
            id: 'player.publicredirectpage.wait.error',
            defaultMessage: ' Es ist ein unerwarteter Fehler aufgetreten.',
          })}

          <br />
          {errorMessage}
          {(error as any).httpStatus === 403 && (
            <>
              <p>
                {intl.formatMessage({
                  id: 'player.publicredirectpage.wait.error403a',
                  defaultMessage:
                    'Der Chatbot hat die Verbindung von der aktuellen Seiten (URL) abgelehnt.',
                })}
              </p>
              <p>
                <strong>
                  {isInIframe
                    ? intl.formatMessage({
                        id: 'player.publicredirectpage.wait.error403b',
                        defaultMessage: 'Der Chatbot läuft in einem iFrame!',
                      })
                    : ''}{' '}
                  {intl.formatMessage({
                    id: 'player.publicredirectpage.wait.error403c',
                    defaultMessage:
                      'Bitte überprüfe, ob der Chatbot so konfiguriert ist, dass er Aufrufe von der aktuellen Seite (URL) erlaubt.',
                  })}
                </strong>
              </p>
            </>
          )}
        </Typography>
      )}
    </Box>
  );
};
