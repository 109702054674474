import { useQuery } from 'react-query';

import { authorizedGet } from '../authorizedApi';
import { keycloak } from '../../keycloak';
import {
  CoacheeDiaryEntryType,
  CoacheeDiaryPrintTaskEntry,
} from '../../entities/CoacheeDiaryTypes';

export const useFetchCoacheeDiaryEntries = (type: CoacheeDiaryEntryType) => {
  const { isLoading, data, isError, error, failureCount, refetch } = useQuery<
    CoacheeDiaryPrintTaskEntry[],
    Error
  >(
    'coacheediaryentries',
    async () => {
      try {
        // if an CoacheeDiaryEntryType is provided, then filter, else, use certificates

        const getUrl = `/coacheediary?types=${
          type ? type : CoacheeDiaryEntryType.CERTIFICATE
        }`;
        const getCoacheeDiaryEntries = authorizedGet(getUrl);
        const getEntriesResponse = await getCoacheeDiaryEntries();

        const res = await getEntriesResponse.json();

        return res;
      } catch (_error) {
        return [];
      }
    },
    {
      retry: 1,
      // only load when we have an auth token
      enabled: !!keycloak.token,
    }
  );

  return {
    isLoading,
    coacheeDiaryEntries: data,
    isError,
    error,
    failureCount,
    refetch,
  };
};
