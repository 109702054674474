import {
  CardImageSize,
  CardSize,
  ObjectCard,
  ObjectCardHeaderMenu,
} from '@evoach/ui-components';
import { Box } from '@mui/system';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useFetchPrograms } from '../../api/program/useFetchProgramsQuery';
import { Program } from '../../entities';
import { AppRoutes } from '../../routing/routes';

interface PublicProgramListProps {
  programidlist: string[];
  parentprogramid: string;
}

export const PublicProgramList: React.FC<PublicProgramListProps> = ({
  programidlist,
  parentprogramid,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { programs } = useFetchPrograms(programidlist);

  const rows = useMemo(() => {
    return programs
      ? programs
          .filter(
            (programs: Program) =>
              programs && !!programs.program && !!programs.program.title
          )
          .map((program: Program, mindex: number) => {
            return {
              id: mindex,
              programname: program.program.title,
              programid: program.programid,
              programdescription: program.program.description,
              programimage: program.program.image,
              programresolvedsrc: program.program.resolvedsrc,
              programduration: program.program.duration,
              programstartdate: program.program.startDate,
              programlang: program.program.language,
              badgetext: '',
              ordernumber: program.program.ordernumber ?? 0,
            };
          })
          .sort((a: any, b: any) => {
            return (b.ordernumber ?? 0) - 0 - (a.ordernumber ?? 0) - 0;
          })
      : [];
  }, [programs]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      {rows.map((row: any, index: number) => {
        return (
          <Box
            style={{
              marginTop: '20px',
              marginLeft: index === 0 ? '0px' : '15px',
            }}
            key={'objectcardlistdiv' + row.moduleid + '_' + index}
          >
            <ObjectCard
              title={row.programname}
              createdBy={row.programprovider}
              description={row.programdescription}
              duration={row.programduration}
              id={row.programid}
              image={row.programimage}
              imageSrc={row.programresolvedsrc}
              imageSize={
                programidlist ? CardImageSize.MEDIUM : CardImageSize.LARGE
              }
              cardSize={programidlist ? CardSize.SMALL : CardSize.LARGE}
              badgeText={row.badgetext}
              headermenu={
                <>
                  <ObjectCardHeaderMenu
                    id={row.programid}
                    buttonTitle={intl.formatMessage({
                      id: 'player.publicprogramlist.cards.openprogram',
                      defaultMessage: 'Öffnen',
                    })}
                    onButtonClick={() => {
                      navigate(
                        `${AppRoutes.PUBLICPROGRAM}/${parentprogramid}/subprogram/${row.programid}`
                      );
                    }}
                  />
                </>
              }
            />
          </Box>
        );
      })}
    </Box>
  );
};
