import { Box } from '@mui/system';

export interface ProgramKeyVisualProps {
  src: string;
  handleClick: () => void;
}

export const ProgramKeyVisual = ({
  src,
  handleClick,
}: ProgramKeyVisualProps) => {
  return (
    <Box display="inline-block" width="88%" marginBottom="15px">
      <Box
        width="100%"
        onClick={() => {
          handleClick();
        }}
        sx={{ cursor: 'pointer' }}
      >
        <img
          alt=""
          src={src}
          style={{
            width: 'inherit',
            aspectRatio: '4 / 1',
            objectFit: 'cover',
            objectPosition: '50% 50%',
          }}
        />
      </Box>
    </Box>
  );
};
