//import { assertUnreachable } from '../util/index';

import { SupportedLocales } from './SupportedLocales';

export const getLocaleMessages = async (locale: SupportedLocales) => {
  switch (locale) {
    case SupportedLocales.DE:
      return (await import('../lang/default.json')) as unknown as Record<
        string,
        string
      >;
    case SupportedLocales.EN:
      return (await import('../lang/en.json')) as unknown as Record<
        string,
        string
      >;
    case SupportedLocales.NL:
      return (await import('../lang/nl.json')) as unknown as Record<
        string,
        string
      >;
    case SupportedLocales.RO:
      return (await import('../lang/ro.json')) as unknown as Record<
        string,
        string
      >;
    case SupportedLocales.FR:
      return (await import('../lang/fr.json')) as unknown as Record<
        string,
        string
      >;
    case SupportedLocales.ES:
      return (await import('../lang/es.json')) as unknown as Record<
        string,
        string
      >;
    default: // return English if a language is requested that does not fit
      return (await import('../lang/en.json')) as unknown as Record<
        string,
        string
      >;
  }
};
