import { makeStyles } from '@mui/styles';
import { evoachDefaultTheme } from '@evoach/ui-components';

/**
 * Used by several tables like InvitationList, AdminUserList, etc.
 *
 * If you change something here, it applies to several tables
 */

/**
 * longTextNoWrap:
 * wrap a text after a dedicated length and add ... add the end
 *
 * cursorPointer:
 * underline element and change cursor to pointer; use evoach link color
 */
export const useTableStyles = makeStyles({
  longTextNoWrap: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '18vw',
    textOverflow: 'ellipsis',
  },
  noWrapChildren: { '& > *': { whiteSpace: 'nowrap' } },
  noWrap: { whiteSpace: 'nowrap' },
  cursorPointer: {
    cursor: 'pointer',
    color: evoachDefaultTheme.palette.secondary.main,
    textDecoration: 'underline',
    textDecorationColor: evoachDefaultTheme.palette.secondary.main,
  },
});

/**
 * return a formatted date string
 *
 * @param {Date} date
 * @returns
 */
export const formatDate = (date?: Date): string => {
  if (date === undefined || date === null) {
    return '-';
  }
  const d = new Date(date);
  return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`;
};
