export class ApiError {
  httpStatus: number;
  message: string;
  details: Record<string, string>;

  constructor(
    httpStatus: number,
    message: string,
    details: Record<string, string>
  ) {
    this.httpStatus = httpStatus;
    this.message = message;
    this.details = details;
  }
}
