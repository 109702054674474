import { MachineContext, translateWithFallback } from '@evoach/ui-components';
import { cloneDeep } from 'lodash';
import { IntlShape, defineMessages } from 'react-intl';
import { NavigateFunction } from 'react-router-dom';
import { assign } from 'xstate';

import { authorizedPost, unauthorizedPost } from '../../api';
import { AppRoutes } from '../../routing/routes';

// these messages are displayed in case of errors when calling
// the backend. The messages are only available in the UI language
// which is potentially not the module language.
// The alternative would be to add these texts to the context
// of the Start New Session component in Creator, but that would
// require that the coach translates them
defineMessages({
  grantpermission: {
    id: 'player.actions.startNewSessionAction.grantPermissionFailed',
    defaultMessage:
      'Leider kann ich den nächsten Chatbot nicht starten. Es ist ein Fehler aufgetreten (Zugriff verweigert).',
  },
  startsession: {
    id: 'player.actions.startNewSessionAction.createNewSession',
    defaultMessage:
      'Leider kann ich den nächsten Chatbot nicht starten. Beim Starten des Chatbots ist ein Fehler aufgetreten (2).',
  },
});

/**
 * renderStartNewSessionSession renders the initial information for the
 * Start New Session component from Creator. It consists of
 *
 * 1) a message
 * 2) yes/no buttons
 *
 * In addition, it stores the moduleid which is the basis for the new session
 * in the user variables.
 *
 * see PROD-1124
 */
export const renderStartNewSession = (intl: IntlShape) => {
  return assign((context: MachineContext, _event: any, actionMetadata) => {
    const newContext = cloneDeep(context);

    const payload = actionMetadata.action.payload;

    const editableCoachMessage = translateWithFallback(intl, payload.message);

    // coach message with text from Creator
    if (editableCoachMessage !== '') {
      newContext.widgetData.push({
        type: 'coachMessage',
        temporary: false,
        props: {
          message: editableCoachMessage,
        },
      });
    }

    // yes Not Button with texts from Creator
    newContext.widgetData.push({
      type: 'yesNoButton',
      temporary: true,
      props: {
        yesButtonText: translateWithFallback(intl, payload.buttonTextYes),
        noButtonText: translateWithFallback(intl, payload.buttonTextNo),
        saveResultTo: payload.saveResultTo,
      },
    });

    // save moduleid as variable to have it available in the subsequent actions!
    newContext.userData['startNewSession.moduleid'] = payload.moduleId; // upper case I !

    return newContext;
  });
};

/**
 * setWriteModulePermission - xState service that adds permissions for the
 * coachee to the module to be used for the next session.
 * It relies on parameters in context.userData and context.sessionData
 * It returns a Promise which contains the list of created module permissions.
 *
 * This service is added to the list newSessionServices which is added as
 * services to the xState machine in SessionPlayer.
 *
 * @param {MachineContext} context
 * @returns {Promise<ModulePermissions[]>}
 */
export const setGrantPermission = async (context: MachineContext) => {
  if (!context.sessionData) return;
  // module relqated data
  const moduleId = context.userData['startNewSession.moduleid'];
  // session related data
  const sessionId = context.sessionData['evoach.sessionId'];
  const isPublicModule = context.sessionData['evoach.isPublicModule'];
  return writePermissionForNextModule(moduleId, sessionId, isPublicModule);
};

/**
 * setCreateSession - xState service that creates a new session.
 * It relies on parameters in context.userData and context.sessionData
 * It returns a Promise which contains the new sessionid.
 *
 * This service is added to the list newSessionServices which is added as
 * services to the xState machine in SessionPlayer.
 *
 * @param {MachineContext} context
 * @returns {Promise<string>} sessionid
 */
export const setCreateSession = async (context: MachineContext) => {
  if (!context.sessionData) return;
  // module relqated data
  const moduleId = context.userData['startNewSession.moduleid'];
  // session related data
  const sessionLanguage = context.sessionData['evoach.sessionLanguage'];
  const isPublicModule = context.sessionData['evoach.isPublicModule'];
  return createNewSession({
    moduleid: moduleId,
    language: sessionLanguage,
    publicRoute: isPublicModule,
  });
};

/**
 * redirectToSession - is an action triggered by the onDone hook in the
 * xState state machine if the session was successfully created. It gets
 * a navigate object for React routes redirection from SessionPlayer.
 */
export const redirectToSession = (navigate: NavigateFunction) => {
  return assign((context: MachineContext, event: any) => {
    // Why is event.data => sessionid ?
    // After calling the invoke xState with setCreateSession, the Promise
    // of setCreateSession returns the sessionid as a string. If successfull,
    // the action of the onDone hook is this redirectToSession action. This
    // action automatically gets the event of the triggering Promise!
    const sessionid = event.data;
    if (!sessionid || sessionid === '') {
      return context;
    }
    navigate(`${AppRoutes.COACHING}/${sessionid}`);
    return context;
  });
};

/**
 * renderGrantPermissionError - message that is printed if an error occurs for
 * write permission. This action is triggered by the onError hook of the
 * "..._setGrantPermission" invoke state in the state machine.
 * @param {IntlShape} intl
 * @returns {MachineContext} newContext
 */

export const renderGrantPermissionError = (intl: IntlShape) => {
  return assign((context: MachineContext, _event: any) => {
    const newContext = cloneDeep(context);

    newContext.widgetData.push({
      type: 'coachMessage',
      temporary: false,
      props: {
        message: translateWithFallback(
          intl,
          'player.actions.startNewSessionAction.grantPermissionFailed'
        ),
      },
    });
    return newContext;
  });
};

/**
 * renderCreateSessionError - message that is printed if an error occurs for
 * creating a session. This action is triggered by the onError hook of the
 * "..._setCreateSession" invoke state in the state machine.
 * @param {IntlShape} intl
 * @returns {MachineContext} newContext
 */

export const renderCreateSessionError = (intl: IntlShape) => {
  return assign((context: MachineContext, _event: any) => {
    const newContext = cloneDeep(context);

    newContext.widgetData.push({
      type: 'coachMessage',
      temporary: false,
      props: {
        message: translateWithFallback(
          intl,
          'player.actions.startNewSessionAction.createNewSession'
        ),
      },
    });
    return newContext;
  });
};

/**
 * writePermissionForNextModule try to grant permission to moduleid from
 * session sessionid for current user.
 *
 * @param {string} moduleid
 * @param {string} sessionid
 * @returns {boolean} true = permission granted, false = permission not granted
 */
const writePermissionForNextModule = async (
  moduleid: string,
  sessionid: string,
  publicRoute: boolean = false
) => {
  // TODO handle public routes? ==> adapt here + in backend
  if (publicRoute) {
    console.error('public routes not supported.');
  }
  const newPerm = authorizedPost(`/module/${moduleid}/modulepermission`, {
    sessionid: sessionid,
  });
  const response = await newPerm();
  return await response.json();
};

/**
 * createNewSession creates a new special session for the current user.
 *
 * @param {string} moduleid of module of which the session should be created
 * @param {string} language in which the session should be started / the module should be loaded
 * @param {boolean} publicRoute, @optional @default false , if true, the unauthorized route is taken
 * @param {string} programInstanceId @optional helps to identify whether a session was started out of a program
 * @returns {string} session id of new session (emppty string in case of error)
 */
export const createNewSession = async ({
  moduleid,
  language,
  publicRoute = false,
  programInstanceId = '',
}: {
  moduleid: string;
  language: string;
  publicRoute?: boolean;
  programInstanceId?: string;
}): Promise<string> => {
  // programInstanceId valid for non-public routes, only
  const url = !publicRoute
    ? `/session/?language=${language}&moduleid=${moduleid}&programinstanceid=${programInstanceId}`
    : `/session/${moduleid}?language=${language}`;

  const startNewSession = !publicRoute
    ? authorizedPost(url)
    : unauthorizedPost(url);

  const response = await startNewSession();
  const data = await response.json();

  return data.sessionid;
};

/**
 * export services here for better readability of state machine init in SessionPlayer
 */
export const newSessionServices = {
  setGrantPermission: (context: MachineContext) => setGrantPermission(context),
  setCreateSession: (context: MachineContext) => setCreateSession(context),
};
