import React, { useState } from 'react';
import { defineMessages } from '@formatjs/intl';

interface CollapsibleProps {
  title: string;
  children?: React.ReactNode;
}

const collapsibleComponentMessages = defineMessages({
  hide: {
    id: 'player.components.collapsible.hide',
    defaultMessage: 'Verberge',
  },
  show: {
    id: 'player.components.collapsible.show',
    defaultMessage: 'Zeige',
  },
});

export const Collapsible: React.FC<CollapsibleProps> = ({
  title,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {isOpen
          ? `${collapsibleComponentMessages.hide} ${title}`
          : `${collapsibleComponentMessages.show} ${title}`}
      </button>
      {isOpen && children}
    </div>
  );
};
