import { CoachCard, EvoachButton, ImageDisplay } from '@evoach/ui-components';
import {
  Check,
  Close,
  Facebook,
  Info,
  Instagram,
  LinkedIn,
  ModeEdit,
  Twitter,
} from '@mui/icons-material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import HomeIcon from '@mui/icons-material/Home';
import YouTubeIcon from '@mui/icons-material/YouTube';
import {
  Box,
  Button,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Avatar from 'react-avatar';
import { defineMessage, useIntl } from 'react-intl';

import { AccountContext, RoleEnum } from '../../account';
import { useUpdateAccount } from '../../api/account/useUpdateAccount';
import { useFetchAsset } from '../../api/asset/useFetchAsset';
import { AccountMetainfo, AccountProfile, EMPTY_PROFILE } from '../../entities';
import { FeatureEnum } from '../../entities/subscriptionTypes';
import { TranslationContext } from '../../intl/TranslationContext';
import { AssetType } from '../PropertiesAssets/assetHelper';
import { AssetMenu } from '../PropertiesAssets/AssetMenu';

const profilePictureHint = defineMessage({
  id: 'player.profile.profilePictureHint',
  defaultMessage:
    'Hier kannst du ein Profilbild konfigurieren. Wenn du kein Bild festlegst, werden die Initialen deines Namens angezeigt.',
});

const profilePicture = defineMessage({
  id: 'player.profile.profilePicture',
  defaultMessage: 'Profilbild',
});

export const Profile: React.FC = () => {
  const intl = useIntl();

  const { account, refetchAccount, hasRole, hasFeature } =
    useContext(AccountContext);

  const maxWidth = '400px';

  // load current locale
  const { locale } = useContext(TranslationContext);

  // provide update function for account metainfos
  const { mutate } = useUpdateAccount();

  // get basic user information
  const firstName = useMemo(() => {
    return account?.givenname;
  }, [account?.givenname]);
  const lastName = useMemo(() => {
    return account?.familyname;
  }, [account?.familyname]);
  const email = useMemo(() => {
    return account?.email;
  }, [account?.email]);

  const isCoach = useMemo(() => {
    return hasRole(RoleEnum.COACH) && hasFeature(FeatureEnum.CREATOR_B);
  }, [hasFeature, hasRole]);

  const [isEditMode, setIsEditMode] = useState(false);

  //
  // all objects are somehow optional as they were introduced over time step
  // by step and may not be present for all users. If anything is not present,
  // the initial value is an empty string
  //
  // save in state
  const [coachProfile, setCoachProfile] = useState<AccountProfile>(
    account?.metainfos?.profile ?? EMPTY_PROFILE
  );

  // update state if account is changing (e.g. if loading takes time)
  useEffect(() => {
    if (!account) return;
    // always set empty profile to avoid textfields toggling between
    // unconrtrolled / controlled state
    setCoachProfile({
      ...EMPTY_PROFILE,
      ...(account?.metainfos?.profile ?? {}),
    });
  }, [account]);

  //
  // save profile with current settings
  //
  // build metainfos by states and pass to mutate
  //
  const saveProfile = useCallback(() => {
    const metainfo: AccountMetainfo = {
      profile: coachProfile,
      language: locale,
    };
    mutate(metainfo, {
      onSuccess: () => {
        refetchAccount();
      },
      onError: (error: unknown) => {
        alert(
          intl.formatMessage({
            id: 'player.profile.errorwhilesaving',
            defaultMessage:
              'Beim Speichern deines Profils ist ein Fehler aufgetreten.',
          })
        );
        console.error(error);
      },
    });
  }, [coachProfile, locale, mutate, refetchAccount, intl]);

  const updateCoachProfile = (key: string, value: string) => {
    setCoachProfile({
      ...coachProfile,
      [key]: value,
    });
  };
  //
  // asset was selected, updateProfilePicture
  //
  const updateProfilePicture = useCallback(
    (val: string, key: 'assetid' | 'src') => {
      if (key === 'src') {
        setCoachProfile({
          ...coachProfile,
          profilePictureSrc: val, // src for external URL
          profilePictureAssetId: '',
        });
      }
      if (key === 'assetid') {
        setCoachProfile({
          ...coachProfile,
          profilePictureAssetId: val, // assetid for local asset
        });
      }
    },
    [coachProfile]
  );

  //
  // asset was selected, updateLogoPicture
  //
  const updateLogoPicture = useCallback(
    (val: string, key: 'assetid' | 'src') => {
      if (key === 'src') {
        setCoachProfile({
          ...coachProfile,
          logoPictureSrc: val, // src for external URL
          logoPictureAssetId: '',
        });
      }
      if (key === 'assetid') {
        setCoachProfile({
          ...coachProfile,
          logoPictureAssetId: val, // assetid for local asset
        });
      }
    },
    [coachProfile]
  );

  //
  // what picture to show? Asset id has higher priority than external url
  // if none is set, we return undefined
  //
  const { asset: coachAsset } = useFetchAsset(
    coachProfile.logoPictureAssetId !== ''
      ? coachProfile.logoPictureAssetId
      : ''
  );
  const logoSrc =
    coachAsset !== undefined && coachAsset.url?.trim() !== ''
      ? coachAsset.url
      : coachProfile.logoPictureSrc !== undefined &&
        coachProfile.logoPictureSrc.trim() !== ''
      ? coachProfile.logoPictureSrc
      : undefined;

  //
  // what picture to show? Asset id has higher priority than external url
  // if none is set, we return undefined
  //
  const { asset: profileAsset } = useFetchAsset(
    coachProfile.profilePictureAssetId !== ''
      ? coachProfile.profilePictureAssetId
      : ''
  );

  const profileSrc =
    profileAsset !== undefined && profileAsset.url?.trim() !== ''
      ? profileAsset.url
      : coachProfile.profilePictureSrc !== undefined &&
        coachProfile.profilePictureSrc.trim() !== ''
      ? coachProfile.profilePictureSrc
      : undefined;

  // render profile information
  return (
    <div
      className="profile"
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '5rem',
      }}
    >
      <div
        className="userData"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          width: '100%',
          maxWidth: maxWidth,
        }}
      >
        <Typography variant="h6">
          {intl.formatMessage(
            {
              id: 'player.profile.userdataheader',
              defaultMessage: 'Hallo {firstname} {lastname}',
            },
            {
              firstname: firstName,
              lastname: lastName,
            }
          )}
        </Typography>
        <Typography variant="body1">
          {intl.formatMessage(
            {
              id: 'player.profile.userdatadesc',
              defaultMessage:
                'Du bist angemeldet mit der E-Mail Adresse {mail}. Auf dieser Seite kannst du deine Profildaten ändern.',
            },
            {
              mail: email,
            }
          )}
        </Typography>

        {isCoach === true ? (
          <>
            {isEditMode ? (
              <Box display="flex" flexDirection="column" width="fit-content">
                <Paper sx={{ padding: '20px', width: '700px' }}>
                  <Box display="flex" flexDirection="column" gap="2em">
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        gap="5px"
                      >
                        <Box
                          sx={{
                            typography: 'h6',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                          }}
                        >
                          {intl.formatMessage(profilePicture)}
                          <Tooltip
                            title={intl.formatMessage(profilePictureHint)}
                            placement="top"
                            arrow
                          >
                            <Info
                              color="secondary"
                              sx={{ cursor: 'pointer' }}
                            />
                          </Tooltip>
                        </Box>
                        <Avatar
                          name={`${firstName} ${lastName}`}
                          size="100px"
                          round={true}
                          color="#AAAAAA"
                          src={profileSrc}
                        />
                        <AssetMenu
                          assetType={AssetType.IMAGE}
                          updateAssetValue={updateProfilePicture}
                          externalUrl={coachProfile.profilePictureSrc ?? ''}
                        />
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        gap="5px"
                      >
                        <Box sx={{ typography: 'h6' }}>
                          {intl.formatMessage({
                            id: 'player.profile.coachLogo',
                            defaultMessage: 'Coach Logo',
                          })}
                        </Box>
                        <ImageDisplay
                          height={100}
                          assetid={logoSrc}
                          resolvedsrc={logoSrc}
                          src={logoSrc}
                        />
                        <AssetMenu
                          assetType={AssetType.IMAGE}
                          updateAssetValue={updateLogoPicture}
                          externalUrl={coachProfile.logoPictureSrc ?? ''}
                        />
                      </Box>
                    </Box>
                    <Box display="flex" flexWrap="wrap" gap="12px">
                      <TextField
                        label={intl.formatMessage({
                          id: 'player.profile.coachDescription',
                          defaultMessage: 'Coach Beschreibung',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'player.profile.coachDescription.placeholder',
                          defaultMessage:
                            'Gebe Beschreibung von dir als Coach ein',
                        })}
                        variant="outlined"
                        fullWidth
                        multiline
                        minRows={2}
                        maxRows={4}
                        value={coachProfile.description}
                        onChange={(evt: any) =>
                          setCoachProfile({
                            ...coachProfile,
                            description: evt.target.value,
                          })
                        }
                      />
                      <TextField
                        sx={{ flexGrow: 1 }}
                        label={intl.formatMessage({
                          id: 'player.profile.coachingMail',
                          defaultMessage: 'Alternative E-Mail-Adresse',
                        })}
                        placeholder={email}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AlternateEmailIcon />
                            </InputAdornment>
                          ),
                        }}
                        value={coachProfile.coachingMail}
                        variant="outlined"
                        onChange={(evt: any) =>
                          setCoachProfile({
                            ...coachProfile,
                            coachingMail: evt.target.value,
                          })
                        }
                      />
                      <TextField
                        sx={{ flexGrow: 1 }}
                        label={intl.formatMessage({
                          id: 'player.profile.website',
                          defaultMessage: 'Website',
                        })}
                        placeholder="https://mycoachinghomepage.com"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <HomeIcon />
                            </InputAdornment>
                          ),
                        }}
                        value={coachProfile.websiteUrl}
                        variant="outlined"
                        onChange={(evt: any) =>
                          setCoachProfile({
                            ...coachProfile,
                            websiteUrl: evt.target.value,
                          })
                        }
                      />
                      <TextField
                        sx={{ flexGrow: 1 }}
                        label={intl.formatMessage({
                          id: 'player.profile.linkedIn',
                          defaultMessage: 'LinkedIn',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'player.profile.linkedIn.placeholder',
                          defaultMessage: 'https://linkedin.com/in/username',
                        })}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LinkedIn />
                            </InputAdornment>
                          ),
                        }}
                        value={coachProfile.linkedinProfileUrl}
                        variant="outlined"
                        onChange={(evt: any) =>
                          setCoachProfile({
                            ...coachProfile,
                            linkedinProfileUrl: evt.target.value,
                          })
                        }
                      />
                      <TextField
                        sx={{ flexGrow: 1 }}
                        label={intl.formatMessage({
                          id: 'player.profile.youtube',
                          defaultMessage: 'Youtube',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'player.profile.youtube.placeholder',
                          defaultMessage:
                            'https://www.youtube.com/channel/mychannel',
                        })}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <YouTubeIcon />
                            </InputAdornment>
                          ),
                        }}
                        value={coachProfile.youtubeProfileUrl}
                        variant="outlined"
                        onChange={(evt: any) =>
                          updateCoachProfile(
                            'youtubeProfileUrl',
                            evt.target.value
                          )
                        }
                      />

                      <TextField
                        sx={{ flexGrow: 1 }}
                        label={intl.formatMessage({
                          id: 'player.profile.insta',
                          defaultMessage: 'Instagram',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'player.profile.insta.placeholder',
                          defaultMessage:
                            'https://www.instagram.com/yourprofile/',
                        })}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Instagram />
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        value={coachProfile.instagramProfileUrl}
                        onChange={(evt: any) =>
                          setCoachProfile({
                            ...coachProfile,
                            instagramProfileUrl: evt.target.value,
                          })
                        }
                      />
                      <TextField
                        sx={{ flexGrow: 1 }}
                        label={intl.formatMessage({
                          id: 'player.profile.twitter',
                          defaultMessage: 'Twitter',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'player.profile.twitter.placeholder',
                          defaultMessage: 'https://twitter.com/@username',
                        })}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Twitter />
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        value={coachProfile.twitterProfileUrl}
                        onChange={(evt: any) =>
                          setCoachProfile({
                            ...coachProfile,
                            twitterProfileUrl: evt.target.value,
                          })
                        }
                      />
                      <TextField
                        sx={{ flexGrow: 1 }}
                        label={intl.formatMessage({
                          id: 'player.profile.facebook',
                          defaultMessage: 'Facebook',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'player.profile.facebook.placeholder',
                          defaultMessage: 'https://facebook.com/username',
                        })}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Facebook />
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        value={coachProfile.facebookProfileUrl}
                        onChange={(evt: any) =>
                          setCoachProfile({
                            ...coachProfile,
                            facebookProfileUrl: evt.target.value,
                          })
                        }
                      />
                      <Box sx={{ flexGrow: 3 }}></Box>
                    </Box>
                  </Box>
                </Paper>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  marginTop="1em"
                  gap="10px"
                >
                  <Button
                    onClick={() => {
                      saveProfile();
                      setIsEditMode(!isEditMode);
                    }}
                    color="secondary"
                    startIcon={<Check />}
                  >
                    {intl.formatMessage({
                      id: 'player.profile.saveChangesMulti',
                      defaultMessage: 'Speichern',
                    })}
                  </Button>
                  <Button
                    onClick={() => {
                      setIsEditMode(!isEditMode);
                      setCoachProfile(
                        account?.metainfos?.profile ?? coachProfile
                      );
                    }}
                    startIcon={<Close />}
                  >
                    {intl.formatMessage({
                      id: 'player.profile.cancel',
                      defaultMessage: 'Verwerfen',
                    })}
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box
                position="relative"
                display="flex"
                flexDirection="column"
                gap="1em"
                width="fit-content"
              >
                <CoachCard
                  cardWidth="700px"
                  coachAvatar={profileSrc}
                  coachName={`${firstName} ${lastName}`}
                  coachDescription={coachProfile.description}
                  coachLogo={logoSrc}
                  coachMail={coachProfile.coachingMail}
                  coachWebsite={coachProfile.websiteUrl}
                  instagramLink={coachProfile.instagramProfileUrl}
                  coachingLabels={[]}
                  linkedInLink={coachProfile.linkedinProfileUrl}
                  twitterLink={coachProfile.twitterProfileUrl}
                  youtubeLink={coachProfile.youtubeProfileUrl}
                />
                <Box alignSelf="flex-start">
                  <Button
                    onClick={() => setIsEditMode(!isEditMode)}
                    color="secondary"
                    startIcon={<ModeEdit />}
                  >
                    {intl.formatMessage({
                      id: 'player.profile.edit',
                      defaultMessage: 'Profil bearbeiten',
                    })}
                  </Button>
                </Box>
              </Box>
            )}
          </>
        ) : (
          <>
            <Box>
              <EvoachButton onClick={saveProfile}>
                {intl.formatMessage({
                  id: 'player.profile.saveChanges',
                  defaultMessage: 'Änderungen Speichern',
                })}
              </EvoachButton>
            </Box>
            <div
              className="coachData"
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
              }}
            >
              <Typography variant="h6">
                {intl.formatMessage(profilePicture)}
              </Typography>
              <Typography variant="body1" sx={{ maxWidth: maxWidth }}>
                {intl.formatMessage(profilePictureHint)}
              </Typography>
              <Avatar
                name={`${firstName} ${lastName}`}
                size="150px"
                round={true}
                color="#AAAAAA"
                src={profileSrc}
              />
              <Box>
                <AssetMenu
                  assetType={AssetType.IMAGE}
                  updateAssetValue={updateProfilePicture}
                  externalUrl={coachProfile.profilePictureSrc ?? ''}
                />
              </Box>
            </div>
          </>
        )}
      </div>
      <div
        className="userPreferences"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          width: '100%',
          maxWidth: maxWidth,
        }}
      >
        &nbsp;
      </div>
    </div>
  );
};
