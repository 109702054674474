//
// routes for React router
//
// !Important
// if you name the path values like '/login' be aware that the prefix
// '/public' (applies also to '/publiccoaching') is used to determine whether
// we are in embedded mode. This leads to different types of API calls without
// tokens.
// If you add a new route, use the prefix '/public' only if it is used for
// embedded modules!

export enum AppRoutes {
  BLANK = '/',
  LOGIN = '/login',
  SIGNUP = '/signup',
  PROFILE = '/profile',
  COACHING = '/coaching',
  RECEPTION = '/reception',
  INVITATION = '/invitation',
  PLAYGROUND = '/playground',
  DISCLAIMER = '/disclaimer',
  PUBLIC = '/public',
  PUBLICREDIRECT = '/publicredirect',
  PUBLICCOACHING = '/publiccoaching',
  PUBLICPLAYER = '/publicplayer',
  PUBLICPROGRAM = '/program',
  PROGRAMINVITATION = '/programinvitation',
  PROGRAMINSTANCE = '/programinstance',
}

export enum ReceptionRoutes {
  PROGRAMS = 'programs',
  MODULES = 'modules',
  SESSIONS = 'sessions',
}

export const ReceptionRoutesArray = Object.values(ReceptionRoutes).map(
  (route) => '/' + route
) as string[];

//
// the /public route requires the moduleid and the language to start
// the session with the correct module
//
export const PublicCoachingRouteParamModuleName = 'moduleId' as const;
export const PublicCoachingRouteParamLanguageName = 'language' as const;
// PROD-1862 / used to make prevent browser caching
export const PublicCoachingRouteParamDateNOW = 'datenow' as const;
export interface PublicCoachingRouteParams {
  [index: string]: string | undefined;
  [PublicCoachingRouteParamModuleName]: string;
  [PublicCoachingRouteParamLanguageName]: string | undefined;
  [PublicCoachingRouteParamDateNOW]: string | undefined; // PROD-1862
}

//
// coaching route gets a sessionid
//
export const CoachingRouteParamName = 'sessionId' as const;
export interface CoachingRouteParams {
  [index: string]: string;
  [CoachingRouteParamName]: string;
}

//
// invitationid is provided for /invitation route, only
//
export const InvitationRouteParamName = 'invitationId' as const;
export interface InvitationRouteParams {
  [index: string]: string;
  [InvitationRouteParamName]: string;
}

//
// public program route gets a programId
//
export enum ProgramRouteTypeEnum {
  PROGRAM = 'program',
  INSTANCE = 'instance',
}
export const PublicProgramRouteParamName = 'programId' as const;
export const PublicProgramRouteSubParamName = 'subprogramId' as const;

export interface PublicProgramRouteParams {
  [index: string]: string;
  [PublicProgramRouteParamName]: string;
  [PublicProgramRouteSubParamName]: string;
}

export const ProgramInstanceRouteParamName = 'programInstanceId' as const;
export const ProgramInstanceRouteSubParamName = 'subprogramInstanceId' as const;
export interface ProgramInstanceRouteParams {
  [index: string]: string;
  [PublicProgramRouteParamName]: string;
  [ProgramInstanceRouteSubParamName]: string;
}

export interface PublicRedirectRouteParams {
  [index: string]: string;
  [PublicCoachingRouteParamModuleName]: string;
  [PublicCoachingRouteParamLanguageName]: string;
  [PublicCoachingRouteParamDateNOW]: string; // not used, only to prevent caching
}
