import {
  CardImageSize,
  CardIndicatorIcon,
  CardSize,
  EvoachButton,
  ObjectCard,
  ObjectCardHeaderMenu,
} from '@evoach/ui-components';
import { History } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
  FormControl,
  Input,
  InputAdornment,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useFetchModulesQuery, useStartSessionMutation } from '../../api';
import {
  categoryTranslations,
  ModuleCategoryEnum,
} from '../../entities/ModuleMetaData';
import { ModulePermission } from '../../entities/ModulePermissions';
import { AppRoutes } from '../../routing/routes';
import { WindowContext } from '../../window/WindowContext';

import { SessionProtocolsOverlay } from './SessionProtocolsOverlay';
import { StartSessionDialog } from './StartSessionDialog';

//
// ! Module list with cards
// Show all modules in a list and provide functionality to start a session
//
export const ModuleListWithCards: React.FC = () => {
  const intl = useIntl();

  const { modules, isLoading } = useFetchModulesQuery();

  // catch window resize events
  const { clientHeight } = useContext(WindowContext);

  // highlighting with badges
  const unhighlight = (moduleid: string) => {
    try {
      const newHighlightedModules = JSON.parse(
        localStorage.getItem(`evoach.player.modules.highlighted`) ?? '[]'
      ).filter((module: any) => module.moduleid !== moduleid);
      localStorage.setItem(
        `evoach.player.modules.highlighted`,
        JSON.stringify(newHighlightedModules)
      );
    } catch (reason: unknown) {
      console.error(reason);
    }
  };

  // if module list is reloaded, re-generate mapping for rows
  // rows is also used by child components
  const rows = useMemo(() => {
    //
    // check whether a module should get a "new" badge
    //
    const getBadgeText = (moduleid: string): string => {
      const newBadgeText = intl.formatMessage({
        id: 'player.reception.modulelist.cards.newbadgetext',
        defaultMessage: 'Neu',
      });

      let highlightedModules: undefined | Array<any>;

      try {
        highlightedModules = JSON.parse(
          localStorage.getItem(`evoach.player.modules.highlighted`) ?? '[]'
        );
      } catch (reason: unknown) {
        console.error(reason);
      }

      // if module is in localStorage & new -> add badge text
      return Array.isArray(highlightedModules)
        ? highlightedModules.some((module: any) => {
            const isModuleInHighlightedList = module.moduleid === moduleid;
            const expirationTime = 86400000; // 1 day
            const isModuleRecentlyAdded =
              Date.now() - module.highlightedDate < expirationTime;
            return isModuleInHighlightedList && isModuleRecentlyAdded;
          })
          ? newBadgeText
          : ''
        : '';
    };

    //
    // get the list of inviting accounts
    //
    const getInvitingAccounts = (permissions: ModulePermission[]) => {
      const coaches: Record<string, any> = {};
      permissions
        .filter(
          (perm: ModulePermission) =>
            perm.owns ||
            (perm.invitinginvitationid !== null &&
              perm.invitinginvitationid !== undefined &&
              perm.invitinginvitationid !== '')
        )
        .forEach((perm: ModulePermission) => {
          if (perm.owns) {
            coaches[perm.account.accountid] = perm.account;
          } else {
            coaches[perm.invitingaccount.accountid] = perm.invitingaccount;
          }
        });

      return coaches;
    };

    let ret = modules
      ? modules
          .filter(
            (module: any) =>
              module && !!module.metadata && !!module.metadata.title
          )
          .filter((module: any) => !module.issubmodule)
          .map((module: any, mindex: number) => {
            return {
              id: mindex,
              modulename: module.metadata.title,
              moduleid: module.moduleid,
              moduledescription: module.metadata.description,
              moduleimage: module.metadata.image,
              moduleresolvedsrc: module.metadata.resolvedsrc,
              moduleresolvedcoachlogosrc: module.metadata.resolvedCoachLogoSrc,
              moduleduration: module.metadata.duration,
              modulecategories: module.metadata.categories,
              modulesupportedlang: module.metadata.moduleLanguages,
              moduledefaultlanguage: module.metadata.defaultLanguage,
              modulenumphases: Array.isArray(module.metadata.phases)
                ? module.metadata.phases.length
                : 0,
              moduleisdefault: module.isdefaultmodule,
              moduleisshared:
                module.permissions[0].invitinginvitationid !== undefined &&
                module.permissions[0].invitinginvitationid !== null,
              // TODO refcator for several accounts
              moduleprovider:
                module.isdefaultmodule || module.permissions[0].owns
                  ? module.permissions[0].account.givenname +
                    ' ' +
                    module.permissions[0].account.familyname
                  : module.permissions[0].invitingaccount.givenname +
                    ' ' +
                    module.permissions[0].invitingaccount.familyname,
              moduleorderingnumber: module.metadata.orderingnumber ?? 1000,
              badgetext: getBadgeText(module.moduleid),
              invitingaccounts: getInvitingAccounts(module.permissions),
            };
          })
          .sort((a: any, b: any) => {
            return b.moduleorderingnumber - a.moduleorderingnumber;
          })
      : [];

    return ret;
  }, [modules, intl]);

  // height calc
  const maxHeight = clientHeight - 315 + 'px';

  // prepare session start
  const navigate = useNavigate();

  const { mutate } = useStartSessionMutation();

  const [sessionDialogOpen, setSessionDialogOpen] = useState<boolean>(false);
  const [currentModuleId, setCurrentModuleId] = useState<string>('');
  const [currentLanguage, setCurrentLanguage] = useState<string>('');
  const [currentModuleLanguages, setCurrentModuleLanguages] = useState<
    string[]
  >([]);
  // if dialog is required to define coach, get it there
  const [currentCoach, setCurrentCoach] = useState<string>('');
  // list of inviting coaches for StartSessionDialog
  const [invitingCoaches, setInvitingCoaches] = useState<Record<string, any>>(
    {}
  );

  //
  // Start a session
  //
  // handle multiple languages and multiple inviting accounts
  //
  const startSession = (
    moduleId: string,
    availableLanguages: string[],
    defaultLanguage: string,
    invitingaccounts: Record<string, any>
  ) => {
    // no moduleid? return
    if ((false && !moduleId) || moduleId.trim() === '') {
      return;
    }
    //
    // If current module is not available in current UI language but in
    // other languages, then ask for language to start session with.
    // If there is more than one inviting coach ask for the coach to choose.
    //
    // If language is obvious and coach is obvious, start session directly
    //

    // pre-selected coach: no coach or exactly one
    const ccoach =
      Object.keys(invitingaccounts).length === 0
        ? ''
        : Object.keys(invitingaccounts)[0];

    if (
      availableLanguages.includes(intl.locale.toUpperCase()) &&
      Object.keys(invitingaccounts).length < 2
    ) {
      // create session
      createSessionInBackend(moduleId, intl.locale.toUpperCase(), ccoach);
    } else {
      // prefered language not available. Ask for language:
      setInvitingCoaches(invitingaccounts);
      setCurrentCoach(ccoach);
      setCurrentModuleId(moduleId);
      setCurrentLanguage(defaultLanguage);
      setCurrentModuleLanguages(availableLanguages);
      setSessionDialogOpen(true);
    }

    return;
  };

  // used in StartSessionDialog
  const onLanguageChange = (language: string) => {
    setCurrentLanguage(language);
  };

  // used in StartSessionDialog
  const onCoachChange = (coachid: string) => {
    setCurrentCoach(coachid);
  };

  //
  // createSessionInBackend
  // send moduleid, the accountid of the coach (if there are more than one
  // who granted access to the module) and the language that is requested
  //
  const createSessionInBackend = (
    moduleid: string,
    language: string,
    coachAccountId: string
  ) => {
    mutate(
      {
        moduleId: moduleid,
        language: language,
        publicRoute: false,
        coachAccountId: coachAccountId,
      },
      {
        onSuccess: (sessionId: string) => {
          navigate(`${AppRoutes.COACHING}/${sessionId}`);
        },
      }
    );
  };

  //
  // Coach or language was not clear and a dialog popped up to ask the coachee
  // which one to choose. Now, the dialog was closed and we may start the session.
  //
  const onCloseSessionDialog = (doit: boolean) => {
    if (doit && currentModuleId !== '') {
      createSessionInBackend(currentModuleId, currentLanguage, currentCoach);
    } else {
      setCurrentModuleId('');
      setCurrentLanguage('');
    }
    setSessionDialogOpen(false);
  };

  const getCardJSX = (row: any) => (
    <ObjectCard
      title={intl.formatMessage({ id: row.modulename })}
      description={intl.formatMessage({ id: row.moduledescription })}
      duration={intl.formatMessage({
        id: row.moduleduration,
      })}
      createdByImages={row.moduleresolvedcoachlogosrc}
      id={row.moduleid}
      image={row.moduleimage}
      imageSrc={row.moduleresolvedsrc}
      imageSize={CardImageSize.MEDIUM}
      cardSize={CardSize.SMALL}
      badgeText={row.badgetext}
      labels={row.modulecategories?.map((cat: string) =>
        intl.formatMessage(categoryTranslations[cat as ModuleCategoryEnum])
      )}
      indicator={
        row.moduleisdefault
          ? CardIndicatorIcon.DEFAULTMODULE
          : row.moduleisshared
          ? CardIndicatorIcon.SHAREDMODULE
          : CardIndicatorIcon.NONE
      }
      indicatorDefaultModuleText={intl.formatMessage({
        id: 'player.reception.modulelist.cards.tooltip.defaultmodule',
        defaultMessage: 'Dieser Chatbot ist ein öffentlich verfügbarer Chatbot',
      })}
      indicatorSharedModuleText={intl.formatMessage({
        id: 'player.reception.modulelist.cards.tooltip.sharedmodule',
        defaultMessage: 'Diesen Chatbot hast du über eine Einladung erhalten',
      })}
      headermenu={
        <>
          <ObjectCardHeaderMenu
            id={row.moduleid}
            buttonTitle={intl.formatMessage({
              id: 'player.reception.modulelist.cards.startsession',
              defaultMessage: 'Chatbot starten',
            })}
            onButtonClick={(moduleid: string) => {
              unhighlight(moduleid);
              startSession(
                moduleid,
                row.modulesupportedlang,
                row.moduledefaultlanguage,
                row.invitingaccounts
              );
            }}
            customMenu={<SessionProtocolsOverlay moduleId={row.moduleid} />}
            customMenuIcon={
              <Tooltip
                arrow
                placement="top"
                title={intl.formatMessage({
                  id: 'player.reception.modulelist.cards.history.tooltip',
                  defaultMessage: 'Vorherige Sessions',
                })}
              >
                <History color="secondary" />
              </Tooltip>
            }
          />
        </>
      }
    />
  );

  const [filteredList, setFilteredList] = useState<any>(rows);

  useEffect(() => {
    setFilteredList(rows);
  }, [rows]);

  const [isTextSearch, setIsTextSearch] = useState<boolean>(false);

  const searchCard = (text: string) => {
    setIsTextSearch(text !== undefined && text.length > 0);
    setFilteredList(
      rows
        .map((row: any) => {
          if (
            intl
              .formatMessage({ id: row.modulename })
              .toLowerCase()
              .includes(text.toLowerCase()) ||
            intl
              .formatMessage({
                id: row.moduledescription,
              })
              .toLowerCase()
              .includes(text.toLowerCase()) ||
            (row.moduleprovider + '').toLowerCase().includes(text.toLowerCase())
          ) {
            return row;
          } else {
            return undefined;
          }
        })
        .filter((row: any) => row !== undefined)
    );
  };

  // do not display any information while list is loading
  if (isLoading) {
    return (
      <>
        {intl.formatMessage({
          id: 'player.reception.modulelist.cards.listisloading',
          defaultMessage: 'Chatbot Themen werden geladen ...',
        })}
      </>
    );
  }

  return (
    <div
      style={{
        width: '100%',
        height: maxHeight,
        maxHeight: maxHeight,
      }}
    >
      {(filteredList.length > 0 || isTextSearch) && (
        <>
          <div
            style={{
              display: 'grid',
              grid: 'auto / 250px auto',
              marginLeft: '30px',
              alignContent: 'baseline',
              alignItems: 'baseline',
            }}
          >
            <FormControl variant="standard">
              <Input
                onFocus={(e) => e.target.select()}
                onChange={(e) => {
                  if (searchCard && e.target.value !== undefined) {
                    searchCard(e.target.value);
                  }
                }}
                id="input-with-icon-adornment"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                }
                autoComplete="off"
                autoCorrect="off"
                placeholder={intl.formatMessage({
                  id: 'player.reception.modulelist.cards.searchbox',
                  defaultMessage: 'Themen durchsuchen ...',
                })}
              />
            </FormControl>
            <Typography
              variant="body2"
              color="#AAAAAA"
              style={{ marginLeft: '40px' }}
            >
              {filteredList.length}/{rows.length}
            </Typography>
          </div>

          <Box
            sx={{
              marginTop: '10px',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            {filteredList.map((row: any, index: never) => {
              //
              return (
                <Box
                  style={{ marginTop: '20px', marginLeft: '10px' }}
                  key={'objectcardlistdiv' + row.moduleid + '_' + index}
                  component="div"
                >
                  {getCardJSX(row)}
                </Box>
              );
            })}
          </Box>
        </>
      )}
      {filteredList.length === 0 && !isTextSearch && (
        <Box sx={{ marginTop: '30px', minWidth: '320px', maxWidth: '600px' }}>
          <Typography>
            {intl.formatMessage({
              id: 'player.reception.modulelist.nomodules.hint',
              defaultMessage:
                'Du siehst keine Chatbot Themen? Normalerweise bekommst du von deinem Coach eine Einladung mit der dir die Chatbot Themen freigeschaltet werden.',
            })}
          </Typography>
          <Typography sx={{ margintop: '10px' }}>
            {intl.formatMessage({
              id: 'player.reception.modulelist.nomodules.hint2',
              defaultMessage:
                'evoach stellt auch frei zugängliche Chatbot Themen zur Verfügung. Wenn du diese freischalten möchtest, klicke auf die Schaltfläche "Öffentliche Chatbots freischalten".',
            })}
          </Typography>
          {
            // link for the following invitation was provided by Rebecca
            // PROD-1804
          }
          <EvoachButton
            onClick={() => {
              navigate(
                `${AppRoutes.INVITATION}/e3969ea3-8f08-400d-832c-7e42f60f97ae`
              );
            }}
            sx={{ marginTop: '20px' }}
          >
            {intl.formatMessage({
              id: 'player.reception.modulelist.nomodules.link',
              defaultMessage: 'Öffentliche Chatbots freischalten',
            })}
          </EvoachButton>
        </Box>
      )}

      <StartSessionDialog
        isOpen={sessionDialogOpen}
        onClose={(close: boolean) => onCloseSessionDialog(close)}
        currentModuleLanguages={currentModuleLanguages}
        currentLanguage={currentLanguage}
        onLanguageChange={onLanguageChange}
        onCoachChange={onCoachChange}
        invitingCoaches={invitingCoaches}
      />
    </div>
  );
};
