import { useQuery } from 'react-query';

import { authorizedGet } from '../authorizedApi';

/**
 * useFetchProgramInstanceSession gets the session for the current user
 *
 * @param {string} programInstanceId
 * @returns
 */
export const useFetchProgramInstanceSession = (
  programInstanceId: string = ''
) => {
  const { isLoading, data, isError, error, refetch } = useQuery<any, Error>(
    `programinstance-session-${programInstanceId}`,
    async () => {
      if (!programInstanceId) {
        return undefined;
      }

      const url = `/programinstance/${programInstanceId}/session`;
      let fetchProgramInstanceSessionCall = authorizedGet(url);

      const response = await fetchProgramInstanceSessionCall();
      const data = await response.json();

      return data;
    },
    {
      retry: false,
    }
  );

  return {
    isLoading,
    isError,
    error,
    sessionData: data,
    refetch,
  };
};
