import {
  evoachDefaultTheme,
  formatDate,
  getTranslatedText,
} from '@evoach/ui-components';
import { Launch } from '@mui/icons-material';
import { Box, Divider, IconButton, Link, Stack } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { AccountContext, RoleEnum } from '../../account';
import { useFetchSessionsQuery } from '../../api';
import { SessionType } from '../../entities/Session';
import { AppRoutes, ReceptionRoutes } from '../../routing/routes';

import { ExportSessionButton } from './customInputs/ExportSessionButton';
import {
  DeleteSessionButton,
  DoNotShareSessionButton,
  ResumeOrViewSessionButton,
  ShareSessionButton,
} from './ListButtons';

export interface SessionProtocolsOverlayProps {
  moduleId: string;
}

export const SessionProtocolsOverlay: React.FC<
  SessionProtocolsOverlayProps
> = ({ moduleId }) => {
  const intl = useIntl();
  const { sessions, isLoading, refetch } = useFetchSessionsQuery({
    moduleid: moduleId,
  });
  const { hasRole } = useContext(AccountContext);
  const navigate = useNavigate();

  const refetchHandler = () => {
    setTimeout(refetch, 500);
  };

  const [numberOfSessionsToBeShown, setNumberOfSessionsToBeShown] = useState(5);

  useEffect(() => () => setNumberOfSessionsToBeShown(5), []);

  const sessionRows = useMemo(() => {
    return sessions
      ? sessions
          .filter((session: any) => session.sessionid)
          .slice(0, numberOfSessionsToBeShown) // only show last 5
          .map((session: any, mindex: number) => {
            return {
              id: mindex,
              sessionname: getTranslatedText(session.metadata.title, intl),
              sessionid: session.sessionid,
              sessiondescription: getTranslatedText(
                session.metadata.description,
                intl
              ),
              sessionnotes: session.coacheenote,
              sessioncreated: formatDate(session.tscreated, intl.locale),
              sessioncreatedRaw: new Date(session.tscreated), // for proper sorting, not displayed
              sessionlastupdated: formatDate(session.tsupdated, intl.locale),
              sessionlastupdatedRaw: new Date(session.tsupdated),
              sessionsharings: session.sessionsharings_sharingid ? 1 : 0, // for proper sorting, not displayed
              sessiontype: session.sessiontype,
              coach: session.coach, // PROD-1670,
              sessionprogress: session.progress,
            };
          })
      : [];
  }, [sessions, intl, numberOfSessionsToBeShown]);

  const hasAtLeastOneSession = !isLoading && sessions.length > 0;

  return (
    <Box
      padding="0px 5px 0px 5px"
      maxWidth="400px"
      sx={{ typography: evoachDefaultTheme.typography.body2 }}
    >
      <>
        {hasAtLeastOneSession ? (
          <>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box padding="4px" textAlign="center" flexGrow={1}>
                {intl.formatMessage({
                  id: 'player.reception.modulelist.cards.sessionoverlay.previoussessions',
                  defaultMessage: 'Vorherige Sessions',
                })}
              </Box>
            </Stack>
            <Divider sx={{ margin: '5px' }} />
            <Box>
              {sessionRows.map((session: any) => (
                <Stack
                  key={session.sessionid}
                  flexDirection="row"
                  alignItems="center"
                  gap="5px"
                  justifyContent="space-between"
                >
                  <Box>
                    <ResumeOrViewSessionButton
                      sessionId={session.sessionid}
                      sessionProgress={session.sessionprogress}
                    />
                  </Box>
                  <Box>
                    {session.sessionsharings === 1 ? (
                      <DoNotShareSessionButton
                        refetchHandler={refetchHandler}
                        sessionId={session.sessionid}
                      />
                    ) : (
                      <ShareSessionButton
                        refetchHandler={refetchHandler}
                        sessionId={session.sessionid}
                        disabled={
                          session.sessiontype ===
                          SessionType.DEFAULT_WITHOUT_INVITATION
                        }
                      />
                    )}
                  </Box>
                  <Box flexGrow={1} minWidth="140px">
                    {session.sessionlastupdated}
                  </Box>
                  <Box flexGrow={2} textAlign="right" minWidth="40px">
                    {session.sessionprogress + '%'}
                  </Box>
                  {hasRole(RoleEnum.EVOACHADMIN) && (
                    <Box>
                      <ExportSessionButton sessionid={session.sessionid} />
                    </Box>
                  )}
                  <Box>
                    <DeleteSessionButton
                      sessionId={session.sessionid}
                      refetchHandler={refetchHandler}
                    />
                  </Box>
                </Stack>
              ))}
            </Box>
            {!isLoading && sessions.length > 5 && (
              <Box width="100%" textAlign="center" margin="5px">
                {numberOfSessionsToBeShown < 10 ? (
                  <Link
                    sx={{ cursor: 'pointer' }}
                    component="button"
                    underline="none"
                    variant="body2"
                    onClick={() =>
                      setNumberOfSessionsToBeShown(
                        numberOfSessionsToBeShown + 5
                      )
                    }
                  >
                    {intl.formatMessage({
                      id: 'player.reception.modulelist.cards.sessionoverlay.more',
                      defaultMessage: 'Mehr laden',
                    })}
                  </Link>
                ) : (
                  <Link
                    sx={{ cursor: 'pointer' }}
                    component="button"
                    underline="none"
                    variant="body2"
                    onClick={() =>
                      navigate(
                        `${AppRoutes.RECEPTION}/${ReceptionRoutes.SESSIONS}`
                      )
                    }
                  >
                    {intl.formatMessage({
                      id: 'player.reception.modulelist.cards.sessionoverlay.allprotocols',
                      defaultMessage: 'Alle Chatbot Protokolle ansehen',
                    })}
                    <IconButton>
                      <Launch fontSize="small" />
                    </IconButton>
                  </Link>
                )}
              </Box>
            )}
          </>
        ) : (
          <Box
            sx={{ typography: evoachDefaultTheme.typography.body2 }}
            margin="15px"
          >
            {intl.formatMessage({
              id: 'player.reception.modulelist.cards.sessionoverlay.nosessions',
              defaultMessage:
                'Sobald du eine Session mit diesem Chatbot gemacht hast, findest du hier die Session Protokolle.',
            })}
          </Box>
        )}
      </>
    </Box>
  );
};
